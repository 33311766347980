import React, { useState, useEffect } from "react";
import {
  SnackbarProvider,
  closeSnackbar,
  enqueueSnackbar,
  useSnackbar,
} from "notistack";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../store/auth/login/actions";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { PageHeader, SnackbarComponent, Widget } from "../../Components";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import theme from "../../Theme";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Modal,
  Typography,
  Divider,
  Grid,
  IconButton,
} from "@mui/material";
import {
  dateSearchRaUsersCompaniesViaApi,
  getShuffledDataViaApi,
  shuffledDataViaApi,
} from "../../store/search/action";
import BusinessIcon from "@mui/icons-material/Business";
import dayjs from "dayjs";
import JSZip from "jszip";
import * as XLSX from "xlsx";
import CloseIcon from "@mui/icons-material/Close";
import GetAppIcon from "@mui/icons-material/GetApp";

dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");

const ChipComponent = ({ member, level, color, AllRATeamCompaniesCount }) => {
  const filteredResult = AllRATeamCompaniesCount?.filter(
    (item) => item.raId === member._id
  );
  const count = filteredResult?.length > 0 ? filteredResult[0].count : 0;
  return (
    <Chip
      label={`${member.userFirstName} ${member.userLastName} - ${count}`}
      size="small"
      avatar={
        <Avatar
          sx={{
            bgcolor:
              level === "senior"
                ? theme.palette.secondary.dark
                : level === "junior"
                ? theme.palette.error.dark
                : theme.palette.success.dark,
            color:
              level === "senior"
                ? `${theme.palette.secondary.contrastText} !important`
                : level === "junior"
                ? `${theme.palette.error.contrastText} !important`
                : `${theme.palette.success.contrastText} !important`,
          }}
        >
          {member.userFirstName[0]}
        </Avatar>
      }
      sx={{ cursor: "default" }}
      color={color}
      variant="outlined"
    />
  );
};

const TeamContainer = ({ members, level, color, AllRATeamCompaniesCount }) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 1,
      padding: 1,
      border: members.length ? "" : "1px dashed #ccc",
      borderRadius: "4px",
      backgroundColor: members.length ? "inherit" : "#f0f0f0",
    }}
  >
    {members.length ? (
      members.map((member, index) => (
        <ChipComponent
          key={member._id}
          member={member}
          level={level}
          color={color}
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ))
    ) : (
      <Typography variant="body2" color="textSecondary">
        No team members
      </Typography>
    )}
  </Box>
);
const getUserById = (userId, allUsers) => {
  return allUsers.find((user) => user._id === userId) || null;
};

const categorizeUsers = (users) => {
  const categories = { senior: [], junior: [], fresher: [] };
  users.forEach((user) => {
    if (user.userLevel && (user.userLevel.toLowerCase() === "senior"||user.userLevel.toLowerCase()==="manager" )) {
      categories.senior.push(user);
    } else if (user.userLevel && user.userLevel.toLowerCase() === "junior") {
      categories.junior.push(user);
    } else if (user.userLevel && user.userLevel.toLowerCase() === "fresher") {
      categories.fresher.push(user);
    }
  });
  return categories;
};

const transformData = (shuffledData, allUsers) => {
  return shuffledData.map((bdm) => {
    const bdmDetails = getUserById(bdm.BdmId, allUsers);
    const assignedUsers = bdm.assignUsers
      .map((userId) => getUserById(userId, allUsers))
      .filter((user) => user !== null);
    const categorizedUsers = categorizeUsers(assignedUsers);
    return {
      bdm: bdmDetails,
      assignedRATeam: categorizedUsers,
    };
  });
};
const BdmDownload = () => {
  const [assignments, setAssignments] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const storeData = useSelector((state) => state.Login.storeData) || [];
  const client=storeData.clientDb
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const AllCompaniesListRedux= useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
  let AllCompaniesList =Array.isArray(AllCompaniesListRedux)?AllCompaniesListRedux:[]
    
  const shuffledDataRedux = useSelector((state) => state.Search.shuffled) || [];
  const [transformedData, setTransformedData] = useState([]);

  
  useEffect(() => {
    if (shuffledDataRedux.length > 0 && allUsers.length > 0) {
      const latestShuffledData = shuffledDataRedux[0]?.shuffled;
      if (latestShuffledData && latestShuffledData.length > 0) {
        const latestData = latestShuffledData[latestShuffledData.length - 1];
        const transformed = transformData(latestData.shuffledData, allUsers);
        setTransformedData(transformed);
      }
    }
  }, [shuffledDataRedux, allUsers]);

  let AlluserComapnies = Array.isArray(AllCompaniesList)
    ? AllCompaniesList.reduce((acc, companies) => {
        const useraddedBy = companies.addedBy;
        const existingGroupIndex = acc.findIndex(
          (group) => group.useraddedBy === useraddedBy
        );
        if (existingGroupIndex !== -1) {
          acc[existingGroupIndex].companies.push(companies);
        } else {
          acc.push({ useraddedBy, companies: [companies] });
        }
        return acc;
      }, []).map((group) => ({
        raName: group.useraddedBy,
        companies: group.companies,
      }))
    : [];
  const mergedData = AlluserComapnies.map((group) => {
    const userData = allUsers.find((user) => user._id === group.raName);
    if (userData) {
      return {
        raID: userData._id,
        raName: userData.userFirstName,
        userSudoFirstName: userData.userSudoFirstName,
        emailid: userData.userEmailId,
        position: userData.userLevel,
        companies: group.companies.map((company) => {
          return {
            ...company,
            addedBy: userData.userFirstName, 
          };
        }),
      };
    } else {
      return group;
    }
  });

  const companiesListWithIds = Array.isArray(mergedData)
    ? mergedData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];

    const RATeamAccounts = allUsers.filter(user => user.userType.includes('RATeam') && user.status !== "Inactive"
      &&user.userEmailId !== "ratest@tektreeinc.com"&&
      user.userEmailId !== "lilyra@tektreeinc.com" &&
      user.userEmailId !== "oliviara@tektreeinc.com" &&
      user.userEmailId !== "emmara@tektreeinc.com" &&
      user.userEmailId !== "charlottera@tektreeinc.com" &&
      user.userEmailId !== "danielra@tektreeinc.com" &&
      user.userEmailId !== "miara@tektreeinc.com" &&
      user.userEmailId !== "williamra@tektreellc.com" );
    const AllRATeamCompaniesCount = RATeamAccounts.map(user => {
      const count = AllCompaniesList.reduce((acc, company) => {
        if (user._id.toString() === company.addedBy.toString()) {
          return acc + (company.contactDetails?.filter(contact => contact.emailId).length || 0);
        }
        return acc;
      }, 0);
      return { raId: user._id, count };
    });

  let TotalEmailCount = 0;
  if (AllRATeamCompaniesCount) {
    TotalEmailCount = AllRATeamCompaniesCount.reduce(
      (total, count) => total + count.count,
      0
    );
  }

  const dispatch = useDispatch();

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, [dispatch]);
  useEffect(() => {
     const action={
      client:client
     }
    dispatch(getShuffledDataViaApi(action));
  }, []);

  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client,
      };

     
      dispatch(dateSearchRaUsersCompaniesViaApi(action));
    }
  };
  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
  }, []);
  const activeBDMs = allUsers.filter(
    (user) => user.userType.includes("BDM") && user.userStatus === "Active" &&
    user.userEmailId !== "emily.johnson@tektreecorp.com"&&
    user.userEmailId !== "olivia.clark@tektreellc.com"&&
    user.userEmailId !== "emma.scott@tektreellc.com"&&
    user.userEmailId !== "william.turner@tektreellc.com"&&
    user.userEmailId !== "charlotte.mitchell@tektreellc.com"&&
    user.userEmailId !== "daniel.clark@tektreellc.com"
  );
 
  const RATeamMembers = allUsers.filter(
    (user) =>
      user.userType.includes("RATeam")&&
      user.userStatus === "Active"
  );
  const SeniourCount = RATeamAccounts.filter(
    (user) => user.userLevel === "Senior"||user.userLevel==="Manager"
  );

  const JuniorCount = RATeamMembers.filter(
    (user) => user.userLevel === "Junior"
  );
  const FresherCount = RATeamMembers.filter(
    (user) => user.userLevel === "Fresher"
  );
  const filterResultCount = (team) => {
    return team.reduce((total, member) => {
      const filteredResult = AllRATeamCompaniesCount?.find(
        (item) => item.raId === member._id
      );
      return total + (filteredResult ? filteredResult.count : 0);
    }, 0);
  };

  const calculateTotalValue = (row) => {
    const totalSenior = filterResultCount(row.seniorTeam || []);
    const totalJunior = filterResultCount(row.juniorTeam || []);
    const totalFresher = filterResultCount(row.fresherTeam || []);
    return totalSenior + totalJunior + totalFresher;
  };
  const columns = [
    {
      field: "bdm",
      headerName: "BDM",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {params.value?.userSudoFirstName} {params.value?.userSudoLastName}
          </Typography>
          <Typography variant="body2">
            {params.value.userSudoEmailId}
          </Typography>
        </Box>
      ),
    },
    {
      field: "seniorTeam",
      headerName: "Senior Team",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <TeamContainer
          members={params.value}
          level="senior"
          color="secondary"
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ),
    },
    {
      field: "juniorTeam",
      headerName: "Junior Team",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <TeamContainer
          members={params.value}
          level="junior"
          color="error"
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ),
    },
    {
      field: "fresherTeam",
      headerName: "Fresher Team",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <TeamContainer
          members={params.value}
          level="fresher"
          color="success"
          AllRATeamCompaniesCount={AllRATeamCompaniesCount}
        />
      ),
    },
    {
      field: "count",
      headerName: "Total Emails Count",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography>{calculateTotalValue(params.row)}</Typography>
      ),
    },
    {
      field: "actions",
      headerName: "Download",
      width: 88,
      sortable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IconButton
            onClick={() =>
              handleDownloadModalOpen("actionDownload", params.row)
            }
          >
            <GetAppIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows = transformedData.map((assignment, index) => ({
    id: index,
    bdm: assignment.bdm,
    seniorTeam: assignment.assignedRATeam.senior || [],
    juniorTeam: assignment.assignedRATeam.junior || [],
    fresherTeam: assignment.assignedRATeam.fresher || [],
  }));

  const [saveModal, setSaveModal] = useState(false);
  const handleSaveModalOpne = () => {
    setSaveModal(!saveModal);
  };

  const shuffledData = [];

  // Iterate over each entry in the data
  transformedData.forEach((entry) => {
    // Extract BDM email
    const bdmFirstName = entry.bdm && entry.bdm.userFirstName;
    const bdmpseudoEmail = entry.bdm && entry.bdm.userEmailId;
    const bdmId = entry.bdm && entry.bdm._id;

    // Initialize assignUsers array for this BDM
    const assignUsers = [];

    // Extract senior, junior, and fresher RA Team members
    const seniors = entry.assignedRATeam.senior.map((member) => member._id);
    const juniors = entry.assignedRATeam.junior.map((member) => member._id);
    const freshers = entry.assignedRATeam.fresher.map((member) => member._id);

    // Push senior, junior, and fresher IDs to assignUsers array
    assignUsers.push(...seniors, ...juniors, ...freshers);

    // Push BDM email and assignUsers array to shuffledData
    shuffledData.push({ BdmId: bdmId, bdmName: bdmFirstName, assignUsers });
  });
  const handleSaveSuffleData = () => {
    if (shuffledData.length > 0) {
      const action = {
        addedBy: storeData._id,
        addedOn: new Date(),
        shuffledData,
        client:client
      };

      dispatch(shuffledDataViaApi(action));
      handleSaveModalOpne();
    } else {
      alert("please assign the suffeled data");
    }
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [downloadModalOpen, setdownloadModalOpen] = useState(false);
  const [selectDownloadType, setSelectDownloadType] = useState("");
  const [clickDownloadType, setClickDownloadType] = useState("");
  const [clickDownloadAction, setClickDownloadAction] = useState("");
  const handleDownloadModalOpen = (value, data) => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType(value);
    if (value === "actionDownload") {
      const shuffledData = [];
      const bdmFirstName = data.bdm.userFirstName;
      const assignUsers = [];

      const seniors = data.seniorTeam.map((member) => member._id);
      const juniors = data.juniorTeam.map((member) => member._id);
      const freshers = data.fresherTeam.map((member) => member._id);

      assignUsers.push(...seniors, ...juniors, ...freshers);

      shuffledData.push({ bdmName: bdmFirstName, assignUsers });
      setClickDownloadAction(shuffledData);
    }
  };

  const handleRowDownload = (rowData, dbmName) => {
    const csv = convertToCSV(rowData);

    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `${dbmName}_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function convertToCSV(company) {
    const headers = [
      "assignBDMName",
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";
    company.forEach((data) => {
      // Add data rows to CSV
      data.companies.forEach((company) => {
        // Add job details if available
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          // Add contact details if available
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              csv += `"${data.bdmName || ""}","${data.raName || ""}","${
                company.companyName || ""
              }","${company.comment || ""}","${job.jobTitle || ""}","${
                job.jobLocation || ""
              }","${job.jobDescription || ""}","${job.jobWebsite || ""}","${
                contact.firstName || ""
              }","${contact.lastName || ""}","${contact.designation || ""}","${
                contact.emailId || ""
              }","${contact.phoneNumber || ""}"\n`;
            });
          } else {
            // Add a row with empty contact details if no contact details are available
            csv += `"${data.bdmName || ""}","${data.raName || ""}","${
              company.companyName || ""
            }","${company.comment || ""}","${job.jobTitle || ""}","${
              job.jobLocation || ""
            }","${job.jobDescription || ""}","${
              job.jobWebsite || ""
            }","","","","",""\n`;
          }
        } else {
          // Add a row with empty job details if no job details are available
          csv += `"${data.dbmName || ""}","${data.raName || ""}","${
            company.companyName || ""
          }","${company.comment || ""}","","","","","","","","",""\n`;
        }
      });
    });
    return csv;
  }

  function convertToCSVSingle(data) {
    const headers = [
      "assignBDMName",
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";

    // Add data rows to CSV
    data.companies.forEach((company) => {
      // Add job details if available
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        // Add contact details if available
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            csv += `"${data.bdmName || ""}","${data.raName || ""}","${
              company.companyName || ""
            }","${company.comment || ""}","${job.jobTitle || ""}","${
              job.jobLocation || ""
            }","${job.jobDescription || ""}","${job.jobWebsite || ""}","${
              contact.firstName || ""
            }","${contact.lastName || ""}","${contact.designation || ""}","${
              contact.emailId || ""
            }","${contact.phoneNumber || ""}"\n`;
          });
        } else {
          // Add a row with empty contact details if no contact details are available
          csv += `"${data.bdmName || ""}","${data.raName || ""}","${
            company.companyName || ""
          }","${company.comment || ""}","${job.jobTitle || ""}","${
            job.jobLocation || ""
          }","${job.jobDescription || ""}","${
            job.jobWebsite || ""
          }","","","","",""\n`;
        }
      } else {
        // Add a row with empty job details if no job details are available
        csv += `"${data.dbmName || ""}","${data.raName || ""}","${
          company.companyName || ""
        }","${company.comment || ""}","","","","","","","","",""\n`;
      }
    });

    return csv;
  }

  const handleDownloadAll = () => {
    const currentDate = new Date().toISOString().slice(0, 10);
    const zip = new JSZip();
    let dataAssign = [];
    if (selectedRows.length > 0) {
      const selectShuffledData = selectedRows.map((item) => {
        const bdmFirstName = item.bdm.userFirstName;

        const seniors = item.seniorTeam.map((member) => member._id);
        const juniors = item.juniorTeam.map((member) => member._id);
        const freshers = item.fresherTeam.map((member) => member._id);

        const assignUsers = [...seniors, ...juniors, ...freshers];

        return { bdmName: bdmFirstName, assignUsers };
      });
      selectShuffledData.forEach((user) => {
        user.assignUsers.forEach((userID) => {
          companiesListWithIds.forEach((company) => {
            if (company.raID === userID) {
              dataAssign.push({
                bdmName: user.bdmName,
                ...company,
              });
            }
          });
        });
      });

      const transformData = (data) => {
        let result = [];
        let currentBDM = null;

        data.forEach((record) => {
          if (!currentBDM || currentBDM.bdmName !== record.bdmName) {
            currentBDM = {
              bdmName: record.bdmName,
              records: [],
            };
            result.push(currentBDM);
          }
          currentBDM.records.push(record);
        });

        return result;
      };

      const transformedData = transformData(dataAssign);
      transformedData.forEach((company) => {
        const csv = convertToCSV(company.records);
        const fileName = `${company.bdmName}_${currentDate}.csv`;
        zip.file(fileName, csv);
      });

      zip.generateAsync({ type: "blob" }).then((content) => {
        // Create a download link for the ZIP file
        const zipFileName = `AllRATeamData_${currentDate}.zip`;
        const url = window.URL.createObjectURL(content);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", zipFileName);
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        document.body.removeChild(link);
      });
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        shuffledData.forEach((user) => {
          user.assignUsers.forEach((userID) => {
            companiesListWithIds.forEach((company) => {
              if (company.raID === userID) {
                dataAssign.push({
                  bdmName: user.bdmName,
                  ...company,
                });
              }
            });
          });
        });

        const transformData = (data) => {
          let result = [];
          let currentBDM = null;

          data.forEach((record) => {
            if (!currentBDM || currentBDM.bdmName !== record.bdmName) {
              currentBDM = {
                bdmName: record.bdmName,
                records: [],
              };
              result.push(currentBDM);
            }
            currentBDM.records.push(record);
          });

          return result;
        };

        const transformedData = transformData(dataAssign);
        transformedData.forEach((company) => {
          const csv = convertToCSV(company.records);
          const fileName = `${company.bdmName}_${currentDate}.csv`;
          zip.file(fileName, csv);
        });
        zip.generateAsync({ type: "blob" }).then((content) => {
          // Create a download link for the ZIP file
          const zipFileName = `AllRATeamData_${currentDate}.zip`;
          const url = window.URL.createObjectURL(content);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", zipFileName);
          document.body.appendChild(link);
          // Trigger the download
          link.click();
          document.body.removeChild(link);
        });
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownload = (rowData) => {
    const csv = ALLconvertToCSV(rowData);
    const currentDate = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
    const fileName = `AllRATeamSheet_${currentDate}.csv`;
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  function ALLconvertToCSV(company) {
    const headers = [
      "assignByBdmName",
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    // Add headers to CSV
    let csv = headers.join(",") + "\n";
    company.forEach((data) => {
      // Add data rows to CSV
      data.companies.forEach((company) => {
        // Add job details if available
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          // Add contact details if available
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              csv += `"${data.bdmName || ""}","${data.raName || ""}","${
                company.companyName || ""
              }","${company.comment || ""}","${job.jobTitle || ""}","${
                job.jobLocation || ""
              }","${job.jobDescription || ""}","${job.jobWebsite || ""}","${
                contact.firstName || ""
              }","${contact.lastName || ""}","${contact.designation || ""}","${
                contact.emailId || ""
              }","${contact.phoneNumber || ""}"\n`;
            });
          } else {
            // Add a row with empty contact details if no contact details are available
            csv += `"${data.bdmName || ""}","${data.raName || ""}","${
              company.companyName || ""
            }","${company.comment || ""}","${job.jobTitle || ""}","${
              job.jobLocation || ""
            }","${job.jobDescription || ""}","${
              job.jobWebsite || ""
            }","","","","",""\n`;
          }
        } else {
          // Add a row with empty job details if no job details are available
          csv += `"${data.bdmName || ""}","${data.raName || ""}","${
            company.companyName || ""
          }","${company.comment || ""}","","","","","","","","",""\n`;
        }
      });
    });
    return csv;
  }
  const handleDownloadAllOneFile = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      let dataAssign = [];
      shuffledData.forEach((user) => {
        user.assignUsers.forEach((userID) => {
          companiesListWithIds.forEach((company) => {
            if (company.raID === userID) {
              dataAssign.push({
                bdmName: user.bdmName,
                ...company,
              });
            }
          });
        });
      });

      handleAllRowDownload(dataAssign);
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleRowDownloadExcel = (rowData, dbmName) => {
    // Convert the data to the same format as the CSV function
    const formattedData = convertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `${dbmName}_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };
  const convertToJSON = (dataJson) => {
    const headers = [
      "assignBDMName",
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];

    dataJson.map((data) => {
      data.companies.forEach((company) => {
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              json.push({
                assignBDMName: data.bdmName || "",
                addedByRaName: data.raName || "",
                companyName: company.companyName || "",
                comment: company.comment || "",
                jobTitle: job.jobTitle || "",
                jobLocation: job.jobLocation || "",
                jobDescription: job.jobDescription || "",
                jobWebsite: job.jobWebsite || "",
                contactFirstName: contact.firstName || "",
                contactLastName: contact.lastName || "",
                contactDesignation: contact.designation || "",
                contactEmail: contact.emailId || "",
                contactPhoneNumber: contact.phoneNumber || "",
              });
            });
          } else {
            json.push({
              assignBDMName: data.bdmName || "",
              addedByRaName: data.raName || "",
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: "",
              contactLastName: "",
              contactDesignation: "",
              contactEmail: "",
              contactPhoneNumber: "",
            });
          }
        } else {
          json.push({
            assignBDMName: data.bdmName || "",
            addedByRaName: data.raName || "",
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: "",
            jobLocation: "",
            jobDescription: "",
            jobWebsite: "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      });
    });
    return json;
  };

  const convertToJSONSingle = (data) => {
    const headers = [
      "assignBDMName",
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    data.companies.forEach((company) => {
      if (company.jobDetails && company.jobDetails.length > 0) {
        const job = company.jobDetails[0];
        if (company.contactDetails && company.contactDetails.length > 0) {
          company.contactDetails.forEach((contact) => {
            json.push({
              assignBDMName: data.bdmName || "",
              addedByRaName: data.raName || "",
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: contact.firstName || "",
              contactLastName: contact.lastName || "",
              contactDesignation: contact.designation || "",
              contactEmail: contact.emailId || "",
              contactPhoneNumber: contact.phoneNumber || "",
            });
          });
        } else {
          json.push({
            assignBDMName: data.bdmName || "",
            addedByRaName: data.raName || "",
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: job.jobTitle || "",
            jobLocation: job.jobLocation || "",
            jobDescription: job.jobDescription || "",
            jobWebsite: job.jobWebsite || "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      } else {
        json.push({
          assignBDMName: data.bdmName || "",
          addedByRaName: data.raName || "",
          companyName: company.companyName || "",
          comment: company.comment || "",
          jobTitle: "",
          jobLocation: "",
          jobDescription: "",
          jobWebsite: "",
          contactFirstName: "",
          contactLastName: "",
          contactDesignation: "",
          contactEmail: "",
          contactPhoneNumber: "",
        });
      }
    });
    return json;
  };
  const createExcelZipFile = (excelData) => {
    // Create a new instance of JSZip
    const zip = new JSZip();

    // Add each Excel file to the ZIP archive
    excelData.forEach((data, index) => {
      const currentDate = new Date().toISOString().slice(0, 10);
      const fileName = `${data.bdmName}_${currentDate}.xlsx`;

      // Create a worksheet from the formatted data
      const worksheet = XLSX.utils.json_to_sheet(data.json);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

      // Generate the file as a binary string
      const workbookBinary = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });

      // Convert the binary string to an array buffer
      const buffer = new ArrayBuffer(workbookBinary.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < workbookBinary.length; i++) {
        view[i] = workbookBinary.charCodeAt(i) & 0xff;
      }

      // Add the file to the ZIP archive
      zip.file(fileName, buffer);
    });

    // Generate the ZIP file asynchronously
    zip.generateAsync({ type: "blob" }).then((content) => {
      // Create a download link for the ZIP file
      const currentDate = new Date().toISOString().slice(0, 10);
      const zipFileName = `RATeamData_${currentDate}.zip`;
      const url = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", zipFileName);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      document.body.removeChild(link);
    });
  };
  const handleDownloadAllExcel = () => {
    let dataAssign = [];
    if (selectedRows.length > 0) {
      const excelData = [];

      const selectShuffledData = selectedRows.map((item) => {
        const bdmFirstName = item.bdm.userFirstName;

        const seniors = item.seniorTeam.map((member) => member._id);
        const juniors = item.juniorTeam.map((member) => member._id);
        const freshers = item.fresherTeam.map((member) => member._id);

        const assignUsers = [...seniors, ...juniors, ...freshers];

        return { bdmName: bdmFirstName, assignUsers };
      });
      selectShuffledData.forEach((user) => {
        user.assignUsers.forEach((userID) => {
          companiesListWithIds.forEach((company) => {
            if (company.raID === userID) {
              dataAssign.push({
                bdmName: user.bdmName,
                ...company,
              });
            }
          });
        });
      });
      const transformData = (data) => {
        let result = [];
        let currentBDM = null;

        data.forEach((record) => {
          if (!currentBDM || currentBDM.bdmName !== record.bdmName) {
            currentBDM = {
              bdmName: record.bdmName,
              records: [],
            };
            result.push(currentBDM);
          }
          currentBDM.records.push(record);
        });

        return result;
      };

      const transformedData = transformData(dataAssign);

      transformedData.forEach((company) => {
        const formattedData = convertToJSON(company.records);
        excelData.push({ json: formattedData, bdmName: company.bdmName });
      });

      createExcelZipFile(excelData);
    } else {
      if (companiesListWithIds && companiesListWithIds.length > 0) {
        const excelData = [];

        shuffledData.forEach((user) => {
          user.assignUsers.forEach((userID) => {
            companiesListWithIds.forEach((company) => {
              if (company.raID === userID) {
                dataAssign.push({
                  bdmName: user.bdmName,
                  ...company,
                });
              }
            });
          });
        });
        const transformData = (data) => {
          let result = [];
          let currentBDM = null;

          data.forEach((record) => {
            if (!currentBDM || currentBDM.bdmName !== record.bdmName) {
              currentBDM = {
                bdmName: record.bdmName,
                records: [],
              };
              result.push(currentBDM);
            }
            currentBDM.records.push(record);
          });

          return result;
        };
        const transformedData = transformData(dataAssign);
        transformedData.forEach((company) => {
          const formattedData = convertToJSON(company.records);
          excelData.push({ json: formattedData, bdmName: company.bdmName });
        });

        createExcelZipFile(excelData);
      } else {
        enqueueSnackbar(
          `Today's data is unavailable and cannot be downloaded.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
      }
    }
  };

  const handleAllRowDownloadExcel = (rowData) => {
    // Convert the data to the same format as the CSV function
    const formattedData = ALLconvertToJSON(rowData);

    // Create a worksheet from the formatted data
    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "AllData");

    // Generate the file name with the current date
    const currentDate = new Date().toISOString().slice(0, 10);
    const fileName = `AllRATeamSheet_${currentDate}.xlsx`;

    // Write the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };

  // Function to convert the data to the same format as the CSV function
  const ALLconvertToJSON = (company) => {
    const headers = [
      "assignBdmName",
      "addedByRaName",
      "companyName",
      "comment",
      "jobTitle",
      "jobLocation",
      "jobDescription",
      "jobWebsite",
      "contactFirstName",
      "contactLastName",
      "contactDesignation",
      "contactEmail",
      "contactPhoneNumber",
    ];

    const json = [];
    company.forEach((data) => {
      data.companies.forEach((company) => {
        if (company.jobDetails && company.jobDetails.length > 0) {
          const job = company.jobDetails[0];
          if (company.contactDetails && company.contactDetails.length > 0) {
            company.contactDetails.forEach((contact) => {
              json.push({
                assignBdmName: data.bdmName || "",
                addedByRaName: data.raName || "",
                companyName: company.companyName || "",
                comment: company.comment || "",
                jobTitle: job.jobTitle || "",
                jobLocation: job.jobLocation || "",
                jobDescription: job.jobDescription || "",
                jobWebsite: job.jobWebsite || "",
                contactFirstName: contact.firstName || "",
                contactLastName: contact.lastName || "",
                contactDesignation: contact.designation || "",
                contactEmail: contact.emailId || "",
                contactPhoneNumber: contact.phoneNumber || "",
              });
            });
          } else {
            json.push({
              assignBdmName: data.bdmName || "",
              addedByRaName: data.raName || "",
              companyName: company.companyName || "",
              comment: company.comment || "",
              jobTitle: job.jobTitle || "",
              jobLocation: job.jobLocation || "",
              jobDescription: job.jobDescription || "",
              jobWebsite: job.jobWebsite || "",
              contactFirstName: "",
              contactLastName: "",
              contactDesignation: "",
              contactEmail: "",
              contactPhoneNumber: "",
            });
          }
        } else {
          json.push({
            assignBdmName: data.bdmName || "",
            addedByRaName: data.raName || "",
            companyName: company.companyName || "",
            comment: company.comment || "",
            jobTitle: "",
            jobLocation: "",
            jobDescription: "",
            jobWebsite: "",
            contactFirstName: "",
            contactLastName: "",
            contactDesignation: "",
            contactEmail: "",
            contactPhoneNumber: "",
          });
        }
      });
    });
    return json;
  };

  const handleDownloadAllOneFileXlSX = () => {
    if (companiesListWithIds && companiesListWithIds.length > 0) {
      let dataAssign = [];
      shuffledData.forEach((user) => {
        user.assignUsers.forEach((userID) => {
          companiesListWithIds.forEach((company) => {
            if (company.raID === userID) {
              dataAssign.push({
                bdmName: user.bdmName,
                ...company,
              });
            }
          });
        });
      });
      handleAllRowDownloadExcel(dataAssign);
    } else {
      enqueueSnackbar(`Today's data is unavailable and cannot be downloaded.`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };

  const handleSelectDownloadType = (value) => {
    setSelectDownloadType(value);
  };
  const handleDownload = () => {
    let dataAssign = [];
    let dbmName = "";
    if (selectDownloadType === ".csv") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAll();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFile();
      } else if (clickDownloadType === "actionDownload") {
        clickDownloadAction.forEach((user) => {
          user.assignUsers.forEach((userID) => {
            companiesListWithIds.forEach((company) => {
              if (company.raID === userID) {
                dataAssign.push({
                  bdmName: user.bdmName,
                  ...company,
                });
                dbmName = user.bdmName;
              }
            });
          });
        });
        handleRowDownload(dataAssign, dbmName);
      }
    } else if (selectDownloadType === ".xlsx") {
      if (clickDownloadType === "downloadAll") {
        handleDownloadAllExcel();
      } else if (clickDownloadType === "downloadAllOneSheet") {
        handleDownloadAllOneFileXlSX();
      } else if (clickDownloadType === "actionDownload") {
        clickDownloadAction.forEach((user) => {
          user.assignUsers.forEach((userID) => {
            companiesListWithIds.forEach((company) => {
              if (company.raID === userID) {
                dataAssign.push({
                  bdmName: user.bdmName,
                  ...company,
                });
                dbmName = user.bdmName;
              }
            });
          });
        });
        handleRowDownloadExcel(dataAssign, dbmName);
      }
    } else if (selectDownloadType === "") {
      enqueueSnackbar(`Please select one atleast one option`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };
  const handleCancelDownload = () => {
    setdownloadModalOpen(!downloadModalOpen);
    setClickDownloadType("");
    setSelectDownloadType("");
    setClickDownloadAction("");
  };
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <DndProvider backend={HTML5Backend}>
        <LayoutSidebarMenu pageTitle="Assign RA Team to BDMs">
          <Grid
            container
            columnSpacing={2}
            columns={{ xs: 12, sm: 12, md: 15, lg: 15 }}
          >
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Total BDM's"
                value={activeBDMs.length}
                icon={<BusinessIcon />}
                color="primary"
                // variant="contained"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Seniour RA"
                value={SeniourCount.length}
                icon={<BusinessIcon />}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Juniour RA"
                value={JuniorCount.length}
                icon={<BusinessIcon />}
                color="error"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Fresher RA"
                value={FresherCount.length}
                icon={<BusinessIcon />}
                color="success"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={3}>
              <Widget
                title="Emails Count"
                value={TotalEmailCount}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <DataGrid
              rows={rows}
              columns={columns}
              disableRowSelectionOnClick
              autoHeight
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 0.5,
                },
              }}
              checkboxSelection
              onRowSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                const selectedRows = rows.filter((row) =>
                  selectedIDs.has(row.id)
                );

                setSelectedRows(selectedRows);
              }}
            />
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <Button
              variant="contained"
              onClick={() => handleDownloadModalOpen("downloadAll")}
            >
              Download All
            </Button>
            <Button
              variant="contained"
              onClick={() => handleDownloadModalOpen("downloadAllOneSheet")}
            >
              Download All In One Sheet
            </Button>
          </Box>
        </LayoutSidebarMenu>
      </DndProvider>
      <Modal
        open={saveModal}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to save shuffeled data!
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveModalOpne()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveSuffleData()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={downloadModalOpen}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Select your preferred download format
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              variant="contained"
              color={selectDownloadType === ".xlsx" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".xlsx")}
            >
              .xlsx
            </Button>
            <Button
              variant="contained"
              color={selectDownloadType === ".csv" ? "error" : "inherit"}
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectDownloadType(".csv")}
            >
              .csv
            </Button>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 2 }}>
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleCancelDownload()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleDownload()}
            >
              Download
            </Button>
          </Box>
        </Box>
      </Modal>
      <SnackbarComponent />
    </SnackbarProvider>
  );
};

export default BdmDownload;
