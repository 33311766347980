import React, { useEffect, useRef, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomFormField, MainBackground, SectionTitle } from "..";
import theme from "../../Theme";
import {
  addJobBoardsHistoryViaApi,
  clearSearchResults,
  deleteJobBoardsHistoryViaApi,
  getIntegrationsViaApi,
  getJobBoardsHistoryViaApi,
  getjobBoardsStatusHistoryViaApi,
  monsterAdvancedResumeSearchViaApi,
  saveJobBoardsHistoryViaApi,
  stopLoadingRedux,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Monster from "../JobBoardsResult/Monster";
import {
  countries,
  workAuthorizationOptions,
  MonsterRadius,
  optionsMonsterPostFrom,
  optionsMonsterTo,
  countryMonsterOptions,
  MonsterRadiusOptions,
  MonsterEducationLevel,
  salaryTypeMonsterOptions,
  LanguageskillsMonster,
  ProfessionalLevelMonsterOptions,
  careerLevelMonster,
  indusriesMonsterOptions,
  statesArrayOptionMonster,
} from "../../Data/JobPostingData";
import { Parser } from "papaparse";
import { debounce, size, values } from "lodash";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";
const ChipSet = ({
  title,
  value,
  color = "default", // default,error,info,primary,secondary,success,warning
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <Typography fontSize={13}>{title} :</Typography>
      <Chip label={value} size="small" color={color} />
    </Box>
  );
};
const JobBoardCareerBuilder = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const MonsterResumesList = useSelector(
    (state) => state.Resumes.monsterResumesList
  );
  const JobBoardHistory = useSelector((state) => state.JobBoards.jobBoardsHistory);
const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
  ? JobBoardHistory.filter((job) => job.searchType === "Monster"&& job.status === "recent")
  : [];
  const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
  ? JobBoardHistory.filter((job) => job.searchType === "Monster" && job.status === "saved")
  : [];
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [showOptions, setShowOptions] = useState(false);
  const storeData = useSelector((state) => state.Login.storeData);
  const integrationsList = useSelector((state) => state.Resumes.integrations);
  const client = storeData.clientDb;
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };
  let matched = 0;
  function parseCandidatedData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      MonsterResumesList,
      "application/xml"
    ); // Ensure the correct source
    // Select all resumes in the XML document
    const resumes = xmlDoc.getElementsByTagName("Resume");
    // Transform resumes into JSON format
    const candidates = Array.from(resumes).map((resume, index) => {
      const firstName = resume.querySelector("First")?.textContent || "";
      const lastName = resume.querySelector("Last")?.textContent || "";
      const middleName = resume.querySelector("Middle")?.textContent || "";
      const email =
        resume.querySelector("MD5-EmailAddress")?.textContent || "N/A";
      const board = resume.querySelector("Board");
      const City = resume.querySelector("City")?.textContent || "N/A";
      // Get the Location element
      const locationElement = xmlDoc.querySelector("Location");
      if (!locationElement) {
        return {
          country: "N/A",
          state: "N/A",
          city: "N/A",
        }; // Return N/A if Location element is not found
      }

      // Extract the location string (e.g., "US-FL-Largo")
      const location = locationElement.textContent || "";
      const [country, state, city] = location.split("-");
      const Relocation =
        resume.querySelector("Relocation")?.textContent || "N/A";
      const Title = resume.querySelector("Title")?.textContent || "N/A";
      const Description =
        resume.querySelector("Description")?.textContent || "N/A";
      const DateModified =
        resume.querySelector("DateModified")?.textContent || "N/A";
      const resumesNode = xmlDoc.getElementsByTagName("Resumes")[0];
      const foundCount = resumesNode.getAttribute("Found");
      const jobTypeElements = xmlDoc.getElementsByTagName("JobType");
      const companyName =
        resume.querySelector("Company > Name")?.textContent || "N/A";
      // Extract the text content of each JobType
      const jobTypes = Array.from(jobTypeElements).map(
        (jobType) => jobType.textContent
      );

      // Format the desired output
      const desiredJobTypes = `Desired Job Type: ${jobTypes.join(", ")}`;
      const educationElements = resume.getElementsByTagName("Education");

      // Extract the Level text content
      const educationLevels = Array.from(educationElements)
        .map((education) => {
          const level = education.querySelector("Level");
          return level ? level.textContent : null; // Return null if Level is not found
        })
        .filter((level) => level !== null); // Filter out any null values
      const salaryElement = xmlDoc.querySelector("Salary");
      if (!salaryElement) {
        return "N/A"; // Return if Salary element is not found
      }
      // Extract DateFrom and DateTo elements
      const dateFromElement = xmlDoc.querySelector("DateFrom");
      const dateToElement = xmlDoc.querySelector("DateTo");

      // If either date is missing, return 0 years and 0 months
      if (!dateFromElement || !dateToElement) {
        return { years: 0, months: 0 };
      }

      // Extract Year and Month values
      const yearFrom = parseInt(dateFromElement.getAttribute("Year"), 10);
      const monthFrom = parseInt(dateFromElement.getAttribute("Month"), 10);
      const yearTo = parseInt(dateToElement.getAttribute("Year"), 10);
      const monthTo = parseInt(dateToElement.getAttribute("Month"), 10);

      // Calculate the difference in years and months
      let years = yearTo - yearFrom;
      let months = monthTo - monthFrom;

      // Adjust for negative months
      if (months < 0) {
        years--;
        months += 12;
      }

      // Extract Min, Max, Currency, and Type
      const minSalary = salaryElement.querySelector("Min")?.textContent || "0";
      const maxSalary = salaryElement.querySelector("Max")?.textContent || "0";
      const currency =
        salaryElement.querySelector("Currency")?.textContent || "N/A";
      const type = salaryElement.querySelector("Type")?.textContent || "N/A";
      // Get the WorkAuth element
      const workAuthElement = resume.querySelector("WorkAuth");
      const Authorization =
        workAuthElement?.querySelector("Country")?.textContent || "";

      const resumeElement = xmlDoc.querySelector("Resume");

      const boardElement = xmlDoc.querySelector("Board");

      // Extract the ID attribute
      const boardId = boardElement ? boardElement.getAttribute("ID") : "N/A";
      matched = foundCount;
      return {
        id: index + 1,
        ResumeId: resume.getAttribute("SID") || "N/A",
        ResumeBoardId: boardId,
        Name: `${firstName} ${""} ${middleName} ${""} ${lastName}`,
        Source: board?.textContent || "N/A",
        Email: email,
        Location: {
          country: country || "N/A",
          state: state || "N/A",
          city: city || "N/A",
        },
        Relocation: Relocation,
        Title: Title,
        Description: Description,
        ResumeUpdated: DateModified,
        DesiredJobType: desiredJobTypes,
        HighestEducation:
          educationLevels.length > 0 ? educationLevels[0] : "N/A",
        DesiredSalary: `${minSalary}-${maxSalary} ${currency}/${type}`,
        Authorization: Authorization,
        ExperienceYears: years,
        ExperienceMonths: months,
        companyName: companyName,
        HomeLocation: location,
      };
    });
    return candidates;
  }

  function creditCalculations() {
    let totalCredits = 0;
    let availableCredits = 0;
    let assignedCredits = 0;
    let usedCredits = 0;

    if (
      storeData.userType.includes("SuperAdmin") ||
      storeData.userType.includes("Developer")
    ) {
      if (Array.isArray(integrationsList)) {
        integrationsList.forEach((accountApi) => {
          // Assuming accountApi.totalAvailable is defined
          totalCredits = accountApi.totalAvailable || 0; // Safeguard against undefined

          if (Array.isArray(accountApi.assignedTo)) {
            // Calculate allocatedCredits and usedCredits
            accountApi.assignedTo.forEach((assignedUser) => {
              assignedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
              usedCredits += assignedUser.usedCredits || 0; // Safeguard against undefined
            });
          }
        });
      }
    } else {
      if (Array.isArray(integrationsList)) {
        integrationsList.forEach((accountApi) => {
          if (Array.isArray(accountApi.assignedTo)) {
            // Calculate allocatedCredits and usedCredits for the specific user
            accountApi.assignedTo.forEach((assignedUser) => {
              if (assignedUser.accountId === storeData._id) {
                assignedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
                usedCredits += assignedUser.usedCredits || 0; // Safeguard against undefined
              }
            });
          }
        });
      }
    }

    const subtractCredit = assignedCredits - usedCredits; // Calculate the difference
    availableCredits = totalCredits - subtractCredit; // Calculate available credits

    // Return an object with all calculated values
    return {
      totalCredits,
      availableCredits,
      assignedCredits,
      usedCredits,
    };
  }

  const creditData = creditCalculations();
  const [openTabId, setOpenTabId] = useState("recentHistory");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
    if(newValue==="recentHistory"){
      const action={
        client:client,
        addedBy:storeData._id,
        searchType:'Monster',
        status:'recent'
      }
      dispatch(getjobBoardsStatusHistoryViaApi(action))
    }else {
      const action={
        client:client,
        addedBy:storeData._id,
        searchType:'Monster',
        status:'saved'
      }
      dispatch(getjobBoardsStatusHistoryViaApi(action))
    }
  };
  // Call the function to parse candidate data
  const candidateList = parseCandidatedData();
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  useEffect(() => {
    // Dynamically set the height of the content when options are toggled
    if (contentRef.current) {
      setHeight(showOptions ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [showOptions]);
  const [keyWords, setKeyWords] = useState("");
  const [errorKeyword, setErrorKeyword] = useState("");
  const [fromDate, setFromDate] = useState(0);
  const [country, setCountry] = useState(840);
  const [toDate, setToDate] = useState(43200);
  const [radius, setRadius] = useState(30);
  const [selectWithRadius, setSelectWithRadius] = useState(
    "Search ZIP Code Radius"
  );
  const [selectedStates, setSelectedStates] = useState([]);
  const [optionsCityZip, setOptionCityZip] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectCityZip, setSelectCityZip] = useState("");
  const [highEducation, setHighEducation] = useState("");
  const [stateMonsterOptions, setStateMonsterOptions] = useState([]);

  const [wa, setWa] = useState("");
  const [twlid, setTwlid] = useState("");
  const [cn, setcn] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [schoolAttend, setSchoolAttend] = useState("");
  const [tnsalmin, setTnsalmin] = useState("");
  const [tnsalmax, setTnsalmax] = useState("");
  const [tsaltyp, setTsaltyp] = useState("1");
  const [tsni, setTsni] = useState("");
  const [clv, setClv] = useState([]);
  const [Lskill, setLskill] = useState("");
  const [LProf, setLProf] = useState("");

  const [tjtft, setTjtft] = useState("");
  const [tjtpt, setTjtpt] = useState("");
  const [tjtpd, setTjtpd] = useState("");

  const [tjtp, setTjtp] = useState("");
  const [tjti, setTjti] = useState("");
  const [tjttc, setTjttc] = useState("");
  const [tjts, setTjts] = useState("");
  const [twindus, setTwindus] = useState("");

  const handleSearchMonster = () => {
    if (!keyWords) {
      return setErrorKeyword("please type any keyword");
    }

    let monsterAccountId = "";
    if (
      storeData.userType.includes("SuperAdmin") ||
      storeData.userType.includes("Developer")
    ) {
      if (Array.isArray(integrationsList)) {
        integrationsList.some(
          (accountApi) => (monsterAccountId = accountApi._id)
        );
      }
    }
    if (
      !Array.isArray(storeData.userType) ||
      !(
        storeData.userType.includes("SuperAdmin") ||
        storeData.userType.includes("Developer")
      )
    ) {
      let access = false;

      if (Array.isArray(integrationsList)) {
        access = integrationsList.some(
          (accountApi) =>
            Array.isArray(accountApi.assignedTo) &&
            accountApi.assignedTo.some(
              (account) =>
                account.accountId === storeData._id && account.access,
              (monsterAccountId = accountApi._id)
            )
        );
      }

      if (!access) {
        enqueueSnackbar(
          `You have no access to Monster search. Please contact the admin.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
        return "";
      }
    }

    const parts = selectCityZip
      ? selectCityZip.split("-").map((part) => part.trim())
      : [];

    // Assign values based on available parts
    const inputcity = parts[0] || ""; // First part is city
    const inputZipCode = parts[1] ? parts[1] : "";
    // Second part is zip code

    const action = {
      sort: "rank,distance,mdate",
      radius: radius || 30,
      query: keyWords,
      mdateminage: fromDate,
      mdatemaxage: toDate,
      country: inputZipCode ? "" : country || 840,
      rlid: selectedStates.length > 0 ? selectedStates.join("+") : 316,
      zipcode: inputZipCode,
      rpcr: inputZipCode ? `${inputZipCode}-${radius || 30}` : "",
      tsni: tnsalmax || tnsalmin ? 0 : tsni ? tsni : "",
      twlid: twlid ? 1 : "",
      wa: wa ? 164 - 1 : "",
      qrcn: cn,
      qrjt: jobTitle,
      qrjd: jobDescription,
      qsn: schoolAttend,
      tnsalmin: tnsalmin,
      tnsalmax: tnsalmax,
      tsaltyp: tsaltyp,
      tsalcur: tnsalmin || tnsalmax ? 1 : "",
      clv: clv.length > 0 ? clv.join(",") : "",
      edulv: highEducation,
      tjtft: tjtft ? 1 : "",
      tjtpt: tjtpt ? 1 : "",
      tjtpd: tjtpd ? 1 : "",
      tjtp: tjtp ? 1 : "",
      tjti: tjti ? 1 : "",
      tjttc: tjttc ? 1 : "",
      tjts: tjts ? 1 : "",
      Lskill: `${Lskill}-${LProf}`,
      twindus: twindus.length > 0 ? twindus : "",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId: monsterAccountId,
    };
    setLoading(true);
    dispatch(monsterAdvancedResumeSearchViaApi(action));
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "Monster",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      dispatch(getIntegrationsViaApi(action));
      navigate("/jobBoardsResult", { state: { ...action,keyWords:keyWords, searchType: "Monster" } });
      dispatch(getJobBoardsHistoryViaApi(historyAction));
    }, 1500);
  };
  
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  useEffect(() => {
    const action = {
      client: client,
    };
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "Monster",
    };
    setTimeout(() => {
      dispatch(getIntegrationsViaApi(action));
      dispatch(getJobBoardsHistoryViaApi(historyAction));
    }, 2000);
  }, []);
  const handleStateChange = (value) => {
    setSelectedStates(value); // Ensure value is always a flat array
  };

  const handleCallCountriesWithApi = async (value) => {
    setSelectCityZip("");
    setSearchTerm(value);
    if (!value) {
      setOptionCityZip([]);
      return;
    }
    // Only make the API call if the user has typed more than 2 characters
    if (value.length < 3) {
      return;
    }

    try {
      const response = await fetch(
        "https://apiats.tektreeinc.com/countries/getCities",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client: "tektree", // Replace with the actual client value if dynamic
            countryCode: country, // Replace with the actual countryCode value if dynamic
            city: value,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }

      const data = await response.json();
      setOptionCityZip(data);
      // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  const handleSelectCityZip = (value) => {
    setSearchTerm(value);
    setSelectCityZip(value);
  };
  const handleSelectCountry = async (value) => {
    setCountry(value);
    try {
      const response = await fetch(
        "https://apiats.tektreeinc.com/countries/getStates",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            client: "tektree", // Replace with the actual client value if dynamic
            countryCode: value, // Replace with the actual countryCode value if dynamic
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`); // Check for HTTP errors
      }

      const data = await response.json();
      setStateMonsterOptions(data);
      // setOptions(data); // Assumes data is an array of objects with 'value' and 'label' properties
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  const handleSelctOptionRadionsORStates = (value) => {
    setSelectWithRadius(value);
    setCountry("");
    setRadius("");
    setSelectedStates([]);
    setSelectCityZip("");
    setSearchTerm("");
  };

  const handleClose = () => "";
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MM/DD/YY HH:mm:ss`);
  };
  const handleClickHistory = (value) => {
    const action = value.query;
    action.country = action.rpcr ? "" : action.country;

    setLoading(true);
    dispatch(monsterAdvancedResumeSearchViaApi(action));
    const historyAction = {
      id: value._id,
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: value.status,
      searchType: "Monster",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      dispatch(getIntegrationsViaApi(action));
      dispatch(getJobBoardsHistoryViaApi(historyAction));
      navigate("/jobBoardsResult", { state: { ...action,keyWords:action.query, searchType: "Monster" } });
    }, 2000);
  };
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [selectHistory, setSelectHistory] = useState("");
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
  const handleClickSaveHistory=(value)=>{
    setHistoryTitle("");
    setErrTitle("");
    setSelectHistory("");
    if (value) {
      setAddNoteModal(!addNoteModal);
      setSelectHistory(value);
    }

  }
  const handleClickDeleteHistory=(value)=>{
    if (value) {
      setSelectHistory(value);
      setDeleteHistoryModal(!deleteHistoryModal);
    }
  }
  const handleConfirmHistorySave = () => {
    setErrTitle("");
    if (!selectHistory && !historyTitle) {
      setErrTitle("please type title");
      return "";
    }
    const action = {
      id: selectHistory._id,
      client: client,
      title: historyTitle,
    };
    dispatch(saveJobBoardsHistoryViaApi(action));
    setLoading(true);
    setAddNoteModal(!addNoteModal);
    setTimeout(() => {
      const action = {
        addedBy: storeData._id,
        searchType: 'Monster',
        status: "recent",
        client: client,
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }, 2000);
  };
  const handleConfirmHistoryDelete = () => {
    const action = {
      id: selectHistory._id,
      client: client,
    };
    dispatch(deleteJobBoardsHistoryViaApi(action));
    setLoading(true);
    setDeleteHistoryModal(!deleteHistoryModal);
    setTimeout(() => {
      const fetchAction = {
        client: client,
        addedBy: storeData._id,
        searchType: 'Monster',
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
    }, 2000);
  };
  return (
    <Box>
      <MainBackground  p={0}>
      <Grid container>
      <Grid item xs={8}>
        <Box
          sx={{
            p: 2,
            bgcolor: theme.palette.primary[100],
          }}
        >
          <Box sx={{ mb: 3 }}>
            <SectionTitle
              title="Applicant Search In Monster"
              size="sm"
              color="secondary"
            />
          </Box>
          <Grid container columns={12} rowSpacing={2} columnSpacing={2}>
            <Grid item xs={7}>
              <CustomFormField
                title={"Keyword"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,
                      defaultValue: keyWords,
                      onChange: (value) => setKeyWords(value),
                      error: !!errorKeyword,
                      errorMessage: errorKeyword,
                    },
                  },
                ]}
              />
              <Box mt={2} />
              <CustomFormField
                title={"Resume Posted"}
                //colunm={true}
                components={[
                  // {
                  //   type: "rtText",
                  //   props: {
                  //     // text: "from",
                  //   },
                  // },
                  {
                    type: "selectLabel",
                    props: {
                      title: "From",
                      options: optionsMonsterPostFrom,
                      value: fromDate,
                      onChange: (value) => setFromDate(value),
                    },
                  },
                  {
                    type: "rtText",
                    props: {
                      text: "-",
                    },
                  },
                  {
                    type: "selectLabel",
                    props: {
                      title: "To",
                      options: optionsMonsterTo,
                      value: toDate,
                      onChange: (value) => setToDate(value),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={5}>
              <CustomFormField
                title={"Location (Choose 1):"}
                colunm={true}
                components={[
                  {
                    type: "radio",
                    props: {
                      title: "Search ZIP Code Radius",
                      defaultValue: selectWithRadius,
                      options: [
                        "Search ZIP Code Radius",
                        "Search within Selected Location",
                      ],
                      onChange: (value) =>
                        handleSelctOptionRadionsORStates(value),
                    },
                  },
                  {
                    type: "selectLabel",

                    props: {
                      title: "Select Country",
                      value: country,
                      options: countryMonsterOptions,
                      onChange: (value) => handleSelectCountry(value),
                    },
                  },
                ]}
              />
              <Box>
                {selectWithRadius === "Search ZIP Code Radius" && (
                  <Box id="radius">
                    <CustomFormField
                      //title={"Location (Choose 1):"}
                      //colunm={true}
                      components={[
                        {
                          type: "selectLabel",
                          props: {
                            title: "Show jobseekers with in",
                            value: radius,
                            options: MonsterRadiusOptions,
                            onChange: (value) => setRadius(value),
                          },
                        },

                        {
                          type: "selectLabelMultipleFrom",
                          props: {
                            title: "of Zipcode/City",
                            defaultValue: "",
                            options: optionsCityZip,
                            onChangeForm: (value) =>
                              handleCallCountriesWithApi(value),
                            searchTerm: searchTerm,
                            selectCityZip: selectCityZip,
                            onChange: (value) => handleSelectCityZip(value),
                          },
                        },
                      ]}
                    />
                  </Box>
                )}

                {selectWithRadius === "Search within Selected Location" && (
                  <Box id="selected-location">
                    {/* <CustomFormField
                    //title={"Location (Choose 1):"}
                    colunm={true}
                    components={[
                      {
                        type: "selectLabelMultiple",
                        props: {
                          title: "Select State",
                          value: selectedStates, // Should be a flat array
                          multiple: true,
                          options: stateMonsterOptions, // Your array of states
                          onChange: handleStateChange, // Use the handler here
                        },
                      },
                    ]}
                  /> */}
                    <CustomFormField
                      //title={"Location (Choose 1):"}
                      colunm={true}
                      components={[
                        {
                          type: "selectLabelMultiple",
                          props: {
                            title: "Select State",
                            value: selectedStates, // Should be a flat array
                            multiple: true,
                            options: statesArrayOptionMonster, // Your array of states
                            onChange: handleStateChange, // Use the handler here
                          },
                        },
                      ]}
                    />
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <CustomFormField
                colunm={true}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      title: "Search within Radius",
                      size: "small",
                      defaultValue: twlid,
                      options: [
                        "Include Job Seekers Employable in Any of the Selected Locations.",
                      ],
                      onClick: (value) => setTwlid(value),
                    },
                  },
                  {
                    type: "checkbox",
                    props: {
                      title: "Search within Radius",
                      size: "small",
                      defaultValue: wa,
                      options: [
                        "Include Job Seekers Authorized to Work for any Employer in Any of the Selected Locations.",
                      ],
                      onClick: (value) => setWa(value),
                    },
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                color="secondary"
                startIcon={<AddIcon />}
                size="small"
                onClick={toggleOptions}
              >
                Advanced Options Monster
              </Button>

              <Box
                sx={{
                  height,
                  overflow: "hidden",
                  transition: "height 0.3s ease-in-out",

                  mt: 2,
                }}
                ref={contentRef}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Resume Details"}
                      colunm={true}
                      components={[
                        {
                          type: "rtText",
                          props: {
                            text: "Enter Keywords to Search Areas of Job Seekers Resume",
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Most Recent Employer"}
                      components={[
                        {
                          type: "text",
                          props: {
                            placeholder: "Most Recent Employer",
                            defaultValue: cn,
                            onChange: (value) => setcn(value),
                          },
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Most Recent Job Title"}
                      components={[
                        {
                          type: "text",
                          props: {
                            placeholder: "Most Recent Job Title",
                            defaultValue: jobTitle,
                            onChange: (value) => setJobTitle(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Most Recent Description"}
                      components={[
                        {
                          type: "text",
                          props: {
                            placeholder: "Most Recent Description",
                            defaultValue: jobDescription,
                            onChange: (value) => setJobDescription(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"School Attended"}
                      components={[
                        {
                          type: "text",
                          props: {
                            placeholder: "School Attended",
                            defaultValue: schoolAttend,
                            onChange: (value) => setSchoolAttend(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Desired Salary"}
                      components={[
                        {
                          type: "rtText",
                          props: {
                            text: "Min",
                          },
                        },
                        {
                          type: "text",
                          props: {
                            placeholder: "Min",
                            defaultValue: tnsalmin,
                            onChange: (value) => setTnsalmin(value),
                          },
                        },
                        {
                          type: "rtText",
                          props: {
                            text: "Max",
                          },
                        },
                        {
                          type: "text",
                          props: {
                            placeholder: "Max",
                            defaultValue: tnsalmax,
                            onChange: (value) => setTnsalmax(value),
                          },
                        },
                        {
                          type: "selectLabel",
                          props: {
                            title: "From",
                            value: tsaltyp,
                            options: salaryTypeMonsterOptions,
                            onChange: (value) => setTsaltyp(value),
                          },
                        },
                      ]}
                    />
                    <CustomFormField
                      //title={"Show Resumes without a Desire Salery"}
                      components={[
                        {
                          type: "checkbox",
                          props: {
                            title: "Show Resumes without a Desired Salary",
                            options: ["Show Resumes without a Desired Salary"],
                            size: "small",
                            defaultValue: tsni,
                            onClick: (value) => setTsni(value),
                          },
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Job Seeker Profile"}
                      colunm={true}
                      components={[
                        {
                          type: "selectLabel",
                          props: {
                            title: "Job Seeker Profile",
                            defaultValue: highEducation,
                            options: MonsterEducationLevel,
                            onChange: (value) => setHighEducation(value),
                          },
                        },
                        {
                          type: "rtText",
                          props: {
                            text: "Show Jobseekers with Selected Education Level or Higher",
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"More Profile Options"}
                      colunm={true}
                      components={[
                        {
                          type: "selectLabelMultiple",
                          multiple: true,
                          props: {
                            title: "Career Level",

                            value: clv,
                            multiple: true,
                            options: careerLevelMonster,
                            onChange: (value) => setClv(value),
                          },
                        },
                        {
                          type: "rtText",
                          props: {
                            text: "Career Level",
                          },
                        },
                        {
                          type: "rtText",
                          props: {
                            text: "Only show Job seekers with the selected career level",
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Language Proficiency"}
                      components={[
                        {
                          type: "selectLabel",
                          multiple: true,
                          props: {
                            title: "Select a Language",
                            value: Lskill,
                            options: LanguageskillsMonster,
                            onChange: (value) => setLskill(value),
                          },
                        },
                        {
                          type: "selectLabel",
                          multiple: true,
                          props: {
                            title: "Beginner",
                            value: LProf,
                            onChange: (value) => setLProf(value),
                            options: ProfessionalLevelMonsterOptions,
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Job Status"}
                      colunm={true}
                      components={[
                        {
                          type: "checkbox",
                          multiple: true,
                          props: {
                            title: "Job Seeker Profile",
                            options: ["Full-Time"],
                            defaultValue: tjtft,
                            onClick: (value) => setTjtft(value),
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Seeker Profile",
                            options: ["Part-Time"],
                            defaultValue: tjtpt,
                            onClick: (value) => setTjtpt(value),
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Seeker Profile",
                            options: ["Contract"],
                            defaultValue: tjtpd,
                            onClick: (value) => setTjtpd(value),
                          },
                        },
                        {
                          type: "rtText",
                          props: {
                            text: "Only show Job seekers interested in the selected job status",
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Job Type"}
                      colunm={true}
                      components={[
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ["Employee"],
                            defaultValue: tjtp,
                            onClick: (value) => setTjtp(value),
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ["Intern"],
                            defaultValue: tjti,
                            onClick: (value) => setTjti(value),
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: [
                              "Temporary/Contract/Permanent or Fulltime",
                            ],
                            defaultValue: tjttc,
                            onClick: (value) => setTjttc(value),
                          },
                        },
                        {
                          type: "checkbox",
                          props: {
                            title: "Job Type",
                            options: ["Seasonal"],
                            defaultValue: tjts,
                            onClick: (value) => setTjts(value),
                          },
                        },
                        {
                          type: "rtText",
                          props: {
                            text: "Only show Job seekers interested in the selected job type",
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <CustomFormField
                      title={"Industries"}
                      colunm={true}
                      components={[
                        {
                          type: "selectLabelMultiple",
                          multiple: true,
                          props: {
                            title: "Select a Language",
                            value: twindus,
                            options: indusriesMonsterOptions,
                            onChange: (value) => setTwindus(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={15} alignContent={"center"}>
              <Box display={"flex"} justifyContent={"center"} gap={2}>
                <Button
                  variant="contained"
                  onClick={() => handleSearchMonster()}
                >
                  Search
                </Button>
                <Button variant="contained">Reset</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: "space-between",
            my: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {(storeData.userType.includes("SuperAdmin") ||
              storeData.userType.includes("Developer")) && (
              <>
                <ChipSet
                  title="Total Credits"
                  value={creditData?.totalCredits || 0}
                  color="success"
                />
                <Divider orientation="vertical" flexItem />
                <ChipSet
                  title="Available Credits"
                  value={creditData?.availableCredits || 0}
                  color="warning"
                />
              </>
            )}

            <Divider orientation="vertical" flexItem />
            <ChipSet
              title="Assigned Credits"
              value={creditData?.assignedCredits || 0}
              color="info"
            />
            <Divider orientation="vertical" flexItem />
            <ChipSet
              title="Used Credits"
              value={creditData?.usedCredits || 0}
              color="error"
            />
          </Box>
          <Box></Box>
        </Box>
       </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              bgcolor: theme.palette.grey[50],
              px: 3,
              py: 1,
              height: "100%",
            }}
          >
            <TabContext value={openTabId}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="ob Boards tabs">
                  <Tab label="Recent History" value="recentHistory" />
                  <Tab label="Saved History" value="savedHistory" />
                </TabList>
              </Box>

              <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
                {JobBoardsHistoryRecent.map((search) => (
                  // <Box key={search._id} sx={{ marginBottom: 2 }}>
                  //   {/* Displaying the query and country as a clickable link */}
                  //   <Box>
                  //     <Box
                  //       href="#"
                  //       sx={{
                  //         color: "#4285F4",
                  //         cursor: "pointer",
                  //         textDecoration: "none",
                  //       }}
                  //       onClick={() => handleClickHistory(search)}
                  //     >
                  //       {search.query.query} in{" "}
                  //       {
                  //         countryMonsterOptions.find(
                  //           (country) => country.value === search.query.country
                  //         )?.label
                  //       }
                  //     </Box>
                  //   </Box>
                  //   <Box>{formatDateForDisplay(search.addedOn)}</Box>
                  //   <Divider sx={{ marginTop: 1 }} />
                  // </Box>
                  <SearchHistorySection 
                  key={search._id} data={`${search.query.query} in ${countryMonsterOptions.find(
                    (country) => country.value === search.query.country
                  )?.label}`}  
                  onClick={() => handleClickHistory(search)} addedOn={search.addedOn} 
                  onClickSave={()=>handleClickSaveHistory(search)}
                  status="recent"  
                   />
                ))}
              </TabPanel>

              <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
              {JobBoardsHistorySaved.map((search) => (
                  // <Box key={search._id} sx={{ marginBottom: 2 }}>
                  //   {/* Displaying the query and country as a clickable link */}
                  //   <Box>
                  //     <Box
                  //       href="#"
                  //       sx={{
                  //         color: "#4285F4",
                  //         cursor: "pointer",
                  //         textDecoration: "none",
                  //       }}
                  //       onClick={() => handleClickHistory(search)}
                  //     >
                  //       {search.query.query} in{" "}
                  //       {
                  //         countryMonsterOptions.find(
                  //           (country) => country.value === search.query.country
                  //         )?.label
                  //       }
                  //     </Box>
                  //   </Box>
                  //   <Box>{formatDateForDisplay(search.addedOn)}</Box>
                  //   <Divider sx={{ marginTop: 1 }} />
                  // </Box>
                  <SearchHistorySection key={search._id} data={`${search.query.query} in ${countryMonsterOptions.find(
                              (country) => country.value === search.query.country
                            )?.label}`} 
                  onClick={() => handleClickHistory(search)} 
                  addedOn={search.addedOn} 
                  onClickDelete={()=>handleClickDeleteHistory(search)}
                  status="saved" 
                  title={search.title} 
                  />
                ))}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
        </Grid>
      </MainBackground>

    <Modal
        open={addNoteModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexWrap: "wrap",

              mb: 3,
            }}
          >
            <SectionTitle title=" Save Search" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                textAlign: "center",
              }}
            >
              {/* {itemToDelete ? `${itemToDelete.companyName}` : ""} */}
            </Typography>
            {/* <Typography fontWeight={"400"}>
                from My Companies List?
              </Typography> */}
            <CustomFormField
              title={"Search Title"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    required: true,
                    defaultValue: historyTitle,
                    onChange: (value) => setHistoryTitle(value),
                    error: !!errTitle,
                    errorMessage: errTitle,
                  },
                },
              ]}
            />
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setAddNoteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistorySave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteHistoryModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeleteHistoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistoryDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
    </Box>
  );
};

export default JobBoardCareerBuilder;
