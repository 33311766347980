import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import LogoMonster from "../../Assets/Images/logo-monster.jpg";
import {
  CustomFormField,
  FilterHeader,
  KeyData,
  MainBackground,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import theme from "../../Theme";
import { DataGrid } from "@mui/x-data-grid";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
import styled from "@emotion/styled";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { assignedToMonsterAccessViaApi, distributeMonsterCreditsViaApi, getIntegrationsViaApi, getMonsterCreditsViaApi, integrationNewMonsterAccountViaApi, stopLoadingRedux } from "../../store/actions";
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#2ECA45",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));



const MasterSettings = () => {
  const dispatch=useDispatch()
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;

  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const filteredUserIds = allUsers
  .filter((user) => !user.userType.includes('RATeam'))
  .map((user) => user._id);
  const integrationsList = useSelector((state) => state.Resumes.integrations);
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);

  const integrationListId = Array.isArray(integrationsList) 
      ? integrationsList.map((account, index) => ({
          id: index+1,
          ...account
      }))
      : [];
  

  const [monsterDrawer, setMonsterDrawer] = useState(false);
  const handleMonsterDrawer = () => {
    setMonsterDrawer(!monsterDrawer);
  };
  const [selectedAccountId, setSelectedAccountId] = useState('');
  const [accountId,setAccountId]=useState('')
  const handleSelectAccountId = (_id,id) => {
    setSelectedAccountId(id);
    setAccountId(_id)
  };
  const filteredAssignTo = integrationListId
  .filter((job) => job.id === selectedAccountId) // Filter jobs based on selectedAccountId
  .flatMap((job) => job.assignedTo); // Get only the assignedTo details
// Get only the assignTo details
    const findAccount=integrationListId
    .find((job) => job.id === selectedAccountId)

const filteredAssignToId = filteredAssignTo.map((user, index) => {
  const recruiterData = allUsers.find((u) => u._id === user?.accountId);
  const recruiterName = recruiterData?.userSudoFirstName ?? "";

  return {
    id: index + 1, // Add an id property based on index
    userName: recruiterName, // Assign the recruiter's name
    ...user, // Spread other properties of user
  };
});
  ///filted
  ////////////
  const [monsterUserDrawer, setMonsterUserDrawer] = useState(false);
  const [selecedUserData,setSelectUserData]=useState()
  const [avilableCredit,setAvailability]=useState();
  const [usedCredit,setUsedCredit]=useState();
const [distributeCredit,setDistributeCredit]=useState();
  const handleMonsterUserDrawer = () => {
    setMonsterUserDrawer(!monsterUserDrawer);
  };
  
  const handleClickUser = (user) => {
    setSelectUserData(user);
    setAvailability()
    setUsedCredit()
    setDistributeCredit()
    // Ensure findAccount is defined before accessing its properties
    if (!findAccount) {
        console.error("findAccount is undefined");
        return;
    }

    let totalCredits = findAccount.totalAvailable;
    let usedCredits = 0;
    let allocatedCredits = 0;

    // Check if assignedTo is an array
    if (Array.isArray(findAccount.assignedTo)) {
        // Calculate allocatedCredits and usedCredits
        findAccount.assignedTo.forEach((assignedUser) => {
            allocatedCredits += assignedUser.allocatedCredits || 0; // Safeguard against undefined
            usedCredits += assignedUser.usedCredits || 0;         // Safeguard against undefined
        });
    } 

    const subtractCredit = allocatedCredits - usedCredits; // Calculate the difference
    
    setAvailability(totalCredits - subtractCredit);
    setUsedCredit(usedCredits); // Corrected to use 'usedCredits' instead of 'usedCredit'
    setMonsterUserDrawer((prev) => !prev);
};
const handledistributeCredit=(value)=>{
  if(value>avilableCredit){
    return alert('Please check the Available credits')
  }
  setDistributeCredit(value)
}
  const handleCreditSave=()=>{
     if(!distributeCredit){
      return alert('please eneter credits')
     }
     const action={
      client:client,
      mainAccountId:findAccount._id,
      accountId:selecedUserData.accountId,
      allocatedCredits: distributeCredit,
     }
dispatch(distributeMonsterCreditsViaApi(action))
setMonsterUserDrawer(!monsterUserDrawer);
setLoading(!loading)

// Wait to fetch updated integration data
setTimeout(() => {
  dispatch(getIntegrationsViaApi(action));
}, 2000);
  }
  ////////////
  const [monsterAddUserDrawer, setMonsterAddUserDrawer] = useState(false);
  const [selectUserId,setSelectUserId]=useState('');
  const handleMonsterAddUserDrawer = () => {
    setMonsterAddUserDrawer(!monsterAddUserDrawer);
    setSelectUserId('')
  };
  const handleMonsterAddUser = () => {
    if (selectUserId && accountId) {
      const action = {
        client,
        accountId,
        selectUserId,   // Renamed to keep consistent with your backend field name
        assignedBy: storeData._id,
        assignedOn: new Date()
      };
  
      dispatch(assignedToMonsterAccessViaApi(action));
      setLoading(true);
      setMonsterAddUserDrawer(!monsterAddUserDrawer);
  
      // Wait to fetch updated integration data
      setTimeout(() => {
        dispatch(getIntegrationsViaApi(action));
      }, 2000);
    }
  };
  
//////
const [monsterAddAccountDrawer,setMonsterAddAccountDrawer]=useState(false)

const handleMonsterAddAccount=()=>{
  setMonsterAddAccountDrawer(!monsterAddAccountDrawer)
}



  ///////////////
  const [value, setValue] = useState("accounts");
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  //////////////////////////////////////////////////////////////
  
 



  const gridColumnsMonster = [
    {
      field: "accountName",
      headerName: "Name",
      width: 280,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "addedBy",
      headerName: "created At",
      width: 280,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { createdAt } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {FormatDateTime(createdAt, "full")}
          </Box>
        );
      },
    },
    {
      field: "access",
      headerName: "Access",
      width: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { access } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* <Switch defaultChecked={access ? true : false} /> */}

            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked={access ? true : false}
                />
              }
            />
          </Box>
        );
      },
    },
    {
      field: "assignTo",
      headerName: "Assign To",
      width: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        let { id,_id, assignedTo } = params.row;
        
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <IconButton size="small" onClick={() => handleSelectAccountId(_id,id)}>
              <GroupOutlinedIcon fontSize="inherit" />
            </IconButton>
            {assignedTo?.length}
          </Box>
        );
      },
    },
    {
      field: "harvesting",
      headerName: "Harvesting",
      width: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { harvesting } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {/* <Switch defaultChecked={access ? true : false} /> */}

            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked={harvesting ? true : false}
                />
              }
            />
          </Box>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.2 }}>
            <IconButton size="small">
              <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small">
              <GroupsOutlinedIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small">
              <PersonAddAltOutlinedIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small">
              <FileDownloadOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const gridColumnsMonsterUsersList = [
    {
      field: "userName",
      headerName: "User Name",
      width: 280,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { userName } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {userName}
          </Box>
        );
      },
    },
    {
      field: "allocatedCredits",
      headerName: "Credits Allocated",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { allocatedCredits } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {allocatedCredits}
          </Box>
        );
      },
    },
    {
      field: "usedCredits",
      headerName: "Credits Used",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { usedCredits } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {usedCredits}
          </Box>
        );
      },
    },
    {
      field: "access",
      headerName: "Access",
      width: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { access } = params.row;
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ m: 1 }}
                  defaultChecked={access ? true : false}
                />
              }
            />
          </Box>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 140,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.2 }}>
            <IconButton size="small" onClick={()=>handleClickUser(params.row)}>
              <EditOutlinedIcon fontSize="inherit" />
            </IconButton>
            <IconButton size="small">
              <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Box>
        );
      },
    },
  ];

 
useEffect(()=>{
  const action={
    client:client
  }
  dispatch(getIntegrationsViaApi(action))
},[])
const [accountName,setAccountName]=useState('')
const [clientId,setClientId]=useState('')
const [secretId,setSecretId]=useState('')
const [companyAccessTicket,setCompanyAccessTicket]=useState('')
const handleAddNewMonsterAccount=()=>{
  if(accountName&&clientId&&secretId&&companyAccessTicket){
    const action={
      client:client,
      accountName:accountName,
      clientId:clientId,
      secretId:secretId,
      accountType:'Monster',
      companyAccessTicket:companyAccessTicket,
      addedOn:new Date(),
      addedBy:storeData._id,
    }
    dispatch(integrationNewMonsterAccountViaApi(action))
  }

}
const handleCancelAddMonster=()=>{
  setAccountName('')
  setClientId('')
  setSecretId('')
  setCompanyAccessTicket('')
  setMonsterAddAccountDrawer(!monsterAddAccountDrawer)

}

useEffect(() => {
  if (loadingRedux) {
    dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
    setLoading(false);

    // Set a timer to stop loading after 5 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Cleanup timer on unmount
    return () => clearTimeout(timer);
  }
}, [loadingRedux, dispatch]);
const handleClose = () => '';

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Master Settings"}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <MainBackground>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      <Avatar src={LogoMonster} />
                      <Typography variant="h5" sx={{ fontWeight: "700" }}>
                        Monster
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                        Please request the Monster pepresentative providethe API
                        access with Registerd name to configure and ....
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button variant="text" onClick={handleMonsterDrawer}>
                        Setup
                      </Button>
                    </Box>
                  </Box>
                </MainBackground>
              </Grid>
            </Grid>
          </Box>
          <SnackbarComponent/>
        </LayoutSidebarMenu>
      </SnackbarProvider>

      <Drawer open={monsterDrawer} onClose={handleMonsterDrawer} anchor="right">
        <Box
          sx={{
            width: 1200,
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Avatar src={LogoMonster} />
              <Typography variant="h5" sx={{ fontWeight: "700" }}>
                Monster
              </Typography>
            </Box>
            <Box mt={2}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Accounts" value="accounts" />
                    <Tab label="Activties" value="activties" />
                  </TabList>
                </Box>
                <TabPanel value="accounts">
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <Box></Box>
                <Button
                              size="small"
                              variant="outlined"
                              onClick={handleMonsterAddAccount}
                            >
                              Add
                            </Button>
                      </Box>
                  <DataGrid
                    rows={integrationListId}
                    columns={gridColumnsMonster}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnResize
                    disableColumnSort
                    hideFooter
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: 0,
                          pageSize: 100,
                        },
                      },
                    }}
                    pageSizeOptions={[100]}
                    getRowHeight={() => "auto"}
                    autoHeight
                    sx={{
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        alignItems: "center !important",
                      },
                      [`& .MuiDataGrid-checkboxInput`]: {
                        color: theme.palette.primary.main,
                        p: 0,

                        "&.Mui-checked": {
                          color: theme.palette.primary.dark,
                        },
                      },
                      //Scroller
                      [`& .MuiDataGrid-scrollbar`]: {
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      },
                      [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                        borderRadius: "10px", // Apply border radius
                      },

                      [`& .MuiDataGrid-scrollbar:hover`]: {
                        scrollbarColor: `${theme.palette.primary.dark}`,
                      },
                    }}
                  />
                  <Divider sx={{ mt: 5 }} />
                  
                  {selectedAccountId && (
                    <Box mt={3}>
                      <MainBackground>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <SectionTitle title="Assiged to" />
                          {selectedAccountId}
                          <Box sx={{ display: "flex", gap: 0.5 }}>
                            <Button
                              size="small"
                              variant="text"
                              onClick={() => setSelectedAccountId(null)}
                              color="error"
                            >
                              close
                            </Button>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={handleMonsterAddUserDrawer}
                            >
                              Add
                            </Button>
                          </Box>
                        </Box>
                        <Box>
                          <DataGrid
                            rows={filteredAssignToId}
                            columns={gridColumnsMonsterUsersList}
                            disableRowSelectionOnClick
                            disableColumnMenu
                            disableColumnSorting
                            disableColumnSelector
                            disableColumnFilter
                            disableColumnReorder
                            disableColumnResize
                            disableColumnSort
                            hideFooter
                            initialState={{
                              pagination: {
                                paginationModel: {
                                  page: 0,
                                  pageSize: 100,
                                },
                              },
                            }}
                            pageSizeOptions={[100]}
                            getRowHeight={() => "auto"}
                            autoHeight
                            sx={{
                              "& .MuiDataGrid-cell": {
                                display: "flex",
                                alignItems: "center !important",
                              },
                              [`& .MuiDataGrid-checkboxInput`]: {
                                color: theme.palette.primary.main,
                                p: 0,

                                "&.Mui-checked": {
                                  color: theme.palette.primary.dark,
                                },
                              },
                              //Scroller
                              [`& .MuiDataGrid-scrollbar`]: {
                                scrollbarWidth: "thin",
                                scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                              },
                              [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                                {
                                  borderRadius: "10px", // Apply border radius
                                },

                              [`& .MuiDataGrid-scrollbar:hover`]: {
                                scrollbarColor: `${theme.palette.primary.dark}`,
                              },
                            }}
                          />
                        </Box>
                      </MainBackground>
                    </Box>
                  )}
                </TabPanel>
                <TabPanel value="activties">Activties</TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Drawer
        open={monsterUserDrawer}
        onClose={handleMonsterUserDrawer}
        anchor="right"
      >
        <Box
          sx={{
            width: 500,
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <SectionTitle title="Assign Job Board Credit" />
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <KeyData title={"Account Name"} value={findAccount?.accountName} />
            <KeyData title={"Total Credits"} value={findAccount?.totalAvailable} />
            <KeyData title={"Available Credits"} value={avilableCredit} />
            <KeyData title={"Assigned Credits"} value={selecedUserData?.allocatedCredits||0} />
            <KeyData title={"Used Credits"} value={usedCredit} />
            <KeyData title={"User Name"} value={selecedUserData?.userName} />

            <CustomFormField
              title={"Allocated Credits"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    type: "number",
                    placeholder: `Allocated Credits`,
                    defaultValue:distributeCredit,
                     onChange:(value)=>handledistributeCredit(value)
                  },
                },
              ]}
            />
            <CustomFormField
              title={"Renewal Type"}
              required
              components={[
                {
                  type: "select",
                  props: {
                    title: "Select",
                    defaultValue: "",
                    options: ["Option - 1", "Option - 2", "Option - 3"],
                  },
                },
              ]}
            />
            <CustomFormField
              title={"Add to Renewal"}
              required
              components={[
                {
                  type: "switch",
                  props: {
                    defaultValue: "",
                    options: ["Yes", "No"],
                  },
                },
              ]}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Button color="error" onClick={handleMonsterUserDrawer}>
              Cancel
            </Button>
            <Button onClick={handleCreditSave}>Save</Button>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        open={monsterAddUserDrawer}
        onClose={handleMonsterAddUserDrawer}
        anchor="right"
      >
        <Box
          sx={{
            width: 500,
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <SectionTitle title="Add User to Monster" />
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <CustomFormField
              title={"Find Users"}
              required
              components={[
                {
                  type: "selectUser",
                  props: {
                    defaultValue: selectUserId,
                    options: filteredUserIds,
                    allUsers: allUsers,
                    onChange:(value)=>setSelectUserId(value)
                  },
                },
              ]}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Button color="error" onClick={handleMonsterAddUserDrawer}>
              Cancel
            </Button>
            <Button onClick={handleMonsterAddUser}>Save</Button>
          </Box>
        </Box>
      </Drawer>
      <Drawer
      open={monsterAddAccountDrawer}
      onClose={handleMonsterAddAccount}
      anchor="right"
      >
 <Box
          sx={{
            width: 500,
            p: 2,
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <SectionTitle title="Add Account to Monster" />
          </Box>
          <Box
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <CustomFormField
              title={"AccountName"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    defaultValue: accountName,
                   onChange:(value)=>setAccountName(value)
                  },
                },
              ]}
            />
            <CustomFormField
              title={"ClientId"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    defaultValue:clientId ,
                   onChange:(value)=>setClientId(value)
                  },
                },
              ]}
            />
            <CustomFormField
              title={"SecretId"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    defaultValue:secretId ,
                   onChange:(value)=>setSecretId(value)
                  },
                },
              ]}
            />
            <CustomFormField
              title={"company Access Ticket(CAT)"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    defaultValue:companyAccessTicket ,
                   onChange:(value)=>setCompanyAccessTicket(value)
                  },
                },
              ]}
            />
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "end" }}>
            <Button color="error" onClick={handleCancelAddMonster}>
              Cancel
            </Button>
            <Button onClick={handleAddNewMonsterAccount}>Save</Button>
          </Box>
        </Box>
      </Drawer>
      <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={loading}
  onClick={handleClose}
>
  <CircularProgress color="inherit" size="3rem" />
</Backdrop>
    </React.Fragment>
  );
};

export default MasterSettings;