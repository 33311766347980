

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
  ListItemText,
  FormHelperText,
  Modal,
  Button,
  Divider,
} from "@mui/material";
import React, { useState } from "react";
import SectionTitle from "../Helper/SectionTitle";
import HelpIcon from "@mui/icons-material/Help";
import theme from "../../Theme";
import { DatePicker } from "@mui/x-date-pickers";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomFormField from "../Common/CustomFormField";
import {
  currencyCodes,
  payScheduleTypes,
  employmentTypes,
  remoteJobOptions,
  jobStatusOptions,
  jobTypeOptions,
  requiredDocumentsOptions,
  turnaroundTimeOptions,
  priorityOptionsOptions,
  workAuthorizationOptions,
  industryOptions,
  languagesOptions,
  taxTermsOptions,
  states,
  countries,
} from "../../Data/JobPostingData";
import KeyData from "../Helper/KeyData";
const DefineStyle = {
  fontSize: 14,
  bgColor: "white",
  //bgColor: `linear-gradient(0deg, rgba(${theme.palette.primary.default},.07) 0%, rgba(${theme.palette.primary.default},0.02) 100%)`,
  boderColor: `rgba(${theme.palette.primary.default},.2)`,
  textColor: theme.palette.text.primary,
  placeholderColor: theme.palette.grey[900],
};

const FormSectionTitle = ({ title, info, required }) => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <Typography
          noWrap
          sx={{
            fontSize: 14,
            fontWeight: 300,
            lineHeight: 1.2,
            letterSpacing: 0.5,
            color: theme.palette.text.primary,
          }}
        >
          {title}
        </Typography>
        {required && (
          <Typography
            noWrap
            sx={{
              fontSize: 12,
              lineHeight: 0.8,
              mx: 0.2,
              color: theme.palette.error.dark,
            }}
          >
            *
          </Typography>
        )}
        {info && (
          <Tooltip title={info} arrow placement="top">
            <HelpIcon
              sx={{ color: "warning.main", fontSize: "12px", mx: 0.5 }}
            />
          </Tooltip>
        )}
      </Box>
    </React.Fragment>
  );
};

const CustomText = ({
  placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange, // Generic onChange handle
  disabled,
  error = false, // Error state,
  errorMessage = "", // Error message to display
  type = "text",
}) => {
  const handleChangeText = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <React.Fragment>
      <TextField
        placeholder={placeholder}
        multiline={multiline}
        rows={multiline && 3}
        size="small"
        fullWidth
        defaultValue={defaultValue}
        InputProps={{
          readOnly: readOnly,
          sx: {
            fontSize: DefineStyle.fontSize,
            color: DefineStyle.textColor,
            py: 0.5,
          },
        }}
        sx={{
          background: DefineStyle.bgColor,
          "& .MuiInputBase-input::placeholder": {
            color: DefineStyle.placeholderColor,
            fontSize: DefineStyle.fontSize,
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: DefineStyle.boderColor, // Border color for the fieldset
            },
          },
        }}
        disabled={disabled}
        onChange={handleChangeText}
        error={error} // Highlight field if error
        helperText={error ? errorMessage : ""} // Display error message
      />
    </React.Fragment>
  );
};

const CustomTextEditor = ({ placeholder, defaultValue, onChange }) => {
  const handleTextEditorChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <React.Fragment>
      <ReactQuill
        //ref={quillRef}
        theme="snow"
        value={defaultValue}
        onChange={handleTextEditorChange}
        placeholder={placeholder}
        modules={{
          toolbar: {
            container: [
              ["bold", "italic", "underline"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link"],
              ["clean"],
              [{ color: [] }, { background: [] }],
            ],
          },
        }}
      />
    </React.Fragment>
  );
};

const CustomSelect = ({
  title,
  defaultValue = [],
  multiple = false,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
}) => {
  // Ensure defaultValue is always an array for multiple select
  const value = multiple
    ? Array.isArray(defaultValue)
      ? defaultValue
      : []
    : defaultValue || "";

  const handleChange = (event) => {
    const newValue = event.target.value;
    // For multi-select, newValue is an array
    // For single-select, newValue is a single value
    onChange(newValue);
  };

  return (
    <FormControl fullWidth error={error} size="small">
      <Select
        fullWidth
        size="small"
        value={value}
        multiple={multiple}
        displayEmpty
        onChange={handleChange}
        renderValue={(selected) => {
          if (multiple) {
            if (selected.length === 0) {
              return (
                <Typography
                  noWrap
                  sx={{
                    color: "gray", // DefineStyle.placeholderColor or similar
                    opacity: 0.4,
                    fontSize: "0.875rem", // DefineStyle.fontSize or similar
                    py: 0.15,
                    maxWidth: "60%",
                  }}
                >
                  {title}
                </Typography>
              );
            }
            return (
              <div>
                {selected.map((value) => (
                  <Typography key={value} noWrap>
                    {value}
                  </Typography>
                ))}
              </div>
            );
          } else {
            if (selected === "") {
              return (
                <Typography
                  noWrap
                  sx={{
                    color: "gray", // DefineStyle.placeholderColor or similar
                    opacity: 0.4,
                    fontSize: "0.875rem", // DefineStyle.fontSize or similar
                    py: 0.15,
                    maxWidth: "60%",
                  }}
                >
                  {title}
                </Typography>
              );
            }
            return selected;
          }
        }}
        sx={{
          maxWidth: "100%",
          background: "white", // DefineStyle.bgColor or similar
          "& .MuiSelect-select": {
            padding: "11px 8px 10px 8px",
            fontSize: "0.875rem", // DefineStyle.fontSize or similar
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "gray", // DefineStyle.borderColor or similar
          },
        }}
      >
        <MenuItem disabled value="" sx={{ fontSize: "0.875rem" }}>
          {" "}
          {/* DefineStyle.fontSize or similar */}
          <Typography noWrap>{title}</Typography>
        </MenuItem>
        {options.map((option, index) =>
          typeof option === "object" ? (
            <MenuItem key={index} value={option.value}>
              <ListItemText primary={option.key} />
            </MenuItem>
          ) : (
            <MenuItem
              key={index}
              value={option}
              sx={{ fontSize: "12px", fontWeight: 600 }}
            >
              {option}
            </MenuItem>
          )
        )}
      </Select>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

//   const CustomRadio = ({ title, defaultValue, options = [] }) => {
//     return (
//       <RadioGroup
//         row
//         name={`${title}-radio-buttons-group`}
//         defaultValue={defaultValue}
//       >
//         {options.map((option, index) => {
//           if (typeof option === "object") {
//             return (
//               <FormControlLabel
//                 key={index}
//                 value={option.value}
//                 control={
//                   <Radio
//                     size="small"
//                     sx={{
//                       color: DefineStyle.placeholderColor,
//                       opacity: 0.5,
//                       py: 0.7,
//                       "&.Mui-checked": {
//                         color: theme.palette.warning.main,
//                       },
//                     }}
//                   />
//                 }
//                 label={option.label}
//                 disabled={option.disabled || false} // Handle disabled option
//               />
//             );
//           } else {
//             return (
//               <FormControlLabel
//                 key={index}
//                 value={option}
//                 control={
//                   <Radio
//                     size="small"
//                     sx={{
//                       color: DefineStyle.placeholderColor,
//                       opacity: 0.5,
//                       py: 0.7,
//                       "&.Mui-checked": {
//                         color: "primary.main",
//                         opacity: 1,
//                       },
//                     }}
//                   />
//                 }
//                 label={option} // Assuming the option value and label are the same
//                 sx={{
//                   "& .MuiFormControlLabel-label": {
//                     fontSize: DefineStyle.fontSize,
//                     color: DefineStyle.placeholderColor,
//                     opacity: 0.5,
//                   },
//                 }}
//               />
//             );
//           }
//         })}
//       </RadioGroup>
//     );
//   };
const CustomRadio = ({
  title,
  defaultValue,
  options = [],
  onChange,
  error = false,
  errorMessage = "",
}) => {
  const handleRadioChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(newValue); // Pass only the value to the handler
    }
  };
  return (
    <FormControl fullWidth error={error} size="small">
      <RadioGroup
        row
        name={`${title}-radio-buttons-group`}
        value={defaultValue} // Use value for controlled component
        onChange={handleRadioChange} // Handle changes
      >
        {options.map((option, index) => {
          const value = typeof option === "object" ? option.value : option;
          const label = typeof option === "object" ? option.label : option;

          return (
            <FormControlLabel
              key={index}
              value={value}
              control={
                <Radio
                  size="small"
                  sx={{
                    color: DefineStyle.placeholderColor,
                    opacity: 0.5,
                    py: 0.7,
                    "&.Mui-checked": {
                      opacity: 1,
                      color: theme.palette.primary.main,
                    },
                  }}
                />
              }
              label={label}
              disabled={typeof option === "object" && option.disabled} // Handle disabled option
            />
          );
        })}
      </RadioGroup>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const CustomSwitch = ({
  title,
  defaultValue,
  options = ["Yes", "No"],
  onChange,
  error = false,
  errorMessage = "",
}) => {
  // Manage the state of the switch
  // const [checked, setChecked] = useState(defaultValue);

  // Handle switch toggle
  const handleChange = (event) => {
    // setChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <FormControl fullWidth error={error} size="small">
      <Box display="flex" alignItems="center">
        <Switch
          checked={defaultValue}
          onChange={handleChange}
          sx={{
            width: 50,
            height: 26,
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: "4px",
              transitionDuration: "300ms",
              color: DefineStyle.boderColor,
              "&.Mui-checked": {
                transform: "translateX(24px)",
                color: theme.palette.primary.main,
                "& + .MuiSwitch-track": {
                  backgroundColor: theme.palette.grey[200],
                  opacity: 1,
                  border: 0,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                  theme.palette.mode === "light"
                    ? "red"
                    : theme.palette.grey[600],
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                //opacity: theme.palette.mode === "light" ? 1 : 1,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 18,
              height: 18,
            },
            "& .MuiSwitch-track": {
              borderRadius: 13,
              backgroundColor: theme.palette.grey[200],
              opacity: 1,
            },
          }}
        />

        <Typography
          sx={{
            marginLeft: 1,
            fontSize: DefineStyle.fontSize,
            color: defaultValue
              ? theme.palette.primary.main
              : DefineStyle.placeholderColor,
            opacity: defaultValue ? 1 : 0.5,
          }}
        >
          {defaultValue ? options[0] : options[1]}
        </Typography>
      </Box>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

const CustomDatePicker = ({
  placeholder,
  defaultValue,
  readOnly = false,
  multiline = false,
  onChange,
}) => {
  const handleChangeDate = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <React.Fragment>
      <Box>
        <DatePicker
          sx={{
            width: "100%",
            borderRadius: "0px",
            background: DefineStyle.bgColor,
            "& .MuiOutlinedInput-root": {
              fontSize: DefineStyle.fontSize,
              "& fieldset": {
                borderColor: theme.palette.grey[500],
                borderColor: DefineStyle.boderColor,
              },
            },
            "& .MuiInputBase-input": {
              padding: "12px 8px 12px 8px", // Adjust padding as needed
            },
          }}
          defaultValue={defaultValue} // Corrected syntax here
          onChange={handleChangeDate}
        />
      </Box>
    </React.Fragment>
  );
};

const FormSection = ({ title, info, sectionInfo, required, components }) => {
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box>
          <FormSectionTitle title={title} info={info} required={required} />
        </Box>
        <Box
          sx={{
            display: "flex",
            rowGap: 1,
            columnGap: 1,
            flexWrap: "wrap",
          }}
        >
          {components.map((component, index) => {
            const ComponentType = getComponentType(component.type);
            return (
              <Box key={index} sx={{ flex: 1, flexBasis: "120px" }}>
                <ComponentType {...component.props} sectionTitle={title} />
              </Box>
            );
          })}
        </Box>
        {sectionInfo && (
          <Box sx={{ px: 0.5, mt: -0.5 }}>
            <Typography
              noWrap
              sx={{
                fontSize: 12,
                color: DefineStyle.placeholderColor,
                letterSpacing: 0.5,
              }}
            >
              {sectionInfo}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};

// Helper function to get the correct component type
const getComponentType = (type) => {
  switch (type) {
    case "select":
      return CustomSelect;
    case "text":
      return CustomText;
    case "datepicker":
      return CustomDatePicker;
    case "radio":
      return CustomRadio;
    case "switch":
      return CustomSwitch;
    case "textEditor":
      return CustomTextEditor;
    default:
      return null;
  }
};

const AddApplicantForm = ({
  firstName,
  setFirstName,
  middleName,
  setMiddleName,
  lastName,
  setLastName,
  gender,
  setGender,
  emailAddress,
  mobileNumbers,
  setMobileNumbers,
  setEmailAddress,
  state,
  setState,
  city,
  setCity,
  zipCode,
  setZipCode,
  resume,
  setResume,
  experienceYears,
  setExperienceYears,
  experienceMonths,
  setExperienceMonths,
  jobTitle,
  setJobTitle,
  source,
  setSource,
  availability,
  setAvailability,
  payRateSalary,
  setPayRateSalary,
  skills,
  setSkills,
  workAuthorization,
  setWorkAuthorization,
  relocate,
  setRelocate,
  errors,
  resumeSourcesNames,
  educationDetails,
  setEducationDetails,
  degreesNames,
  editApplicantModalOpen,
  selectApplicatResumes,
  setSelectApplicantResumes,
}) => {
  const handleInputChangeEducation = (index, fieldName, value) => {
    // Create a copy of the current educationDetails array
    const updatedEducationDetails = [...educationDetails];
  
    // Update the specific field for the given index
    updatedEducationDetails[index] = {
      ...updatedEducationDetails[index],
      [fieldName]: value,
    };
  
    // Set the updated education details back to the state
    setEducationDetails(updatedEducationDetails);
  };
  

  
  // Handler function to update nested fields in payRateSalary
const handleNestedInputChangePayRate = (field, value) => {
  setPayRateSalary((prevPayRateSalary) => ({
    ...prevPayRateSalary,
    [field]: value,
  }));
};

  const [selectOptionResume, setSelectOptionResume] = useState(false);
  const [changeResume, setChangeResume] = useState("");
  const handleModalResumeChange = (value) => {
    setSelectOptionResume(!selectOptionResume);
    setChangeResume(value);
  };
  const handleSelectOptionResumeChanges = (option) => {
    if (option === "existingResumeNewVersion") {
      const filteredData = selectApplicatResumes.filter(item => item.originalFileName);
      const lastResume =
      filteredData[filteredData.length - 1];
      const { document, version, createdBy } = lastResume;

      // Increment version
      const updatedVersion = version + 1;

      // Update state immutably
      setSelectApplicantResumes([
        ...filteredData,
        { document, version: updatedVersion, createdBy },
      ]);
    } else if (option === "addNewResume") {
      const filteredData = selectApplicatResumes.filter(item => item.originalFileName);
      const lastResume =
      filteredData[filteredData.length - 1];
      const { document, version, createdBy } = lastResume;
      // Increment document and reset version to 1
      const updatedDocument = document + 1;
      setSelectApplicantResumes([
        ...filteredData,
        { document: updatedDocument, version: 1, createdBy },
      ]);
    }
    setResume(changeResume)
    setSelectOptionResume(!selectOptionResume);
  };
  const handleInputChangeMobileNumber = (index, value) => {
    const updatedMobileNumbers = [...mobileNumbers];
    updatedMobileNumbers[index].number = value;
    setMobileNumbers(updatedMobileNumbers);
  };
  // Handlers for email and mobile number changes
const handleEmailChange = (index, value) => {
  setEmailAddress((prevEmails) =>
    prevEmails.map((email, i) => (i === index ? { ...email, email: value } : email))
  );
};
  return (
    <React.Fragment>
      <Box
        sx={{
          px: 4,
          py: 3,
          display: "flex",
          flexDirection: "column",
          gap: 6,
        }}
      >
        <Box>
          <Grid container rowSpacing={5} columnSpacing={3}>
            <Grid item xs={12}>
              <SectionTitle title="Personal Details " />
            </Grid>

            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"First Name"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,
                      defaultValue: firstName,
                      placeholder: "First Name",
                      onChange: (value) => setFirstName(value),
                      error: !!errors.firstName,
                      errorMessage: errors.firstName,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Middle  Name"}
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,
                      placeholder: "Middle  Name",
                      defaultValue: middleName,
                      onChange: (value) => setMiddleName(value),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Last  Name"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: "Last  Name",
                      required: true,
                      defaultValue: lastName,
                      onChange: (value) => setLastName(value),
                      error: !!errors.lastName,
                      errorMessage: errors.lastName,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Gender"}
                required
                components={[
                  {
                    type: "radio",
                    props: {
                      title: "Select Gender",
                      required: true,
                      options: ["Male", "Female"],
                      defaultValue: gender,
                      onChange: (value) => setGender(value),
                      error: !!errors.gender,
                      errorMessage: errors.gender,
                    },
                  },
                ]}
              />
            </Grid>
            {emailAddress.map((email, index) => (
    <Grid item xs={12} sm={2} key={index}>
      <CustomFormField
        title={`${email.type.charAt(0).toUpperCase() + email.type.slice(1)} Email Address`}
        required={email.type === 'primary'} // Primary is required
        components={[
          {
            type: "text",
            props: {
              placeholder: `${email.type.charAt(0).toUpperCase() + email.type.slice(1)} Email Address`,
              required: email.type === 'primary',
              defaultValue: email.email,
              onChange: (value) => handleEmailChange(index, value),
              error: !!errors.primaryEmail, // Check if there's a primary email error
              errorMessage: errors.primaryEmail || '', 
            },
          },
        ]}
      />
    </Grid>
  ))}
            {mobileNumbers.map((mobile, index) => (
  
    <Grid item xs={12} sm={2}key={index}>
      <CustomFormField
        title={`Mobile Number (${mobile.type})`} // Dynamically set title
        required={mobile.type === 'primary'} // Primary is required
        components={[
          {
            type: "text",
            props: {
              placeholder: `${mobile.type} Mobile Number`, // Placeholder based on type
              required: mobile.type === 'primary', // Set required only for primary
              defaultValue: mobile.number, // Default value from the mobile object
              onChange: (value) => handleInputChangeMobileNumber(index, value), // Pass index to update specific mobile number
              error: !!errors.mobileNumber , // Handle error per mobile number
              errorMessage: !!errors.mobileNumber // Show error message for each mobile number
            },
          },
        ]}
      />
    </Grid>
 
))}


            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"State"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: "State",
                      required: true,
                      defaultValue: state,
                      onChange: (value) => setState(value),
                      error: !!errors.state,
                      errorMessage: errors.state,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"City "}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: "City ",
                      required: true,
                      defaultValue: city,
                      onChange: (value) => setCity(value),
                      error: !!errors.city,
                      errorMessage: errors.city,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Zip code"}
                // required
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: "Zip code",
                      required: true,
                      defaultValue: zipCode,
                      onChange: (value) => setZipCode(value),

                      // error: !!errors.zipCode,
                      // errorMessage: errors.zipCode,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <SectionTitle title="Professional Details"/>
            </Grid>

            <Grid item xs={12} sm={4}>
              <CustomFormField
                title={"Upload Resume "}
                required
                components={[
                  {
                    type: "file",
                    props: {
                      placeholder: "Upload Resume",
                      required: true,
                      defaultValue:resume,
                      // onChange: (value) =>handleInputChangeProfessional("resume", value),
                      onChange: (value) =>
                        editApplicantModalOpen
                          ? handleModalResumeChange(value)
                          : setResume(value),
                      error: !!errors.resumes,
                      errorMessage: errors.resumes,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Experience"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: "Years",
                      type: "number",
                      required: true,
                      defaultValue: experienceYears,
                      onChange: (value) => setExperienceYears(value),
                      error: !!errors.years,
                      errorMessage: errors.years,
                    },
                  },
                  {
                    type: "text",
                    props: {
                      placeholder: "Months",
                      type: "number",
                      required: true,
                      defaultValue: experienceMonths,
                      onChange: (value) => setExperienceMonths(value),
                      error: !!errors.months,
                      errorMessage: errors.months,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Job Title"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,
                      defaultValue: jobTitle,
                      onChange: (value) => setJobTitle(value),
                      error: !!errors.title,
                      errorMessage: errors.title,
                    },
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Source of resume"}
                required
                components={[
                  {
                    type: "autocomplete",
                    props: {
                      placeholder: "Source of resume",
                      required: true,
                      options: resumeSourcesNames,
                      defaultValue: source,
                      onChange: (value) => setSource(value),
                      error: !!errors.source,
                      errorMessage: errors.source,
                    },
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Availability"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      placeholder: "Availability",
                      required: true,
                      defaultValue: availability,
                      onChange: (value) => setAvailability(value),
                      error: !!errors.availability,
                      errorMessage: errors.availability,
                    },
                  },
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box>
                <CustomFormField
                  title={"Pay Rate / Salary"}
                  info={"Pay Rate / Salary Info"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Currency",
                        defaultValue: payRateSalary?.currency,
                        options: currencyCodes,
                        onChange: (value) =>
                          handleNestedInputChangePayRate("currency", value),
                      },
                    },
                    {
                      type: "text",
                      props: {
                        required: true,
                        type: "number",
                        placeholder: "min",
                        defaultValue: payRateSalary?.minAmount,
                        onChange: (value) =>
                          handleNestedInputChangePayRate("minAmount", value),
                        error: !!errors.payRateMinAmount,
                        errorMessage: errors.payRateMinAmount,
                      },
                    },
                    {
                      type: "text",
                      props: {
                        required: true,
                        type: "number",
                        placeholder: "max",
                        defaultValue: payRateSalary?.maxAmount,
                        onChange: (value) =>
                          handleNestedInputChangePayRate("maxAmount", value),
                        error: !!errors.payRateMaxAmount,
                        errorMessage: errors.payRateMaxAmount,
                      },
                    },
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: payScheduleTypes,
                        defaultValue: payRateSalary?.paySchedule,
                        onChange: (value) =>
                          handleNestedInputChangePayRate("paySchedule", value),
                        error: !!errors.payRatePaySchedule,
                        errorMessage: errors.payRatePaySchedule,
                      },
                    },
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: employmentTypes,
                        defaultValue: payRateSalary?.taxTerm,
                        onChange: (value) =>
                          handleNestedInputChangePayRate("taxTerm", value),
                        error: !!errors.payRateTaxTerm,
                        errorMessage: errors.payRateTaxTerm,
                      },
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Skills"}
                required
                components={[
                  {
                    type: "autoselect",
                    props: {
                      placeholder: "skills",
                      required: true,
                      defaultValue: skills,
                      onChange: (value) => setSkills(value),
                      error: !!errors.skills,
                      errorMessage: errors.skills,
                    },
                  },
                ]}
              />
              note: Please type and press Enter.
            </Grid>

            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Work Authorization"}
                required={true}
                components={[
                  {
                    type: "select",
                    props: {
                      placeholder: "Work Authorization",
                      required: true,
                      size: 0.5,
                      options: workAuthorizationOptions,
                      // multiple:true,
                      defaultValue: workAuthorization,
                      onChange: (value) => setWorkAuthorization(value),

                      error: !!errors.workAuthorization,
                      errorMessage: errors.workAuthorization,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <CustomFormField
                title={"Ready to relocate"}
                // required={true}
                components={[
                  {
                    type: "switch",
                    props: {
                      required: true,
                      defaultValue: relocate,
                      options: ["Yes", "No"],
                      onChange: (value) => setRelocate(value),
                      // error: !!errors.relocate,
                      // errorMessage: errors.relocate,
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={12}>
              <SectionTitle title="Education Details" />
            </Grid>
            {educationDetails.map((details, index) => {
  return (
    <Grid container rowSpacing={5} columnSpacing={3} key={index}>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"School Name"}
    
          components={[
            {
              type: "text",
              props: {
                placeholder: "Enter the name",
              
                defaultValue: details.schoolName,
                onChange: (value) =>
                  handleInputChangeEducation(index, "schoolName", value),
                // error: !!errors[`educationDetails[${index}].schoolName`],
                // errorMessage: errors[`educationDetails[${index}].schoolName`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"Degree"}
       
          components={[
            {
              type: "autocomplete",
              props: {
                placeholder: "Degree",
               
                size: 0.5,
                options: degreesNames,
                defaultValue: details.degree,
                onChange: (value) =>
                  handleInputChangeEducation(index, "degree", value),
                // error: !!errors[`educationDetails[${index}].degree`],
                // errorMessage: errors[`educationDetails[${index}].degree`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"Year Completed"}
          components={[
            {
              type: "datepicker",
              props: {
             
                placeholder: "Add Job Start Date",
                defaultValue: details.yearCompleted,
                onChange: (value) =>
                  handleInputChangeEducation(index, "yearCompleted", value),
                // error: !!errors[`educationDetails[${index}].yearCompleted`],
                // errorMessage: errors[`educationDetails[${index}].yearCompleted`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"Major Study"}
          components={[
            {
              type: "text",
              props: {
                placeholder: "",
                defaultValue: details.major,
                onChange: (value) =>
                  handleInputChangeEducation(index, "major", value),
                // error: !!errors[`educationDetails[${index}].major`],
                // errorMessage: errors[`educationDetails[${index}].major`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"Minor Study"}
          components={[
            {
              type: "text",
              props: {
                placeholder: "",
                defaultValue: details.minor,
                onChange: (value) =>
                  handleInputChangeEducation(index, "minor", value),
                // error: !!errors[`educationDetails[${index}].minor`],
                // errorMessage: errors[`educationDetails[${index}].minor`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"GPA"}
          components={[
            {
              type: "text",
              props: {
                placeholder: "",
                defaultValue: details.gpa,
                onChange: (value) =>
                  handleInputChangeEducation(index, "gpa", value),
                // error: !!errors[`educationDetails[${index}].gpa`],
                // errorMessage: errors[`educationDetails[${index}].gpa`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"Country"}
         
          components={[
            {
              type: "select",
              props: {
                placeholder: "",
                // required: true,
                size: 0.5,
                options: countries,
                defaultValue: details.country,
                onChange: (value) =>
                  handleInputChangeEducation(index, "country", value),
                // error: !!errors[`educationDetails[${index}].country`],
                // errorMessage: errors[`educationDetails[${index}].country`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"State"}
          // required={true}
          components={[
            {
              type: "select",
              props: {
                placeholder: "",
                // required: true,
                size: 0.5,
                options: states,
                defaultValue: details.state,
                onChange: (value) =>
                  handleInputChangeEducation(index, "state", value),
                // error: !!errors[`educationDetails[${index}].state`],
                // errorMessage: errors[`educationDetails[${index}].state`],
              },
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CustomFormField
          title={"City"}
          // required
          components={[
            {
              type: "text",
              props: {
                placeholder: "",
                defaultValue: details.city,
                onChange: (value) =>
                  handleInputChangeEducation(index, "city", value),
                // error: !!errors[`educationDetails[${index}].city`],
                // errorMessage: errors[`educationDetails[${index}].city`],
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
})}

          </Grid>
        </Box>
      </Box>
      <Modal
        open={selectOptionResume}
        //onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Please select any one option
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectOptionResumeChanges("existingResumeNewVersion")}
            >
              Add Existing Resume New Version
            </Button>
            <Button
              variant="contained"
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSelectOptionResumeChanges("addNewResume")}
            >
              Add Another New Resume
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AddApplicantForm;
