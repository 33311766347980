// import React, { useState, useEffect } from 'react';
// import Typography from '@mui/material/Typography'; // Assuming you're using Material-UI

// const ResumeViewer = ({ AwsResumeUrl }) => {
//   const [txtContent, setTxtContent] = useState('');
//   const fileExtension = AwsResumeUrl?.split(".")
//   .pop()
//   .split("?")[0]
//   .toLowerCase();

//   useEffect(() => {
//     // Fetch TXT file content if the format is TXT
//     if (fileExtension === 'txt') {
//       const fetchTxtFile = async () => {
//         try {
//           const response = await fetch(AwsResumeUrl);
//           if (!response.ok) {
//             throw new Error('Network response was not ok');
//           }
//           const text = await response.text();
//           setTxtContent(text);
//         } catch (error) {
//           console.error('Error fetching TXT file:', error);
//           setTxtContent('Error loading TXT file content.');
//         }
//       };
//       fetchTxtFile();
//     }
//   }, [AwsResumeUrl, fileExtension]);

//   if (!AwsResumeUrl) {
//     return (
//       <Typography variant="h6" textAlign="center">
//         No resume available.
//       </Typography>
//     );
//   }

//   if (fileExtension === 'pdf') {
//     return (
//       <iframe
//         src={AwsResumeUrl}
//         title="Resume PDF"
//         style={{ width: '100%', height: '900px', border: 'none' }}
//       />
//     );
//   } else if (fileExtension === 'docx' || fileExtension === 'doc') {
//     const microsoftViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(AwsResumeUrl)}`;
//     return (
//       <iframe
//         src={microsoftViewerUrl}
//         style={{ width: '100%', height: '900px', border: 'none' }}
//         title="Resume DOCX"
//       />
//     );
//   } else if (fileExtension === 'txt') {
//     return (
//       <div style={{ whiteSpace: 'pre-wrap', padding: '20px', border: '1px solid #ccc' }}>
//         <h6>Resume TXT Content:</h6>
//         <pre>{txtContent}</pre>
//       </div>
//     );
//   } else {
//     // Fallback for unsupported formats
//     return (
//       <Typography variant="h6" textAlign="center">
//         The file format is not supported for inline viewing. Please{" "}
//         <a href={AwsResumeUrl} target="_blank" rel="noopener noreferrer">
//           download the resume
//         </a>
//         .
//       </Typography>
//     );
//   }
// };

// export default ResumeViewer;






import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography'; 

const ResumeViewer = ({ AwsResumeUrl }) => {
  const [txtContent, setTxtContent] = useState('');
   const fileExtension = AwsResumeUrl?.split(".")
  .pop()
  .split("?")[0]
  .toLowerCase();

  useEffect(() => {
    // Fetch TXT or RTF file content if the format is TXT or RTF
    if (fileExtension === 'txt' || fileExtension === 'rtf') {
      const fetchFileContent = async () => {
        try {
          const response = await fetch(AwsResumeUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const text = await response.text();
          setTxtContent(text);
        } catch (error) {
          setTxtContent(`Error loading ${fileExtension.toUpperCase()} file content.`);
        }
      };
      fetchFileContent();
    }
  }, [AwsResumeUrl, fileExtension]);

  if (!AwsResumeUrl) {
    return (
      <Typography variant="h6" textAlign="center">
        No resume available.
      </Typography>
    );
  }

  if (fileExtension === 'pdf') {
    return (
      <iframe
        src={AwsResumeUrl}
        title="Resume PDF"
        style={{ width: '100%', height: '900px', border: 'none' }}
      />
    );
  } else if (fileExtension === 'docx' || fileExtension === 'doc') {
    const microsoftViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(AwsResumeUrl)}`;
    return (
      <iframe
        src={microsoftViewerUrl}
        style={{ width: '100%', height: '900px', border: 'none' }}
        title="Resume DOCX"
      />
    );
  } else if (fileExtension === 'txt' || fileExtension === 'rtf') {
    return (
      <div style={{ whiteSpace: 'pre-wrap', padding: '20px', border: '1px solid #ccc' }}>
        <h6>Resume {fileExtension.toUpperCase()} Content:</h6>
        <pre>{txtContent}</pre>
      </div>
    );
  } else if (fileExtension === 'html' || fileExtension === 'htm') {
    return (
      <iframe
        src={AwsResumeUrl}
        title="Resume HTML"
        style={{ width: '100%', height: '900px', border: 'none' }}
      />
    );
  } else {
    // Fallback for unsupported formats
    return (
      <Typography variant="h6" textAlign="center">
        The file format is not supported for inline viewing. Please{" "}
        <a href={AwsResumeUrl} target="_blank" rel="noopener noreferrer">
          download the resume
        </a>
        .
      </Typography>
    );
  }
};

export default ResumeViewer;
