export const JOB_POST_RECRUITER_VIA_API='JOB_POST_RECRUITER_VIA_API'
export const SEARCH_JOB_POST_RECRUITER_VIA_API='SEARCH_JOB_POST_RECRUITER_VIA_API'
export const  SUCCESS_JOB_POST_RECRUITER='SUCCESS_JOB_POST_RECRUITER';
export const DELETE_RECRUITER='DELETE_RECRUITER';

export const SEARCH_DATE_JOB_POSTING_DATA_VIA_API='SEARCH_DATE_JOB_POSTING_DATA_VIA_API'
export const SUCCESS_SEARCH_DATE_JOB_POSTING_DATA='SUCCESS_SEARCH_DATE_JOB_POSTING_DATA';
export const JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API='JOB_POST_ASSIGNED_TO_RECRUITER_VIA_API';

export const ADD_APPLICANT_VIA_API='ADD_APPLICANT_VIA_API';
export const SUCCESS_ADD_APPLICANT='SUCCESS_ADD_APPLICANT';
export const GET_ALL_APPLICANTS_VIA_API='GET_ALL_APPLICANTS_VIA_API';

export const GET_ALL_RESUMESOURCES_VIA_API='GET_ALL_RESUMESOURCES_VIA_API';
export const SUCCESS_ALL_RESUMESOURCES='SUCCESS_ALL_RESUMESOURCES';
 

export const ADD_RESUMESOURCES_VIA_API='ADD_RESUMESOURCES_VIA_API';

export const ADD_APPLICANT_NOTE_VIA_API='ADD_APPLICANT_NOTE_VIA_API';

export const TAG_APPLICANT_TO_JOB_VIA_API='TAG_APPLICANT_TO_JOB_VIA_API';

export const GET_ALL_DEGREES_VIA_API='GET_ALL_DEGREES_VIA_API';
export const ADD_DEGREES_VIA_API='ADD_DEGREES_VIA_API';
export const SUCCESS_DEGREES='SUCCESS_DEGREES';
export const AWS_RESUME_URL_VIA_API='AWS_RESUME_URL_VIA_API';
export const SUCCESS_AWS_RESUME_URL='SUCCESS_AWS_RESUME_URL';
export const SUCCESS_HTML_RESUME_CONVERT='SUCCESS_HTML_RESUME_CONVERT'

export const GET_PAGINATION_APPLICANT_VIA_API='GET_PAGINATION_APPLICANT_VIA_API';
export const GET_TOTAL_APPLICANTS_COUNT_VIA_API='GET_TOTAL_APPLICANTS_COUNT_VIA_API';
export const SUCCESS_TOTAL_APPLICANTS_COUNT='SUCCESS_TOTAL_APPLICANTS_COUNT';
export const TAG_AND_SUBMIT_APPLICANTS_API='TAG_AND_SUBMIT_APPLICANTS_API';

export const CHANGE_JOB_STATUS_VIA_API='CHANGE_JOB_STATUS_VIA_API';

export const CHANGE_APPLICANT_STATUS_VIA_API='CHANGE_APPLICANT_STATUS_VIA_API';
export const JOB_CLOSE_REQUEST_ADMIN_VIA_API='JOB_CLOSE_REQUEST_ADMIN_VIA_API';

export const SUBMIT_NOTE_REQUEST = 'SUBMIT_NOTE_REQUEST';
export const CHANGE_STATUS_OF_APPLICANT_VIA_API = 'CHANGE_STATUS_OF_APPLICANT_VIA_API';

export const PARSE_RESUME_VIA_API='PARSE_RESUME_VIA_API'
export const SUCCESS_PARSE_RESUME="SUCCESS_PARSE_RESUME";









