import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Chip,
  Grid,
} from "@mui/material";

import {
  CustomDateFilter,
  DataWidget,
  FilterHeader,
  MainBackground,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import theme from "../../Theme";

import dayjs from "dayjs";
import {
  searchRAReportsViaApi,
  searchRaTeamReportsViaApi,
  searchRATeamStatusViaApi,
  stopLoadingRedux,
} from "../../store/reports/action";
import { getAllUsers } from "../../store/auth/login/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import Chart from "react-google-charts";
import {
  getCompanies,
  raWeekReportsViaApi,
  yesterDayaRAReportsViaApi,
} from "../../store/search/action";
import companyStatus from "../../Components/StatusElements/companyStatus";
import { ShimmerTable } from "react-shimmer-effects";
const RADashboard = () => {
  const {
    companyBlocked,
    companyDNC,
    companyNoFollowUp,
    employeeDNC,
    employeeBlocked,
    companyOnProcess,
  } = companyStatus;

  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client = storeData.clientDb;
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
  }, []);
  const raReports = useSelector((state) => state.Reports?.raReports);
  const raTeamReports = useSelector((state) => state.Reports?.raTeamReports);
  const raTeamStatus = useSelector((state) => state.Reports?.raTeamStatus);

  const raWeekReports = useSelector((state) => state.Reports?.raWeekReports);
  const yesterDayRAReports = useSelector(
    (state) => state.Reports?.yesterDayRAReports
  );
  const [loading, setLoading] = useState(true);
  const [loadingTeamReport, setLoadingTeamReport] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const filteredUsers = allUsers.filter(
    (user) =>
      user.userType.includes("RATeam") &&
      !(
        user.userEmailId === "ratest@tektreeinc.com" ||
        user.userEmailId === "lilyra@tektreeinc.com" ||
        user.userEmailId === "oliviara@tektreeinc.com" ||
        user.userEmailId === "emmara@tektreeinc.com" ||
        user.userEmailId === "charlottera@tektreeinc.com" ||
        user.userEmailId === "danielra@tektreeinc.com" ||
        user.userEmailId === "miara@tektreeinc.com" ||
        user.userEmailId === "williamra@tektreellc.com"
      )
  );

  const companiesList =
    useSelector((state) => state.Search.companiesList) || [];
  let filterCompleteCompaniesCount = 0;
  let filterYesterDayaCompleteCompaniesCount = 0;
  let filterTotalCompaniesCount = 0;
  let filterYesterDataTotalCompaniesCount = 0;
  // Iterate over the data to count completed entries
  if (Array.isArray(companiesList)) {
    companiesList.forEach((entry) => {
      if (
        entry.jobDetails &&
        entry.contactDetails &&
        entry.jobDetails.length > 0 &&
        entry.contactDetails.length > 0
      ) {
        filterCompleteCompaniesCount++; // Increment the count directly
      }
    });
  }

  filterTotalCompaniesCount = Array.isArray(companiesList)
    ? companiesList.length
    : [];

  filterYesterDataTotalCompaniesCount = Array.isArray(yesterDayRAReports)
    ? yesterDayRAReports.length
    : [];

  if (Array.isArray(yesterDayRAReports)) {
    yesterDayRAReports.forEach((company) => {
      if (
        company.jobDetails &&
        company.contactDetails &&
        company.jobDetails.length > 0 &&
        company.contactDetails.length > 0
      ) {
        filterYesterDayaCompleteCompaniesCount++;
      }
    });
  }

  const [searchRADashboardCompanyName, setSearchRADashboardCompanyName] =
    useState("");
  const [searchRADashboardJobPosition, setSearchRADashboardjobPosition] =
    useState("");
  const [searchRADasboardBDMName, setSearchRADashboardBDMName] = useState([]);
  const [searchRADashboardStatus, setSearchRADashboardStatus] = useState([]);
  const formatDate = (dateString) => {
    const date = moment.tz(dateString, "UTC"); // Parse date in UTC
    return date.tz("Asia/Kolkata").format("DD-MM-YYYY"); // Convert to Kolkata time and format
  };
  const optionsDaySelect = ["Today", "Yesterday", "Lastweek", "Custom Date"];
  const [selectDate, setSelectDate] = useState(optionsDaySelect[0]);

  const handleChangeSelectDate = (event) => {
    setSelectDate(event.target.value);
  };

  const RaTeamDetails = [
    {
      id: 1,
      bdmName: "RA 01",
      companyNo: 98,
      lastDayCompanyNo: 90,
      completedCompanyNo: 3,
      contactNo: 7,
      lastDayContactNo: 286,
      lastDaySpam: 20,
      lastDayBounced: 12,
      lastDayUnread: 2,
      jobOpeningsNo: 27,
      lastDayJobOpeningsNo: 18,
      job: {
        Openings: {
          title: "Job Openings",
          value: 27,
        },
        Closed: {
          title: "Job Closed",
          value: 23,
        },
        hold: {
          title: "Job hold",
          value: 23,
        },
        backout: {
          title: "Job Backout",
          value: 23,
        },
        fille: {
          title: "Job Fille",
          value: 21,
        },
        interviewL1: {
          title: "Interview L1",
          value: 12,
        },
        interviewL2: {
          title: "Interview L2",
          value: 12,
        },
        interviewL3: {
          title: "Interview L3",
          value: 12,
        },
        agreement: {
          title: "Agreement",
          value: 12,
        },
        priority: {
          title: "Priority",
          value: 12,
        },
        placed: {
          title: "Placed",
          value: 12,
        },
      },
    },
    {
      id: 2,
      bdmName: "RA 02",
      companyNo: 74,
      lastDayCompanyNo: 68,
      completedCompanyNo: 15,
      contactNo: 45,
      lastDayContactNo: 184,
      lastDaySpam: 15,
      lastDayBounced: 32,
      lastDayUnread: 6,
      jobOpeningsNo: 34,
      lastDayJobOpeningsNo: 44,
    },
    {
      id: 3,
      bdmName: "RA 03",
      companyNo: 72,
      lastDayCompanyNo: 80,
      completedCompanyNo: 40,
      contactNo: 108,
      lastDayContactNo: 227,
      lastDaySpam: 38,
      lastDayBounced: 5,
      lastDayUnread: 16,
      jobOpeningsNo: 13,
      lastDayJobOpeningsNo: 10,
    },
    {
      id: 4,
      bdmName: "RA 04",
      companyNo: 40,
      lastDayCompanyNo: 98,
      completedCompanyNo: 37,
      contactNo: 105,
      lastDayContactNo: 248,
      lastDaySpam: 8,
      lastDayBounced: 55,
      lastDayUnread: 6,
      jobOpeningsNo: 22,
      lastDayJobOpeningsNo: 8,
    },
    {
      id: 5,
      bdmName: "RA 05",
      companyNo: 40,
      lastDayCompanyNo: 98,
      completedCompanyNo: 37,
      contactNo: 105,
      lastDayContactNo: 248,
      lastDaySpam: 8,
      lastDayBounced: 55,
      lastDayUnread: 6,
      jobOpeningsNo: 22,
      lastDayJobOpeningsNo: 8,
    },
    {
      id: 6,
      bdmName: "RA 06",
      companyNo: 97,
      lastDayCompanyNo: 75,
      completedCompanyNo: 97,
      contactNo: 295,
      lastDayContactNo: 248,
      lastDaySpam: 3,
      lastDayBounced: 2,
      lastDayUnread: 1,
      jobOpeningsNo: 62,
      lastDayJobOpeningsNo: 8,
    },
  ];

  const RaTeamDetailsColumn = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "addedBy",
      headerName: "RA Name",
      width: 150,
      minWidth: 300,
      sortable: false,
      valueGetter: (value, row) => {
        if (row.addedBy) {
          const userData = allUsers.find((user) => user._id === row.addedBy);
          const raName = userData?.userFirstName ?? "";
          return raName;
        }
      },
    },
    {
      field: "companyNo",
      headerName: "Total Companies",
      width: 150,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const companies = params?.row?.companies || [];
      
        const totalJobCount = companies.reduce((count, company) => {
          const jobDetails = company?.jobDetails || [];
          
          const activeJobs = jobDetails.filter(
            (job) => job.status === "Active"
          );
          
          return count + activeJobs.length;
        }, 0);
      
        return totalJobCount;
      },
      
    },
    {
      field: "completedCompanyNo",
      headerName: "Completed Companies",
      width: 250,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const companies = Array.isArray(params?.row?.companies) ? params.row.companies : [];
        const completedCompanyNo = companies.reduce((count, company) => {
          if (
            Array.isArray(company?.jobDetails) && 
            Array.isArray(company?.contactDetails) &&
            company.jobDetails.length > 0 &&
            company.contactDetails.length > 0
          ) {
            const activeJobs = company.jobDetails.filter(
              (job) => job.status === "Active"
            );
            return count + activeJobs.length;
          }
          return count;
        }, 0);
      
        return completedCompanyNo;
      },
      
          
    },

    {
      field: "contactNo",
      headerName: "Total Contacts",
      width: 250,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const companies = params?.row?.companies || [];
        const contactDetailsCount = companies
          .filter((company) => company.status === "Active") // Filter for companies with status "Active"
          .reduce((count, company) => {
            return (
              count +
              (Array.isArray(company.contactDetails)
                ? company.contactDetails.length
                : 0)
            );
          }, 0);
        return contactDetailsCount;
      },
    },
    {
      field: "jobPositivesNo",
      headerName: "Total Job Positives",
      width: 250,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const companies = Array.isArray(params?.row?.companies) ? params.row.companies : [];
        const positiveJobCount = companies.reduce((count, company) => {
          const positiveJobs = Array.isArray(company?.jobDetails) ? company.jobDetails.filter(
            (job) => job.status === "Positive"
          ) : [];
          return count + positiveJobs.length;
        }, 0);
      
        return positiveJobCount;
      },      
    },
    {
      field: "jobOpeningsNo",
      headerName: "Total Job Openings",
      width: 250,
      minWidth: 300,
      sortable: false,
      renderCell: (params) => {
        const companies = Array.isArray(params?.row?.companies) ? params.row.companies : [];
        const openJobCount = companies.reduce((count, company) => {
          const openJobs = Array.isArray(company?.jobDetails) ? company.jobDetails.filter(
            (job) => job.status === "Open"
          ) : [];
          return count + openJobs.length;
        }, 0);
      
        return openJobCount;
      },
      
    },
  ];

  const JobOpenings = [
    {
      id: 1,
      bdmName: "bdmName 1",
      companyName: "Company Name 1",
      jobTitle: "Job Title 1",
      jobLocation: "Job Location 1",
    },
    {
      id: 2,
      bdmName: "bdmName 2",
      companyName: "Company Name 2",
      jobTitle: "Job Title 2",
      jobLocation: "Job Location 2",
    },
    {
      id: 3,
      bdmName: "bdmName 3",
      companyName: "Company Name 3",
      jobTitle: "Job Title 3",
      jobLocation: "Job Location 3",
    },
    {
      id: 4,
      bdmName: "bdmName 4",
      companyName: "Company Name 4",
      jobTitle: "Job Title 4",
      jobLocation: "Job Location 4",
    },
    {
      id: 5,
      bdmName: "bdmName 5",
      companyName: "Company Name 5",
      jobTitle: "Job Title 5",
      jobLocation: "Job Location 5",
    },
    {
      id: 6,
      bdmName: "bdmName 6",
      companyName: "Company Name 6",
      jobTitle: "Job Title 6",
      jobLocation: "Job Location 6",
    },
    {
      id: 7,
      bdmName: "bdmName 7",
      companyName: "Company Name 7",
      jobTitle: "Job Title 7",
      jobLocation: "Job Location 7",
    },
    {
      id: 8,
      bdmName: "bdmName 8",
      companyName: "Company Name 8",
      jobTitle: "Job Title 8",
      jobLocation: "Job Location 8",
    },
  ];

  

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [totalCompanyNo, setTotalCompanyNo] = useState(0);
  const [totalLastDayCompanyNo, setTotalLastDayCompanyNo] = useState(0);
  const [totalLastDayContactNo, setTotalLastDayContactNo] = useState(0);

  const [totalJobOpeningsNo, setTotalJobOpeningsNo] = useState(0);
  const [totalLastDayJobOpeningsNo, setTotalLastDayJobOpeningsNo] = useState(0);

  const handleSelectionChange = (newSelectionModel) => {
    setSelectedRowIds(newSelectionModel);
  };

  // }
  const [searchToDate, setSearchToDate] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");

  const handleDateChange = (selectedDates) => {
    setSearchToDate("");
    setSearchFromDate("");
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        raUsers:
          filteredUsers &&
          filteredUsers.filter((user) => user.userType.includes("RATeam")),
        Id: storeData._id,
        client: client,
      };
      setLoading(true);
      dispatch(getCompanies(action));
      dispatch(searchRAReportsViaApi(action));
      dispatch(searchRATeamStatusViaApi(action));
      // Decrease one day from searchFromDate
      action.searchFromDate = dayjs(action.searchFromDate)
        .subtract(1, "day")
        .toISOString();
      // Decrease one day from searchFromDate
      action.searchToDate = dayjs(action.searchToDate)
        .subtract(1, "day")
        .toISOString();
      dispatch(yesterDayaRAReportsViaApi(action));
    }
  };
  const handleDateChangeTeam = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        raUsers:
          filteredUsers &&
          filteredUsers.filter((user) => user.userType.includes("RATeam")),
        Id: storeData._id,
        client: client,
      };
      setLoadingTeamReport(true);
      dispatch(searchRaTeamReportsViaApi(action));
    }
  };

  const columnsRA = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
  
    {
      field: "companyName",
      headerName: "Client Name",
      width: 150,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchRADashboardCompanyName={setSearchRADashboardCompanyName}
          selectedValue={searchRADashboardCompanyName}
          title={"RADashboardCompanyName"}
        />
      ),
    },
    {
      field: "jobTitle",
      headerName: "Job Position",
      width: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          const companies = row.jobDetails;
          return companies[0].jobTitle;
        }
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchRADashboardjobPosition={setSearchRADashboardjobPosition}
          selectedValue={searchRADashboardJobPosition}
          title={"RADashboardJobPosition"}
        />
      ),
    },

    {
      field: "bdmName",
      headerName: "BDM Name",
      width: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.addedBy) {
          const userData = allUsers.find((user) => user._id === row.addedBy);
          const bdmName = userData?.userSudoFirstName ?? "";
          return bdmName;
        }
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setSearchRADashboardBDMName={setSearchRADashboardBDMName}
          selectedValue={searchRADasboardBDMName}
          title={"RADashboardBDMName"}
          options={allUsers
            ?.filter((user) => user.userType.includes("BDM"))
            .filter((user) => user.userSudoFirstName)
            .map((user) => user.userSudoFirstName)}
        />
      ),
    },

    
    {
      field: "addedOn",
      headerName: "BDM Response Date",
      width: 130,
      valueGetter: (value, row) => {
        if (row.addedOn) {
          const dateFormate = formatDate(row.addedOn);
          return dateFormate;
        }
      },
    },
    {
      field: "leadRADate",
      headerName: "Lead Date",
      width: 130,
      valueGetter: (value, row) => {
        if (row.leadRADate) {
          const dateFormate = formatDate(row.leadRADate);
          return dateFormate;
        }
      },
    },
    {
      field: "jobOpeningsNo",
      headerName: "Job Status",
      width: 130,
      sortable: false,

      renderCell: (params) => {
        const { row } = params;
        if (row.status === companyOnProcess && row.jobDetails.length > 0) {
          const company = row.jobDetails[0];
          const status =
            company.status === "Active" ? "Positive" : company.status;

          return (
            <Box>
              <Chip
                label={status}
                size="small"
                sx={{ px: 0.5 }}
                color={
                  status === "Positive"
                    ? "success"
                    : "Open"
                    ? "success"
                    : status === "Close"
                    ? "error"
                    : status === "Hold"
                    ? "warning"
                    : ["Interview L1", "Interview L2", "Interview L3"].includes(
                        status
                      )
                    ? "info"
                    : status === "Priority"
                    ? "secondary"
                    : "default"
                }
              />
            </Box>
          );
        }
        return "";
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          setSearchRADashboardStatus={setSearchRADashboardStatus}
          title={"RADasboardStatus"}
          selectedValue={searchRADashboardStatus}
          type="multiselect"
          options={[
            "Positive",
            "Open",
            "Close",
            "Blocked",
            "Hold",
            "Interview L1",
            "Interview L2",
            "Interview L3",
          ]}
        />
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Download",
    //   width: 88,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100%",
    //       }}
    //     >
    //       <IconButton
    //         // onClick={() =>
    //         //   handleDownloadModalOpen("actionDownload", params.row)
    //         // }
    //       >
    //         <GetAppIcon />
    //       </IconButton>
    //     </Box>
    //   ),
    // },
  ];
  const columnsRAs = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "raName",
      headerName: "RA Name",
      width: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.leadRAId) {
          const raData = allUsers.find((user) => user._id === row.leadRAId);
          const leadRaName = raData?.userFirstName ?? "";
          return leadRaName;
        }
      },
    },
    
    {
      field: "companyName",
      headerName: "Client Name",
      width: 150,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchRADashboardCompanyName={setSearchRADashboardCompanyName}
          selectedValue={searchRADashboardCompanyName}
          title={"RADashboardCompanyName"}
        />
      ),
    },
    {
      field: "jobTitle",
      headerName: "Job Position",
      width: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          const companies = row.jobDetails;
          return companies[0].jobTitle;
        }
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="line"
          setSearchRADashboardjobPosition={setSearchRADashboardjobPosition}
          selectedValue={searchRADashboardJobPosition}
          title={"RADashboardJobPosition"}
        />
      ),
    },

    {
      field: "bdmName",
      headerName: "BDM Name",
      width: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.addedBy) {
          const userData = allUsers.find((user) => user._id === row.addedBy);
          const bdmName = userData?.userSudoFirstName ?? "";
          return bdmName;
        }
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setSearchRADashboardBDMName={setSearchRADashboardBDMName}
          selectedValue={searchRADasboardBDMName}
          title={"RADashboardBDMName"}
          options={allUsers
            ?.filter((user) => user.userType.includes("BDM"))
            .filter((user) => user.userSudoFirstName)
            .map((user) => user.userSudoFirstName)}
        />
      ),
    },
    {
      field: "addedBy",
      headerName: "BDM Response Date",
      width: 130,
      valueGetter: (value, row) => {
        if (row.addedOn) {
          const dateFormate = formatDate(row.addedOn);
          return dateFormate;
        }
      },
    },
    {
      field: "leadRADate",
      headerName: "Lead Date",
      width: 130,
      valueGetter: (value, row) => {
        if (row.leadRADate) {
          const dateFormate = formatDate(row.leadRADate);
          return dateFormate;
        }
      },
    },
    {
      field: "jobOpeningsNo",
      headerName: "Job Status",
      width: 130,
      sortable: false,

      renderCell: (params) => {
        const { row } = params;
        if (row.status === companyOnProcess && row.jobDetails.length > 0) {
          const company = row.jobDetails[0];
          const status =
            company.status === "Active" ? "Positive" : company.status;

          return (
            <Box>
              <Chip
                label={status}
                size="small"
                sx={{ px: 0.5 }}
                color={
                  status === "Positive"
                    ? "success"
                    : "Open"
                    ? "success"
                    : status === "Close"
                    ? "error"
                    : status === "Hold"
                    ? "warning"
                    : ["Interview L1", "Interview L2", "Interview L3"].includes(
                        status
                      )
                    ? "info"
                    : status === "Priority"
                    ? "secondary"
                    : "default"
                }
              />
            </Box>
          );
        }
        return "";
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          setSearchRADashboardStatus={setSearchRADashboardStatus}
          title={"RADasboardStatus"}
          selectedValue={searchRADashboardStatus}
          type="multiselect"
          options={[
            "Positive",
            "Open",
            "Close",
            "Blocked",
            "Hold",
            "Interview L1",
            "Interview L2",
            "Interview L3",
          ]}
        />
      ),
    },
    // {
    //   field: "actions",
    //   headerName: "Download",
    //   width: 88,
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Box
    //       sx={{
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         height: "100%",
    //       }}
    //     >
    //       <IconButton
    //         // onClick={() =>
    //         //   handleDownloadModalOpen("actionDownload", params.row)
    //         // }
    //       >
    //         <GetAppIcon />
    //       </IconButton>
    //     </Box>
    //   ),
    // },
  ];
  const allRAUserCompanies = Array.isArray(raTeamStatus)
    ? raTeamStatus
        .reduce((acc, companies) => {
          const useraddedBy = companies.leadRAId || companies.addedBy;

          const existingGroupIndex = acc.findIndex(
            (group) => group.useraddedBy === useraddedBy
          );
          if (existingGroupIndex !== -1) {
            acc[existingGroupIndex].companies.push(companies);
          } else {
            acc.push({ useraddedBy, companies: [companies] });
          }
          return acc;
        }, [])
        .map((group) => ({
          addedBy: group.useraddedBy,
          companies: group.companies,
        }))
    : [];
  const filterallRAUserCompanies = Array.isArray(allRAUserCompanies)
    ? allRAUserCompanies.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];
  const filterRAUserData = Array.isArray(raReports)
    ? raReports.filter((data) => data.leadRAId === storeData._id)
    : [];

  const filterSearchData = Array.isArray(filterRAUserData)
    ? filterRAUserData.filter((data) => {
        const lowerCaseCompanyName = data.companyName?.toLowerCase() || "";
        const lowerCaseJobPosition =
          data.jobDetails?.[0]?.jobTitle?.toLowerCase() || "";

        const statusType =
          data.jobDetails?.[0]?.status === "Active"
            ? "Open"
            : data.jobDetails?.[0]?.status || "";
        const lowerCasebdmName =
          allUsers.find((user) => user._id === data.addedBy)
            ?.userSudoFirstName || "";
        const lowerCaseSearchJobPosition =
          searchRADashboardJobPosition?.toLowerCase() || "";
        const lowerCaseSearchCompany =
          searchRADashboardCompanyName?.toLowerCase() || "";
        const SearchStatus = searchRADashboardStatus || [];

        const SearchBDMName = searchRADasboardBDMName || [];
        return (
          lowerCaseJobPosition.includes(lowerCaseSearchJobPosition) &&
          lowerCaseCompanyName.includes(lowerCaseSearchCompany) &&
          (SearchStatus.length === 0 || SearchStatus.includes(statusType)) &&
          (SearchBDMName.length == 0 ||
            SearchBDMName.includes(lowerCasebdmName))
        );
      })
    : [];
  const companiesListWithIds = Array.isArray(filterSearchData)
    ? filterSearchData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];
  const filterRATeamReports = Array.isArray(raTeamReports)
    ? raTeamReports.filter((data) => !!data.leadRAId)
    : [];
  const filterRATeamreportsId = Array.isArray(filterRATeamReports)
    ? filterRATeamReports.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];

  let countOpen = 0;
  let countPositive = 0;
  let countClose = 0;
  let countPlaced = 0;

  companiesListWithIds.forEach((company) => {
    if (company.jobDetails && company.jobDetails.length > 0) {
      company.jobDetails.forEach((job) => {
        if (job.status === "Positive") {
          countPositive++;
        } else if (job.status === "Open") {
          countOpen++;
        } else if (job.status === "Close") {
          countClose++;
        } else if (job.status === "Placed") {
          countPlaced++;
        }
      });
    }
  });

  function getDatesBetween(startDate, endDate) {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      dates.push(currentDate.format("DD-MM-YYYY"));
      currentDate = currentDate.add(1, "day");
    }

    return dates;
  }

  const [weekStartDate, setWeekStartDate] = useState("");
  const [weekEndDate, setWeekEndDate] = useState("");
  const betweenDates = getDatesBetween(weekStartDate, weekEndDate);

  const weekDataReports = () => {
    const thisMonday = dayjs().tz("Asia/Kolkata").startOf("week").add(1, "day"); // start of Monday
    const thisFriday = dayjs()
      .tz("Asia/Kolkata")
      .endOf("week")
      .subtract(1, "day"); // end of Friday
    // handleDateChange([thisMonday.startOf("day"),  thisFriday.endOf("day")]);
    // return  `${thisMonday.format("DD/MMMM")} - ${thisFriday.format(
    //     "DD/MMMM, YYYY"
    //   )}`

    setWeekStartDate(thisMonday);
    setWeekEndDate(thisFriday);
    const action = {
      Id: storeData._id,
      searchFromDate: thisMonday.startOf("day"),
      searchToDate: thisFriday.endOf("day"),
      client: client,
    };

    dispatch(raWeekReportsViaApi(action));
  };

  const matchingDateWiseData = (data, dates) => {
    const setData = [
      [
        "Date",
        "Total Companies",
        "Completed Companies",
        "Total Contact Details",
      ],
    ];

    function formatDateWithDay(dateString) {
      const formattedDate = dayjs(dateString, "DD-MM-YYYY").format(
        "DD/MM/YYYY"
      );
      const dayName = dayjs(dateString, "DD-MM-YYYY").format("dd"); // 'dd' gives the two-letter day name
      return `${formattedDate}/${dayName}`;
    }

    dates.forEach((date) => {
      let totalCompanies = 0;
      let completedCompanies = 0;
      let totalContactDetails = 0;

      data.forEach((company) => {
        const addedOnDate = dayjs(company.addedOn)
          .tz("Asia/Kolkata")
          .format("DD-MM-YYYY");

        if (date === addedOnDate) {
          totalCompanies += 1;

          if (
            company.jobDetails &&
            company.contactDetails &&
            company.jobDetails.length > 0 &&
            company.contactDetails.length > 0
          ) {
            completedCompanies++;
          }

          if (company.contactDetails && Array.isArray(company.contactDetails)) {
            totalContactDetails += company.contactDetails.length;
          }
        }
      });

      const formattedDate = formatDateWithDay(date);
      setData.push([
        formattedDate,
        totalCompanies,
        completedCompanies,
        totalContactDetails,
      ]);
    });

    return setData;
  };

  const takeDatewiseRaData = matchingDateWiseData(raWeekReports, betweenDates);

  useEffect(() => {
    // const today = new Date();
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
    handleDateChange([today, today.endOf("day")]);
    handleDateChangeTeam([today, today.endOf("day")]);
    weekDataReports();
    const action = {
      Id: storeData._id,
      searchFromDate: dayjs().subtract(1, "day").startOf("day"),
      searchToDate: dayjs().subtract(1, "day").endOf("day"),
    };
    // dispatch(yesterDayaRAReportsViaApi(action))
  }, []);

  //////////////////////////////

  const options = {
    //title: "Monthly Coffee Production by Country",
    vAxis: {
      //title: "Cups",
    },
    hAxis: {
      //title: "Month",
      titleTextStyle: {
        fontSize: 18, // Adjust the font size as needed
        color: theme.palette.primary.main, // Adjust the color as needed
        italic: false, // Ensure italic font style is disabled
        bold: true, // Optionally bold the title
      },
      textStyle: {
        fontSize: 12,
        slantedText: true,
        slantedTextAngle: 90,
      },
    },
    seriesType: "bars",
    series: { 5: { type: "line" } },
    chartArea: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 40,
    },
  };
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      setLoadingTeamReport(false);
      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
        setLoadingTeamReport(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle="RA Dashboard">
          <Box sx={{ mb: 2 }}>
            <CustomDateFilter handleDateChange={handleDateChange} />
          </Box>

          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <MainBackground>
                  <Box>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <DataWidget
                          title="Total Companies"
                          value={filterTotalCompaniesCount}
                          cValue={filterYesterDataTotalCompaniesCount}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <DataWidget
                          title="Completed Companies"
                          value={filterCompleteCompaniesCount}
                          cValue={filterYesterDayaCompleteCompaniesCount}
                          color={"error"}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <DataWidget
                          title="Total Contact Details"
                          value={
                            Array.isArray(companiesList)
                              ? companiesList.reduce((count, company) => {
                                  return (
                                    count +
                                    (Array.isArray(company.contactDetails)
                                      ? company.contactDetails.length
                                      : 0)
                                  );
                                }, 0)
                              : 0
                          }
                          // value={RaTeamDetails[0].contactNo}
                          // cValue={RaTeamDetails[0].lastDayContactNo}
                          cValue={
                            yesterDayRAReports
                              ? yesterDayRAReports.reduce((count, company) => {
                                  return (
                                    count +
                                    (company.contactDetails
                                      ? company.contactDetails.length
                                      : 0)
                                  );
                                }, 0)
                              : 0
                          }
                          color={"warning"}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <SectionTitle title={"This Week Report"} size="sm" />
                    <Chart
                      chartType="ComboChart"
                      width="100%"
                      height="200px"
                      data={takeDatewiseRaData}
                      options={options}
                    />
                  </Box>
                </MainBackground>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <MainBackground height="full">
                  <SectionTitle title={"Email Report"} />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Emails Sent by BDM"
                        value={RaTeamDetails[0].lastDayContactNo}
                        cValue={RaTeamDetails[0].lastDayContactNo}
                        type="line"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Spam Mails"
                        value={RaTeamDetails[0].lastDaySpam}
                        cValue={RaTeamDetails[0].lastDayContactNo}
                        type="line"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Bounced Mails"
                        value={RaTeamDetails[0].lastDayBounced}
                        cValue={RaTeamDetails[0].lastDayContactNo}
                        type="line"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Unread Mails"
                        value={RaTeamDetails[0].lastDayUnread}
                        cValue={
                          RaTeamDetails[0].lastDayContactNo -
                          RaTeamDetails[0].lastDayBounced
                        }
                        type="line"
                      />
                    </Grid>
                  </Grid>
                </MainBackground>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <MainBackground height="full">
                  <SectionTitle title={"Job Report"} />

                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Job Positive"
                        value={countPositive}
                        type="line"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Job Openings"
                        value={countOpen}
                        type="line"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Job Closed"
                        value={countClose}
                        type="line"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DataWidget
                        title="Placed"
                        value={countPlaced}
                        type="line"
                      />
                    </Grid>
                  </Grid>
                </MainBackground>
              </Grid>
            </Grid>
          </Box>

          {storeData.userRole === "Manager" && (
            <>
              
                  <MainBackground>
                    <SectionTitle title=" Team Status" />

                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <DataWidget
                          title="Total Companies"
                          value={
                            Array.isArray(raTeamStatus)
                              ? raTeamStatus.filter(
                                  (company) => company.status === "Active"
                                ).length
                              : 0
                          }
                          compairValue={totalLastDayCompanyNo}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <DataWidget
                          title="Completed Companies"
                          value={
                            Array.isArray(raTeamStatus)
                              ? raTeamStatus.reduce((count, company) => {
                                  if (
                                    Array.isArray(company?.jobDetails) && 
                                    Array.isArray(company?.contactDetails) &&
                                    company.jobDetails.length > 0 &&
                                    company.contactDetails.length > 0
                                  ) {
                                    const activeJobs =
                                      company.jobDetails.filter(
                                        (job) => job.status === "Active"
                                      );
                                    return count + activeJobs.length;
                                  }
                                  return count; // If conditions are not met, return the current count
                                }, 0)
                              : 0
                          }
                          compairValue={totalCompanyNo}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <DataWidget
                          title="Total Contact Details"
                          value={
                            Array.isArray(raTeamStatus)
                              ? raTeamStatus
                                  .filter(
                                    (company) => company.status === "Active"
                                  ) // Filter for companies with status "Active"
                                  .reduce((count, company) => {
                                    return (
                                      count +
                                      (Array.isArray(company.contactDetails)
                                        ? company.contactDetails.length
                                        : 0)
                                    );
                                  }, 0)
                              : 0
                          }
                          compairValue={totalLastDayContactNo}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={3}>
                        <DataWidget
                          title="Total Open Jobs"
                          value={totalJobOpeningsNo}
                          compairValue={totalLastDayJobOpeningsNo}
                        />
                      </Grid>
                    </Grid>
                    {loading ? (
                <>
                  {/* <ShimmerTable row={5} col={5} /> */}
                  <ShimmerTable row={5} col={5} />
                </>
              ) : (
                <>
                    <DataGrid
                      rows={filterallRAUserCompanies}
                      columns={RaTeamDetailsColumn}
                      onRowSelectionModelChange={(newSelectionModel) => {
                        handleSelectionChange(newSelectionModel);
                      }}
                      selectionModel={selectedRowIds}
                      disableColumnMenu
                      disableColumnSorting
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                      //disableRowSelectionOnClick
                      disableColumnSort
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 20 },
                        },
                      }}
                      pageSizeOptions={[20]}
                      hideFooter={filterallRAUserCompanies.length < 20}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,

                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        //Scroller
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px", // Apply border radius
                        },

                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
                    </>
              )}
                  </MainBackground>
              
            </>
          )}
          <Box sx={{ mt: 3 }}>
            <Box my={2} />
            <MainBackground>
              <SectionTitle title="MyLead Assignment" />
              {loading ? (
                <>
                  {/* <ShimmerTable row={5} col={5} /> */}
                  <ShimmerTable row={5} col={5} />
                </>
              ) : (
                <>
              <DataGrid
                rows={companiesListWithIds}
                columns={columnsRA}
                columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
                disableColumnMenu
                disableColumnSorting
                disableColumnSelector
                disableColumnFilter
                disableColumnReorder
                disableColumnResize
                //disableRowSelectionOnClick
                disableColumnSort
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 20 },
                  },
                }}
                pageSizeOptions={[20]}
                hideFooter={companiesListWithIds.length < 20}
                getRowHeight={() => "auto"}
                autoHeight
                sx={{
                  "& .MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center !important",
                  },
                  [`& .MuiDataGrid-checkboxInput`]: {
                    color: theme.palette.primary.main,
                    p: 0,

                    "&.Mui-checked": {
                      color: theme.palette.primary.dark,
                    },
                  },
                  //Scroller
                  [`& .MuiDataGrid-scrollbar`]: {
                    scrollbarWidth: "thin",
                    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                  },
                  [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                    borderRadius: "10px", // Apply border radius
                  },

                  [`& .MuiDataGrid-scrollbar:hover`]: {
                    scrollbarColor: `${theme.palette.primary.dark}`,
                  },
                }}
              />
              </>)}
            </MainBackground>
            <Box py={2} />
          </Box>

          {storeData.userRole === "Manager" && (
            <>
              <Box sx={{ mt: 3 }}>
                <Box my={2} />
                <MainBackground>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "top",
                    }}
                  >
                    <Box>
                      <SectionTitle title={"MyTeamLead Assignment"} />
                    </Box>

                    <CustomDateFilter handleDateChange={handleDateChangeTeam} />
                  </Box>
                 
               {loadingTeamReport ? (
                      <>
                        {/* <ShimmerTable row={5} col={5} /> */}
                        <ShimmerTable row={5} col={5} />
                      </>
                    ) : (
                      <>
                  <DataGrid
                    rows={filterRATeamreportsId}
                    columns={columnsRAs}
                    columnBuffer={filterRATeamreportsId.length} // Set columnBuffer to the number of columns
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnResize
                    //disableRowSelectionOnClick
                    disableColumnSort
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 20 },
                      },
                    }}
                    pageSizeOptions={[20]}
                    hideFooter={filterRATeamreportsId.length < 20}
                    getRowHeight={() => "auto"}
                    autoHeight
                    sx={{
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        alignItems: "center !important",
                      },
                      [`& .MuiDataGrid-checkboxInput`]: {
                        color: theme.palette.primary.main,
                        p: 0,

                        "&.Mui-checked": {
                          color: theme.palette.primary.dark,
                        },
                      },
                      //Scroller
                      [`& .MuiDataGrid-scrollbar`]: {
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      },
                      [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                        borderRadius: "10px", // Apply border radius
                      },

                      [`& .MuiDataGrid-scrollbar:hover`]: {
                        scrollbarColor: `${theme.palette.primary.dark}`,
                      },
                    }}
                  />
                  </>)
                 }
                </MainBackground>
                <Box py={2} />
              </Box>
            </>
          )}
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default RADashboard;
