import { Box, Button, Chip, TextField, Typography } from "@mui/material";
import React from "react";
import CustomSelectThree from "../Common/CustomSelectThree";
import CustomeSelectFour from "../Common/CustomeSelectFour";

const SaveEmailTemplate = ({ closeModal,handleSaveContent,totalBDMUsers,handleChangename,bdmData,setTemplateTitle,templateTitle,emailSubject,handleaddSubjectVariables,setEmailSubject,contactExample,replacePlaceholders }) => {
  
  return (
    <React.Fragment>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "400px",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: {
            xs: 1,
            md: 2,
          },
          borderRadius: 2,
        }}
      >
        <Box sx={{ my: 1 }} >
          <Typography sx={{ fontWeight: "bold" }}>
            Save Email Template
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <TextField
            id="outlined-basic"
            label="Email Template Title"
            fullWidth
            variant="standard"
            value={templateTitle}
            onChange={(e) => setTemplateTitle(e.target.value)}
          />
          
          <TextField
            id="outlined-basic"
            label="Mail Subject"
            fullWidth
            variant="standard"
            value={emailSubject}
            onChange={(e) => setEmailSubject(e.target.value)}
          />
          <Box sx={{ display: "flex", gap: 1 }}>
            <Chip label={"${firstName}"} clickable onClick={(e)=>handleaddSubjectVariables("${firstName}")}/>
            <Chip label={"${lastName}"} clickable  onClick={(e)=>handleaddSubjectVariables("${lastName}")}/>
            <Chip label={"${jobTitle}"} clickable  onClick={(e)=>handleaddSubjectVariables("${jobTitle}")}/>
            <Chip label={"${jobLocation}"} clickable onClick={(e)=>handleaddSubjectVariables("${jobLocation}")}/>
          </Box>
          <Box>
          {replacePlaceholders(emailSubject,contactExample)}
          </Box>
          <CustomeSelectFour title="Assign to" options={totalBDMUsers} onChange={handleChangename} value={bdmData} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: 2,
            pt: 4,
          }}
        >
          <Button color="inherit" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="contained" onClick={()=>handleSaveContent()}>Save EmailTemplate </Button>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SaveEmailTemplate;
