import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./Theme";
import {
  Login,
  Logout,
  SearchCompany,
  Main,
  Users,
  AddUser,
  MyCompaniesList,
  UpdateCompanyStatus,
  Download,
  Test,
  SearchFilter,
  EmailSend,
  GlobalSearch,
  BulkEmail,
  ManualEmail,
  Reports,
  Assign,
  TeamCount,
  UpdateUser,
  Shuffle,
  Client,
  Unsubscribe,
  MyCompaniesListBDM,
  BdmDownload,
  TestEmail,
  ApprovalStatus,
  DashboardAdmin,
  BdmReports,
  RADashboard,
  BDMDashboard,
  EmailTemplates,
  OtherEmailsSend,
  SearchCompanyDetailed,
  DailyProduction,
  BDMJobReports,
  JobReports,
  Controller,
  EmailReports,
  FollowUpAssign,
  AdminChangeCompanyStatus,
  FindInfo,
  DashboardRecruiter,
  JobPostDetails,
  JobPosts,
  Applicants,
  ApplicantDetails,
  Settings,
  BdmLeadsAnalysisView,
  RaAnalysisView,
  PieChartAnalysisview,
  ManageEmails,
  ManageEmailView,
  JobBoards,
  JobBoardsResult,
  ApplicantBulkEmail,
  MasterSettings,
  JobBoardsIntegrateResult,
  ViewResume,
  EmailValidator,
} from "./Pages";
import ProtectedRoute from "./ProtectedRoute";
import { Navigate, useLocation } from "react-router-dom";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboardAdmin/*"
                element={
                  <ProtectedRoute>
                    <DashboardAdmin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/raDashboard/*"
                element={
                  <ProtectedRoute>
                    <RADashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bdmDashboard/*"
                element={
                  <ProtectedRoute>
                    <BDMDashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/searchCompany/*"
                element={
                  <ProtectedRoute>
                    <SearchCompany />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/main/*"
                element={
                  <ProtectedRoute>
                    <Main />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/companiesList/*"
                element={
                  <ProtectedRoute>
                    <MyCompaniesList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/companyStatus/*"
                element={
                  <ProtectedRoute>
                    <UpdateCompanyStatus />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/raDownload/*"
                element={
                  <ProtectedRoute>
                    <Download />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/users/*"
                element={
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/addUser/*"
                element={
                  <ProtectedRoute>
                    <AddUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test/*"
                element={
                  <ProtectedRoute>
                    <Test />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/search/*"
                element={
                  <ProtectedRoute>
                    <SearchFilter />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/email/*"
                element={
                  <ProtectedRoute>
                    <EmailSend />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/globalSearch/*"
                element={
                  <ProtectedRoute>
                    <GlobalSearch />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/reports/*"
                element={
                  <ProtectedRoute>
                    <Reports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bulkEmail/*"
                element={
                  <ProtectedRoute>
                    <BulkEmail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manualEmail/*"
                element={
                  <ProtectedRoute>
                    <ManualEmail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assign/*"
                element={
                  <ProtectedRoute>
                    <Assign />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/teamCount/*"
                element={
                  <ProtectedRoute>
                    <TeamCount />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/updateUser/*"
                element={
                  <ProtectedRoute>
                    <UpdateUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/assign/*"
                element={
                  <ProtectedRoute>
                    <Assign />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/suffle/*"
                element={
                  <ProtectedRoute>
                    <Shuffle />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/client/*"
                element={
                  <ProtectedRoute>
                    <Client />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/companiesListBdm/*"
                element={
                  <ProtectedRoute>
                    <MyCompaniesListBDM />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bdmDownload/*"
                element={
                  <ProtectedRoute>
                    <BdmDownload />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/testEmail/*"
                element={
                  <ProtectedRoute>
                    <TestEmail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/approvalStatus/*"
                element={
                  <ProtectedRoute>
                    <ApprovalStatus />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bdmReports/*"
                element={
                  <ProtectedRoute>
                    <BdmReports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/emailTemplates/*"
                element={
                  <ProtectedRoute>
                    <EmailTemplates />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/otherEmails/*"
                element={
                  <ProtectedRoute>
                    <OtherEmailsSend />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/searchCompanyDetailed/*"
                element={
                  <ProtectedRoute>
                    <SearchCompanyDetailed />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dailyProduction/*"
                element={
                  <ProtectedRoute>
                    <DailyProduction />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bdmJobReports/*"
                element={
                  <ProtectedRoute>
                    <BDMJobReports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobReports/*"
                element={
                  <ProtectedRoute>
                    <JobReports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/controller/*"
                element={
                  <ProtectedRoute>
                    <Controller />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/emailReports/*"
                element={
                  <ProtectedRoute>
                    <EmailReports />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/followUpAssign/*"
                element={
                  <ProtectedRoute>
                    <FollowUpAssign />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/adminChangeCompanyStatus/*"
                element={
                  <ProtectedRoute>
                    <AdminChangeCompanyStatus />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/findInfo/*"
                element={
                  <ProtectedRoute>
                    <FindInfo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboardRecruiter/*"
                element={
                  <ProtectedRoute>
                    <DashboardRecruiter />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobPosts/*"
                element={
                  <ProtectedRoute>
                    <JobPosts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobPostDetails/*"
                element={
                  <ProtectedRoute>
                    <JobPostDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/applicants/*"
                element={
                  <ProtectedRoute>
                    <Applicants />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/applicantDetails/*"
                element={
                  <ProtectedRoute>
                    <ApplicantDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings/*"
                element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bdmLeadAnalysisView/*"
                element={
                  <ProtectedRoute>
                    <BdmLeadsAnalysisView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/RaAnalysisView/*"
                element={
                  <ProtectedRoute>
                    <RaAnalysisView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/PieChartAnalysisview/*"
                element={
                  <ProtectedRoute>
                    <PieChartAnalysisview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manageEmails/*"
                element={
                  <ProtectedRoute>
                    <ManageEmails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manageEmailView/*"
                element={
                  <ProtectedRoute>
                    <ManageEmailView />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobBoards/*"
                element={
                  <ProtectedRoute>
                    <JobBoards />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobBoardsResult/*"
                element={
                  <ProtectedRoute>
                    <JobBoardsResult />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/applicantBulkEmail/*"
                element={
                  <ProtectedRoute>
                    <ApplicantBulkEmail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/masterSettings/*"
                element={
                  <ProtectedRoute>
                    <MasterSettings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/jobBoardsIntegrateResult/*"
                element={
                  <ProtectedRoute>
                    <JobBoardsIntegrateResult />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/viewResume/*"
                element={
                  <ProtectedRoute>
                    <ViewResume />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/emailValidator/*"
                element={
                  <ProtectedRoute>
                    <EmailValidator />
                  </ProtectedRoute>
                }
              />
              <Route path="/logout" element={<Logout />} />
              <Route path="/unSubscribe" element={<Unsubscribe />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </React.StrictMode>
  );
}

export default App;
