import { SnackbarProvider } from "notistack";
import React, { useState, useEffect, useRef } from "react";

import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import FormatDateTime from "../../Components/Helper/FormatDateTime";

import {
  Box,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Divider,
  ButtonGroup,
  Button,
  IconButton,
  Autocomplete,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplyAllRoundedIcon from "@mui/icons-material/ReplyAllRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import theme from "../../Theme";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomFormField } from "../../Components";
import { EmailData } from "../../Data/EmailData";

const NameEmailDetailList = ({ type = "", data = [] }) => {
  return (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "flex-start" }}>
      {type && (
        <Box
          sx={{
            //backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[400],
            px: 0,
            py: 0,
            m: 0,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontWeight: 500,

            fontSize: 12,
            textWrap: "nowrap",
          }}
        >
          {type} :
        </Box>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 1 }}>
        {data.map((item, key) => {
          return (
            <Typography
              variant="caption"
              color={theme.palette.grey[700]}
              fontWeight={item?.name ? 600 : 400}
            >
              {item?.name ? (
                <Tooltip title={item.email} TransitionComponent={Zoom}>
                  {item.name}
                </Tooltip>
              ) : (
                `<${item.email}>`
              )}
              {key < data.length - 1 && ","}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

const NameEmailDetail = ({ type = "", name, email }) => {
  return (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
      {type && (
        <Box
          sx={{
            //backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[400],
            px: 0,
            py: 0,
            m: 0,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontWeight: 500,
            lineHeight: 1,
            fontSize: 12,
          }}
        >
          {type} :
        </Box>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        <Box sx={{ textWrap: "nowrap" }}>
          <Typography
            variant="caption"
            color={theme.palette.grey[700]}
            fontWeight={name ? 600 : 400}
          >
            {name ? (
              <Tooltip title={email} TransitionComponent={Zoom}>
                <span>{name}</span>
              </Tooltip>
            ) : (
              `<${email}>`
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ManageEmailView = () => {
  const mailData = EmailData;
  const [expandedPanels, setExpandedPanels] = useState([]);
  const handleToggle = (panelIndex) => () => {
    setExpandedPanels((prevState) => {
      const newExpandedPanels = [...prevState];
      newExpandedPanels[panelIndex] = !newExpandedPanels[panelIndex];
      return newExpandedPanels;
    });
  };
  //////////////////
  const [writeMail, setWriteMail] = useState(false);
  const [revertType, setRecertType] = useState("");

  const handelCancel = () => {
    setWriteMail(!writeMail);
    setRecertType("");
  };
  const handelReplyAll = () => {
    setWriteMail(!writeMail);
    setRecertType("replyAll");
  };
  const handelReply = () => {
    setWriteMail(!writeMail);
    setRecertType("reply");
  };
  const handelForward = () => {
    setWriteMail(!writeMail);
    setRecertType("forward");
  };

  const userEmailId = "mohsin@tektreeinc.aaaacom";

  const lastMailCommunication =
    mailData[1].mailCommunications[mailData[1].mailCommunications.length - 1];

  /////////////////////////////

  const getFromDetails = [
    {
      email: lastMailCommunication.formEmail,
      ...(lastMailCommunication?.fromName && {
        name: lastMailCommunication.fromName,
      }),
    },
  ];

  const getCcDetails = lastMailCommunication.ccList;
  const getToDetails = lastMailCommunication.toList;

  const allDetails = [...getFromDetails, ...getToDetails, ...getCcDetails];


  const allDetailsFilterd = allDetails.filter(
    (detail) => detail.email !== userEmailId
  );

  /////////////////////////////

  const lastMailCommunicationCcListEmails = lastMailCommunication.ccList.map(
    (cc) => cc.email
  );

  const lastMailCommunicationFromEamil = lastMailCommunication.formEmail;

  const lastMailCommunicationToEamil = lastMailCommunication.toList.map(
    (cc) => cc.email
  );

  const allLastMailCommunication = [
    ...lastMailCommunicationCcListEmails,
    lastMailCommunicationFromEamil,
    ...lastMailCommunicationToEamil,
  ];

  const updateCcMailsList = [
    ...lastMailCommunicationToEamil,
    ...lastMailCommunicationCcListEmails,
  ];
  const updatedList = updateCcMailsList.filter(
    (email) => email !== userEmailId
  );

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  const [write, setWrite] = useState(false);
  const boxRef = useRef(null); // Create a ref for the Box component

  const handleClickOutside = (event) => {
    // Check if the clicked element is outside of the Box component
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setWrite(false); // Set 'write' to false if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const DefineStyle = {
    fontSize: 14,
    bgColor: "white",
    //bgColor: `linear-gradient(0deg, rgba(${theme.palette.primary.default},.07) 0%, rgba(${theme.palette.primary.default},0.02) 100%)`,
    boderColor: `rgba(${theme.palette.primary.default},.2)`,
    textColor: theme.palette.text.primary,
    placeholderColor: theme.palette.grey[900],
  };

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Manage Email View"}>
          <Box>
            <Box
              data-section="mailviewtop"
              sx={{ display: "flex", gap: 5, alignItems: "center" }}
            >
              <Box>
                <Typography variant="h5">{mailData[1].subject}</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                {mailData[1].tags.map((item, key) => {
                  return <Chip key={key} label={item} size="small" />;
                })}
              </Box>
            </Box>

            <Box data-section="mailviewbody" sx={{ mt: 5 }}>
              {mailData[1].mailCommunications.map((item, index) => {
                const isLastItem =
                  index === mailData[1].mailCommunications.length - 1;

                return (
                  <Accordion
                    key={index}
                    expanded={expandedPanels[index] || isLastItem} // Keep the last one expanded by default
                    onChange={handleToggle(index)}
                    sx={{
                      boxShadow: "none", // Removes the shadow
                      "&:before": { display: "none" }, // Removes the default border from Accordion
                      borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    }}
                  >
                    <AccordionSummary sx={{ m: 0, minHeight: "auto" }}>
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            alt={item.fromName}
                            src="/static/images/avatar/2.jpg"
                            sx={{
                              backgroundColor: theme.palette.secondary.dark,
                              width: 30,
                              height: 30,
                              fontSize: 18,
                            }}
                          />
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.7,
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <NameEmailDetail
                                type={"from"}
                                name={item.fromName}
                                email={item.formEmail}
                              />
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                                mt: 0.2,
                                flexWrap: "wrap",
                              }}
                            >
                              <NameEmailDetailList
                                type="to"
                                data={item.toList}
                              />
                              <NameEmailDetailList
                                type="cc"
                                data={item.ccList}
                              />
                              {/* <Box sx={{ display: "flex", gap: 0.5 }}>
                                <Box
                                  sx={{
                                    //backgroundColor: theme.palette.grey[100],
                                    color: theme.palette.grey[400],
                                    px: 0,
                                    py: 0,
                                    m: 0,
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                    fontWeight: 500,
                                    lineHeight: 1,
                                    fontSize: 12,
                                  }}
                                >
                                  to :
                                </Box>
                                {item.toList.map((item, key) => {
                                  return (
                                    <Box
                                      key={key}
                                      sx={{
                                        display: "flex",
                                        gap: 0.7,
                                        alignItems: "center",
                                      }}
                                    >
                                      <NameEmailDetail
                                        name={item.name}
                                        email={item.email}
                                      />
                                    </Box>
                                  );
                                })}
                              </Box>
                              <Box sx={{ display: "flex", gap: 0.5 }}>
                                <Box
                                  sx={{
                                    //backgroundColor: theme.palette.grey[100],
                                    color: theme.palette.grey[400],
                                    px: 0,
                                    py: 0,
                                    m: 0,
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    textTransform: "capitalize",
                                    fontWeight: 500,
                                    lineHeight: 1,
                                    fontSize: 12,
                                  }}
                                >
                                  cc :
                                </Box>
                                {item.ccList.map((item, key) => {
                                  return (
                                    <Box
                                      key={key}
                                      sx={{
                                        display: "flex",
                                        gap: 0.7,
                                        alignItems: "center",
                                      }}
                                    >
                                      <NameEmailDetail
                                        name={item.name}
                                        email={item.email}
                                      />
                                    </Box>
                                  );
                                })}
                              </Box> */}
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            minWidth: 240,
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Typography noWrap sx={{ fontSize: "12px" }}>
                            {FormatDateTime(item.mailSent, "full")}
                          </Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{}}>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: item.mailBody }}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Box>
          {!writeMail && (
            <Box sx={{ px: 2, py: 3 }}>
              <ButtonGroup size="small">
                <Button
                  startIcon={<ReplyAllRoundedIcon />}
                  color="inherit"
                  onClick={handelReplyAll}
                >
                  Reply All
                </Button>
                <Button
                  startIcon={<ReplyRoundedIcon />}
                  color="inherit"
                  onClick={handelReply}
                >
                  Reply
                </Button>
                <Button
                  startIcon={<ForwardRoundedIcon />}
                  color="inherit"
                  onClick={handelForward}
                >
                  Forward
                </Button>
              </ButtonGroup>
            </Box>
          )}
          {writeMail && (
            <>
              <Box
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: theme.palette.grey[200],
                  bgcolor: "white",
                  mt: 3,
                }}
              >
                <Box sx={{ pb: 2 }}>
                  <Box
                    ref={boxRef}
                    onClick={() => setWrite(true)}
                    sx={{
                      "&:hover": {
                        backgroundColor: theme.palette.grey[50],
                      },
                    }}
                  >
                    {!write ? (
                      <NameEmailDetailList type="" data={allDetailsFilterd} />
                    ) : (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Box>To</Box>
                            <Box flex={1}>
                              <Autocomplete
                                limitTags={4}
                                multiple
                                id="tags-standard"
                                size="small"
                                options={[]}
                                freeSolo
                                getOptionLabel={(option) => option}
                                defaultValue={
                                  revertType !== "forward"
                                    ? [lastMailCommunicationFromEamil]
                                    : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    //label="To"
                                    size="small"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        py: 0.5,
                                        "&:before": {
                                          borderBottom: "1px solid #CCCCCC", // Change to any desired color
                                        },
                                        "&:hover:not(.Mui-disabled):before": {
                                          borderBottom: "1px solid #CCCCCC", // Change hover color
                                        },
                                        "&:after": {
                                          borderBottom: "1px solid #C2C2C2", // Focused state color
                                        },
                                      },
                                    }}
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      size="small"
                                      sx={{
                                        fontSize: "12px",
                                        borderColor: theme.palette.grey[200],
                                      }}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Box>Cc</Box>
                            <Box flex={1}>
                              <Autocomplete
                                limitTags={4}
                                multiple
                                id="tags-standard"
                                size="small"
                                options={[]}
                                freeSolo
                                getOptionLabel={(option) => option}
                                defaultValue={
                                  revertType === "replyAll" ? updatedList : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    //label="Cc"
                                    size="small"
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        py: 0.5,
                                        "&:before": {
                                          borderBottom: "1px solid #CCCCCC", // Change to any desired color
                                        },
                                        "&:hover:not(.Mui-disabled):before": {
                                          borderBottom: "1px solid #CCCCCC", // Change hover color
                                        },
                                        "&:after": {
                                          borderBottom: "1px solid #C2C2C2", // Focused state color
                                        },
                                      },
                                    }}
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={option}
                                      size="small"
                                      sx={{
                                        fontSize: "12px",
                                        borderColor: theme.palette.grey[200],
                                      }}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
                <ReactQuill
                  //ref={quillRef}
                  theme="snow"
                  //value={defaultValue}
                  //onChange={handleTextEditorChange}
                  placeholder={"reply"}
                  modules={{
                    toolbar: {
                      container: [
                        ["bold", "italic", "underline"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link"],
                        ["clean"],
                        [{ color: [] }, { background: [] }],
                      ],
                    },
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    pt: 1,
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <ButtonGroup>
                      <Button endIcon={<SendRoundedIcon />} color="secondary">
                        Send
                      </Button>
                    </ButtonGroup>
                  </Box>
                  <Box>
                    <IconButton color="error" onClick={handelCancel}>
                      <DeleteRoundedIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Box pt={10} />
            </>
          )}
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default ManageEmailView;
