import {
  Autocomplete,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import theme from "../../Theme";
import AddIcon from "@mui/icons-material/Add";
import CompanyDetails from "../FindInfoComponents/CompanyDetails";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { addNewjobViaApi } from "../../store/actions";
import dayjs from "dayjs";
const FormRow = ({ children }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "baseline",
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};
const FormCell = ({ children, width = 172, flex = 1 }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flex: flex,
          flexBasis: width,
        }}
      >
        {children}
      </Box>
    </React.Fragment>
  );
};

const AddNewJobModal = ({ open, close,excelData,selectCompany,companyJobDetails,
  setExcelData,setCompanyJobDetails,storeData,client,setLoading,handleDateChange }) => {
    const dispatch=useDispatch()
  const industries = useSelector((state) => state.Search.industries || []);
  const industryNames = Array.isArray(industries)
    ? industries.map((item) => item.industryName)
    : [];
  const handleChange = (index, field, value) => {
    const updatedContacts = [...excelData];
    updatedContacts[index][field] = value;
    // setContactDetails(updatedContacts);
    setExcelData(updatedContacts);
  };
  const [comment, setComment] = useState("");
  const [jobTitleError, setJobTitleError] = useState("");
  const [jobLocationError, setJobLocationError] = useState("");
  const [jobDescriptionError, setJobDescriptionError] = useState("");
  const [jobWebsiteError, setJobWebsiteError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [designationError, setDesignationError] = useState("");
  const [emailIdError, setEmailIdError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [showContact, setShowContact] = useState(false);
  const [firstNameIndex, setFirstNameIndex] = useState("");
  const handleJobChange = (index, field, value) => {
    const updatedContacts = [...companyJobDetails];
    updatedContacts[index][field] = value;
    setCompanyJobDetails(updatedContacts);
  };
  const handleAddContact = () => {
    if (excelData.length < 5) {
      setExcelData([
        ...excelData,
        {
          addedBy: storeData._id,
          firstName: "",
          lastName: "",
          designation: "",
          emailId: "",
          contactAddedOn: new Date(),
          phoneNumber: "",
        },
      ]);
    } else {
      enqueueSnackbar(`Not add contacts more than 5`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
  };
const [deletedContactModal, setDeletedContactModal] = useState(false);
  const [delleteContactIndex, setDeleteContactIndex] = useState("");
  const deleteContactModal = (index) => {
    setDeletedContactModal(!deletedContactModal);
    setDeleteContactIndex(index);
  };
  const handleDeleteContact = () => {
    if (excelData.length > 1) {
      const updatedData = [...excelData];

      updatedData.splice(delleteContactIndex, 1);
      setExcelData(updatedData);
      setDeletedContactModal(!deletedContactModal);
    } else {
      enqueueSnackbar(
        `At least one contact type must be present in the list. Deletion failed.`,
        {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        }
      );
    }
  };
const handleDeleteDirectContact = (index) => {
  if (excelData.length > 1) {
    const updatedData = [...excelData];
    const contact = updatedData[index];
    // Check if any of the specific fields are non-empty
    if (
      contact.firstName !== "" ||
      contact.lastName !== "" ||
      contact.designation !== "" ||
      contact.emailId !== ""
    ) {
      deleteContactModal(index);
    } else {
      updatedData.splice(index, 1);
      setExcelData(updatedData);
    }
  } else {
    enqueueSnackbar(
      `At least one contact type must be present in the list. Deletion failed.`,
      {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      }
    );
  }
};

const handleSubmit = () => {
  // Resetting error states
  setJobTitleError("");
  setJobLocationError("");
  setJobDescriptionError("");
  setFirstNameError("");
  setLastNameError("");
  setDesignationError("");
  setEmailIdError("");
  setPhoneError("");
  // Check for invalid job details
  const invalidJobDetails = companyJobDetails.some((contact, index) => {
    if (!contact.jobTitle.trim()) {
      setJobTitleError(`Job title ${index + 1} is required.`);
      return true;
    }
    if (!contact.jobLocation.trim()) {
      setJobLocationError(`Job location ${index + 1} is required.`);
      return true;
    }
    if (!contact.jobDescription.trim()) {
      setJobDescriptionError(`Job description ${index + 1} is required.`);
      return true;
    }
    return false;
  });

  const phoneRegex = /^[+\d()-‑.,\s]+$/;
  const consecutiveSpacesRegex = /\s{2,}/;
  // Check for invalid contact details
  const encounteredEmails = {};
  const invalidContactDetails = excelData.some((contact, index) => {
    if (!contact.firstName.trim()) {
      setFirstNameIndex(index);
      setFirstNameError(`First name ${index + 1} is required.`);
      return true;
    }

    if (!contact.designation.trim()) {
      setFirstNameIndex(index);
      setDesignationError(`Designation ${index + 1} is required.`);
      return true;
    }

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!contact.emailId.trim()) {
      setFirstNameIndex(index);
      setEmailIdError(`Email ID ${index + 1} is required.`);

      return true;
    } else if (!emailRegex.test(contact.emailId.trim())) {
      setEmailIdError(`Email ID ${index + 1} is not in a valid format.`);
      setFirstNameIndex(index);
      return true;
    } else if (contact.emailId.endsWith("@gmail.com")) {
      setFirstNameIndex(index);
      setEmailIdError(
        `Email ID ${
          index + 1
        } must not be a Gmail address write company email.`
      );
      return true;
    }
    // Check if email ID is already encountered
    const trimmedEmail = contact.emailId.trim().toLowerCase(); // Convert to lowercase for case-insensitive comparison
    if (encounteredEmails[trimmedEmail]) {
      setEmailIdError(`Email ID ${index + 1} is a duplicate.`);
      setFirstNameIndex(index);
      return true;
    }

    encounteredEmails[trimmedEmail] = true;
    if (contact.phoneNumber && contact.phoneNumber.trim()) {
      if (!phoneRegex.test(contact.phoneNumber.trim())) {
        setFirstNameIndex(index);
        setPhoneError(
          `Phone number ${index + 1} is not in a valid phone number format.`
        );
        return true;
      } else if (consecutiveSpacesRegex.test(contact.phoneNumber.trim())) {
        setFirstNameIndex(index);
        setPhoneError(
          `Phone number ${index + 1} contains consecutive spaces.`
        );
        return true;
      }
    }

    return false;
  });

  // Check if any required fields are empty or if email format is incorrect
  if (invalidJobDetails || invalidContactDetails) {
    return;
  }

  // Trim extra spaces from job details
  const trimmedJobDetails = companyJobDetails.map((contact) => ({
    ...contact,
    jobTitle: contact.jobTitle.trim(),
    jobLocation: contact.jobLocation.trim(),
    jobDescription: contact.jobDescription.trim(),
    jobWebsite: contact.jobWebsite ? contact.jobWebsite.trim() : "",
    jobIndustrieName: contact.jobIndustrieName
      ? contact.jobIndustrieName.trim()
      : "",
  }));

  // Trim extra spaces from contact details
  const trimmedContactDetails = excelData.map((contact) => ({
    ...contact,
    firstName: contact.firstName.trim(),
    lastName: contact.lastName ? contact.lastName.trim() : "",
    designation: contact.designation.trim(),
    emailId: contact.emailId.trim(),
    phoneNumber: contact.phoneNumber ? contact.phoneNumber.trim() : "",
  }));

  // All validations passed, proceed with submission
  const action = {
    companyName: selectCompany[0]?.companyName,
    addedBy: storeData._id,
    status: "On Process",
    addedOn: new Date(),
    jobDetails: trimmedJobDetails,
    contactDetails: trimmedContactDetails,
    comment: comment ? comment.trim() : "",
    client: client,
    leadRAId:selectCompany[0]?.leadRAId,
    leadRADate:selectCompany[0]?.leadRADate
  };
  dispatch(addNewjobViaApi(action));
  setLoading(true)
  handleDateChange([
    dayjs().tz("America/New_York").startOf("day"),
    dayjs().tz("America/New_York").endOf("day"),
  ]);
  close()
  // dispatch(industriesNamesViaApi(action));
  // const today = dayjs().tz("Asia/Kolkata").startOf("day");

  // handleDateChange([today, today.endOf("day")]);
  // handleClose();
};
  return (
    <React.Fragment>
      <Modal open={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "1000px",
            width: "80%",
            maxHeight: "80vh",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            overflowY: "auto",
            p: {
              xs: 1.5,
              md: 3,
            },
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.primary.dark,
              fontWeight: "bold",
              mb: 1,
            }}
          >
            {selectCompany[0]?.companyName}
          </Typography>
          <Divider />

          <Box>
            <Box sx={{ my: 1 }}>
              <Typography sx={{ fontWeight: "bold" }}>Job Details</Typography>
            </Box>
            {companyJobDetails.map((contact, index) => (
                <FormRow>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Website"
                      placeholder="https://tektreeinc.com"
                      size="small"
                      fullWidth
                      value={contact.jobWebsite}
                      onChange={(e) =>
                        handleJobChange(index, "jobWebsite", e.target.value)
                      }
                      error={Boolean(jobWebsiteError)}
                      helperText={jobWebsiteError}
                    />
                  </FormCell>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Job Description"
                      placeholder="URL"
                      size="small"
                      fullWidth
                      value={contact.jobDescription}
                      onChange={(e) =>
                        handleJobChange(index, "jobDescription", e.target.value)
                      }
                      error={Boolean(jobDescriptionError)}
                      helperText={jobDescriptionError}
                    />
                  </FormCell>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Job Title"
                      placeholder="Application Engineer"
                      size="small"
                      fullWidth
                      value={contact.jobTitle}
                      onChange={(e) =>
                        handleJobChange(index, "jobTitle", e.target.value)
                      }
                      error={Boolean(jobTitleError)}
                      helperText={jobTitleError}
                    />
                  </FormCell>
                  <FormCell>
                    <TextField
                      variant="standard"
                      label="Job Location"
                      placeholder="Location, State"
                      size="small"
                      fullWidth
                      value={contact.jobLocation}
                      onChange={(e) =>
                        handleJobChange(index, "jobLocation", e.target.value)
                      }
                      error={Boolean(jobLocationError)}
                      helperText={jobLocationError}
                    />
                  </FormCell>

                  <FormCell>
                    <Autocomplete
                      disablePortal
                      freeSolo
                      id="combo-box-demo"
                      options={industryNames}
                      value={contact.jobIndustrieName}
                      onChange={(event, newValue) => {
                        handleJobChange(index, "jobIndustrieName", newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        // If you need to handle input changes separately, you can use this
                        handleJobChange(
                          index,
                          "jobIndustrieName",
                          newInputValue
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Industry"
                          variant="standard"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  </FormCell>
                </FormRow>
              ))}

            {/* <FormRow>
              <FormCell>
                <TextField
                  variant="standard"
                  label="Website"
                  placeholder="https://tektreeinc.com"
                  size="small"
                  fullWidth
                />
              </FormCell>
              <FormCell>
                <TextField
                  variant="standard"
                  label="Job Description"
                  placeholder="URL"
                  size="small"
                  fullWidth
                />
              </FormCell>
              <FormCell>
                <TextField
                  variant="standard"
                  label="Job Title"
                  placeholder="Application Engineer"
                  size="small"
                  fullWidth
                />
              </FormCell>
              <FormCell>
                <TextField
                  variant="standard"
                  label="Job Location"
                  placeholder="Location, State"
                  size="small"
                  fullWidth
                />
              </FormCell>

              <FormCell>
                <Autocomplete
                  disablePortal
                  freeSolo
                  id="combo-box-demo"
                  renderInput={(params) => (
                    <TextField
                      label="Select Industry"
                      variant="standard"
                      size="small"
                      fullWidth
                    />
                  )}
                />
              </FormCell>
            </FormRow> */}

            <Box
              sx={{
                my: 1,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Job Poster Details:
              </Typography>
              <Button variant="text" size="small" startIcon={<AddIcon />}
               onClick={handleAddContact}
              >
                Add more Contacts
              </Button>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              {/* <Box
                sx={{
                  display: "flex",
                  gap: 3,
                }}
              >
                <TextField
                  variant="standard"
                  label="First Name"
                  placeholder="Josh"
                  size="small"
                  fullWidth
                />
                <TextField
                  variant="standard"
                  label="Last Name"
                  placeholder="Morgan"
                  size="small"
                  fullWidth
                />
                <TextField
                  variant="standard"
                  label="Designation"
                  placeholder="HR Manager"
                  size="small"
                  fullWidth
                />
                <TextField
                  variant="standard"
                  label="Email Id"
                  placeholder="josh.m@company.com"
                  size="small"
                  fullWidth
                />
                <TextField
                  variant="standard"
                  label="Phone"
                  placeholder="+1 (555) 555 555"
                  size="small"
                  fullWidth
                />
              </Box> */}
              {excelData.map((row, rowIndex) => (
                    <Box
                      key={rowIndex}
                      sx={{
                        display: "flex",
                        gap: 3,
                      }}
                    >
                      <TextField
                        variant="standard"
                        label="First Name"
                        placeholder="Josh"
                        size="small"
                        fullWidth
                        value={row.firstName}
                        onChange={(e) =>
                          handleChange(rowIndex, "firstName", e.target.value)
                        }
                        error={
                          Boolean(firstNameError) &&
                          row.firstName.trim() === "" &&
                          rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex &&
                          row.firstName.trim() === ""
                            ? firstNameError
                            : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Last Name"
                        placeholder="Morgan"
                        size="small"
                        fullWidth
                        value={row.lastName}
                        onChange={(e) =>
                          handleChange(rowIndex, "lastName", e.target.value)
                        }
                        error={
                          Boolean(lastNameError) &&
                          row.lastName.trim() === "" &&
                          rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex &&
                          row.lastName.trim() === ""
                            ? lastNameError
                            : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Designation"
                        placeholder="HR Manager"
                        size="small"
                        fullWidth
                        value={row.designation}
                        onChange={(e) =>
                          handleChange(rowIndex, "designation", e.target.value)
                        }
                        error={
                          Boolean(designationError) &&
                          row.designation.trim() === "" &&
                          rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex &&
                          row.designation.trim() === ""
                            ? designationError
                            : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Email Id"
                        placeholder="josh.m@company.com"
                        size="small"
                        fullWidth
                        value={row.emailId}
                        onChange={(e) =>
                          handleChange(rowIndex, "emailId", e.target.value)
                        }
                        error={
                          Boolean(emailIdError) && rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex ? emailIdError : ""
                        }
                      />
                      <TextField
                        variant="standard"
                        label="Phone"
                        placeholder="+1 (555) 555 555"
                        size="small"
                        fullWidth
                        value={row.phoneNumber}
                        onChange={(e) =>
                          handleChange(rowIndex, "phoneNumber", e.target.value)
                        }
                        error={
                          Boolean(phoneError) && rowIndex === firstNameIndex
                        }
                        helperText={
                          rowIndex === firstNameIndex ? phoneError : ""
                        }
                      />
                      <FormCell width={10} flex={0}>
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() => handleDeleteDirectContact(rowIndex)}
                        >
                          <CloseIcon fontSize="12" />
                        </IconButton>
                      </FormCell>
                    </Box>
                  ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: 2,
              pt: 4,
            }}
          >
            <Button variant="text" onClick={close}>
              Cancel
            </Button>

            <Button variant="contained" onClick={handleSubmit}>Save Job Details </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
          open={deletedContactModal}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: {
                xs: 1,
                md: 2,
              },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                rowGap: 0.5,
                flexWrap: "wrap",

                mb: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={"400"}>
                Are you sure you want to delete
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
            >
              <Button
                color="inherit"
                sx={{ textTransform: "inherit" }}
                onClick={() => setDeletedContactModal(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ textTransform: "inherit" }}
                onClick={() => handleDeleteContact()}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Modal>
    </React.Fragment>
  );
};

export default AddNewJobModal;
