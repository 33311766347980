import { Box, Button, Modal, Typography,Divider } from "@mui/material";
import React, { useState } from "react";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import theme from "../../Theme";
import { companyBlockedAndDncViaApi, employeeBlockedAndDncViaApi, noFallowUpsViaApi } from "../../store/companiesList/action";
import { useSelector, useDispatch } from "react-redux";
import companyStatus from "../StatusElements/companyStatus";
const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked } = companyStatus;
const AddCompany =
 (
    { 
        dataShow,
        filteredStatusResults,
        latestResult,
        storeData,
        query,
        searchResultsData,
        checkCompany,
        saveCompanyName,
        calculateDays,
        queryName,
        handleCheckEmailID,
        handleCheckEmailDomain,
        extractDomain,
        sanitizeString,
        setDataShow,
        setQuery,
        client,
     }
)=> {
    const dispatch = useDispatch();
      const [changeStatus, setChangeStatus] = useState("");
      const [statusModal, setStatusModal] = useState(false);
      const handleClose = () => {
        setStatusModal(!statusModal);
      };
      const handleChangeStatusModal = (status) => {
        setChangeStatus(status);
        setStatusModal(!statusModal);
      };
     let checkEMailStatus = "";
     let checkEMailName = "";
     const handleCheckEmailIDStatus = (latestResult, query) => {
       if (latestResult && latestResult.contactDetails) {
         // Loop through each contact detail
         for (let contact of latestResult.contactDetails) {
           // Check if the sanitized email ID matches the sanitized query
           if (sanitizeString(contact.emailId) === sanitizeString(query)) {
             const statusCheck = contact.status ? contact.status : "Active";
             if (
               (statusCheck === "DNC" || statusCheck === "Blocked") &&
               latestResult.status === "Active"
             ) {
               // If match found, return true
               checkEMailStatus = statusCheck;
               checkEMailName = contact.emailId;
               return true;
             }
           }
         }
       }
       // If no match found, return false
       return false;
     };
     function detectTextType(inputText) {
        // Regular expressions for email and domain detection
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const domainRegex = /[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
    
        // Check if input matches email regex
        if (emailRegex.test(inputText)) {
          return "Email";
        }
    
        if (domainRegex.test(inputText)) {
          return "Domain";
        }
    
        return "Company Name";
      }
      const checkQueryType = detectTextType(queryName);

      const handleChangeStatus = () => {
        const dateTime = new Date();
        let today = new Date();
        let expiryDate = new Date(today);
        expiryDate.setMonth(expiryDate.getMonth() + 6);
        let isoExpiryDate = expiryDate.toISOString();
    
        const textType = detectTextType(queryName);
        let takeDomainName = "";
    
        if (Array.isArray(latestResult.contactDetails)) {
          latestResult.contactDetails.forEach((contact) => {
            if (contact.emailId) {
              takeDomainName = extractDomain(contact.emailId.trim().toLowerCase());
            }
          });
        }
    
        if (textType === "Email") {
          const action = {
            companyName: latestResult.companyName,
            domain: takeDomainName ? takeDomainName : "",
            status: changeStatus ? changeStatus.trim() : "",
            email: query ? query.trim().toLowerCase() : "",
            addedBy: storeData._id,
            addedOn: dateTime,
            expiryDate: isoExpiryDate,
            client:client,
          };
    
          if (changeStatus === "DNC") {
            action.status=employeeDNC
            dispatch(employeeBlockedAndDncViaApi(action));
          } else if (changeStatus === "Blocked") {
             action.status=employeeBlocked
            action.expiryDate='';
            dispatch(employeeBlockedAndDncViaApi(action));
          } else if (changeStatus === companyNoFollowUp) {
            today = new Date();
            expiryDate = new Date(today);
            expiryDate.setDate(expiryDate.getDate() + 18);
            isoExpiryDate = expiryDate.toISOString();
            action.expiryDate = isoExpiryDate;
            dispatch(employeeBlockedAndDncViaApi(action));
          }
        } else {
          if (Array.isArray(latestResult.contactDetails)) {
            latestResult.contactDetails.forEach((contact) => {
              if (contact.emailId) {
                takeDomainName = contact.emailId.trim().toLowerCase();
              } else {
                console.log("contact.emailId is null or empty");
              }
            });
          } else {
            console.log("latestResult.contactDetails is not an array");
          }
    
          const action = {
            companyName: latestResult.companyName,
            domain: takeDomainName ? extractDomain(takeDomainName) : "",
            status: changeStatus ? changeStatus.trim() : "",
            addedBy: storeData._id,
            addedOn: dateTime,
            client:client
          };
    
          if (changeStatus === "DNC") {
            action.expiryDate = isoExpiryDate;
             action.status=companyDNC
            dispatch(companyBlockedAndDncViaApi(action));
          } else if (changeStatus === "Blocked") {
            action.status=companyBlocked
            dispatch(companyBlockedAndDncViaApi(action));
          } else if (changeStatus === companyNoFollowUp) {
            today = new Date();
            expiryDate = new Date(today);
            expiryDate.setDate(expiryDate.getDate() + 18);
            isoExpiryDate = expiryDate.toISOString();
            action.expiryDate = isoExpiryDate;
            dispatch(noFallowUpsViaApi(action));
          }
        }
    
        // Reset state and toggle modal
        setQuery("");
        setDataShow("");
        setStatusModal(!statusModal);
      };
  return (
    <Box>
      {dataShow && sanitizeString(dataShow) === sanitizeString(query) && (
        <>
          {(storeData.userType.includes('SuperAdmin') || storeData.userType.includes('RATeam') || storeData.userType.includes('Developer'))&&
          searchResultsData.length === 0 &&
          checkCompany ? (
            <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
              <Button variant="outlined" onClick={() => saveCompanyName(query)}>
                Add Company
              </Button>
            </Box>
          ) : (
            <>
              {filteredStatusResults && filteredStatusResults.length > 0 ? (
                "" // Render nothing
              ) : (
                <>
                  {(storeData.userType.includes('SuperAdmin') || storeData.userType.includes('RATeam') || storeData.userType.includes('Developer'))&&
                  latestResult &&
                  latestResult.addedOn &&
                  calculateDays(latestResult.addedOn) > 17 ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        m: 3,
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() =>
                          saveCompanyName(latestResult.companyName)
                        }
                      >
                        Add Company
                      </Button>
                    </Box>
                  ) : (
                    "" // Render nothing
                  )}
                </>
              )}
            </>
          )}
          <Box>
            {!storeData.userType.includes("RATeam")&&(<>
            {searchResultsData.length === 0 && checkCompany ? (
              <Box>
                {sanitizeString(query) === sanitizeString(queryName) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={{ color: theme.palette.success.dark }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: theme.palette.success.dark,
                      }}
                    >
                      {query}
                    </Typography>
                    <Typography> does not exist. </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {latestResult &&
                  (sanitizeString(latestResult.companyName) ===
                    sanitizeString(query) ||
                    sanitizeString(latestResult.domain) ===
                      sanitizeString(query) ||
                    sanitizeString(latestResult.domain) ===
                      sanitizeString(extractDomain(query)) ||
                    handleCheckEmailID(latestResult, query) ||
                    handleCheckEmailDomain(latestResult, query)) && (
                    <Box>
                     

                      {latestResult.status === "Active" &&
                        !handleCheckEmailIDStatus(latestResult, query) && (
                          <Box
                            sx={{
                              width: "100%",
                              maxWidth: "1200px",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              gap: 2,
                              my:3,
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="warning"
                              startIcon={<WarningOutlinedIcon />}
                    
                              onClick={() => handleChangeStatusModal("DNC")}
                            >
                              DNC (Do not Contact)
                            </Button>
                            <Button
                             variant="outlined"
                              color="error"
                              startIcon={<RemoveCircleIcon />}
                             
                              onClick={() => handleChangeStatusModal("Blocked")}
                            >
                              Block
                            </Button>
                            <Button
                               variant="outlined"
                              color="warning"
                              startIcon={<WarningOutlinedIcon />}
                             
                              onClick={() =>
                                handleChangeStatusModal(companyNoFollowUp)
                              }
                            >
                              
                              UnSubscribe
                            </Button>
                            
                          </Box>
                        )}
                    </Box>
                  )}
              </Box>
            )}
             {/**mahender update becuase delete contact 24-05-2024 */}
          <Modal
            open={statusModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "400px",
                width: "80%",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: {
                  xs: 1,
                  md: 2,
                },
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  columnGap: 1,
                  rowGap: 0.5,
                  flexWrap: "wrap",

                  mb: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography fontWeight={"400"}>
                  
                  {/* Are you sure you want to change the status of the{" "}
                  {checkQueryType} to {changeStatus} */}
                  {changeStatus===companyNoFollowUp?(
                    <>
                    Are you sure you want to remove this {checkQueryType} from the follow-up list?
                    </>
                  ):(
                    <>
                    Are you sure you want to change the status of the{" "}
                    {checkQueryType} to {changeStatus}
                    </>
                  )}
                  
                </Typography>
              </Box>
              {changeStatus === "DNC" && (
                <>
                  {checkQueryType === "Company Name" ? (
                    <Box sx={{ color: "error.main", pb: 2 }}>
                      Note : The entire company will be marked as Do Not Contact
                      for 6 months{" "}
                    </Box>
                  ) : checkQueryType === "Domain" ? (
                    <Box sx={{ color: "red" }}>
                      Note : The entire company associated with this domain will
                      be marked as Do Not Contact for 6 months.
                    </Box>
                  ) : checkQueryType === "Email" ? (
                    <Box sx={{ color: "red" }}>
                      Note :  Only this email address will be permanently blocked, while the entire company will remain active.{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}
              {changeStatus === "Blocked" && (
                <>
                  {checkQueryType === "Company Name" ? (
                    <Box sx={{ color: "error.main", pb: 2 }}>
                      Note : The entire company will be permanently blocked.{" "}
                    </Box>
                  ) : checkQueryType === "Domain" ? (
                    <Box sx={{ color: "red" }}>
                      Note : The entire company associated with this domain will
                      be permanently blocked.
                    </Box>
                  ) : checkQueryType === "Email" ? (
                    <Box sx={{ color: "red" }}>
                      Note : Only this email address will be permanently
                      blocked, while the entire company will remain active.{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}
              {changeStatus === companyNoFollowUp && (
                <>
                  {checkQueryType === "Company Name" ? (
                    <Box sx={{ color: "error.main", pb: 2 }}>
                      Note : The entire company will not be followed-up for 18
                      days.{" "}
                    </Box>
                  ) : checkQueryType === "Domain" ? (
                    <Box sx={{ color: "red" }}>
                      Note : The entire company associated with this domain will
                      not be followed-up for 18 days.
                    </Box>
                  ) : checkQueryType === "Email" ? (
                    <Box sx={{ color: "red" }}>
                      Note :The entire company will not be followed-up for 18
                      days.{" "}
                    </Box>
                  ) : (
                    ""
                  )}
                </>
              )}

              <Divider />
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Button
                  color="inherit"
                  sx={{ textTransform: "inherit" }}
                  onClick={() => handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "inherit" }}
                  onClick={() => handleChangeStatus()}
                >
                  Change
                </Button>
              </Box>
            </Box>
          </Modal>
            </>)}
          </Box>
        </>
      )}
    </Box>
  );
};
export default AddCompany;
