import { SnackbarProvider } from "notistack";
import React, { useState, useEffect, useRef } from "react";

import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import FormatDateTime from "../../Components/Helper/FormatDateTime";

import {
  Box,
  Chip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Divider,
  ButtonGroup,
  Button,
  IconButton,
  Autocomplete,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReplyAllRoundedIcon from "@mui/icons-material/ReplyAllRounded";
import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import ForwardRoundedIcon from "@mui/icons-material/ForwardRounded";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import theme from "../../Theme";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { CustomFormField } from "../../Components";
import { EmailData } from "../../Data/EmailData";

const emailList = [
  {
    email: "applicant1@test.com",
    name: "Applicant - 1",
  },
  {
    email: "applicant2@test.com",
    name: "Applicant - 2",
  },
  {
    email: "applicant3@test.com",
    name: "Applicant - 3",
  },
  {
    email: "applicant4@test.com",
    name: "Applicant - 4",
  },
  {
    email: "applicant4@test.com",
    name: "Applicant - 4",
  },
  {
    email: "applicant5@test.com",
    name: "Applicant - 5",
  },
  {
    email: "applicant6@test.com",
    name: "Applicant - 6",
  },
];

const NameEmailDetailList = ({ type = "", data = [] }) => {
  //const filteredEmailIds = [...new Set(emailList.map((item) => item.email))];

  return (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "flex-start" }}>
      {type && (
        <Box
          sx={{
            //backgroundColor: theme.palette.grey[100],
            color: theme.palette.grey[400],
            px: 0,
            py: 0,
            m: 0,
            borderRadius: 1,
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
            fontWeight: 500,

            fontSize: 12,
            textWrap: "nowrap",
          }}
        >
          {type} :
        </Box>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap", columnGap: 1 }}>
        {data.map((item, key) => {
          return (
            <Typography
              variant="caption"
              color={theme.palette.grey[700]}
              fontWeight={item?.name ? 600 : 400}
            >
              {item?.name ? (
                <Tooltip title={item.email} TransitionComponent={Zoom}>
                  {item.name}
                </Tooltip>
              ) : (
                `<${item.email}>`
              )}
              {key < data.length - 1 && ","}
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
};

const ApplicantBulkEmail = () => {
  const updatedList = [...new Set(emailList.map((item) => item.email))];
  ///////////////////////////////////////////
  const [write, setWrite] = useState(false);
  const boxRef = useRef(null); // Create a ref for the Box component

  const handleClickOutside = (event) => {
    // Check if the clicked element is outside of the Box component
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setWrite(false); // Set 'write' to false if clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Applicant Bulk Email"}>
          <Box
            sx={{
              p: 2,
              border: 1,
              borderColor: theme.palette.grey[200],
              bgcolor: "white",
              mt: 3,
            }}
          >
            <Box sx={{ pb: 2 }}>
              <Box
                ref={boxRef}
                onClick={() => setWrite(true)}
                sx={{
                  "&:hover": {
                    backgroundColor: theme.palette.grey[50],
                  },
                }}
              >
                {!write ? (
                  <NameEmailDetailList type="" data={emailList} />
                ) : (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box>To</Box>
                        <Box flex={1}>
                          <Autocomplete
                            limitTags={4}
                            multiple
                            id="tags-standard"
                            size="small"
                            options={[]}
                            freeSolo
                            getOptionLabel={(option) => option}
                            defaultValue={updatedList}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                //label="To"
                                size="small"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    py: 0.5,
                                    "&:before": {
                                      borderBottom: "1px solid #CCCCCC", // Change to any desired color
                                    },
                                    "&:hover:not(.Mui-disabled):before": {
                                      borderBottom: "1px solid #CCCCCC", // Change hover color
                                    },
                                    "&:after": {
                                      borderBottom: "1px solid #C2C2C2", // Focused state color
                                    },
                                  },
                                }}
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  size="small"
                                  sx={{
                                    fontSize: "12px",
                                    borderColor: theme.palette.grey[200],
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Box>Cc</Box>
                        <Box flex={1}>
                          <Autocomplete
                            limitTags={4}
                            multiple
                            id="tags-standard"
                            size="small"
                            options={[]}
                            freeSolo
                            getOptionLabel={(option) => option}
                            defaultValue={[]}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                //label="Cc"
                                size="small"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    py: 0.5,
                                    "&:before": {
                                      borderBottom: "1px solid #CCCCCC", // Change to any desired color
                                    },
                                    "&:hover:not(.Mui-disabled):before": {
                                      borderBottom: "1px solid #CCCCCC", // Change hover color
                                    },
                                    "&:after": {
                                      borderBottom: "1px solid #C2C2C2", // Focused state color
                                    },
                                  },
                                }}
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  size="small"
                                  sx={{
                                    fontSize: "12px",
                                    borderColor: theme.palette.grey[200],
                                  }}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ mb: 1 }}>
              <CustomFormField
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,
                      defaultValue: "Subject",
                      placeholder: "Subject",
                    },
                  },
                  {
                    type: "select",
                    props: {
                      title: "Select Template",
                      defaultValue: "",
                      options: ["Selct-01", "Selct-02"],
                    },
                  },
                  {
                    type: "autocomplete",
                    props: {
                      title: "From",
                      multiple: false,
                      defaultValue: ["@tektreeinc.com"],
                      options: [
                        "@tektreeinc.com",
                        "@tektreellc.com",
                        "@techfive.ai",
                      ],
                    },
                  },
                ]}
              />
            </Box>
            <ReactQuill
              //ref={quillRef}
              theme="snow"
              //value={defaultValue}
              //onChange={handleTextEditorChange}
              placeholder={"Write"}
              modules={{
                toolbar: {
                  container: [
                    ["bold", "italic", "underline"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                    ["clean"],
                    [{ color: [] }, { background: [] }],
                  ],
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pt: 1,
                alignItems: "center",
              }}
            >
              <Box>
                <ButtonGroup>
                  <Button endIcon={<SendRoundedIcon />} color="secondary">
                    Send
                  </Button>
                </ButtonGroup>
              </Box>
              <Box>
                <IconButton color="error">
                  <DeleteRoundedIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box pt={10} />
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default ApplicantBulkEmail;
