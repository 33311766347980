import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  ADD_COMPANY_NAME_VIA_API,
  ADD_COMPANY_VIA_API,
  ADD_EMAIL_TEMPLATE_VIA_API,
  ADD_INDUSTRIES_NAMES_VIA_API,
  ADD_JOB_DETAILS_VIA_API,
  ADD_NEW_JOB_VIA_API,
  ASSIGN_USERS_DATA_SEARCH_VIA_API,
  CHANGE_STATUS_VIA_API,
  COUNT_SENT_MAILS_VIA_API,
  DATE_SEARCH_RAUSERS_COMPANIES_VIA_API,
  DATE_SEARCH_USERS_COMPANIES_VIA_API,
  DELETE_COMPANY_VIA_API,
  GET_ALL_INDUSTRIES_VIA_API,
  GET_ALL_TEMPLATES_VIA_API,
  GET_ALL_USERS_COMPANIES_LIST_VIA_API,
  GET_COMPANIES_VIA_API,
  GET_SHUFFLED_DATA_VIA_API,
  GLOBAL_SEARCH_VIA_API,
  INDUSTRIES_NAMES_VIA_API,
  LAST_WEEK_SHUFFLED_DATA_VIA_API,
  RA_WEEK_REPORTS_VIA_API,
  SEARCH_DATA_VIA_API,
  SEND_EMAILS_VIA_API,
  SEND_EXCELUPLOAD_ALL_COMPANIES_VIA_API,
  SHUFFLED_DATA_VIA_API,
  STASTUS_CHANGE_VIA_API,
  SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API,
  SUCCESS_SEARCH_DATA,
  UNSUBSCRIBE_VIA_API,
  YESTERDAY_RA_REPORTS_VIA_API,
} from "./actionType";
import { json } from "react-router-dom";

import {
  checkCompanyName,
  getCompaniesSuccess,
  setSnackbarMessage,
  successCountSentMails,
  successDateSearchUsersCompanies,
  successGetAllIndustries,
  successGetAllTemplates,
  successGlobalSearch,
  successNewCompany,
  successShuffledData,
} from "./action";
import { loadingViaRedux, successRaWeekReport, successYesterDayRAReports } from "../reports/action";
const apiUrl = process.env.REACT_APP_API_URL;
function postSearchDataViaApiSaga(payload) {
  const encodedQuery = encodeURIComponent(payload.cleanedQuery);
  const client=payload.client
  return fetch(apiUrl + "/companyData/" + encodedQuery + "/" + client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function postCompanyDataViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function postCompanyNameViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/addCompanyName", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getCompaniesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/userId", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllCompaniesViaApiSaga() {
  return fetch(apiUrl + "/companyData/getAllCompanies", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function deleteCompanyApi(payload) {
  const myPayload = JSON.stringify({ id: payload.id,client:payload.client });
  return fetch(apiUrl + "/companyData/", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload, // Send the modified object
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function postJobdetailsViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/jobDetails", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function postSendEmailViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/emailLogs/sendBulkEmails", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function postGlobalSearchViaApiSaga(payload) {
  return fetch(apiUrl + "/companyData/searchEmail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ searchEmail: payload }),
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function postExcelUploadAllCompaniesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/excelUpload", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllIndustriesViaApiSaga(payload) {
  const client = payload.client; // Extract client from payload
  return fetch(apiUrl + "/industries/"+client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function postIndustriesNamesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/industries/industriesNames", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function postChangeStatusViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/status", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllTemplatesViaApiSaga(payload) {
  const client = payload.client;
  return fetch(apiUrl + "/templates/" + client, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getCountSentMailsViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/emailLogs/emailCount", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function postDateSearchUsersCompaniesViaSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/DateSearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function dateSearchRaUsersCompaniesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/RAUsersDateSearch", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function postShuffledDataViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/shuffles", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function getShuffledDataViaApiSaga(payload){
  const client=payload.client
  return fetch(`${apiUrl}/shuffles/${client}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

// function postSearchDataViaApiSaga(query) {
  
//   return fetch(apiUrl + "/companyData/" + encodedQuery, {
function statusChangeViaApiSaga(queryName){
  const encodedQuery = encodeURIComponent(queryName);
  return fetch(apiUrl + "/companyData/statusChange/" + encodedQuery, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function unSubscribeViaApi(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/unSubscribers/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addEmailTemplateViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/templates/addTemplate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function assignUsersDataSearchViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/assignUsersData", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function lastWeekShuffledDataViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/shuffles/dateShuffled", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addIndustriesNamesViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/industries/addIndustri", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function addNewjobViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/companyData/addNewJob", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}


{
  /**below calling APIs */
}
function* searchDataSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(postSearchDataViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        return null;
      }
     
      if (!response || (Array.isArray(response) && response.length === 0)) {
        yield put(checkCompanyName(true));
      }

      yield put({ type: SUCCESS_SEARCH_DATA, payload: response });
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addCompanySaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
     
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }
       
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* addCompanyNameSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(postCompanyNameViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }
    
      if(response.Data){
        yield put(successNewCompany(response.Data));

      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}

function* getCompaniesSaga(action) {
  try {
    if (!action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }

    const { response, timeout } = yield race({
      response: call(getCompaniesViaApiSaga, action.payload),
      timeout: delay(60000),
    });

    if (response) {
      yield put(loadingViaRedux(true))
      yield put(getCompaniesSuccess(response));
      
    }
  } catch (error) {
    console.error(error);
   
  }
}
function* getAllUsersCompaniesLisSaga() {
  try {
    // Call the API function with the provided parameters
    const { response, timeout } = yield race({
      response: call(getAllCompaniesViaApiSaga), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put({
        type: SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API,
        payload: response,
      });
    } else {
      // Else code here
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* deleteCompanyViaApiSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }
    // Call the API function with the provided parameters
    const { response, timeout } = yield race({
      response: call(deleteCompanyApi, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response.message === "Company deleted successfully") {
      yield put(setSnackbarMessage(response.message, "success"));
    } else {
      // Else code here
    }

    // Dispatch a success action to update the store
    // yield put(settleItems());
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addJobDetailsSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }
    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      response: call(postJobdetailsViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* sendEmailsSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }
    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      response: call(postSendEmailViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* globalSearchViaSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }
    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      // response: call(postGlobalSearchViaApiSaga, action.payload), // Call the API function
      response: call(postSearchDataViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (Array.isArray(response) && response.length === 0) {
        const message = "This email ID or companyName or domain  does not exist";
        yield put(setSnackbarMessage(message, "warning"));
      }

      yield put(successGlobalSearch(response));
    }
  } catch (error) {

  }
}
function* sendExcelUploadAllCompaniesViaSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }
    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      response: call(postExcelUploadAllCompaniesViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* getAllIndustriesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllIndustriesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      yield put(successGetAllIndustries(response));
    }
  } catch (error) {}
}
function* industriesNamesViaApiSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }

    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      response: call(postIndustriesNamesViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}

function* changeStatusViaApiSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }
    const { response, timeout } = yield race({
      response: call(postChangeStatusViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* getAllTemplatesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getAllTemplatesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      yield put(successGetAllTemplates(response));
    }
  } catch (error) {}
}
function* countSentMailsViaApiSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getCountSentMailsViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      yield put(successCountSentMails(response));
    }
  } catch (error) {}
}
function* dateSearchUsersCompaniesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(postDateSearchUsersCompaniesViaSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      yield put(loadingViaRedux(true))

      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      yield put(successDateSearchUsersCompanies(response));
    }
  } catch (error) {}
}
function* dateSearchRaUsersCompaniesViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(dateSearchRaUsersCompaniesViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      yield put(loadingViaRedux(true))

      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      yield put(successDateSearchUsersCompanies(response));
    }
  } catch (error) {}
}

function* shuffledDataViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(postShuffledDataViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      yield put(loadingViaRedux(true))
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null; 
      }else if (response.message) {
      yield put(successShuffledData(response.Data));
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
    else {
      // Handle timeout here if needed
    }
  } catch (error) {}
}
function* getShuffledDataViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getShuffledDataViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null; 
      }
      yield put(successShuffledData(response));

    }
    else {
      // Handle timeout here if needed
    }
  } catch (error) {}
}


function* stastusChangeViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(statusChangeViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (!response || (Array.isArray(response) && response.length === 0)) {
        yield put(checkCompanyName(true));
      }

      yield put({ type: SUCCESS_SEARCH_DATA, payload: response });
    }
  } catch (error) {}
}
function* unSubscribeViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(unSubscribeViaApi,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null; 
      }
      if(response.message){
        yield put(setSnackbarMessage(response.message, "success")); 

      }
     
    }
    else {
      // Handle timeout here if needed
    }
  } catch (error) {}
}
function* addEmailTemplateViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(addEmailTemplateViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null; 
      }
      if(response.message){
        yield put(setSnackbarMessage(response.message, "success")); 

      }
     
    }
    else {
      // Handle timeout here if needed
    }
  } catch (error) {}
}
function* assignUsersDataSearchViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(assignUsersDataSearchViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      yield put(successDateSearchUsersCompanies(response));
    }
  } catch (error) {}
}

function* lastWeekShuffledDataViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(lastWeekShuffledDataViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error"));
        return null; 
      }
      yield put(successShuffledData(response));

    }
    else {
      // Handle timeout here if needed
    }
  } catch (error) {}
}
function* raWeekReportsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getCompaniesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))

      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      
        yield put(successRaWeekReport(response));
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* yesterDayRAReportsViaSaga(action) {
  try {
    const { response, timeout } = yield race({
      response: call(getCompaniesViaApiSaga,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      yield put(loadingViaRedux(true))

      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));

        return null;
      }
      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      }
      
        yield put(successYesterDayRAReports(response));
    }
  } catch (error) {
    // Handle errors here and dispatch a failure action
    // yield put(settleItems(error));
  }
}
function* addIndustriesNamesViaSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }

    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      response: call(addIndustriesNamesViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* addNewjobViaSaga(action) {
  try {
    if (!action || !action.payload || !navigator.onLine) {
      throw new Error("Action payload is empty or missing action");
    }

    const { response, timeout } = yield race({
      // response: call(postCompanyDataViaApiSaga, action.payload), // Call the API function
      response: call(addNewjobViaApiSaga, action.payload), // Call the API function

      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });

    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}

// Watch for SETTLE_ITEMS action and run settleItemsSaga
function* watchSettleItems() {
  yield takeEvery(SEARCH_DATA_VIA_API, searchDataSaga);
  yield takeEvery(ADD_COMPANY_VIA_API, addCompanySaga);
  yield takeEvery(ADD_COMPANY_NAME_VIA_API, addCompanyNameSaga);
  yield takeEvery(GET_COMPANIES_VIA_API, getCompaniesSaga);
  yield takeEvery(
    GET_ALL_USERS_COMPANIES_LIST_VIA_API,
    getAllUsersCompaniesLisSaga
  );
  yield takeEvery(DELETE_COMPANY_VIA_API, deleteCompanyViaApiSaga);
  yield takeEvery(ADD_JOB_DETAILS_VIA_API, addJobDetailsSaga);
  yield takeEvery(SEND_EMAILS_VIA_API, sendEmailsSaga);
  yield takeEvery(GLOBAL_SEARCH_VIA_API, globalSearchViaSaga);
  yield takeEvery(
    SEND_EXCELUPLOAD_ALL_COMPANIES_VIA_API,
    sendExcelUploadAllCompaniesViaSaga
  );
  yield takeEvery(GET_ALL_INDUSTRIES_VIA_API, getAllIndustriesViaSaga);
  yield takeEvery(INDUSTRIES_NAMES_VIA_API, industriesNamesViaApiSaga);
  yield takeEvery(CHANGE_STATUS_VIA_API, changeStatusViaApiSaga);
  yield takeEvery(GET_ALL_TEMPLATES_VIA_API, getAllTemplatesViaSaga);
  yield takeEvery(COUNT_SENT_MAILS_VIA_API, countSentMailsViaApiSaga);
  yield takeEvery(
    DATE_SEARCH_USERS_COMPANIES_VIA_API,
    dateSearchUsersCompaniesViaSaga
  );
  
  yield takeEvery(SHUFFLED_DATA_VIA_API,shuffledDataViaSaga);
  yield takeEvery(GET_SHUFFLED_DATA_VIA_API,getShuffledDataViaSaga);
  yield takeEvery(STASTUS_CHANGE_VIA_API,stastusChangeViaSaga);
  yield takeEvery(UNSUBSCRIBE_VIA_API,unSubscribeViaSaga);
  yield takeEvery(ADD_EMAIL_TEMPLATE_VIA_API,addEmailTemplateViaSaga);
  yield takeEvery(
    ASSIGN_USERS_DATA_SEARCH_VIA_API,
    assignUsersDataSearchViaSaga
  );
  yield takeEvery(LAST_WEEK_SHUFFLED_DATA_VIA_API,lastWeekShuffledDataViaSaga);
  yield takeEvery(RA_WEEK_REPORTS_VIA_API,raWeekReportsViaSaga)
  yield takeEvery(YESTERDAY_RA_REPORTS_VIA_API,yesterDayRAReportsViaSaga)
  yield takeEvery(ADD_INDUSTRIES_NAMES_VIA_API,addIndustriesNamesViaSaga)
  yield takeEvery(DATE_SEARCH_RAUSERS_COMPANIES_VIA_API,dateSearchRaUsersCompaniesViaSaga)
  yield takeEvery(ADD_NEW_JOB_VIA_API,addNewjobViaSaga)

}

export default function* SearchSaga() {
  yield all([
    // ... other sagas ...

    fork(watchSettleItems),
  ]);
}
