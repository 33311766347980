import { Box, Button, Divider, Grid, Modal } from '@mui/material';
import React from 'react'
import SectionTitle from '../Helper/SectionTitle';
import CustomFormField from '../Common/CustomFormField';

 const JobBoardsQuickSubmitSelectJob = ({open,jobPostingOptions,selectJobQuickSubmit,setSelectJobQuickSubmit,close,submit}) => {
  return (
    <Modal
    open={open}
    aria-labelledby="send email"
    aria-describedby="send email modal"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        maxWidth: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: {
          xs: 1,
          md: 2,
        },
        borderRadius: 2,
      }}
    >
      <Grid container spacing={2} sx={{ height: "100%" }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <SectionTitle title="Quick Submit" />
          </Box>
          <Divider sx={{ my: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomFormField
                title={"Select job"}
                components={[
                  {
                    type: "autocomplete",
                    props: {
                      placeholder: `select the job`,
                      options: jobPostingOptions,
                      defaultValue: selectJobQuickSubmit,
                      onChange: (value) => setSelectJobQuickSubmit(value),
                    },
                  },
                ]}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                mt: 2,
              }}
            >
              <Button
                color="inherit"
                onClick={() => close()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => submit()}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Modal>
  )
}
export default JobBoardsQuickSubmitSelectJob;
