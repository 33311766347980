import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Tab,
  Typography,
  Modal,
  Tooltip,
  Avatar,
  CircularProgress,
  Backdrop,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import theme from "../../Theme";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { pink } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import GroupsIcon from "@mui/icons-material/Groups";
import ShareIcon from "@mui/icons-material/Share";
import LabelIcon from "@mui/icons-material/Label";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  addApplicantNoteViaApi,
  addJobBoardsHistoryViaApi,
  advancedResumeSearchViaApi,
  awsResumeUrl,
  clearSearchResults,
  getAllDegreesViaApi,
  getIntegrationsViaApi,
  getJobBoardsHistoryViaApi,
  getMonsterResumeAndStoreInternalDbViaApi,
  getResumeAndStoreInternalDbViaApi,
  monsterAdvancedResumeSearchViaApi,
  searchDateJobPostingDataViaApi,
  searchResumeIdInternalDbViaApi,
  searchResumeInternalDbViaApi,
  stopLoadingRedux,
  successAdvancedResumeSearch,
  successAwsResumeUrl,
  successHtmlResumeConvert,
  successSearchResumeIdInternalDb,
  tagApplicantToJobViaApi,
} from "../../store/actions";
import { ShimmerText } from "react-shimmer-effects";
import { set } from "lodash";

import { DataGrid } from "@mui/x-data-grid";
import logoDice from "../../Assets/Images/logo-dice.jpg";
import logoMonster from "../../Assets/Images/logo-monster.jpg";
import logoCB from "../../Assets/Images/logo-cb.jpg";

import { closeSnackbar, enqueueSnackbar, SnackbarProvider } from "notistack";
import Highlighter from "react-highlight-words";
import {
  CustomFormField,
  JobBoardResultTable,
  JobBoardsAddNotes,
  JobBoardsQuickSubmitCheckAutharize,
  JobBoardsQuickSubmitConfirmModal,
  JobBoardsQuickSubmitSelectJob,
  KeyData,
  MainBackground,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import ResumeViewer from "../../Components/Common/ResumeViewer";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import {
  optionsMonsterPostFrom,
  optionsMonsterTo,
} from "../../Data/JobPostingData";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
import CandidateSendMail from "../../Components/Modals/CandidateSendMail";
import CandidatesMailMerge from "../../Components/Modals/CandidatesMailMerge";
const data = {
  creditData: {
    remainingCredit: 99,
    currentMonthViews: 1,
    dailyLimit: 299,
    monthlyLimit: 138,
  },
};
const JobBoardsResult = () => {
  const locationData = useLocation();
  const navigate = useNavigate();
  const actionData = locationData.state;
  const dispatch = useDispatch();
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const resumesFile = useSelector((state) => state.Resumes.resumesFile);
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client = storeData.clientDb;
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const HtmlResume = useSelector((state) => state.Recruiters?.htmlResume);
  const integrationsList = useSelector((state) => state.Resumes.integrations);

  const [searchType, setSearchType] = useState(actionData?.searchType || "");
  const [pageSize, setPageSize] = useState(30);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const MonsterResumesList = useSelector(
    (state) => state.Resumes.monsterResumesList
  );
  const CbResumesList = useSelector((state) => state.Resumes.cbResumesList);
  const InternalResumesList = useSelector(
    (state) => state.Resumes.internalResumesList
  );
  const internalDBCandidateList = Array.isArray(InternalResumesList)
    ? InternalResumesList.map((applicant, index) => ({
        id: index,
        ...applicant,
      }))
    : [];
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const jobPostingOptions = Array.isArray(JobPosting)
    ? JobPosting.map((job) => ({
        value: job.jobDetails.jobCode,
        label: `${job.jobDetails.jobCode}-${job.jobDetails.jobTitle}-${job.jobDetails.client}`,
      }))
    : [];
  const templatesData = useSelector((state) => state.Search.templates) || [];
  //Monster
  let matchedMonsterResult = 0;
  function parseMonsterCandidatedData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      MonsterResumesList,
      "application/xml"
    );
    const resumes = xmlDoc.getElementsByTagName("Resume");

    const candidates = Array.from(resumes).map((resume, index) => {
      const firstName = resume.querySelector("First")?.textContent || "";
      const lastName = resume.querySelector("Last")?.textContent || "";
      const middleName = resume.querySelector("Middle")?.textContent || "";
      const email =
        resume.querySelector("MD5-EmailAddress")?.textContent || "N/A";
      const board = resume.querySelector("Board");
      const city = resume.querySelector("City")?.textContent || "N/A";

      // Query Location inside each resume
      const locationElement = resume.querySelector("Location");
      let country = "N/A",
        state = "N/A",
        cityFromLocation = "N/A";
      if (locationElement) {
        const location = locationElement.textContent || "";
        [country, state, cityFromLocation] = location.split("-");
      }

      const relocation =
        resume.querySelector("Relocation")?.textContent || "N/A";
      const title = resume.querySelector("Title")?.textContent || "N/A";
      const description =
        resume.querySelector("Description")?.textContent || "N/A";
      const dateModified =
        resume.querySelector("DateModified")?.textContent || "N/A";
      const companyName =
        resume.querySelector("Company > Name")?.textContent || "N/A";

      // JobTypes
      const jobTypeElements = resume.getElementsByTagName("JobType");
      const jobTypes = Array.from(jobTypeElements).map(
        (jobType) => jobType.textContent
      );
      const desiredJobTypes = `Desired Job Type: ${jobTypes.join(", ")}`;

      // Experience Calculation
      const experienceFromDate = resume.querySelector("Experience > DateFrom");
      const experienceToDate = resume.querySelector("Experience > DateTo");
      const educationElements = resume.getElementsByTagName("Education");

      const educationLevels = Array.from(educationElements)
        .map((education) => {
          const level = education.querySelector("Level");
          return level ? level.textContent : null;
        })
        .filter((level) => level !== null);
      const salaryElement = resume.querySelector("Salary");
      const minSalary = salaryElement?.querySelector("Min")?.textContent || "0";
      const maxSalary = salaryElement?.querySelector("Max")?.textContent || "0";
      const currency =
        salaryElement?.querySelector("Currency")?.textContent || "N/A";
      const type = salaryElement?.querySelector("Type")?.textContent || "N/A";

      // Calculate experience
      let years = 0,
        months = 0;
      if (experienceFromDate && experienceToDate) {
        const yearFrom = parseInt(experienceFromDate.getAttribute("Year"), 10);
        const monthFrom = parseInt(
          experienceFromDate.getAttribute("Month"),
          10
        );
        const yearTo = parseInt(experienceToDate.getAttribute("Year"), 10);
        const monthTo = parseInt(experienceToDate.getAttribute("Month"), 10);

        years = yearTo - yearFrom;
        months = monthTo - monthFrom;

        if (months < 0) {
          years--;
          months += 12;
        }
      }
      const isConfidential =
        resume.querySelector("Confidential")?.textContent === "1";
      const name = isConfidential
        ? "Confidential"
        : `${firstName || ""} ${middleName || ""} ${lastName || ""}`;
      const resumesNode = xmlDoc.getElementsByTagName("Resumes")[0];
      matchedMonsterResult = resumesNode.getAttribute("Found");
      return {
        id: index,
        ResumeId: resume.getAttribute("SID") || "N/A",
        ResumeBoardId: board?.getAttribute("ID") || "N/A",
        Name: name,
        Source: board?.textContent || "N/A",
        Email: email,
        Location: {
          country: country || "N/A",
          state: state || "N/A",
          city: cityFromLocation || "N/A",
        },
        Relocation: relocation,
        JobTitle: title,
        Description: description,
        ResumeUpdated: dateModified,
        DesiredJobType: desiredJobTypes,
        HighestEducation:
          educationLevels.length > 0 ? educationLevels[0] : "N/A",
        DesiredSalary: `${minSalary}-${maxSalary} ${currency}/${type}`,
        ExperienceYears: years,
        ExperienceMonths: months,
        companyName: companyName,
      };
    });

    return candidates;
  }

  // Call the function to parse candidate data
  const monsterCandidateList = parseMonsterCandidatedData();

  const [keyWords, setKeyWords] = useState(actionData?.keyWords || "");
  const [errorKeyword, setErrorKeyword] = useState("");
  const [fromDate, setFromDate] = useState(actionData?.mdateminage || 0);
  const [toDate, setToDate] = useState(actionData?.mdatemaxage || 43200);
  const [cn, setcn] = useState(actionData?.qrcn || "");
  const [jobTitle, setJobTitle] = useState(actionData?.qrjt || "");
  const [jobDescription, setJobDescription] = useState(actionData?.qrjd || "");
  const [schoolAttend, setSchoolAttend] = useState(actionData?.qsn || "");
  const handleSearchMonster = () => {
    if (!keyWords) {
      return setErrorKeyword("please type any keyword");
    }

    let monsterAccountId = "";
    if (
      storeData.userType.includes("SuperAdmin") ||
      storeData.userType.includes("Developer")
    ) {
      if (Array.isArray(integrationsList)) {
        integrationsList.some(
          (accountApi) => (monsterAccountId = accountApi._id)
        );
      }
    }
    if (
      !Array.isArray(storeData.userType) ||
      !(
        storeData.userType.includes("SuperAdmin") ||
        storeData.userType.includes("Developer")
      )
    ) {
      let access = false;

      if (Array.isArray(integrationsList)) {
        access = integrationsList.some(
          (accountApi) =>
            Array.isArray(accountApi.assignedTo) &&
            accountApi.assignedTo.some(
              (account) =>
                account.accountId === storeData._id && account.access,
              (monsterAccountId = accountApi._id)
            )
        );
      }

      if (!access) {
        enqueueSnackbar(
          `You have no access to Monster search. Please contact the admin.`,
          {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          }
        );
        return "";
      }
    }

    const action = {
      sort: "rank,distance,mdate",
      radius: actionData?.radius || 30,
      query: keyWords,
      mdateminage: fromDate,
      mdatemaxage: toDate,
      country: actionData?.rpcr ? "" : actionData?.country,
      rlid: actionData?.rlid || 316,
      zipcode: actionData?.zipcode,
      rpcr: actionData?.rpcr || "",
      tsni: actionData?.tsni || "",
      twlid: actionData?.twlid || "",
      wa: actionData?.wa || "",
      qrcn: cn,
      qrjt: jobTitle,
      qrjd: jobDescription,
      qsn: schoolAttend,
      tnsalmin: actionData?.tnsalmin || "",
      tnsalmax: actionData?.tnsalmax || "",
      tsaltyp: actionData?.tsaltyp || "",
      tsalcur: actionData?.tsalcur || "",
      clv: actionData?.clv || "",
      edulv: actionData?.edulv || "",
      tjtft: actionData?.tjtft || "",
      tjtpt: actionData?.tjtpt || "",
      tjtpd: actionData?.tjtpd || "",
      tjtp: actionData?.tjtp || "",
      tjti: actionData?.tjti || "",
      tjttc: actionData?.tjttc || "",
      tjts: actionData?.tjts || "",
      Lskill: actionData?.Lskill || "",
      twindus: actionData?.twindus || "",
      page: page,
      pagesize: pageSize,
      client: client,
      monsterAccountId: monsterAccountId,
    };
    setLoading(true);
    dispatch(monsterAdvancedResumeSearchViaApi(action));
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "Monster",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      dispatch(getIntegrationsViaApi(action));
      dispatch(getJobBoardsHistoryViaApi(historyAction));
    }, 1500);
  };

  const handlePageChange = (event, value) => {
    if (!keyWords) {
      return setErrorKeyword("please type any keyword");
    }
    if (searchType === "Monster") {
      let monsterAccountId = "";
      if (
        storeData.userType.includes("SuperAdmin") ||
        storeData.userType.includes("Developer")
      ) {
        if (Array.isArray(integrationsList)) {
          integrationsList.some(
            (accountApi) => (monsterAccountId = accountApi._id)
          );
        }
      }
      if (
        !Array.isArray(storeData.userType) ||
        !(
          storeData.userType.includes("SuperAdmin") ||
          storeData.userType.includes("Developer")
        )
      ) {
        let access = false;

        if (Array.isArray(integrationsList)) {
          access = integrationsList.some(
            (accountApi) =>
              Array.isArray(accountApi.assignedTo) &&
              accountApi.assignedTo.some(
                (account) =>
                  account.accountId === storeData._id && account.access,
                (monsterAccountId = accountApi._id)
              )
          );
        }

        if (!access) {
          enqueueSnackbar(
            `You have no access to Monster search. Please contact the admin.`,
            {
              variant: "error",

              action: (key) => (
                <IconButton onClick={() => closeSnackbar()} color="inherit">
                  <CloseIcon />
                </IconButton>
              ),
            }
          );
          return "";
        }
      }
      const action = {
        sort: "rank,distance,mdate",
        radius: actionData.radius || 30,
        query: keyWords,
        mdateminage: fromDate,
        mdatemaxage: toDate,
        country: actionData?.country || 840,
        rlid: actionData?.rlid || 316,
        zipcode: actionData?.zipcode || "",
        rpcr: actionData?.rpcr || "",
        tsni: actionData?.tsni || "",
        twlid: actionData?.twlid || "",
        wa: actionData?.wa || "",
        qrcn: cn,
        qrjt: jobTitle,
        qrjd: jobDescription,
        qsn: schoolAttend,
        tnsalmin: actionData?.tnsalmin || "",
        tnsalmax: actionData?.tnsalmax || "",
        tsaltyp: actionData?.tsaltyp || "",
        tsalcur: actionData?.tsalcur || "",
        clv: actionData?.clv || "",
        edulv: actionData?.edulv || "",
        tjtft: actionData?.tjtft || "",
        tjtpt: actionData?.tjtpt || "",
        tjtpd: actionData?.tjtpd || "",
        tjtp: actionData?.tjtp || "",
        tjti: actionData?.tjti || "",
        tjttc: actionData?.tjttc || "",
        tjts: actionData?.tjts || "",
        Lskill: actionData?.Lskill || "",
        twindus: actionData?.twindus || "",
        page: value,
        pagesize: pageSize,
        client: client,
        monsterAccountId: monsterAccountId,
      };
      setPage(value);
      setLoading(true);
      dispatch(monsterAdvancedResumeSearchViaApi(action));
      setTimeout(() => {
        dispatch(getIntegrationsViaApi(action));
      }, 2000);
    } else if (searchType === "CareerBuilder") {
      const action = {
        Keywords: keyWords,
        State: actionData.State,
        City: actionData.City,
        ZipCode: actionData.ZipCode,
        Names: actionData.Names,
        JobTitle: actionData.JobTitle,
        Employer: actionData.Employer,
        ExcludeTerms: actionData.ExcludeTerms,
        Radius: actionData.Radius,
        PageNumber: value,
        RowsPerPage: pageSize,
      };
      dispatch(advancedResumeSearchViaApi(action));
      setPage(value);
      setLoading(true);
    } else if (searchType === "InternalDB") {
      const action = {
        client: client,
        keyWords: keyWords,
        name: actionData.name,
        jobTitle: actionData.jobTitle,
        pageSize: pageSize,
        page: value,
      };
      setPage(value);
      dispatch(searchResumeInternalDbViaApi(action));
      setLoading(true);
    }
  };
  //CareerBuilder
  let matchedCBResult = 0;
  function parseCBCandidateData() {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      CbResumesList.V2_AdvancedResumeSearchResult,
      "application/xml"
    );
    const candidates = Array.from(
      xmlDoc.getElementsByTagName("ResumeResultItem_V3")
    );
    matchedCBResult = xmlDoc.getElementsByTagName("Hits")[0]?.textContent;
    return candidates.map((item, index) => {
      return {
        id: index, // Assuming id is simply the index + 1
        Name: item.getElementsByTagName("ContactName")[0].textContent || "N/A",
        Email:
          item.getElementsByTagName("ContactEmailMD5")[0].textContent || "N/A",
        phone: item.getElementsByTagName("RecentPay")[0].textContent || "N/A", // Replace with the actual phone field if available
        ResumeId: item.getElementsByTagName("ResumeID")[0].textContent || "N/A",
        ContactEmail:
          item.getElementsByTagName("ContactEmail")[0].textContent || "N/A",
        HomeLocation:
          item.getElementsByTagName("HomeLocation")[0].textContent || "N/A",
        UserDID: item.getElementsByTagName("UserDID")[0].textContent || "N/A",
        SFNmc: item.getElementsByTagName("SFNmc")[0].textContent || "N/A",
        source: "Career Builder",
        HasWordDoc:
          item.getElementsByTagName("HasWordDoc")[0].textContent || "N/A",
        title: item.getElementsByTagName("ResumeTitle")[0].textContent || "N/A",
        RecentJobTitle:
          item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
        highestEducation:
          item.getElementsByTagName("HighestDegree")[0].textContent || "N/A",
        MonthsOfExperience:
          item.getElementsByTagName("MonthsOfExperience")[0].textContent ||
          "N/A",
        RecentEmployer:
          item.getElementsByTagName("RecentEmployer")[0].textContent || "N/A",
        lastUpdated:
          item.getElementsByTagName("LastUpdate")[0].textContent || "N/A",
        JobTitle: item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
        resume: {
          LastActivity:
            item.getElementsByTagName("LastActivity")[0].textContent || "N/A",
          JobTitle:
            item.getElementsByTagName("JobTitle")[0].textContent || "N/A",
          authorization: "US", // Assuming US as default
        },
        location: {
          city:
            item
              .getElementsByTagName("HomeLocation")[0]
              .textContent.split("-")[2] || "N/A",
          state:
            item
              .getElementsByTagName("HomeLocation")[0]
              .textContent.split("-")[1] || "N/A",
          country: "US", // Assuming US as default
        },
      };
    });
  }
  const cbCandidateList = parseCBCandidateData();

  const [dataList, setDataList] = useState(
    searchType === "Monster"
      ? monsterCandidateList
      : searchType === "CareerBuilder"
      ? cbCandidateList
      : internalDBCandidateList
  );

  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const fileExtension = AwsResumeUrl?.split(".")
    .pop()
    .split("?")[0]
    .toLowerCase();
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  ///internalDB
  // Handle checkbox change (toggle selection state)
  const handleCheckboxChange = (event, row, type) => {
    if (type === "Monster") {
      const newSelectedRows = event.target.checked
        ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Check if the applicantId is not already selected
          ? [...selectedRows, row] // Add the row if it's not already selected
          : selectedRows // If already selected, keep it as is
        : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove the row if unchecked
      setSelectedRows(newSelectedRows); // Update the selected rows state
    } else if (type === "CareerBuilder") {
      const newSelectedRows = event.target.checked
        ? !selectedRows.some((r) => r.ResumeId === row.ResumeId) // Check if the applicantId is not already selected
          ? [...selectedRows, row] // Add the row if it's not already selected
          : selectedRows // If already selected, keep it as is
        : selectedRows.filter((r) => r.ResumeId !== row.ResumeId); // Remove the row if unchecked
      setSelectedRows(newSelectedRows); // Update the selected rows state
    } else if (type === "InternalDB") {
      const newSelectedRows = event.target.checked
        ? !selectedRows.some((r) => r.applicantId === row.applicantId) // Check if the applicantId is not already selected
          ? [...selectedRows, row] // Add the row if it's not already selected
          : selectedRows // If already selected, keep it as is
        : selectedRows.filter((r) => r.applicantId !== row.applicantId); // Remove the row if unchecked
      setSelectedRows(newSelectedRows); // Update the selected rows state
    }
  };

  // Handle header checkbox change to select/deselect all
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(dataList); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };
  const handleDeleteUserProfile = (index) => {
    // Filter out the item at the specified index
    const updatedRows = selectedRows.filter((_, i) => i !== index);

    // Check that at least one item remains
    if (updatedRows.length > 0) {
      setSelectedRows(updatedRows);
    } else {
      alert("At least one item must remain.");
    }
  };
  const [bulkActionMenu, setBulkActionMenu] = useState(null);
  const open = Boolean(bulkActionMenu);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [mailMergeModal, setMailMergeModal] = useState(false);
  const handleClickBulkAction = (event) => {
    setBulkActionMenu(event.currentTarget);
  };
  const handleCloseBulkAction = () => {
    setBulkActionMenu(null);
  };
  let toastShown = false; // Flag to track if toast message has been shown
  const handleSendEMail = () => {
    setBulkActionMenu(null);
    if (selectedRows.length === 0) {
      enqueueSnackbar(`Please select atleast one applicant`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    setSendMailModal(!sendMailModal);
  };
  const handleToggleSendMailModal = () => {
    setSendMailModal(!sendMailModal);
  };

  const [selectJobTag, setSelectJobTag] = useState("");
  const [jobId, setJobId] = useState("");
  const [optionsTemplate, setOptionsTemplate] = useState([]);
  const [template, setTemplate] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [templateSubjectType, setTemplateSubjectType] = useState("");
  const [templateBodyType, setTemplateBodyType] = useState("");
  const handleToggleMailMerge = () => {
    setBulkActionMenu(null);
    if (selectedRows.length === 0) {
      enqueueSnackbar(`Please select atleast one applicant`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    setMailMergeModal(!mailMergeModal);
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      const takenAssignedTemplate = templatesData
        ? templatesData.filter(
            (data) => data.templateAssignToId === storeData?._id
          )
        : [];
      const takeTemplateOption = takenAssignedTemplate
        ? takenAssignedTemplate.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
    } else {
      const takeTemplateOption = templatesData
        ? templatesData.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
    }
  };
  const handleChangeTemplate = (value) => {
    setTemplate(value);

    const filtered = templatesData
      ? templatesData.filter((template) => template.templateTitle === value)
      : [];
    const { type, emailSubject, emailBody, _id } = filtered ? filtered[0] : [];
    setTemplateBodyType(emailBody.length > 0 ? emailBody : "");
    setTemplateSubjectType(emailSubject.length > 0 ? emailSubject : "");
    setTemplateId(_id || "");
  };
  const handleToggleCancelMailMerge = () => {
    setMailMergeModal(!mailMergeModal);
    setJobId("");
    setTemplateId("");
    setTemplate("");
    setSelectJobTag("");
  };
  const handleJobSelect = (value) => {
    setSelectJobTag(value);
    if (value && value.value) {
      const takeJobPosting = Array.isArray(JobPosting) ? JobPosting : [];
      const findId = takeJobPosting.find(
        (job) => job.jobDetails.jobCode === value.value
      );
      setJobId(findId._id);
    }
  };
  const [openSendModal, setOpenSendModal] = useState(false);
  const handleShareEMail = () => {
    if (!jobId || !templateId) {
      enqueueSnackbar(`Please select fileds`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true; // Set flag to true after showing the toast message
      return "";
    }
    setOpenSendModal(!openSendModal);
  };
  const handleShareEMailClose = () => {
    setOpenSendModal(!openSendModal);
    setJobId("");
    setTemplateId("");
    setTemplateBodyType("");
    setTemplateSubjectType("");
    setMailMergeModal(!mailMergeModal);
    setTemplate("");
    setSelectJobTag("");
  };

  const handleSelect = (key) => {
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    if (searchType === "Monster") {
      const action = {
        client: client,
        resumeId: monsterCandidateList[key]?.ResumeId,
      };
      setLoading(true);
      dispatch(successSearchResumeIdInternalDb([]));
      dispatch(searchResumeIdInternalDbViaApi(action));
    } else if (searchType === "CareerBuilder") {
      const action = {
        client: client,
        resumeId: cbCandidateList[key]?.ResumeId,
      };
      setLoading(true);
      dispatch(successSearchResumeIdInternalDb([]));
      dispatch(searchResumeIdInternalDbViaApi(action));
    } else if (searchType === "InternalDB") {
      const action = {
        key: internalDBCandidateList[key].resumes?.[
          internalDBCandidateList[key].resumes.length - 1
        ]?.serverLocation,
      };
      dispatch(awsResumeUrl(action));
    }

    setSelectedCandidate(key);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedCandidate(null);
    setDrawerOpen(false);
  };

  const [openTabId, setOpenTabId] = useState("1");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
  };
  const handleGetResume = () => {
    if (searchType === "Monster") {
      let totalAvailable = 0;
      let monsterAccountId = "";
      if (
        storeData.userType.includes("SuperAdmin") ||
        storeData.userType.includes("Developer")
      ) {
        if (Array.isArray(integrationsList)) {
          integrationsList.some((accountApi) => {
            totalAvailable = accountApi.totalAvailable; // Assuming totalAvailable is a single value you want
            monsterAccountId = accountApi._id;
            return totalAvailable > 0; // Exit early if totalAvailable is found
          });
        }
      } else {
        let allocatedCredits = 0;
        let usedCredits = 0;

        if (Array.isArray(integrationsList)) {
          integrationsList.forEach((accountApi) => {
            if (Array.isArray(accountApi.assignedTo)) {
              accountApi.assignedTo.forEach((account) => {
                if (account.accountId === storeData._id) {
                  allocatedCredits += account.allocatedCredits || 0; // Safely handle undefined values
                  usedCredits += account.usedCredits || 0; // Safely handle undefined values
                  totalAvailable = accountApi.totalAvailable; // Assume this is the correct place to get totalAvailable
                  monsterAccountId = accountApi._id;
                }
              });
            }
          });
        }
        if (allocatedCredits === 0) {
          enqueueSnackbar(
            `Your Monster account credits are not assigned please contact Admin!`,
            {
              variant: "error",
              action: (key) => (
                <IconButton onClick={() => closeSnackbar()} color="inherit">
                  <CloseIcon />
                </IconButton>
              ),
            }
          );
          return "";
        }
        if (allocatedCredits <= usedCredits) {
          enqueueSnackbar(`No Credits! please contact your manager`, {
            variant: "error",
            action: (key) => (
              <IconButton onClick={() => closeSnackbar()} color="inherit">
                <CloseIcon />
              </IconButton>
            ),
          });
          return "";
        }
      }
      if (totalAvailable === 0) {
        enqueueSnackbar(`Your Monster Account Credits have reached zero.`, {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
        return "";
      }

      const selectCandidateData = dataList[selectedCandidate];
      setLoading(true);
      const fullName = selectCandidateData.Name.split(" ");
      const firstName = fullName[0] || selectCandidateData.Name;
      const lastName = fullName.slice(1).join(" ") || selectCandidateData.Name; // In case the last name has multiple parts
      const action = {
        client: client,
        resumeBoardId: selectCandidateData.ResumeBoardId,
        resumeId: selectCandidateData.ResumeId,
        addedBy: storeData._id,
        addedOn: new Date(),
        firstName: firstName,
        lastName: lastName,
        experienceMonths: selectCandidateData.ExperienceMonths, // Convert months to years
        experienceYears: selectCandidateData.ExperienceYears,
        jobTitle: selectCandidateData.JobTitle,
        source: selectCandidateData.Source,
        monsterAccountId: monsterAccountId,
      };
      dispatch(getMonsterResumeAndStoreInternalDbViaApi(action));
      // setTimeout(() => {
      //   dispatch(getIntegrationsViaApi(action));
      // }, 2000);
    } else if (searchType === "CareerBuilder") {
      const selectCandidateData = dataList[selectedCandidate];
      setLoading(true);
      const fullName = selectCandidateData.Name.split(" ");
      const firstName = fullName[0] || selectCandidateData.Name;
      const lastName = fullName.slice(1).join(" ") || selectCandidateData.Name; // In case the last name has multiple parts
      const action = {
        client: client,
        resumeId: selectCandidateData.ResumeId,
        addedBy: storeData._id,
        addedOn: new Date(),
        firstName: firstName,
        lastName: lastName,
        experienceMonths: selectCandidateData.MonthsOfExperience, // Convert months to years
        jobTitle: selectCandidateData.JobTitle,
        source: "CareerBuilder",
      };
      dispatch(getResumeAndStoreInternalDbViaApi(action));
    }
  };
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));

    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };
  // useEffect(() => {
  //   if (resumesFile && !AwsResumeUrl) {
  //     const action = {
  //       key: resumesFile?.[0]?.resumes?.[resumesFile[0].resumes.length - 1]
  //         ?.serverLocation,
  //     };

  //     dispatch(awsResumeUrl(action));
  //   }
  // }, [resumesFile]);
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      // setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        // setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const [list, setList] = useState(false);
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);
  function extractKeywords(query) {
    const andClauses = query.replace(/[()]/g, "").split(/\s+and\s+/i);
    const keywords = [];
    andClauses.forEach((clause) => {
      clause.split(/\s+or\s+/i).forEach((keyword) => {
        // Remove any leading and trailing quotes from each keyword
        const cleanKeyword = keyword.trim().replace(/^["“”]|["“”]$/g, "");
        keywords.push(cleanKeyword);
      });
    });
    return keywords;
  }

  const extractedKeywords = keyWords ? extractKeywords(keyWords) : [];

  const { highlightedHtml, keywordCounts } = useMemo(() => {
    const content =
      resumesFile?.[0]?.resumes?.[resumesFile[0].resumes.length - 1]
        ?.htmlResume ||
      HtmlResume ||
      "";

    let updatedHtml = content;
    const counts = {};

    // Sort keywords by length in descending order to prioritize longer phrases
    const sortedKeywords = [...extractedKeywords].sort(
      (a, b) => b.length - a.length
    );

    sortedKeywords.forEach((keyword) => {
      // Escape special characters for regex
      const escapedKeyword = keyword.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
      const regex = new RegExp(`(${escapedKeyword})`, "gi");

      // Count occurrences
      counts[keyword] = (content.match(regex) || []).length;

      // Highlight keyword
      updatedHtml = updatedHtml.replace(
        regex,
        `<span style="background-color: yellow;">$1</span>`
      );
    });

    return { highlightedHtml: updatedHtml, keywordCounts: counts };
  }, [HtmlResume, extractedKeywords, resumesFile]);
  const handleClose = () => "";
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const handleNewWindow = () => {
    const url = "/viewResume"; // Make sure '/viewResume' is defined as a route in your React Router setup
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const [openQuickSubmitModal, setOpenQuickSubmitModal] = useState(false);
  const [selectCandidateQuickSubmit, setSelectCandidateQuickSubmit] = useState(
    []
  );
  const [selectJobQuickSubmit, setSelectJobQuickSubmit] = useState("");
  const handleQuickClickSubmitModal = (type) => {
    setSelectCandidateQuickSubmit("");
    setSelectJobQuickSubmit("");
    if (type === "InternalDB") {
      const findApplicant = dataList[selectedCandidate];
      setSelectCandidateQuickSubmit([findApplicant]);
    }
    if (type === "MonsterCB") {
      if (resumesFile.length > 0) {
        const findApplicant = resumesFile[0];
        setSelectCandidateQuickSubmit([findApplicant]);
      }
    }
    setOpenQuickSubmitModal(!openQuickSubmitModal);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  };
  const handleCancelQuickSubmitModal = () => {
    setOpenQuickSubmitModal(!openQuickSubmitModal);
    setSelectJobQuickSubmit("");
    setSelectCandidateQuickSubmit([]);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const [openModalNotAutharized, setOpenModalNotAutharized] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const handleQuickSubmit = () => {
    const actionData = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(actionData));
    if (selectCandidateQuickSubmit === 0) {
      return enqueueSnackbar("Please select at least one candidate!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    if (!selectJobQuickSubmit) {
      return enqueueSnackbar("Please select a job!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    const takeJobCode = selectJobQuickSubmit.value;
    const findId = JobPosting.find(
      (job) => job.jobDetails.jobCode === takeJobCode
    );
    const submissionData = findId?.submissions || [];

    // Check if any applicant is already tagged by another person
    const isAlreadyTagged = selectCandidateQuickSubmit.some((select) =>
      submissionData.some(
        (job) => select._id === job.applicantId && job.addedBy !== storeData._id
      )
    );
    if (isAlreadyTagged) {
      setOpenModalNotAutharized(!openModalNotAutharized);
      return;
    }
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const handleCloseConfirmModalOpen = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };
  const handleQuickSubmitConfirm = () => {
    const takeJobCode = selectJobQuickSubmit.value;
    const findId = JobPosting.find(
      (job) => job.jobDetails.jobCode === takeJobCode
    );
    const submissionData = findId?.submissions || [];

    // Check if any applicant is already tagged by another person
    const isAlreadyTagged = selectCandidateQuickSubmit.some((select) =>
      submissionData.some(
        (job) => select._id === job.applicantId && job.addedBy !== storeData._id
      )
    );
    if (isAlreadyTagged) {
      setOpenModalNotAutharized(!openModalNotAutharized);
      return;
    }

    const submission = selectCandidateQuickSubmit.map((select) => ({
      addedBy: storeData._id,
      addedOn: new Date(),
      applicantId: select._id,
      applicantStatus: "SubmitJob",
    }));

    const jobSubmissionAction = {
      id: findId ? findId._id : "",
      submissions: submission,
    };

    let tagAction = [];

    selectCandidateQuickSubmit.forEach((selectedRow) => {
      const tagData = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobPostId: findId ? findId._id : "",
        applicantStatus: "SubmitJob",
      };
      const existingTagJobs = Array.isArray(selectedRow.tagJobs)
        ? selectedRow.tagJobs.filter(
            (tag) => tag.jobPostId !== tagData.jobPostId
          )
        : [];
      const updatedTagJobs = [...existingTagJobs, tagData];
      tagAction.push({
        id: selectedRow._id,
        tagJobs: updatedTagJobs,
      });
    });

    const action = {
      jobSubmissionAction,
      tagAction,
      addedBy: storeData._id,
      client: client,
    };
    setLoading(true);
    dispatch(tagApplicantToJobViaApi(action));
    setSelectJobQuickSubmit("");
    handleCancelQuickSubmitModal();
    handleCloseConfirmModalOpen();
    setTimeout(() => {
      dispatch(getAllDegreesViaApi(client));
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 2000);
  };
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  }, []);
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [clickNoteApplicant, setClickNoteApplicant] = useState("");
  const [selectDestiny, setSelectDestiny] = useState("");

  const handleCloseNote = () => {
    setOpenNoteDrawer(false);
    setNote("");
    setNotes([]);
    setClickNoteApplicant("");
    setSelectDestiny("");
  };
  const handleClickNotes = (row, type, key='') => {
    setSelectDestiny("");
    setClickNoteApplicant("");
    setSelectDestiny(type);
    setClickNoteApplicant(row);
    const checkNotes = row?.note || [];
    setNotes(checkNotes);
    setOpenNoteDrawer(!openNoteDrawer);
    setDrawerOpen(false);
    if (searchType === "Monster") {
      const action = {
        client: client,
        resumeId: monsterCandidateList[key]?.ResumeId,
      };
      setLoading(true);
      dispatch(successSearchResumeIdInternalDb([]));
      dispatch(searchResumeIdInternalDbViaApi(action));
    } else if (searchType === "CareerBuilder") {
      const action = {
        client: client,
        resumeId: cbCandidateList[key]?.ResumeId,
      };
      setLoading(true);
      dispatch(successSearchResumeIdInternalDb([]));
      dispatch(searchResumeIdInternalDbViaApi(action));
    }
  };
  const handleNote = (value) => {
    setNote(value);
  };
  const handleNoteSubmit = () => {
    if (selectDestiny === "InternalDB") {
      const newNote = {
        addedBy: storeData._id,
        addedOn: new Date(),
        note: note,
      };
      const existingNotes = Array.isArray(clickNoteApplicant.note)
        ? clickNoteApplicant?.note
        : [];
      const updatedNotes = [...existingNotes, newNote];
      const action = {
        id: clickNoteApplicant?._id,
        note: updatedNotes,
        client: client,
      };
      dispatch(addApplicantNoteViaApi(action));
      handleCloseNote();
      setLoading(true);
      setTimeout(() => {
        const action = {
          client: client,
          keyWords: keyWords,
          name: actionData.name,
          jobTitle: actionData.jobTitle,
          pageSize: pageSize,
          page: page,
        };
        dispatch(searchResumeInternalDbViaApi(action));
      }, 2000);
    } else {
      const newNote = {
        addedBy: storeData._id,
        addedOn: new Date(),
        note: note,
      };
      const existingNotes = Array.isArray(clickNoteApplicant.note)
        ? clickNoteApplicant?.note
        : [];
      const updatedNotes = [...existingNotes, newNote];
      const action = {
        id: clickNoteApplicant?._id,
        note: updatedNotes,
        client: client,
      };
      dispatch(addApplicantNoteViaApi(action));
      handleCloseNote();
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Boards Result"}>
          <Button
            size="small"
            color="error"
            variant="text"
            startIcon={<ArrowBackIcon />}
            sx={{ textTransform: "inherit" }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <MainBackground>
            <Grid container spacing={4}>
              {grid && (
                <>
                  {searchType === "Monster" && (
                    <Grid item xs={2.5}>
                      <Typography>Search Options</Typography>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          gap: 1,
                          flexDirection: "column",
                        }}
                      >
                        <CustomFormField
                          title={"Keyword"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                required: true,
                                defaultValue: keyWords,
                                onChange: (value) => setKeyWords(value),
                                error: !!errorKeyword,
                                errorMessage: errorKeyword,
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Resume Updated"}
                          colunm={true}
                          components={[
                            {
                              type: "rtText",
                              props: {
                                text: "from",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "From",
                                options: optionsMonsterPostFrom,
                                value: fromDate,
                                onChange: (value) => setFromDate(value),
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "to",
                              },
                            },
                            {
                              type: "selectLabel",
                              props: {
                                title: "To",
                                options: optionsMonsterTo,
                                value: toDate,
                                onChange: (value) => setToDate(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Most Recent Employer"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Most Recent Employer",
                                defaultValue: cn,
                                onChange: (value) => setcn(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Most Recent Job Title"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Most Recent Job Title",
                                defaultValue: jobTitle,
                                onChange: (value) => setJobTitle(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Most Recent Description"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Most Recent Description",
                                defaultValue: jobDescription,
                                onChange: (value) => setJobDescription(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"School Attended"}
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "School Attended",
                                defaultValue: schoolAttend,
                                onChange: (value) => setSchoolAttend(value),
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <Button
                          fullWidth
                          variant="outlined"
                          sx={{ mt: 1 }}
                          onClick={handleSearchMonster}
                        >
                          Apply
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  {searchType !== "Monster" && (
                    <Grid item xs={2.5}>
                      <Typography>Filter Result</Typography>
                      <Box>
                        <CustomFormField
                          //colunm={true}
                          //title={"Gender"}
                          //required
                          components={[
                            {
                              type: "radio",

                              props: {
                                title: "Select Gender",
                                required: true,
                                defaultValue: "All Results",
                                options: [
                                  "All Results",
                                  "Resume Results",
                                  "Social Results",
                                ],
                              },
                            },
                          ]}
                        />
                        <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                          Apply
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          mt: 1,
                          display: "flex",
                          gap: 1,
                          flexDirection: "column",
                        }}
                      >
                        <CustomFormField
                          title={"Exclude"}
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Select",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Experience"}
                          colunm={true}
                          components={[
                            {
                              type: "rtText",
                              props: {
                                text: "from",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Select",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "to",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Select",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Last Modified"}
                          colunm={true}
                          components={[
                            {
                              type: "rtText",
                              props: {
                                text: "from",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Select",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                            {
                              type: "rtText",
                              props: {
                                text: "to",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Select",
                                options: [
                                  "Option - 1",
                                  "Option - 2",
                                  "Option - 3",
                                ],
                              },
                            },
                          ]}
                        />
                        <Divider />
                        <CustomFormField
                          title={"Conatact Info"}
                          components={[
                            {
                              type: "checkbox",

                              props: {
                                title: "Select Gender",
                                required: true,
                                defaultValue: "",
                                options: ["Email", "Phone"],
                              },
                            },
                          ]}
                        />
                        <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                          Apply
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </>
              )}
              <Grid item xs={grid ? 9.5 : 12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    gap: 0.5,
                    color: theme.palette.grey[500],
                    mb: 1,
                  }}
                >
                  <Typography sx={{ fontSize: 30, lineHeight: 1 }}>
                    {searchType === "Monster"
                      ? matchedMonsterResult
                      : searchType === "CareerBuilder"
                      ? matchedCBResult
                      : ""}
                  </Typography>
                  <Typography>results</Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        selectedRows.length === dataList.length &&
                        selectedRows.length > 0
                      } // Check if all rows are selected
                      onChange={handleHeaderCheckboxChange} // Handle select all/deselect all
                    />
                    Select Migrated Profile
                    <Button
                      variant="text"
                      startIcon={<SwapHorizIcon />}
                      size="small"
                    >
                      Migrate Profiles
                    </Button>
                    <Box ml={1}>
                      {searchType === "InternalDB" && (
                        <Button
                          size="small"
                          onClick={handleClickBulkAction}
                          endIcon={<KeyboardArrowDownIcon />}
                        >
                          Bulk Actions
                        </Button>
                      )}

                      <Menu
                        anchorEl={bulkActionMenu}
                        open={Boolean(bulkActionMenu)}
                        onClose={handleCloseBulkAction}
                        onClick={handleCloseBulkAction}
                        hideBackdrop
                      >
                        <MenuItem onClick={handleSendEMail}>
                          Send Email
                        </MenuItem>
                        <MenuItem onClick={handleCloseBulkAction}>
                          Share Job
                        </MenuItem>
                        <MenuItem onClick={handleCloseBulkAction}>
                          Add to Group
                        </MenuItem>
                        <MenuItem onClick={handleToggleMailMerge}>
                          Mail Merge
                        </MenuItem>
                      </Menu>
                    </Box>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setList(false);
                        setGrid(true);
                        setIsGrid(false);
                      }}
                      color={isGrid ? "default" : "primary"}
                    >
                      <GridViewOutlinedIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setList(true);
                        setGrid(false);
                        setIsGrid(true);
                      }}
                      color={isGrid ? "primary" : "default"}
                    >
                      <FormatListBulletedIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                  <Box>
                    <Pagination
                      count={
                        searchType === "Monster"
                          ? matchedMonsterResult
                          : searchType === "CareerBuilder"
                          ? matchedCBResult
                          : pageSize
                      }
                      page={page} // Set the current page
                      onChange={handlePageChange} // Handle page change
                      size="small"
                    />
                  </Box>
                </Box>
                <Box sx={{ mt: 1, bgcolor: theme.palette.grey[50], p: 1 }}>
                  <Typography>{keyWords}</Typography>
                </Box>
                {grid && (
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    {searchType === "Monster" && (
                      <>
                        {monsterCandidateList.map((item, key) => {
                          const itemSelected = selectedCandidate === key;
                          return (
                            <Box
                              key={key}
                              sx={{
                                display: "flex",
                                gap: 2,
                                bgcolor: theme.palette.grey[50],
                                border: `1px solid ${theme.palette.grey[300]}`,
                                borderRadius: 1,
                                p: 2,
                                cursor: "pointer",
                              }}
                            >
                              <Box>
                                <IconButton size="small">
                                  <input
                                    type="checkbox"
                                    // checked={selectedRows.some(
                                    //   (r) => r.applicantId === item.applicantId
                                    // )} // Check if the row is selected
                                    // onChange={(e) => handleCheckboxChange(e, item)} // Handle checkbox toggle
                                  />
                                </IconButton>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                                onClick={() => handleSelect(key)}
                              >
                                <Box
                                  sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 2,
                                  }}
                                >
                                  <Box flex={1}>
                                    <Typography
                                      sx={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                      }}
                                    >
                                      {item.Name?.trim() === ""
                                        ? "CONFIDENTIAL"
                                        : item.Name}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                      }}
                                    >
                                      {item.Location?.country} -{" "}
                                      {item.Location?.state} -{" "}
                                      {item.Location?.city}
                                    </Typography>
                                    <Box
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                      }}
                                    >
                                      <FmdGoodIcon fontSize="inherit" />
                                      <Typography fontSize={"inherit"}>
                                        {/* {item?.Title} */}
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.Title}
                                        />
                                        {""} {item?.companyName}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Box flex={1}>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                      }}
                                    >
                                      {/* {item?.Description} */}
                                      <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={extractedKeywords}
                                        autoEscape={true}
                                        textToHighlight={item?.Description}
                                      />
                                    </Typography>
                                  </Box>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Resume Updated"}
                                      fullContent
                                      value={item?.ResumeUpdated}
                                      // value={FormatDateTime(
                                      //   item?.ResumeUpdated,
                                      //   "full"
                                      // )}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Highest Education"}
                                      fullContent
                                      value={item?.HighestEducation}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Target Job Title"}
                                      fullContent
                                      value={
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.JobTitle}
                                        />
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Desired Salary"}
                                      fullContent
                                      value={item?.DesiredSalary}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Relocation"}
                                      fullContent
                                      value={item?.Relocation}
                                    />
                                  </Grid>
                                  <Grid item xs={2}>
                                    <KeyData
                                      title={"Authorization"}
                                      fullContent
                                      value={item?.Authorization}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <KeyData
                                      title={"Desired Job Type"}
                                      fullContent
                                      value={
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.DesiredJobType}
                                        />
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    )}
                    {searchType === "CareerBuilder" && (
                      <>
                        {cbCandidateList.map((item, key) => {
                          const itemSelected = selectedCandidate === key;
                          return (
                            <Box
                              key={key}
                              sx={{
                                display: "flex",
                                gap: 2,
                                bgcolor: theme.palette.grey[50],
                                border: `1px solid ${theme.palette.grey[300]}`,
                                borderRadius: 1,
                                p: 2,
                                cursor: "pointer",
                              }}
                            >
                              <Box>
                                {/* <IconButton size="small">
                                {itemSelected ? (
                                  <CheckBoxIcon fontSize="inherit" color="primary" />
                                ) : (
                                  <CheckBoxOutlineBlankIcon fontSize="inherit" />
                                )}
                              </IconButton> */}
                                <input
                                  type="checkbox"
                                  // checked={selectedRows.some(
                                  //   (r) => r.applicantId === item.applicantId
                                  // )} // Check if the row is selected
                                  // onChange={(e) => handleCheckboxChange(e, item)} // Handle checkbox toggle
                                />
                              </Box>
                              <Box
                                onClick={() => handleSelect(key)}
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 16, fontWeight: 600 }}
                                  >
                                    {item.Name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      color: theme.palette.grey[600],
                                    }}
                                  >
                                    {/* {item?.JobTitle} */}
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={item?.JobTitle}
                                    />
                                  </Typography>
                                  <Box
                                    sx={{
                                      fontSize: 12,
                                      color: theme.palette.grey[600],
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 0.5,
                                    }}
                                  >
                                    <FmdGoodIcon fontSize="inherit" />
                                    <Typography fontSize={"inherit"}>
                                      {item.location.city} -{" "}
                                      {item.location.state} -{" "}
                                      {item.location.country}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <KeyData
                                      title={"Recent Position"}
                                      fullContent
                                      value={
                                        <Highlighter
                                          highlightClassName="YourHighlightClass"
                                          searchWords={extractedKeywords}
                                          autoEscape={true}
                                          textToHighlight={item?.RecentJobTitle}
                                        />
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <KeyData
                                      title={"Recent Education"}
                                      fullContent
                                      value={
                                        item?.highestEducation
                                          ? item?.highestEducation
                                          : "n/a"
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <KeyData
                                      title={"Experience"}
                                      fullContent
                                      value={(
                                        item?.MonthsOfExperience / 12
                                      ).toFixed(1)}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          );
                        })}
                      </>
                    )}
                    {searchType === "InternalDB" && (
                      <>
                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            flexDirection: "column",
                            gap: 1,
                          }}
                        >
                          {internalDBCandidateList.map((item, key) => {
                            const itemSelected = selectedCandidate === key;
                            return (
                              <Box
                                key={key}
                                sx={{
                                  display: "flex",
                                  gap: 2,
                                  bgcolor: theme.palette.grey[50],
                                  border: `1px solid ${theme.palette.grey[300]}`,
                                  borderRadius: 1,
                                  p: 2,
                                  cursor: "pointer",
                                }}
                              >
                                <Box>
                                  <IconButton size="small">
                                    <input
                                      type="checkbox"
                                      checked={selectedRows.some(
                                        (r) =>
                                          r.applicantId === item.applicantId
                                      )} // Check if the row is selected
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          e,
                                          item,
                                          "InternalDB"
                                        )
                                      } // Handle checkbox toggle
                                    />
                                  </IconButton>
                                </Box>
                                <Box
                                  onClick={() => handleSelect(key)}
                                  sx={{
                                    flex: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 2,
                                  }}
                                >
                                  <Box>
                                    <Typography
                                      sx={{ fontSize: 16, fontWeight: 600 }}
                                    >
                                      {item?.firstName} {item?.lastName}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                      }}
                                    >
                                      <Highlighter
                                        highlightClassName="YourHighlightClass"
                                        searchWords={extractedKeywords}
                                        autoEscape={true}
                                        textToHighlight={item?.jobTitle}
                                      />
                                    </Typography>
                                    <Box
                                      sx={{
                                        fontSize: 12,
                                        color: theme.palette.grey[600],
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.5,
                                      }}
                                    >
                                      <FmdGoodIcon fontSize="inherit" />
                                      <Typography fontSize={"inherit"}>
                                        {item?.city} - {item?.state} -{" "}
                                        {item?.country}
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                      <KeyData
                                        title={"Recent Position"}
                                        fullContent
                                        value={
                                          <Highlighter
                                            highlightClassName="YourHighlightClass"
                                            searchWords={extractedKeywords}
                                            autoEscape={true}
                                            textToHighlight={item?.jobTitle}
                                          />
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      {Array.isArray(item?.educationDetails) &&
                                        item?.educationDetails.map(
                                          (degree, index) => (
                                            <KeyData
                                              key={index}
                                              title={`schoolName${degree.schoolName}`}
                                              value={degree.degree}
                                            />
                                          )
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <KeyData
                                        title={"Experience"}
                                        fullContent
                                        value={item?.experienceYears}
                                      />
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </Box>
                )}
                {list && (
                  <Box>
                    <JobBoardResultTable
                      rows={
                        searchType === "Monster"
                          ? monsterCandidateList
                          : searchType === "CareerBuilder"
                          ? cbCandidateList
                          : internalDBCandidateList
                      }
                      searchType={searchType}
                      handleSelect={handleSelect}
                      selectedRows={selectedRows}
                      handleCheckboxChange={handleCheckboxChange}
                      handleClickNotes={handleClickNotes}
                      resumesFile={resumesFile}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </MainBackground>

          {/* Drawer */}
          <Drawer open={drawerOpen} anchor="right" onClose={closeDrawer}>
            {(searchType === "Monster" || searchType === "CareerBuilder") && (
              <>
                <Box
                  sx={{
                    width: 700,
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {loading ? (
                    <>
                      {" "}
                      <ShimmerText line={5} gap={10} />
                    </>
                  ) : (
                    <>
                      {selectedCandidate !== null &&
                      selectedCandidate !== undefined ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Typography variant="h6">
                                {dataList[selectedCandidate]?.Name}
                              </Typography>
                            </Box>

                            <Box>
                              <ButtonGroup>
                                <IconButton size="small">
                                  <Tooltip title="Similar Applicants">
                                    <SupervisorAccountIcon
                                      sx={{ fontSize: "14px" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                                <IconButton size="small">
                                  <Tooltip
                                    title="Open in New Tab"
                                    onClick={handleNewWindow}
                                  >
                                    <OpenInNewIcon sx={{ fontSize: "14px" }} />
                                  </Tooltip>
                                </IconButton>
                                {resumesFile.length > 0 ? (
                                  <IconButton size="small">
                                    <Tooltip title="View the Resume">
                                      <VisibilityIcon
                                        onClick={() =>
                                          handleAxiosLink(
                                            resumesFile?.[0]?.resumes?.[
                                              resumesFile[0].resumes.length - 1
                                            ]?.serverLocation
                                          )
                                        }
                                        title="View the resume"
                                        sx={{ fontSize: "17px" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                ) : (
                                  <IconButton size="small">
                                    <Tooltip title="Mergeto Internal Database">
                                      <AddIcon
                                        onClick={handleGetResume}
                                        sx={{ fontSize: "17px" }}
                                      />{" "}
                                    </Tooltip>
                                  </IconButton>
                                )}
                                <IconButton size="small">
                                  <Tooltip title="Email">
                                    <MailOutlineIcon
                                      sx={{ fontSize: "17px" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                                {resumesFile.length > 0 &&
                                  resumesFile[0]?.resumeId ===
                                    dataList[selectedCandidate]?.ResumeId && (
                                    <IconButton size="small">
                                      <Tooltip
                                        title="Add Notes"
                                        onClick={() =>
                                          handleClickNotes(
                                            resumesFile[0],
                                            "MonsterCB",
                                            ''
                                          )
                                        }
                                      >
                                        <NoteAddOutlinedIcon
                                          sx={{ fontSize: "17px" }}
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  )}
                                <IconButton size="small">
                                  <Tooltip title="Share the Profile">
                                    <ShortcutIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>
                                <IconButton size="small">
                                  <Tooltip title="Add to Group">
                                    <GroupsIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>
                                <IconButton size="small">
                                  <Tooltip title="Share Job">
                                    <ShareIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>
                                <IconButton size="small">
                                  <Tooltip title="Tag to Job">
                                    <LabelIcon sx={{ fontSize: "17px" }} />
                                  </Tooltip>
                                </IconButton>
                                {resumesFile.length > 0 ? (
                                  <IconButton size="small">
                                    <Tooltip
                                      title="Quick Submit to Job"
                                      onClick={() =>
                                        handleQuickClickSubmitModal("MonsterCB")
                                      }
                                    >
                                      <PersonAddAlt1Icon
                                        sx={{ fontSize: "17px" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                ) : (
                                  ""
                                )}
                              </ButtonGroup>
                            </Box>
                          </Box>

                          <Box
                            bgcolor={theme.palette.grey[100]}
                            padding={2}
                            marginY={2}
                          >
                            <Grid container spacing={1}>
                              {/* <Grid item xs={4}>
                          <KeyData
                            title={"Phone Number"}
                            value={dataList[selectedCandidate].phone}
                          />
                        </Grid> */}
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Job Title"}
                                  value={
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={
                                        dataList[selectedCandidate]?.JobTitle
                                      }
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Location"}
                                  value={
                                    dataList[selectedCandidate]?.HomeLocation
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Recent Education"}
                                  value={
                                    dataList[selectedCandidate]
                                      ?.HighestEducation
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Experience"}
                                  value={`${
                                    dataList[selectedCandidate]?.ExperienceYears
                                  }${"-"}${
                                    dataList[selectedCandidate]
                                      ?.ExperienceMonths
                                  }`}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                          <Box
                            display={"flex"}
                            flexDirection={"column"}
                            flex={1}
                          >
                            <TabContext value={openTabId}>
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                              >
                                <TabList
                                  onChange={handleChange}
                                  aria-label="Job Boards tabs"
                                  size="small"
                                  variant="scrollable"
                                >
                                  <Tab label="Resume" value="1" />
                                  <Tab label="Personal Details" value="2" />
                                  <Tab label="Job Applied" value="3" />
                                  <Tab label="Notes" value="4" />
                                  <Tab label="Work Experience" value="5" />
                                  <Tab label="Email Merges" value="6" />
                                </TabList>
                              </Box>
                              <Box
                                sx={{
                                  flex: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <TabPanel
                                  value="1"
                                  sx={{
                                    p: 0,
                                    flex: 1,
                                  }}
                                >
                                  {HtmlResume ? (
                                    // <ResumeText AwsResumeUrl={AwsResumeUrl} />
                                    <>
                                      <Box
                                        sx={{
                                          p: 2,
                                          display: "flex",
                                          gap: 1,
                                          flexWrap: "wrap",
                                        }}
                                      >
                                        {Object.entries(keywordCounts).map(
                                          ([keyword, count]) => (
                                            <Chip
                                              size="small"
                                              label={`${keyword} ${count} time(s)`}
                                              variant="outlined"
                                            />
                                          )
                                        )}
                                      </Box>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: highlightedHtml,
                                        }}
                                        style={{
                                          padding: "20px",
                                          backgroundColor: "#f9f9f9",
                                          borderRadius: "8px",
                                          boxShadow:
                                            "0 2px 4px rgba(0,0,0,0.1)",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: 2,
                                          px: 10,
                                        }}
                                      >
                                        <Typography
                                          variant="h5"
                                          textAlign={"center"}
                                        >
                                          By clicking here, credits will be
                                          used, and you will unlock the resume.
                                        </Typography>

                                        <Button
                                          variant="outlined"
                                          sx={{ textTransform: "inherit" }}
                                          onClick={() => handleGetResume()}
                                        >
                                          Get the resume
                                        </Button>
                                      </Box>
                                    </Box>
                                  )}
                                </TabPanel>
                                <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                                  <Box
                                    bgcolor={theme.palette.grey[100]}
                                    padding={2}
                                    marginY={2}
                                  >
                                    <Grid container spacing={1}>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"FirstName"}
                                          value={resumesFile?.[0]?.firstName}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"Middle Name"}
                                          value={resumesFile?.[0]?.middleName}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"Last Name"}
                                          value={resumesFile?.[0]?.lastName}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"Gender"}
                                          value={resumesFile?.[0]?.gender}
                                        />
                                      </Grid>

                                      <Grid item xs={4}>
                                        {resumesFile?.[0]?.mobileNumbers?.map(
                                          (mob, index) => (
                                            <KeyData
                                              key={index}
                                              title={`${mob.type} Mobile Number`}
                                              value={mob.number || ""}
                                            />
                                          )
                                        )}
                                      </Grid>
                                      <Grid item xs={4}>
                                        {resumesFile?.[0]?.emailAddress?.map(
                                          (mob, index) => (
                                            <KeyData
                                              key={index}
                                              title={`${mob.type} Email Address`}
                                              value={mob.email || ""}
                                            />
                                          )
                                        )}
                                      </Grid>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"State"}
                                          value={resumesFile?.[0]?.state}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"City"}
                                          value={resumesFile?.[0]?.city}
                                        />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <KeyData
                                          title={"ZipCode"}
                                          value={resumesFile?.[0]?.zipCode}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </TabPanel>
                                <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                                  Job Applied
                                </TabPanel>
                                <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                                  Notes
                                  {resumesFile.length > 0 &&
                                    resumesFile[0]?.resumeId ===
                                      dataList[selectedCandidate]?.ResumeId &&
                                    resumesFile[0]?.note?.map(
                                      (noteItem, index) => (
                                        <MainBackground key={index}>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              gap: 1,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: 13,
                                                letterSpacing: 0.2,
                                                color: theme.palette.grey[800],
                                              }}
                                            >
                                              Note: {noteItem.note}
                                            </Typography>
                                            <Divider sx={{ py: 0.5 }} />
                                            <Box
                                              sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                gap: 0.5,
                                                flexWrap: "wrap",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: 0.2,
                                                  color:
                                                    theme.palette.grey[500],
                                                }}
                                              >
                                                User Name -{" "}
                                                {allUsers.find(
                                                  (user) =>
                                                    user._id ===
                                                    noteItem.addedBy
                                                )?.userSudoFirstName ||
                                                  "Unknown"}
                                              </Typography>

                                              <Typography
                                                sx={{
                                                  fontSize: 11,
                                                  letterSpacing: 0.2,
                                                  color:
                                                    theme.palette.grey[500],
                                                }}
                                              >
                                                {FormatDateTime(
                                                  noteItem.addedOn,
                                                  "full"
                                                )}
                                              </Typography>
                                            </Box>
                                          </Box>
                                        </MainBackground>
                                      )
                                    )}
                                </TabPanel>
                                <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                                  Work Experience
                                </TabPanel>
                                <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                                  Email Merges
                                </TabPanel>
                              </Box>
                            </TabContext>
                          </Box>
                        </>
                      ) : (
                        <Typography variant="body1">
                          No details available {selectedCandidate}
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
            {searchType === "InternalDB" && (
              <>
                <Box
                  sx={{
                    width: 700,
                    p: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {selectedCandidate !== null &&
                  selectedCandidate !== undefined ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <Typography variant="h6">
                            {
                              internalDBCandidateList[selectedCandidate]
                                ?.firstName
                            }{" "}
                            {
                              internalDBCandidateList[selectedCandidate]
                                ?.lastName
                            }
                          </Typography>
                        </Box>

                        <Box></Box>
                        <Box>
                          <ButtonGroup>
                            <IconButton size="small">
                              <Tooltip title="Similar Applicants">
                                <SupervisorAccountIcon
                                  sx={{ fontSize: "14px" }}
                                />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Open in New Tab"
                                onClick={handleNewWindow}
                              >
                                <OpenInNewIcon sx={{ fontSize: "14px" }} />
                              </Tooltip>
                            </IconButton>
                            {internalDBCandidateList[selectedCandidate]
                              .resumes &&
                              internalDBCandidateList[selectedCandidate].resumes
                                .length > 0 && (
                                <IconButton size="small">
                                  <Tooltip title="View the Resume">
                                    <VisibilityIcon
                                      onClick={() =>
                                        handleAxiosLink(
                                          internalDBCandidateList[
                                            selectedCandidate
                                          ]?.resumes?.[
                                            internalDBCandidateList[
                                              selectedCandidate
                                            ]?.resumes?.length - 1
                                          ]?.serverLocation
                                        )
                                      }
                                      title="View the resume"
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            <IconButton size="small">
                              <Tooltip title="Email">
                                <MailOutlineIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Add Notes"
                                onClick={() =>
                                  handleClickNotes(
                                    internalDBCandidateList[selectedCandidate],
                                    "InternalDB",
                                    ''
                                  )
                                }
                              >
                                <NoteAddOutlinedIcon
                                  sx={{ fontSize: "17px" }}
                                />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Share the Profile">
                                <ShortcutIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Add to Group">
                                <GroupsIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Share Job">
                                <ShareIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip title="Tag to Job">
                                <LabelIcon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                            <IconButton size="small">
                              <Tooltip
                                title="Quick Submit to Job"
                                onClick={() =>
                                  handleQuickClickSubmitModal("InternalDB")
                                }
                              >
                                <PersonAddAlt1Icon sx={{ fontSize: "17px" }} />
                              </Tooltip>
                            </IconButton>
                          </ButtonGroup>
                        </Box>
                      </Box>

                      <Box
                        bgcolor={theme.palette.grey[100]}
                        padding={2}
                        marginY={2}
                      >
                        <Grid container spacing={1}>
                          {internalDBCandidateList[
                            selectedCandidate
                          ].mobileNumbers.map((number, index) => (
                            <Grid item xs={4} key={index}>
                              <KeyData
                                title={`mobile${number.type}`}
                                value={number.number}
                              />
                            </Grid>
                          ))}

                          <Grid item xs={4}>
                            <KeyData
                              title={"Job Title"}
                              value={
                                internalDBCandidateList[selectedCandidate]
                                  ?.jobTitle
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Location"}
                              value={`${internalDBCandidateList[selectedCandidate]?.city} - ${internalDBCandidateList[selectedCandidate]?.state} - ${internalDBCandidateList[selectedCandidate]?.country}`}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            {Array.isArray(
                              internalDBCandidateList[selectedCandidate]
                                ?.educationDetails
                            ) &&
                              internalDBCandidateList[
                                selectedCandidate
                              ].educationDetails.map((degree, index) => (
                                <KeyData
                                  key={index}
                                  title={`schoolName${degree.schoolName}`}
                                  value={degree.degree}
                                />
                              ))}
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Experience(years)"}
                              value={
                                internalDBCandidateList[selectedCandidate]
                                  .experienceYears
                              }
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box display={"flex"} flexDirection={"column"} flex={1}>
                        <TabContext value={openTabId}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              onChange={handleChange}
                              aria-label="ob Boards tabs"
                              size="small"
                              variant="scrollable"
                            >
                              <Tab label="Resume" value="1" />
                              <Tab label="Personal Details" value="2" />
                              <Tab label="Job Applied" value="3" />
                              <Tab label="Notes" value="4" />
                              <Tab label="Skills" value={"5"} />
                              <Tab label="Work Experience" value="6" />
                              <Tab label="Email Merges" value="7" />
                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            sx={{
                              p: 0,
                              flex: 1,

                              width: "100%",
                              height: "100vh",
                            }}
                          >
                            {/* {AwsResumeUrl ? (
                      <ResumeText AwsResumeUrl={AwsResumeUrl} />
                    ) : (
                      <></>
                    )} */}
                            {HtmlResume ? (
                              <>
                                <Box
                                  sx={{
                                    p: 2,
                                    display: "flex",
                                    gap: 1,
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {Object.entries(keywordCounts).map(
                                    ([keyword, count]) => (
                                      <Chip
                                        size="small"
                                        label={`${keyword} ${count} time(s)`}
                                        variant="outlined"
                                      />
                                    )
                                  )}
                                </Box>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: highlightedHtml,
                                  }}
                                  style={{
                                    padding: "20px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "8px",
                                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                                  }}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </TabPanel>
                          <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                            <Box
                              bgcolor={theme.palette.grey[100]}
                              padding={2}
                              marginY={2}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"FirstName"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.firstName
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"Middle Name"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.middleName
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"Last Name"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.lastName
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"Gender"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.gender
                                    }
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  {internalDBCandidateList[
                                    selectedCandidate
                                  ].emailAddress.map((email, index) => (
                                    <KeyData
                                      title={`email${email.type}`}
                                      value={email.email}
                                    />
                                  ))}
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"State"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        .state
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"City"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        .city
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <KeyData
                                    title={"ZipCode"}
                                    value={
                                      internalDBCandidateList[selectedCandidate]
                                        ?.zipCode
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </TabPanel>
                          <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                            Job Applied
                          </TabPanel>
                          <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                            Notes
                            {internalDBCandidateList[
                              selectedCandidate
                            ]?.note?.map((noteItem, index) => (
                              <MainBackground key={index}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 13,
                                      letterSpacing: 0.2,
                                      color: theme.palette.grey[800],
                                    }}
                                  >
                                    Note: {noteItem.note}
                                  </Typography>
                                  <Divider sx={{ py: 0.5 }} />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      gap: 0.5,
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        letterSpacing: 0.2,
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      User Name -{" "}
                                      {allUsers.find(
                                        (user) => user._id === noteItem.addedBy
                                      )?.userSudoFirstName || "Unknown"}
                                    </Typography>

                                    <Typography
                                      sx={{
                                        fontSize: 11,
                                        letterSpacing: 0.2,
                                        color: theme.palette.grey[500],
                                      }}
                                    >
                                      {FormatDateTime(noteItem.addedOn, "full")}
                                    </Typography>
                                  </Box>
                                </Box>
                              </MainBackground>
                            ))}
                          </TabPanel>
                          <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                            <Box
                              bgcolor={theme.palette.grey[100]}
                              padding={2}
                              marginY={2}
                            >
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    {Array.isArray(
                                      internalDBCandidateList[selectedCandidate]
                                        ?.skills
                                    ) &&
                                      internalDBCandidateList[
                                        selectedCandidate
                                      ]?.skills.map((skill, index) => (
                                        <Grid item xs={3} key={index}>
                                          {" "}
                                          {/* Adjust xs to control width */}
                                          <KeyData
                                            value={
                                              <Highlighter
                                                highlightClassName="YourHighlightClass"
                                                searchWords={extractedKeywords}
                                                autoEscape={true}
                                                textToHighlight={skill || ""}
                                              />
                                            }
                                          />
                                        </Grid>
                                      ))}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </TabPanel>
                          <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                            Work Experience
                          </TabPanel>
                          <TabPanel value="7" sx={{ p: 0, pt: 5 }}>
                            Email Merges
                          </TabPanel>
                        </TabContext>
                      </Box>
                    </>
                  ) : (
                    <Typography variant="body1">
                      No details available {selectedCandidate}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Drawer>
          <Modal
            open={openResumeModal}
            onClose={handleCloseResumeModal}
            aria-labelledby="resume-modal-title"
            aria-describedby="resume-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              {/* Close Button */}
              <IconButton
                aria-label="close"
                onClick={handleCloseResumeModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              {/* Conditional rendering based on file type */}
              {AwsResumeUrl ? (
                <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
              ) : (
                <Typography variant="h6" textAlign="center">
                  No resume available.
                </Typography>
              )}
            </Box>
          </Modal>
          <Backdrop
            sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
            open={loading}
            onClick={handleClose}
          >
            <CircularProgress color="inherit" size="3rem" />
          </Backdrop>
          <SnackbarComponent />
        </LayoutSidebarMenu>
        {/* ///InternalDB */}
        <Modal
          open={sendMailModal}
          onClose={handleToggleSendMailModal}
          aria-labelledby="send email"
          aria-describedby="send email modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CandidateSendMail
              close={handleToggleSendMailModal}
              applicantsList={selectedRows}
              jobId={""}
            />
          </Box>
        </Modal>
        <Modal
          open={mailMergeModal}
          //onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 1000,
              // height: "80%",
              // maxHeight: 500,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              //overflow: "hidden",
            }}
          >
            <Grid container spacing={2} sx={{ height: "100%" }}>
              <Grid item xs={7}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SectionTitle title="Email Merge" />
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.grey[500] }}
                    >
                      No of Recipients: {selectedRows.length}
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Select job"}
                      components={[
                        {
                          type: "autocomplete",
                          props: {
                            placeholder: `select the job`,
                            options: jobPostingOptions,
                            defaultValue: selectJobTag,
                            onChange: (value) => handleJobSelect(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CustomFormField
                      title={"Select Template"}
                      required
                      components={[
                        {
                          type: "select",
                          props: {
                            required: true,
                            placeholder: "Select",
                            defaultValue: template,
                            options: optionsTemplate,
                            onChange: (value) => handleChangeTemplate(value),
                          },
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item sx={12}>
                    <Button
                      color="inherit"
                      onClick={() => handleToggleCancelMailMerge()}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleShareEMail()}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <ScrollView>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      p: 1,
                    }}
                  >
                    {selectedRows.map((item, key) => {
                      return (
                        <Box
                          key={key}
                          sx={{
                            borderRadius: 2,
                            p: 1,
                            mx: 0.5,
                            background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                            border: `1px solid ${theme.palette.grey[200]}`,
                          }}
                        >
                          <Box>
                            <Grid container spacing={0.2}>
                              <Grid item xs={9}>
                                <Typography sx={{ fontWeight: "bold" }} noWrap>
                                  {item?.firstName || ""}
                                  {item?.middleName || ""}
                                  {item?.lastName || ""}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                sx={{
                                  alignSelf: "center",
                                  justifySelf: "self-end",

                                  textAlign: "right",
                                }}
                              >
                                <Typography sx={{ fontSize: 11 }}>
                                  Profile used
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={8}>
                                <KeyData
                                  title={"Email"}
                                  value={
                                    item?.emailAddress
                                      ? item.emailAddress
                                          .filter((item1) => item1.email) // Filter out objects without an email
                                          .map((item1) => item1.email)
                                          .join(", ")
                                      : ""
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Experience"}
                                  value={item.experienceYears || ""}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={8}>
                                <KeyData
                                  title={"Job Title"}
                                  value={
                                    <Highlighter
                                      highlightClassName="YourHighlightClass"
                                      searchWords={extractedKeywords}
                                      autoEscape={true}
                                      textToHighlight={item?.jobTitle || ""}
                                    />
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Notice Period"}
                                  value={item?.availability}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              <Grid item xs={10}>
                                {" "}
                                <KeyData
                                  title={" Pay rate"}
                                  value={`${
                                    item?.payRateSalary?.currency || ""
                                  }/${item?.payRateSalary?.minAmount || ""}
                                /${item?.payRateSalary?.maxAmount || ""}/${
                                    item?.payRateSalary?.paySchedule || ""
                                  }/${item?.payRateSalary?.taxTerm || ""}`}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={2}
                                sx={{
                                  alignSelf: "center",
                                  justifySelf: "self-end",

                                  textAlign: "right",
                                }}
                              >
                                <IconButton size="xsmall" color="error">
                                  <DeleteOutlineOutlinedIcon
                                    fontSize="inherit"
                                    onClick={() => handleDeleteUserProfile(key)}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </ScrollView>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={openSendModal}
          aria-labelledby="send email"
          aria-describedby="send email modal"
          onClose={handleShareEMailClose}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <CandidatesMailMerge
              close={handleShareEMailClose}
              applicantsList={selectedRows}
              jobId={jobId}
              optionsTemplate={optionsTemplate}
              templateSubjectType={templateSubjectType}
              setTemplateSubjectType={setTemplateSubjectType}
              templateBodyType={templateBodyType}
              setTemplateBodyType={setTemplateBodyType}
              template={template}
              setTemplate={setTemplate}
              templateId={templateId}
            />
          </Box>
        </Modal>
        <JobBoardsQuickSubmitSelectJob
          open={openQuickSubmitModal}
          close={handleCancelQuickSubmitModal}
          submit={handleQuickSubmit}
          setSelectJobQuickSubmit={setSelectJobQuickSubmit}
          selectJobQuickSubmit={selectJobQuickSubmit}
          jobPostingOptions={jobPostingOptions}
        />
        <JobBoardsQuickSubmitCheckAutharize
          open={openModalNotAutharized}
          close={setOpenModalNotAutharized}
        />
        <JobBoardsQuickSubmitConfirmModal
          open={isConfirmModalOpen}
          close={handleCloseConfirmModalOpen}
          cofirm={handleQuickSubmitConfirm}
          jobId={selectJobQuickSubmit?.label}
          applicantName={`${
            selectCandidateQuickSubmit[0]?.firstName || ""
          }${" "}${selectCandidateQuickSubmit[0]?.middleName || ""}${" "}${
            selectCandidateQuickSubmit[0]?.lastName || ""
          }`}
        />
        <JobBoardsAddNotes
          open={openNoteDrawer}
          close={handleCloseNote}
          allUsers={allUsers}
          notes={notes}
          note={note}
          handleNote={handleNote}
          handleNoteSubmit={handleNoteSubmit}
        />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default JobBoardsResult;
