import { Box, Divider } from "@mui/material";
import React from "react";
import Backgrounds from "../Backgrounds/Backgrounds";
import KeyData from "../Helper/KeyData";
import companyStatus from "../StatusElements/companyStatus";
const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked } = companyStatus;
const CompanyDetails = ({
  dataShow,
  searchResultsData,
  sanitizeString,
  query,
  queryName,
  mergeContactDetails,
  extractDomain,
  latestResult,
  checkCompany,
  handleCheckEmailID,
  handleCheckEmailDomain,
  colorStatus,
  storeData,
  calculateDays,
  formatDate,
  allUsers,
  beforeData,
}) => {
  let checkEMailStatus = "";
  let checkEMailName = "";
  const handleCheckEmailIDStatus = (latestResult, query) => {
    if (latestResult && latestResult.contactDetails) {
      // Loop through each contact detail
      for (let contact of latestResult.contactDetails) {
        // Check if the sanitized email ID matches the sanitized query
        if (sanitizeString(contact.emailId) === sanitizeString(query)) {
          const statusCheck = contact.status ? contact.status : "Active";
          if (
            (statusCheck === employeeDNC || statusCheck === employeeBlocked) &&
            latestResult.status === "Active"
          ) {
            // If match found, return true
            checkEMailStatus = statusCheck;
            checkEMailName = contact.emailId;
            return true;
          }
        }
      }
    }
    // If no match found, return false
    return false;
  };
  
  return (
    <Box>
      {dataShow && sanitizeString(dataShow) === sanitizeString(query) && (
        <Box>
          {searchResultsData.length === 0 && checkCompany ? (
            <Box>
              {(storeData.userType.includes('SuperAdmin') || storeData.userType.includes('RATeam') || storeData.userType.includes('Developer'))&&sanitizeString(query) === sanitizeString(queryName) && (
                <>
                  <Box>
                    <Backgrounds>
                      <KeyData
                        title={"Company Name"}
                        value={query}
                        size={"large"}
                      />
                    </Backgrounds>
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <>
              {mergeContactDetails &&
                mergeContactDetails.map((result, index) =>
                  sanitizeString(result.companyName) ===
                    sanitizeString(query) ||
                  sanitizeString(result.domain) === sanitizeString(query) ||
                  sanitizeString(result.domain) ===
                    sanitizeString(extractDomain(query)) ||
                  handleCheckEmailID(latestResult, query) ||
                  handleCheckEmailDomain(latestResult, query) ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        maxWidth: 992,
                        margin: "0px auto",
                        width: "100%",
                      }}
                    >
                      <Divider />
                      <KeyData
                        title={"Company Name"}
                        value={result.companyName}
                        size={"large"}
                        color={colorStatus}
                      />
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "space-around",
                        }}
                      >
                        <KeyData
                          title={"Company Status"}
                          value={result.status}
                          color={colorStatus}
                        />
                        <Divider orientation="vertical" flexItem />
                        <KeyData
                          title={"Industry"}
                          value={
                            (result.jobDetails &&
                              result.jobDetails[0] &&
                              result.jobDetails[0].jobIndustrieName) ||
                            "--"
                          }
                          color={colorStatus}
                        />
                        <Divider orientation="vertical" flexItem />
                        <KeyData
                          title={"Website"}
                          value={
                            (result.jobDetails &&
                              result.jobDetails[0] &&
                              result.jobDetails[0].jobWebsite) ||
                            (result.contactDetails &&
                              result.contactDetails[0] &&
                              result.contactDetails[0].emailId &&
                              `https://www.${
                                result.contactDetails[0].emailId.split("@")[1]
                              }`) ||
                            "--"
                          }
                          color={colorStatus}
                          link
                        />
                        {!storeData.userType.includes("RATeam") && (
                          <>
                            {latestResult.status === "Active" &&
                              handleCheckEmailIDStatus(latestResult, query) && (
                                <>
                                  <Divider orientation="vertical" flexItem />
                                  <KeyData
                                    title={"EMail ID Status"}
                                    value={checkEMailStatus}
                                    color={
                                        checkEMailStatus === employeeDNC?'warning'
                                        :checkEMailStatus===employeeBlocked?
                                        'error' :'primary'
                                    }
                                  />
                                </>
                              )}
                          </>
                        )}
                        {!storeData.userType.includes("BDM") && (
                          <>
                            <Divider orientation="vertical" flexItem />
                            <KeyData
                              title={"Last Added By"}
                              value={ result.status!=='Active'?allUsers.find(
                                (user) => user._id ===beforeData&&beforeData.length>0&& beforeData[0].addedBy
                              )?.userFirstName :
                                allUsers.find(
                                  (user) => user._id === result.addedBy
                                )?.userFirstName
                              }
                              color={colorStatus}
                            />
                            <Divider orientation="vertical" flexItem />
                            <KeyData
                              title={"Last Added On"}
                              value={formatDate(result.addedOn)}
                              color={colorStatus}
                            />
                            <Divider orientation="vertical" flexItem />
                            <KeyData
                              title={"Lock-In Period"}
                              // value={calculateDays(result.addedOn)}
                              value={
                                calculateDays(result.addedOn) > 17
                                  ? "Company Unlock"
                                  : 18 - calculateDays(result.addedOn)
                              }
                              color={colorStatus}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    ""
                  )
                )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};
export default CompanyDetails;
