import {
  SnackbarProvider,
  enqueueSnackbar,
  closeSnackbar,
  useSnackbar,
} from "notistack";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import React, { useRef, useState, useEffect } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Backgrounds,
  KeyData,
  SaveEmailTemplate,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  addEmailTemplateViaApi,
  getAllTemplatesViaApi,
} from "../../store/search/action";
import { Subject } from "@mui/icons-material";
import { getAllUsers } from "../../store/auth/login/actions";
import theme from "../../Theme";

//
import EditIcon from "@mui/icons-material/Edit";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

const EmailTemplates = () => {
  const storeData = useSelector((state) => state.Login.storeData);
  const client=storeData.clientDb
  const templatesDataRedux = useSelector((state) => state.Search.templates) || [];
  const templatesData=Array.isArray(templatesDataRedux) ?templatesDataRedux:[]
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  let totalBDMUsers =
    allUsers && allUsers.filter((user) => user.userType.includes("BDM")||user.userType.includes("RecruitmentTeam"));
  if (storeData.userType.includes("BDM")||storeData.userType.includes('RecruitmentTeam')) {
    totalBDMUsers = totalBDMUsers.filter((user) => user._id === storeData._id);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const quillRef = useRef(null);
  const [editorHtml, setEditorHtml] = useState("");

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [bdmData, setBdmData] = useState("");
  const [templateTitle, setTemplateTitle] = useState("");
  useEffect(() => {
    if (storeData.userType.includes("BDM")||storeData.userType.includes('RecruitmentTeam')) {
      const filteredBDMUsers = totalBDMUsers.filter(
        (user) => user._id === storeData._id
      );
      setBdmData(filteredBDMUsers.length > 0 ? filteredBDMUsers[0] : {});
    }
  }, [storeData, totalBDMUsers]);

  const [emailSubject, setEmailSubject] = useState(
    "${jobTitle} in ${jobLocation}"
  );
  const handleSaveModalOpen = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const html = editor.root.innerHTML;
      if (html === "<p><br></p>") {
        // enqueueSnackbar("Please fill the template", { variant: "warning" });
        enqueueSnackbar(`Please fill the template`, {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar()} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      } else {
        setSaveModalOpen(true);
      }
    }
  };
  const handlesaveModalClose = () => {
    setSaveModalOpen(false);
    setBdmData("");
    setTemplateTitle("");
  };
  const handleBackdropClick = (event) => {
    event.stopPropagation();
  };

  const insertVariable = (variable) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const cursorPosition = editor.getSelection().index;
      editor.insertText(cursorPosition, `{{${variable}}}`);
    }
  };

  const handleSaveContent = () => {
    if (!bdmData || !templateTitle) {
      enqueueSnackbar(`Please fill the template title and assign to `, {
        variant: "warning",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      return null;
    }

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const html = editor.root.innerHTML;
      // Implement your save logic here
      const action = {
        Id: selectedTemplate?._id || "",
        addedById: storeData._id,
        templateAssignToId: bdmData._id,
        templateAddedOn: selectedTemplate?.templateAddedOn || new Date(),
        templateDateLastUsed:
          selectedTemplate?.templateDateLastUsed || new Date(),
        templateDateLastModified: new Date(),
        templateUsedCount: selectedTemplate?.templateUsedCount || "",
        templateTitle: templateTitle,
        emailSubject: emailSubject,
        emailBody: html,
        client:client,
      };
      dispatch(addEmailTemplateViaApi(action));
      handlesaveModalClose();
      dispatch(getAllTemplatesViaApi(action));
    }
  };

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
    setEditorHtml(template.emailBody);
    setTemplateTitle(template.templateTitle);
    const bdmData =
      allUsers &&
      allUsers.find((user) => user._id === template.templateAssignToId);
    setBdmData(bdmData || "");
    setEmailSubject(template.emailSubject || "");
  };
  const handleTemplateCopyPreviews = (template) => {
    setEditorHtml(template.emailBody);
    setEmailSubject(template.emailSubject || "");
  };
  const handleChangename = (title, user) => {
    setBdmData(user);
  };
  const handleaddSubjectVariables = (variable) => {
    setEmailSubject(emailSubject + " " + variable);
  };
  const replacePlaceholders = (text, contact) => {
    return text.replace(/\${(\w+)}/g, (match, p1) => {
      switch (p1) {
        case "firstName":
          return contact.firstName;
        case "jobTitle":
          return contact.jobTitle;
        case "jobLocation":
          return contact.jobLocation || "Hyderabad"; // Default location
        default:
          return match; // Return the placeholder itself if not recognized
      }
    });
  };
  const contactExample = {
    firstName: "Abhi",
    jobTitle: "Web Developer",
    jobLocation: "Hyderabad",
  };

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllTemplatesViaApi(action));
  }, []);

  const quillRefView = useRef(null);

  const [openViewModal, setOpenViewModal] = useState(false);
  const [viewTemplateSubject, setViewTemplateSubject] = useState("");
  const [viewTemplateBody, setViewTemplateBody] = useState("");

  const handleChangeOpenView = (template) => {
    setOpenViewModal(!openViewModal);
    setViewTemplateBody(template.emailBody);
    setViewTemplateSubject(template.emailSubject || "");
  };
  const handleCancelOpenView = () => {
    setOpenViewModal(!openViewModal);
    setViewTemplateSubject("");
    setViewTemplateBody("");
  };
  const handleViewDuplicate = () => {
    setEditorHtml(viewTemplateBody || "");
    setEmailSubject(viewTemplateSubject || "");
    setOpenViewModal(!openViewModal);
    setViewTemplateSubject("");
    setViewTemplateBody("");
  };

  /////////////////////Custom Buttons
  // Define the buttons array
  const buttons = [
    { label: "${firstName}", value: "${firstName}" },
    { label: "${lastName}", value: "${lastName}" },
    { label: "${jobTitle}", value: "${jobTitle}" },
    { label: "${jobLocation}", value: "${jobLocation}" },
  ];

  // Custom toolbar button component
  const CustomButton = ({ label, handler }) => (
    <button className="ql-custom" onClick={handler}>
      {label}
    </button>
  );

  const insertCustomText = (quillRef, value) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      quill.insertText(range.index, value);
    }
  };

  // Create handlers for each button
  const handlers = buttons.reduce((acc, button, index) => {
    acc[`customButton${index}`] = () =>
      insertCustomText(quillRef, button.value);
    return acc;
  }, {});

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Email Templates"}>
          <Grid container spacing={2} height={"100%"}>
            <Grid item xs={12} md={7} lg={8} xl={9}>
              <Backgrounds height="full">
                <ScrollView>
                  <SectionTitle title={"Email Template"} />
                  <Box>
                    <ReactQuill
                      ref={quillRef}
                      theme="snow"
                      value={editorHtml}
                      onChange={setEditorHtml}
                      placeholder="Text Here"
                      modules={{
                        toolbar: {
                          container: [
                            ["bold", "italic", "underline"],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link"],
                            ["clean"],
                            [{ color: [] }, { background: [] }],
                          ],
                        },
                      }}
                    />
                    <div id="toolbar">
                      {buttons.map((button, index) => (
                        <CustomButton
                          key={index}
                          label={button.label}
                          handler={handlers[`customButton${index}`]}
                        />
                      ))}
                    </div>
                  </Box>
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box sx={{ flex: 1 }} />
                    <Button
                      variant="contained"
                      color="primary"
                      // onClick={handleSaveContent}
                      onClick={() => handleSaveModalOpen()}
                    >
                      Save Content
                    </Button>
                  </Box>
                </ScrollView>
              </Backgrounds>
            </Grid>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <Backgrounds height="full" p={0}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    gap: 4,
                  }}
                >
                  <Box
                    sx={{ flex: 1, display: "flex", flexDirection: "column" }}
                  >
                    {/* <SectionTitle title={storeData.userType=== "  Saved Email Templates"} /> */}
                    <Box p={1.5}>
                      <SectionTitle
                        title={
                          storeData.userType.includes("BDM")
                            ? "My Email Templates"
                            : "All Templates"
                        }
                      />
                    </Box>
                    <Box sx={{ flex: 1, px: 1 }}>
                      <ScrollView>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 1.5,
                            pb: 1,
                            pr: 0.5,
                          }}
                        >
                          {templatesData.map((item, key) => {
                            const bdmData =
                              allUsers &&
                              allUsers.find(
                                (user) => user._id === item.templateAssignToId
                              );
                            if (storeData.userType.includes("BDM")||storeData.userType.includes("RecruitmentTeam")) {
                              if (storeData._id !== item.templateAssignToId) {
                                return null;
                              }
                            }
                            return (
                              <Box
                                key={key}
                                sx={{
                                  borderRadius: 2,
                                  p: 1,
                                  mx: 0.5,
                                  background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                                  border: `1px solid ${theme.palette.grey[200]}`,
                                }}
                              >
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      columnGap: 1,
                                      alignItems: "center",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontWeight: "bold" }}
                                      noWrap
                                    >
                                      {item?.templateTitle || ""}
                                    </Typography>
                                    <Typography sx={{ fontSize: 11 }}>
                                      Used:{item?.templateUsedCount || "N/A"}
                                    </Typography>
                                  </Box>
                                  {(storeData.userType.includes('SuperAdmin') || storeData.userType.includes('Admin') || storeData.userType.includes('Developer') ||
                                    item.templateAssignToId ===
                                      storeData._id) && (
                                    <>
                                      <Divider />
                                      <Box
                                        display={"flex"}
                                        flexWrap={"wrap"}
                                        py={0.5}
                                      >
                                        <Typography
                                          variant="caption"
                                          sx={{ fontWeight: "bold" }}
                                          noWrap
                                        >
                                          {bdmData?.userSudoFirstName || ""}
                                        </Typography>
                                        <Box flex={1} />
                                        <Typography variant="caption" noWrap>
                                          {bdmData?.userEmailId || ""}
                                        </Typography>
                                      </Box>
                                      <Divider />
                                    </>
                                  )}
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 1,
                                      my: 1,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "space-between",
                                        gap: 1,
                                      }}
                                    >
                                      {(storeData.userType.includes('SuperAdmin') || storeData.userType.includes('Admin') || storeData.userType.includes('Developer') ||
                                        item.templateAssignToId ===
                                          storeData._id) && (
                                        <>
                                          <KeyData
                                            title={"Created on:"}
                                            value={formatDate(
                                              item?.templateAddedOn || ""
                                            )}
                                          />
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                          <KeyData
                                            title={"Last Used:"}
                                            value={formatDate(
                                              item?.templateDateLastUsed || ""
                                            )}
                                          />
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                          <KeyData
                                            title={"Modified on:"}
                                            value={formatDate(
                                              item?.templateDateLastModified ||
                                                ""
                                            )}
                                          />
                                        </>
                                      )}
                                    </Box>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 1,
                                      justifyContent: "end",
                                    }}
                                  >
                                    <Tooltip title="View">
                                      <IconButton
                                        aria-label="View"
                                        size="xsmall"
                                        color="primary"
                                        onClick={() =>
                                          handleChangeOpenView(item)
                                        }
                                      >
                                        <VisibilityIcon fontSize="inherit" />
                                      </IconButton>
                                    </Tooltip>
                                    {(storeData.userType.includes('SuperAdmin') || storeData.userType.includes('Admin') || storeData.userType.includes('Developer') ||
                                      item.templateAssignToId ===
                                        storeData._id) && (
                                      <>
                                        <Tooltip title="Edit">
                                          <IconButton
                                            aria-label="Edit"
                                            size="xsmall"
                                            color="warning"
                                            onClick={() =>
                                              handleTemplateClick(item)
                                            }
                                          >
                                            <EditIcon fontSize="inherit" />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Duplicate">
                                          <IconButton
                                            aria-label="Duplicate"
                                            size="xsmall"
                                            color="secondary"
                                            onClick={() =>
                                              handleTemplateCopyPreviews(item)
                                            }
                                          >
                                            <FileCopyIcon fontSize="inherit" />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                          <IconButton
                                            aria-label="Delete"
                                            size="xsmall"
                                            color="error"
                                          >
                                            <DeleteIcon fontSize="inherit" />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </ScrollView>
                    </Box>
                  </Box>
                  {(storeData.userType.includes('BDM')||storeData.userType.includes('RecruitmentTeam') )&& (
                    <Box
                      sx={{ flex: 1, display: "flex", flexDirection: "column" }}
                    >
                      {/* <SectionTitle title={"Saved Email Templates"} /> */}
                      <Box p={1.5}>
                        <SectionTitle title={"Reference Email Templates"} />
                      </Box>
                      <Box sx={{ flex: 1, px: 1 }}>
                        <ScrollView>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1.5,
                              pb: 1,
                              pr: 0.5,
                            }}
                          >
                            {templatesData.map((item, key) => {
                              const bdmData =
                                allUsers &&
                                allUsers.find(
                                  (user) => user._id === item.templateAssignToId
                                );

                              if (storeData.userType.includes('BDM')) {
                                if (storeData._id === item.templateAssignToId) {
                                  return null;
                                }
                              }
                              return (
                                <Box
                                  key={key}
                                  sx={{
                                    borderRadius: 2,
                                    p: 1,
                                    mx: 0.5,
                                    background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                                    border: `1px solid ${theme.palette.grey[200]}`,
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        columnGap: 1,
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontWeight: "bold" }}
                                        noWrap
                                      >
                                        {item?.templateTitle || ""}
                                      </Typography>

                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          columnGap: 1,
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: 11 }}>
                                          Used:
                                          {item?.templateUsedCount || "N/A"}
                                        </Typography>
                                        <Tooltip title="View">
                                          <IconButton
                                            aria-label="View"
                                            size="xsmall"
                                            color="primary"
                                            onClick={() =>
                                              handleChangeOpenView(item)
                                            }
                                          >
                                            <VisibilityIcon fontSize="inherit" />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              );
                            })}
                          </Box>
                        </ScrollView>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Backgrounds>
            </Grid>
          </Grid>

          {/* Modal         */}
          <Modal
            open={saveModalOpen}
            // onClose={handlesaveModalClose}
          >
            <SaveEmailTemplate
              closeModal={handlesaveModalClose}
              handleSaveContent={handleSaveContent}
              totalBDMUsers={totalBDMUsers}
              handleChangename={handleChangename}
              bdmData={bdmData}
              templateTitle={templateTitle}
              setTemplateTitle={setTemplateTitle}
              emailSubject={emailSubject}
              handleaddSubjectVariables={handleaddSubjectVariables}
              setEmailSubject={setEmailSubject}
              replacePlaceholders={replacePlaceholders}
              contactExample={contactExample}
            />
          </Modal>
          <Modal
            open={openViewModal}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "1000px",
                width: "80%",
                maxHeight: "80vh",
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                overflowY: "auto",
                p: {
                  xs: 1.5,
                  md: 3,
                },
                borderRadius: 2,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: theme.palette.primary.dark,
                  fontWeight: "bold",
                  mb: 1,
                }}
              >
                View the template
              </Typography>
              <Divider />
              <Box>
                <ReactQuill
                  ref={quillRefView}
                  theme="snow"
                  value={viewTemplateBody}
                  onChange={setViewTemplateBody}
                  placeholder="Text Here"
                  modules={{
                    toolbar: false, // Disable the toolbar
                  }}
                  readOnly={true} // Make the editor read-only
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: 2,
                  pt: 4,
                }}
              >
                <Button variant="text" onClick={handleCancelOpenView}>
                  Cancel
                </Button>
                {/* <Button>Add later</Button> */}
                <Button
                  variant="contained"
                  onClick={() => handleViewDuplicate()}
                >
                  Duplicate
                </Button>
              </Box>
            </Box>
          </Modal>
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default EmailTemplates;