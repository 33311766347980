import { SnackbarProvider, closeSnackbar, enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Chip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BDMCustomeDateFilter, Widget,FilterHeader } from "../../Components";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment-timezone";
import { getAllUsers } from "../../store/auth/login/actions";
import dayjs from 'dayjs';
//mport dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import companyStatus from "../../Components/StatusElements/companyStatus";
import { useLocation, useNavigate } from "react-router-dom";
// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/New_York'); 
const BdmLeadsAnalysisView = () => {
  const { companyBlocked, companyDNC, companyNoFollowUp, employeeDNC, employeeBlocked,companyOnProcess } = companyStatus;
  const location = useLocation()
  const navigate = useNavigate();
  const bdmDetail = location.state.report
  const bdmName = location.state.bdmName

  const processDetail = (bdmDetail) => {
    if (Array.isArray(bdmDetail)) {
      // If bdmDetail is an array, process it directly
      return bdmDetail.map((company, index) => ({
        ...company,
        id: index + 1,
        bdmName 
      }));
    } else if (typeof bdmDetail === 'object' && bdmDetail !== null) {
      // If bdmDetail is a single object
      // Assuming you need to handle the object as having arrays in its properties
      const allCompanies = Object.values(bdmDetail).flat().filter(item => typeof item === 'object' && item !== null);
  
      return allCompanies.map((company, index) => ({
        ...company,
        id: index + 1,
        bdmName
      }));
    } else {
      // If bdmDetail is neither an array nor an object, return an empty array
      return [];
    }
  };
  
  const reportData = processDetail(bdmDetail);
  


  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client=storeData.clientDb
  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
  }, []);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

  const [searchJobPosition,setSearchJobPosition]=useState("");
  const [searchCompany,setSearchCompany]=useState("");
  const [searchStatus,setSearchStatus]=useState([]);
  const [searchRAName,setSearchRAName]=useState([]);
  const [searchBDMName,setSearchBDmName]=useState([]);

  let retrictEmailID =
  allUsers &&
  allUsers.filter(
    (user) =>
      user.userEmailId === "ratest@tektreeinc.com" ||
      user.userEmailId === "lilyra@tektreeinc.com" ||
      user.userEmailId === "oliviara@tektreeinc.com" ||
      user.userEmailId === "emmara@tektreeinc.com" ||
      user.userEmailId === "charlottera@tektreeinc.com" ||
      user.userEmailId === "danielra@tektreeinc.com" ||
      user.userEmailId === "miara@tektreeinc.com" ||
      user.userEmailId === "williamra@tektreellc.com"
  );

let retrictEmailIDBDM =
  allUsers &&
  allUsers.filter(
    (user) =>
      user.userEmailId === "emily.johnson@tektreecorp.com" ||
    user.userEmailId === "olivia.clark@tektreellc.com"||
    user.userEmailId === "emma.scott@tektreellc.com"||
    user.userEmailId === "william.turner@tektreellc.com"||
    user.userEmailId === "charlotte.mitchell@tektreellc.com"||
    user.userEmailId === "daniel.clark@tektreellc.com"
  );
  
  let AllCompaniesList =
    useSelector((state) => state.CompaniesList?.AllBDMCompanies) || [];
    AllCompaniesList =
    AllCompaniesList &&
    AllCompaniesList.filter((company) =>company.status!==companyDNC&&company.status!==companyBlocked);
  AllCompaniesList = AllCompaniesList.filter((company) => {
    if (company.status === companyOnProcess) {
      // Check if there are job details
      if (company.jobDetails && company.jobDetails.length > 0) {
        // Check if any job detail meets the criteria
        return company.jobDetails.some((jobDetails) => {
          // Check job detail status and approval status
          if (
            (jobDetails.status === "Close" ||
              jobDetails.status === "Hold" ||
              jobDetails.status === "Backout") &&
            jobDetails.approvalStatus === false
          ) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        return true; 
      }
    } else {
      return true; 
    }
  });

   
  
  const isAuthorizedUser =(storeData.userType.includes('SuperAdmin') ||  storeData.userType.includes('Developer'))
  
  if (!isAuthorizedUser) {
    
    AllCompaniesList = AllCompaniesList.filter((company) => {
      const addedBy = company.addedBy;
      const isRestricted =
        retrictEmailID.some((user) => user._id === addedBy) ||retrictEmailID.some((user)=>user._id===company.leadRAId)||
        retrictEmailIDBDM.some((user) => user._id === addedBy);
    
      return !isRestricted;
    });
  }
  let AlluserComapnies = Array.isArray(AllCompaniesList)
    ? AllCompaniesList
    : [];

  const formatDate = (dateString) => {
    const date = moment.tz(dateString, "UTC"); // Parse date in UTC
    return date.tz("Asia/Kolkata").format("DD-MM-YYYY"); // Convert to Kolkata time and format
  };
 
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz('America/New_York');
    const isDST = moment.tz(date, 'America/New_York').isDST();
    const timeZoneAbbr = isDST ? 'EDT' : 'EST';
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  
  
  const mergedData = AlluserComapnies.map((group) => {
    const userData = allUsers.find((user) => user._id === group.addedBy);
    const raData = allUsers.find((user) => user._id === group.leadRAId);
    const bdmName = userData?.userSudoFirstName ?? "";
    const leadRaName = raData?.userFirstName ?? "";
    if (userData) {
      return {
        bdmName: bdmName,
        leadRaName: leadRaName,
        ...group,
        leadRADate: group?.leadRADate ? formatDate(group.leadRADate) : "",
        addedOn: group?.addedOn ? formatDateForDisplay(group.addedOn) : "",
        jobDetails: group.jobDetails.map((company) => {
          const jobAddedUser = allUsers.find(
            (user) => user._id === company.addedBy
          );
          return {
            ...company,
            addedBy: jobAddedUser.userFirstName,
          };
        }),

        contactDetails: group.contactDetails.map((company) => {
          const contactUser = allUsers.find(
            (user) => user._id === company.addedBy
          );
          return {
            ...company,
            addedBy: contactUser.userFirstName, // Update addedBy to user's name
          };
        }),
      };
    } else {
      return group;
    }
  });
  
  const filterSearchData = Array.isArray(mergedData) 
  ? mergedData.filter((data) => {
      const lowerCaseCompanyName =data.companyName?.toLowerCase() || "";
      const lowerCaseJobPosition = data.jobDetails?.[0]?.jobTitle?.toLowerCase() || "";
      const statusType = data.jobDetails?.[0]?.status === 'Active'
      ? 'Positive'
      : data.jobDetails?.[0]?.status || '';
      const lowerCasebdmName=data.bdmName||"";
      const lowerCaseLeadRaName=data.leadRaName||"";
    
      const lowerCaseSearchJobPosition = searchJobPosition?.toLowerCase() || "";
      const lowerCaseSearchCompany = searchCompany?.toLowerCase() || "";
      const SearchStatus=searchStatus||[];
      const SearchBDMName=searchBDMName||[];
      const SearchRAName=searchRAName||[];
      return (lowerCaseJobPosition.includes(lowerCaseSearchJobPosition)
      &&lowerCaseCompanyName.includes(lowerCaseSearchCompany)&&
      (SearchStatus.length === 0 || SearchStatus.includes(statusType))
      &&(SearchBDMName.length==0||SearchBDMName.includes(lowerCasebdmName))
      &&(SearchRAName.length==0||SearchRAName.includes(lowerCaseLeadRaName)))
    })
  : [];
  let  companiesListWithIds = Array.isArray(filterSearchData)
    ? filterSearchData.map((company, index) => ({
        ...company,
        id: index + 1,
      }))
    : [];


  



   const optionsBDM=allUsers
   .filter((user) => user.userStatus === 'Active' &&user.userType.includes('BDM') && user.userSudoFirstName)
   .map((user) => user.userSudoFirstName)
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "addedOn",
      headerName: "Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "companyName",
      headerName: "Client Name",
      minWidth: 150, 
      flex: 1,
      renderHeader: (params) => <FilterHeader params={params} setSearchCompany={setSearchCompany} selectedValue={searchCompany} title={'companyName'}/>,

    },
    {
      field: "jobTitle",
      headerName: "Job Position",
      minWidth: 150,
      flex: 1,
      valueGetter: (value, row) => {
        if (row.jobDetails && row.jobDetails.length > 0) {
          const companies = row.jobDetails;
          return companies[0].jobTitle;
        }
      },
      renderHeader: (params) => <FilterHeader params={params} setSearchJobPosition={setSearchJobPosition} selectedValue={searchJobPosition} title={'jobTitle'}/>,
    },

    {
      field: "bdmName",
      headerName: "BDM Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => <FilterHeader params={params} setSearchBDmName={setSearchBDmName} selectedValue={searchBDMName} title={'bdmName'} type="multiselect"  options={optionsBDM}/>,
    },
    {
      field: "leadRaName",
      headerName: "RA Name",
      minWidth: 150,
      flex: 1,
      // renderHeader: (params) => <FilterHeader params={params} setSearchRAName={setSearchRAName} selectedValue={searchRAName} title={'raName'} type="multiselect" options={optionsRA}/>,
      renderCell: (params) => {
        const { row } = params;
        const user = allUsers.find((user) => user._id === row.leadRAId);
      
        return (
          <div>
            {user ? user.userFirstName : '--'} {/* Render user's first name or '--' if not found */}
          </div>
        );
      }
      
    },
    {
      field: "leadRADate",
      headerName: "Lead Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        if (row.jobDetails && row.jobDetails.length > 0) {
          const company = row.jobDetails[0];
          const status = company.status === 'Active' ? 'Positive' : company.status;
    
          return (
            <Box>
              <Chip
                label={status}
                size="small"
                sx={{ px: 0.5 }}
                color={
                  status === "Positive"?
                  "success"
                  :status === "Open"
                    ? "success"
                    : status === "Close"
                    ? "error"
                    : status === "Hold"
                    ? "warning"
                    : ["Interview L1", "Interview L2", "Interview L3"].includes(status)
                    ? "info"
                    : status === "Priority"
                    ? "secondary"
                    : "default"
                }
              />
            </Box>
          );
        }
        return '';
      },
      renderHeader: (params) => <FilterHeader params={params} setSearchStatus={setSearchStatus} title={'status'} selectedValue={searchStatus} type="multiselect" options={['Positive','Open','Close','Blocked','Hold','Interview L1','Interview L2','Interview L3']}/>,

    },
   
  ];
  const handleBackClick = () => {
    navigate('/dashboardAdmin'); // Replace '/dashboard' with your actual dashboard route
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle="BDM Report">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              gap: 3,
              mb: 3,
            }}
          >
            
          </Box>
          <Button onClick={handleBackClick} >Back</Button>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <DataGrid
              rows={reportData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              disableColumnMenu // Add this line to hide the column menu
              autoHeight
              columnBuffer={companiesListWithIds.length} // Set columnBuffer to the number of columns
              disableRowSelectionOnClick
             
            />
          </Box>
          
          
        </LayoutSidebarMenu>
      </SnackbarProvider>
     
    </React.Fragment>
  );
};

export default BdmLeadsAnalysisView;
















