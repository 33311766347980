// import {
//     Box,
//     Button,
//     Menu,
//     MenuItem,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     Grid,
//     Typography,
//   } from "@mui/material";
//   import React, { useState, useEffect } from "react";
//   import dayjs from "dayjs";
//   import { DateCalendar } from "@mui/x-date-pickers";
//   import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
//   dayjs.extend(require("dayjs/plugin/timezone"));
//   dayjs.extend(require("dayjs/plugin/utc"));
//   dayjs.tz.setDefault("Asia/Kolkata");
  
//   const ProductionDateFilter = ({handleDateChange}) => {
//     const [anchorEl, setAnchorEl] = useState(null);
//     const [selectedOption, setSelectedOption] = useState("This Week");
//     const [displayDate, setDisplayDate] = useState(
//       `${dayjs().startOf("week").add(1, 'day').format("DD/MMMM")} - ${dayjs().endOf("week").subtract(1, 'day').format(
//         "DD/MMMM, YYYY"
//       )}`
//     );
//     const [isModalOpen, setIsModalOpen] = useState(false);
//     const [customStartDate, setCustomStartDate] = useState(null);
//     const [customEndDate, setCustomEndDate] = useState(null);
//     const open = Boolean(anchorEl);
  
//     const handleClick = (event) => {
//       setAnchorEl(event.currentTarget);
//     };
  
//     const handleClose = () => {
//       setAnchorEl(null);
//     };
  
//     const handleMenuItemClick = (option) => {
//       setSelectedOption(option);
//       if (option === "Custom Date") {
//         openCustomDateModal();
//       } else {
//         setDisplayDate(getDateBasedOnOption(option));
//         handleClose();
//       }
//     };
  
//     const openCustomDateModal = () => {
//       setIsModalOpen(true);
//     };
  
//     const handleModalClose = () => {
//       setIsModalOpen(false);
//       // Reset to Today's date when closing the modal without saving
//       setDisplayDate(dayjs().format("DD/MMMM, YYYY"));
//       setSelectedOption("Today");
//     };
  
//     const handleSaveCustomDate = () => {
//       // Perform any validation or additional logic here if needed
//       const startDate = customStartDate
//         ? customStartDate.format("DD/MMMM, YYYY")
//         : "";
//       const endDate = customEndDate ? customEndDate.format("DD/MMMM, YYYY") : "";
//       setDisplayDate(`${startDate} - ${endDate}`);
//       setIsModalOpen(false);
//       handleDateChange([customStartDate.startOf("day"),customEndDate?customEndDate.endOf("day"):dayjs().endOf("day")]);
//     };
  
  
//     const getDateBasedOnOption = (option) => {
//       switch (option) {
//         case "Today":
//           handleDateChange([dayjs().startOf("day"), dayjs().endOf("day")]);
//           return dayjs().format("DD/MMMM, YYYY");
//         case "Yesterday":
//           handleDateChange([dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")]);
//           return dayjs().subtract(1, "day").format("DD/MMMM, YYYY");
//           case "This Week":
//             const thisMonday = dayjs().startOf("week").add(1, 'day'); // start of Monday
//             const thisFriday = dayjs().endOf("week").subtract(1, 'day'); // end of Friday
//             handleDateChange([thisMonday.startOf("day"),  thisFriday.endOf("day")]);
//             return  `${thisMonday.format("DD/MMMM")} - ${thisFriday.format(
//                 "DD/MMMM, YYYY"
//               )}`;
//         case "Last Week":
//           const today = dayjs();
//           const lastMonday = today
//             .subtract(1, "week")
//             .startOf("day")
//             .subtract(today.day() - 1, "days");
//           const lastFriday = today
//             .subtract(1, "week")
//             .endOf("day")
//             .subtract(today.day() - 5, "days");
//             handleDateChange([lastMonday.startOf("day"), lastFriday.endOf("day")]);
//           return `${lastMonday.format("DD/MMMM")} - ${lastFriday.format(
//             "DD/MMMM, YYYY"
//           )}`;
//         case "This Month":
//           const startOfMonth = dayjs().startOf("month").format("DD/MMMM");
//           const todayDate = dayjs().format("DD/MMMM, YYYY");
//           handleDateChange([dayjs().startOf("month").startOf("day"), dayjs().endOf("day")]);
//           return `${startOfMonth} - ${todayDate}`;
//         case "Last Month":
//           const startOfLastMonth = dayjs()
//             .subtract(1, "month")
//             .startOf("month")
//             .format("DD/MMMM");
//           const endOfLastMonth = dayjs()
//             .subtract(1, "month")
//             .endOf("month")
//             .format("DD/MMMM, YYYY");
//           handleDateChange([dayjs().subtract(1, "month").startOf("month").startOf("day"), dayjs().subtract(1, "month").endOf("month").endOf("day")]);
//           return `${startOfLastMonth} - ${endOfLastMonth}`;
//         case "Last 30 Days":
//           const startDateLast30Days = dayjs()
//             .subtract(30, "days")
//             .format("DD/MMMM");
//           const endDateLast30Days = dayjs().format("DD/MMMM, YYYY");
//           handleDateChange([dayjs().subtract(30, "days").startOf("day"), dayjs().endOf("day")]);
//           return `${startDateLast30Days} - ${endDateLast30Days}`;
//         default:
//           return dayjs().format("DD/MMMM, YYYY");
//       }
//     };
  
//     // Close the menu if clicking outside
//     useEffect(() => {
//       const handleClickOutside = (event) => {
//         if (anchorEl && anchorEl.contains && !anchorEl.contains(event.target)) {
//           handleClose();
//         }
//       };
  
//       document.addEventListener("mousedown", handleClickOutside);
  
//       return () => {
//         document.removeEventListener("mousedown", handleClickOutside);
//       };
//     }, [anchorEl]);
  
//     const menuOptions = [
//       "Today",
//       "Yesterday",
//       "This Week",
//       "Last Week",
//       "This Month",
//       "Last Month",
//       "Last 30 Days",
//       "Custom Date",

//     ];
  
//     return (
//       <React.Fragment>
//         <Box sx={{ display: "flex", justifyContent: "space-between" }}>
//           <Box sx={{ flex: 1 }}></Box>
//           <Box>
        
//             <Button onClick={handleClick} startIcon={<CalendarMonthIcon />}>
//               <Typography sx={{ fontSize: "inherit", fontWeight: 600 }}>
//                 {selectedOption}
//               </Typography>
//               - {displayDate}
//             </Button>
//           </Box>
//         </Box>
//         <Menu
//           id="demo-positioned-menu"
//           aria-labelledby="demo-positioned-button"
//           anchorEl={anchorEl}
//           open={open}
//           onClose={handleClose}
//           anchorOrigin={{
//             vertical: "bottom",
//             horizontal: "left",
//           }}
//           transformOrigin={{
//             vertical: "top",
//             horizontal: "left",
//           }}
//           hideBackdrop={true}
//         >
//           {menuOptions.map((option, index) => (
//             <MenuItem
//               key={index}
//               selected={option === selectedOption}
//               onClick={() => handleMenuItemClick(option)}
//             >
//               {option}
//             </MenuItem>
//           ))}
//         </Menu>
  
//         <Dialog
//           open={isModalOpen}
//           onClose={handleModalClose}
//           fullWidth={true}
//           maxWidth={"md"}
//         >
//           <DialogTitle>Select Custom Date Range</DialogTitle>
//           <DialogContent>
//             <Grid container spacing={2}>
//               <Grid item xs={6}>
//                 <DateCalendar
//                   label="Start Date"
//                   value={customStartDate}
//                   onChange={(date) => setCustomStartDate(dayjs(date).tz("Asia/Kolkata").startOf('day'))}
//                   disableFuture
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <DateCalendar
//                   label="End Date"
//                   value={customEndDate}
//                   onChange={(date) => setCustomEndDate(dayjs(date).tz("Asia/Kolkata").startOf('day'))}
//                   disabled={!customStartDate}
//                   minDate={customStartDate}
//                   maxDate={dayjs()}
//                 />
//               </Grid>
//             </Grid>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleModalClose}>Cancel</Button>
//             <Button onClick={handleSaveCustomDate}>Save</Button>
//           </DialogActions>
//         </Dialog>
//       </React.Fragment>
//     );
//   };
  
//   export default ProductionDateFilter;



////vinay rename the filter


import {
  Box,
  Button,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");

const ProductionDateFilter = ({handleDateChange}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("This Week");
  const [displayDate, setDisplayDate] = useState(
    `${ dayjs().startOf("week").subtract(2, 'days').format("DD/MMMM")} - ${dayjs().startOf("week").add(4, 'days').format(
      "DD/MMMM, YYYY"
    )}`
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    if (option === "Custom Date") {
      openCustomDateModal();
    } else {
      setDisplayDate(getDateBasedOnOption(option));
      handleClose();
    }
  };

  const openCustomDateModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    // Reset to Today's date when closing the modal without saving
    setDisplayDate(dayjs().format("DD/MMMM, YYYY"));
    setSelectedOption("Today");
  };

  const handleSaveCustomDate = () => {
    // Perform any validation or additional logic here if needed
    const startDate = customStartDate
      ? customStartDate.format("DD/MMMM, YYYY")
      : "";
    const endDate = customEndDate ? customEndDate.format("DD/MMMM, YYYY") : "";
    setDisplayDate(`${startDate} - ${endDate}`);
    setIsModalOpen(false);
    handleDateChange([customStartDate.startOf("day"),customEndDate?customEndDate.endOf("day"):dayjs().endOf("day")]);
  };


  const getDateBasedOnOption = (option) => {
    switch (option) {
      case "Today":
        handleDateChange([dayjs().startOf("day"), dayjs().endOf("day")]);
        return dayjs().format("DD/MMMM, YYYY");
  
      case "Yesterday":
        handleDateChange([dayjs().subtract(1, "day").startOf("day"), dayjs().subtract(1, "day").endOf("day")]);
        return dayjs().subtract(1, "day").format("DD/MMMM, YYYY");
  
      case "This Week":
        // Last week's Friday
        const lastWeekFriday = dayjs().startOf("week").subtract(2, 'days'); // This will give us the last Friday
        // This week's Thursday
        const thisWeekThursday = dayjs().startOf("week").add(4, 'days'); // This will give us the current week's Thursday
        handleDateChange([lastWeekFriday.startOf("day"), thisWeekThursday.endOf("day")]);
        return `${lastWeekFriday.format("DD/MMMM")} - ${thisWeekThursday.format("DD/MMMM, YYYY")}`;
  
      case "Last Week":
        const today = dayjs();
        const lastWeekStart = today.subtract(1, "week").startOf("week");
        const lastWeekEnd = today.subtract(1, "week").endOf("week");
        handleDateChange([lastWeekStart.startOf("day"), lastWeekEnd.endOf("day")]);
        return `${lastWeekStart.format("DD/MMMM")} - ${lastWeekEnd.format("DD/MMMM, YYYY")}`;
  
      case "This Month":
        const startOfMonth = dayjs().startOf("month").format("DD/MMMM");
        const todayDate = dayjs().format("DD/MMMM, YYYY");
        handleDateChange([dayjs().startOf("month").startOf("day"), dayjs().endOf("day")]);
        return `${startOfMonth} - ${todayDate}`;
  
      case "Last Month":
        const startOfLastMonth = dayjs().subtract(1, "month").startOf("month").format("DD/MMMM");
        const endOfLastMonth = dayjs().subtract(1, "month").endOf("month").format("DD/MMMM, YYYY");
        handleDateChange([dayjs().subtract(1, "month").startOf("month").startOf("day"), dayjs().subtract(1, "month").endOf("month").endOf("day")]);
        return `${startOfLastMonth} - ${endOfLastMonth}`;
  
      case "Last 30 Days":
        const startDateLast30Days = dayjs().subtract(30, "days").format("DD/MMMM");
        const endDateLast30Days = dayjs().format("DD/MMMM, YYYY");
        handleDateChange([dayjs().subtract(30, "days").startOf("day"), dayjs().endOf("day")]);
        return `${startDateLast30Days} - ${endDateLast30Days}`;
  
      default:
        return dayjs().format("DD/MMMM, YYYY");
    }
  };
  
  
  
 
 
 
 
 
 
 
 
 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (anchorEl && anchorEl.contains && !anchorEl.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchorEl]);

  const menuOptions = [
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "This Month",
    "Last Month",
    "Last 30 Days",
    "Custom Date",

  ];

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ flex: 1 }}></Box>
        <Box>
      
          <Button onClick={handleClick} startIcon={<CalendarMonthIcon />}>
            <Typography sx={{ fontSize: "inherit", fontWeight: 600 }}>
              {selectedOption}
            </Typography>
            - {displayDate}
          </Button>
        </Box>
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        hideBackdrop={true}
      >
        {menuOptions.map((option, index) => (
          <MenuItem
            key={index}
            selected={option === selectedOption}
            onClick={() => handleMenuItemClick(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <Dialog
        open={isModalOpen}
        onClose={handleModalClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle>Select Custom Date Range</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DateCalendar
                label="Start Date"
                value={customStartDate}
                onChange={(date) => setCustomStartDate(dayjs(date).tz("Asia/Kolkata").startOf('day'))}
                disableFuture
              />
            </Grid>
            <Grid item xs={6}>
              <DateCalendar
                label="End Date"
                value={customEndDate}
                onChange={(date) => setCustomEndDate(dayjs(date).tz("Asia/Kolkata").startOf('day'))}
                disabled={!customStartDate}
                minDate={customStartDate}
                maxDate={dayjs()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Cancel</Button>
          <Button onClick={handleSaveCustomDate}>Save</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ProductionDateFilter;
