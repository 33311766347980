// import React from "react";

// const UserAcesses = () => {
//   return <div>UserAcesses</div>;
// };

// export default UserAcesses;

import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Typography,
  Divider,
  FormControlLabel,
  Grid,
  Button,
  TextField,
  Tooltip,
  IconButton,
  Modal,
  Avatar,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllUsers,
  stopLoadingRedux,
  updateUsersTypeViaApi,
} from "../../../store/actions";
import { ShimmerTable } from "react-shimmer-effects";

import VisibilityIcon from "@mui/icons-material/Visibility";
import AccessData from "../../../Components/AccessData/AccessData";
import theme from "../../../Theme";

const { SpecialFeatures, SuperAdmin, AdminShowSpecialAccess } = AccessData;
const UserAcesses = () => {
  const dispatch = useDispatch();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const client = storeData.clientDb;
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const { enqueueSnackbar } = useSnackbar();

  const [allUsersList, setAllUsersList] = useState([]);
  const [modifiedUsers, setModifiedUsers] = useState([]); // Track modified users
  const [searchQuery, setSearchQuery] = useState("");
  const [openModalPage, setOpenModalPage] = useState(false);
  const [selectUser, setSelectUser] = useState([]);
  const roles = storeData.userType.includes("Admin")
    ? ["BDM", "RecruitmentTeam"]
    : ["SuperAdmin", "Developer", "Admin", "RATeam", "BDM", "RecruitmentTeam"];
  useEffect(() => {
    const filterUsersByRole = () => {
      if (storeData.userType.includes("Admin")) {
        return allUsers.filter(
          (user) =>
            !user.userType.includes("SuperAdmin") &&
            !user.userType.includes("Developer") &&
            !user.userType.includes("RATeam")
        );
      }
      return allUsers;
    };

    setAllUsersList(
      filterUsersByRole().map((user) => ({
        ...user,
        userType: Array.isArray(user.userType) ? user.userType : [],
      }))
    );
  }, [allUsers, storeData.userType]);

  const handleChange = (userId, role) => (event) => {
    const updatedUsersList = allUsersList.map((user) =>
      user._id === userId
        ? {
            ...user,
            userType: event.target.checked
              ? [...user.userType, role]
              : user.userType.filter((r) => r !== role),
          }
        : user
    );
    setAllUsersList(updatedUsersList);

    const modifiedUser = updatedUsersList.find((user) => user._id === userId);
    setModifiedUsers((prevModified) => {
      const existingUser = prevModified.find((user) => user._id === userId);
      if (existingUser) {
        return prevModified.map((user) =>
          user._id === userId
            ? {
                ...user,
                userType: event.target.checked
                  ? [...user.userType, role]
                  : user.userType.filter((r) => r !== role),
              }
            : user
        );
      }
      return [...prevModified, modifiedUser];
    });
  };

  const handleSave = async () => {
    if (modifiedUsers.length === 0) {
      enqueueSnackbar("No changes to save", { variant: "info" });
      return;
    }
    setLoading(true);
    try {
      await dispatch(updateUsersTypeViaApi(modifiedUsers)); // Send only modified users
      const action = {
        client: client,
      };
      dispatch(getAllUsers(action));
      setModifiedUsers([]);
    } catch (error) {
      enqueueSnackbar("Failed to update user roles", { variant: "error" });
    }
  };

  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
  }, [dispatch]);
  const filteredUsers = allUsersList.filter((user) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      user.userFirstName?.toLowerCase().includes(searchLower) ||
      user.userEmailId?.toLowerCase().includes(searchLower) ||
      user.userSudoEmailId?.toLowerCase().includes(searchLower) ||
      user.userSudoFirstName?.toLowerCase().includes(searchLower) ||
      user.userType.some((role) => role.toLowerCase().includes(searchLower))
    );
  });

  const handleViewData = (user) => {
    setOpenModalPage(!openModalPage);
    setSelectUser([user]);
  };
  const handleViewCancel = () => {
    setOpenModalPage(!openModalPage);
    setSelectUser([]);
  };
  const handleSelectPages = (event, option) => {
    setSelectUser((prevUsers) =>
      prevUsers.map((user) =>
        user._id === selectUser[0]._id
          ? {
              ...user,
              specialAccessList: event.target.checked
                ? [...(user.specialAccessList || []), option] // Ensure it's an array
                : (user.specialAccessList || []).filter((r) => r !== option),
            }
          : user
      )
    );

    // setAllUsersList((prevUsers) =>
    //   prevUsers.map((user) =>
    //     user._id === selectUser[0]._id
    //       ? {
    //           ...user,
    //           specialAccessList: event.target.checked
    //             ? [...user.specialAccessList, option]
    //             : user.specialAccessList.filter((r) => r !== option),
    //         }
    //       : user
    //   )
    // );
  };

  // Extract links and icons
  const filterLinks = (menu) => {
    let links = [];
    menu.forEach((item) => {
      if (item.linkTo) {
        links.push(item.linkTo);
      }
      if (item.subMenu) {
        item.subMenu.forEach((subItem) => {
          if (subItem.linkTo) {
            links.push(subItem.linkTo);
          }
        });
      }
    });
    return links;
  };
  const filteredLinks = filterLinks(
    storeData.userType.includes("Admin") ? AdminShowSpecialAccess : SuperAdmin
  );
  const handleSavePages = async () => {
    if (selectUser.length === 0) {
      enqueueSnackbar("No changes to save", { variant: "info" });
      return;
    }
    setLoading(true);
    try {
      await dispatch(updateUsersTypeViaApi(selectUser));
      const action = {
        client: client,
      };
      dispatch(getAllUsers(action));
      setSelectUser([]);
      handleViewCancel();
    } catch (error) {
      enqueueSnackbar("Failed to update user roles", { variant: "error" });
    }
  };

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false));
      setLoading(false);

      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  return (
    <React.Fragment>
      <Box sx={{ position: "relative", minHeight: "100vh" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: 2,
          }}
        >
          {/* Search Box */}
          <Box sx={{ minWidth: 400 }}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Search users..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          <Box>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Box>
        </Box>
        {loading ? (
          <ShimmerTable row={5} col={5} />
        ) : (
          <Grid container spacing={3} sx={{ pt: 2 }}>
            {filteredUsers.map((user) => (
              <Grid item xs={3} key={user._id}>
                <Box
                  sx={{
                    width: "100%",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.2,

                    border: "1px solid #ddd",
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        flex: 1,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 24,
                          height: 24,
                          fontSize: 12,
                          bgcolor: theme.palette.secondary.dark,
                        }}
                      >
                        {user.userFirstName.trim().slice(0, 1)}
                      </Avatar>
                      <Typography variant="h6" noWrap>
                        {user.userFirstName}
                      </Typography>
                    </Box>
                    <Box flex={1} />
                    <Box>
                      <Tooltip title="Preview">
                        <IconButton
                          size="small"
                          color="warning"
                          onClick={() => handleViewData(user)}
                        >
                          <VisibilityIcon fontSize="12" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    {roles.map((role) => (
                      <FormControlLabel
                        key={role}
                        control={
                          <Checkbox
                            size="small"
                            checked={user.userType.includes(role)}
                            onChange={handleChange(user._id, role)}
                            inputProps={{
                              "aria-label": `checkbox for ${role}`,
                            }}
                          />
                        }
                        label={role}
                        sx={{ mb: 0.2 }}
                      />
                    ))}
                  </Box>
                </Box>
                {/* <Box sx={{ p: 2, border: "1px solid #ddd", borderRadius: 2 }}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    {user.userFirstName}
                  </Typography>
                  <Divider sx={{ mb: 2 }} />
                  {roles.map((role) => (
                    <FormControlLabel
                      key={role}
                      control={
                        <Checkbox
                          checked={user.userType.includes(role)}
                          onChange={handleChange(user._id, role)}
                          inputProps={{
                            "aria-label": `checkbox for ${role}`,
                          }}
                        />
                      }
                      label={role}
                      sx={{ mb: 1 }}
                    />
                  ))}
                </Box> */}
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Modal
        open={openModalPage}
        // onClose={handleClose} // Uncomment and implement if needed
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to assign to SpecialFeatures RA
            </Typography>
          </Box>
          <Divider />
          {filteredLinks.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={selectUser[0]?.specialAccessList?.includes(option)}
                  onChange={(event) => handleSelectPages(event, option)}
                  inputProps={{
                    "aria-label": `checkbox for ${option}`,
                  }}
                />
              }
              label={option} // Assuming `option` is a string. If it's an object, use `option.title`
              sx={{ mb: 1 }}
            />
          ))}

          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleViewCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSavePages()} // Uncomment and implement if needed
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default UserAcesses;
