import { Exposure, Psychology } from "@mui/icons-material";
import {
  ADD_COMPANY_NAME_VIA_API,
  ADD_COMPANY_VIA_API,
  ADD_EMAIL_TEMPLATE_VIA_API,
  ADD_INDUSTRIES_NAMES_VIA_API,
  ADD_JOB_DETAILS_VIA_API,
  ADD_NEW_JOB_VIA_API,
  ASSIGN_USERS_DATA_SEARCH_VIA_API,
  CHANGE_STATUS_VIA_API,
  CHECK_COMPANY_NAME,
  CLEAR_SNACKBAR_MESSAGE,
  COUNT_SENT_MAILS_VIA_API,
  DATE_SEARCH_RAUSERS_COMPANIES_VIA_API,
  DATE_SEARCH_USERS_COMPANIES_VIA_API,
  DELETE_COMPANY_REDUX,
  DELETE_COMPANY_VIA_API,
  DELETE_SEARCH,
  GET_ALL_INDUSTRIES_VIA_API,
  GET_ALL_TEMPLATES_VIA_API,
  GET_ALL_USERS_COMPANIES_LIST_VIA_API,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_VIA_API,
  GET_SHUFFLED_DATA_VIA_API,
  GLOBAL_SEARCH_VIA_API,
  INDUSTRIES_NAMES_VIA_API,
  LAST_WEEK_SHUFFLED_DATA_VIA_API,
  RA_WEEK_REPORTS_VIA_API,
  REMOVE_COUNT_MAILS,
  REMOVE_NEW_COMPANY,
  SEARCH_DATA_VIA_API,
  SEND_EMAILS_VIA_API,
  SEND_EXCELUPLOAD_ALL_COMPANIES_VIA_API,
  SET_SNACKBAR_MESSAGE,
  SHUFFLED_DATA_VIA_API,
  STASTUS_CHANGE_VIA_API,
  SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API,
  SUCCESS_COUNT_SENT_MAILS,
  SUCCESS_DATE_SEARCH_USERS_COMPANIES,
  SUCCESS_GET_ALL_INDUSTRIES,
  SUCCESS_GET_ALL_TEMPLATES,
  SUCCESS_NEW_COMPANY,
  SUCCESS_SEARCH_DATA,
  SUCCESS_SHUFFLED_DATA,
  SUCCESS_YESTERDAY_RA_REPORTS,
  SUCCES_GLOBAL_SEARCH,
  UNSUBSCRIBE_VIA_API,
  YESTERDAY_RA_REPORTS_VAIA_API,
  YESTERDAY_RA_REPORTS_VIA_API,
} from "./actionType";
import { type } from "@testing-library/user-event/dist/type";


export const searchDataViaApi = (action) => {
  return {
    type: SEARCH_DATA_VIA_API,
    payload: action,
  };
};
export const successSearchData = (action) => {
  return {
    type: SUCCESS_SEARCH_DATA,
    payload: action,
  };
};
export const addJobDataViaApi = (action) => {
  return {
    type: ADD_COMPANY_VIA_API,
    payload: action,
  };
};
export const deleteSearch = () => {
  return {
    type: DELETE_SEARCH,
    payload: {},
  };
};

export const setSnackbarMessage = (message, variant) => {
  return {
    type: SET_SNACKBAR_MESSAGE,
    payload: { message, variant }, // Include variant (e.g., 'success' or 'error')
  };
};

export const clearSnackbarMessage = () => ({
  type: CLEAR_SNACKBAR_MESSAGE,
});

export const addCompanyViaApi = (action) => {
  return {
    type: ADD_COMPANY_NAME_VIA_API,
    payload: action,
  };
};
export const getCompanies = (action) => ({
  type: GET_COMPANIES_VIA_API,
  payload: action,
});

export const getCompaniesSuccess = (data) => ({
  type: GET_COMPANIES_SUCCESS,
  payload: data,
});
export const getAllUsersCompaniesList = () => {
  return {
    type: GET_ALL_USERS_COMPANIES_LIST_VIA_API,
    payload: {},
  };
};
export const successAllUsersCompaniesList = (data) => {
  return {
    type: SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API,
    payload: data,
  };
};
export const deleteCompanyViaApi = (action) => {
  return {
    type: DELETE_COMPANY_VIA_API,
    payload: action,
  };
};

export const addJobDetailsViaApi = (action) => {
  return {
    type: ADD_JOB_DETAILS_VIA_API,
    payload: action,
  };
};
export const checkCompanyName=(action)=>{
  return{
    type:CHECK_COMPANY_NAME,
    payload:action,
  }
}
export const deleteCompanyRedux=(id)=>{
  return{
    type:DELETE_COMPANY_REDUX,
    payload:id,
  }
}
export const sendEmailViaApi=(action)=>{
  return{
    type:SEND_EMAILS_VIA_API,
    payload:action,
  }
}

export const globalSearchViaApi=(searchEmail)=>{
  return{
    type:GLOBAL_SEARCH_VIA_API,
    payload:searchEmail,
  }
}
export const successGlobalSearch=(action)=>{
  return{
    type:SUCCES_GLOBAL_SEARCH,
    payload:action
  }
}
export const sendExcelUploadAllCompaniesViaApi =(action)=>{
  return{
    type:SEND_EXCELUPLOAD_ALL_COMPANIES_VIA_API,
    payload:action
  }
}
export const getAllIndustriesViaApi=(action)=>{
  return{
    type:GET_ALL_INDUSTRIES_VIA_API,
    payload:action
  }
}
export const successGetAllIndustries=(allIndutries)=>{
  return{
    type:SUCCESS_GET_ALL_INDUSTRIES,
    payload:allIndutries
  }
}
export const industriesNamesViaApi=(action)=>{
  
  return{
    type:INDUSTRIES_NAMES_VIA_API,
    payload:action
  }
}
export const changeStatusViaApi=(action)=>{
  return{
    type:CHANGE_STATUS_VIA_API,
    payload:action
  }
}

export const getAllTemplatesViaApi =(action)=>{
  return{
    type:GET_ALL_TEMPLATES_VIA_API,
    payload:action
  }
}
export const successGetAllTemplates=(emailTemplate)=>{
  return{
    type:SUCCESS_GET_ALL_TEMPLATES,
    payload:emailTemplate
  }
}

export const countSentMailsViaApi=(action)=>{
 
  return{
    type:COUNT_SENT_MAILS_VIA_API,
    payload:action
  }
}

export const successCountSentMails=(count)=>{
  

  return{
    type:SUCCESS_COUNT_SENT_MAILS,
    payload:count
  }
}
export const removeCountMails=()=>{
  return{
    type:REMOVE_COUNT_MAILS,
    payload:{}
  }
}
export const dateSearchUsersCompaniesViaApi=(action)=>{
  return{
    type:DATE_SEARCH_USERS_COMPANIES_VIA_API,
    payload:action
  }
}
export const successDateSearchUsersCompanies=(response)=>{
  return{
     type:SUCCESS_DATE_SEARCH_USERS_COMPANIES,
     payload:response
  }
}
export const shuffledDataViaApi=(action)=>{
  return{
    type:SHUFFLED_DATA_VIA_API,
    payload:action
  }
}
export const successShuffledData=(response)=>{
  return{
    type:SUCCESS_SHUFFLED_DATA,
    payload:response
  }
}
export const getShuffledDataViaApi=(action)=>{
  return{
    type:GET_SHUFFLED_DATA_VIA_API,
    payload:action
  }
}
export const statusChangeViaApi=(action)=>{
  return{
    type:STASTUS_CHANGE_VIA_API,
    payload:action
  }
}
export const unSubscribeViaApi=(action)=>{
  return{
    type:UNSUBSCRIBE_VIA_API,
    payload:action
  }
}
export const successNewCompany=(action)=>{
  return{
    type:SUCCESS_NEW_COMPANY,
    payload:action
  }
}
export const removeNewCompany=()=>{
  return{
    type:REMOVE_NEW_COMPANY,
    payload:{}
  }
}
export const addEmailTemplateViaApi=(action)=>{
  return{
    type:ADD_EMAIL_TEMPLATE_VIA_API,
    payload:action
  }
}
export const assignUsersDataSearchViaApi=(action)=>{
  return{
    type:ASSIGN_USERS_DATA_SEARCH_VIA_API,
    payload:action
  }
}
export const lastWeekShuffledDataViaApi=(action)=>{
  return{
    type:LAST_WEEK_SHUFFLED_DATA_VIA_API,
    payload:action
  }
}
export const raWeekReportsViaApi=(action)=>{
  return{
      type:RA_WEEK_REPORTS_VIA_API,
      payload:action
  }
}
export const yesterDayaRAReportsViaApi=(action)=>{
  return{
      type:YESTERDAY_RA_REPORTS_VIA_API,
      payload:action
  }
}
export const addIndustriesNamesViaApi=(action)=>{
  return{
      type:ADD_INDUSTRIES_NAMES_VIA_API,
      payload:action
  }
}

export const dateSearchRaUsersCompaniesViaApi=(action)=>{
  return{
    type:DATE_SEARCH_RAUSERS_COMPANIES_VIA_API,
    payload:action
  }
}

export const addNewjobViaApi=(action)=>{
  return{
    type:ADD_NEW_JOB_VIA_API,
    payload:action
  }
}

