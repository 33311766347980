

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Checkbox,
//   Typography,
//   Divider,
//   FormControlLabel,
//   Grid,
//   Button,
//   TextField,
//   Tooltip,
//   IconButton,
//   Modal,
// } from "@mui/material";
// import { SnackbarProvider, useSnackbar } from "notistack";
// import { useDispatch, useSelector } from "react-redux";
// import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
// import {
//   getAllUsers,
//   stopLoadingRedux,
//   updateUsersTypeViaApi,
// } from "../../store/actions";
// import { ShimmerTable } from "react-shimmer-effects";
// import { SnackbarComponent } from "../../Components";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import AccessData from "../../Components/AccessData/AccessData";
// const {SpecialFeatures,SuperAdmin,AdminShowSpecialAccess}=AccessData
// const Settings = () => {
//   const dispatch = useDispatch();
//   const { storeData } = useSelector((state) => ({
//     storeData: state.Login.storeData,
//   }));
//   const client= storeData.clientDb;
//   const allUsersData = useSelector((state) => state.Login.allUsers);
//   const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
//   const [loading, setLoading] = useState(true);
//   const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
//   const { enqueueSnackbar } = useSnackbar();

//   const [allUsersList, setAllUsersList] = useState([]);
//   const [modifiedUsers, setModifiedUsers] = useState([]); // Track modified users
//   const [searchQuery, setSearchQuery] = useState("");
//   const [openModalPage,setOpenModalPage]=useState(false)
//   const[selectUser,setSelectUser]=useState([])
//   const roles = storeData.userType.includes("Admin")
//   ? ["BDM", "RecruitmentTeam"]
//   : ["SuperAdmin", "Developer", "Admin", "RATeam", "BDM", "RecruitmentTeam"];
//   useEffect(() => {
//     const filterUsersByRole = () => {
//       if (storeData.userType.includes("Admin")) {
//         return allUsers.filter(
//           (user) => !user.userType.includes("SuperAdmin") && !user.userType.includes("Developer") && !user.userType.includes("RATeam")
//         );
//       }
//       return allUsers;
//     };
    
//     setAllUsersList(
//       filterUsersByRole().map((user) => ({
//         ...user,
//         userType: Array.isArray(user.userType) ? user.userType : [],
//       }))
//     );
//   }, [allUsers, storeData.userType]);

//   const handleChange = (userId, role) => (event) => {
//     const updatedUsersList = allUsersList.map((user) =>
//       user._id === userId
//         ? {
//             ...user,
//             userType: event.target.checked
//               ? [...user.userType, role]
//               : user.userType.filter((r) => r !== role),
//           }
//         : user
//     );
//     setAllUsersList(updatedUsersList);

//     const modifiedUser = updatedUsersList.find((user) => user._id === userId);
//     setModifiedUsers((prevModified) => {
//       const existingUser = prevModified.find((user) => user._id === userId);
//       if (existingUser) {
//         return prevModified.map((user) =>
//           user._id === userId
//             ? {
//                 ...user,
//                 userType: event.target.checked
//                   ? [...user.userType, role]
//                   : user.userType.filter((r) => r !== role),
//               }
//             : user
//         );
//       }
//       return [...prevModified, modifiedUser];
//     });
//   };


//   const handleSave = async () => {
//     if (modifiedUsers.length === 0) {
//       enqueueSnackbar("No changes to save", { variant: "info" });
//       return;
//     }
//     setLoading(true)
//     try {
      
//       await dispatch(updateUsersTypeViaApi(modifiedUsers)); // Send only modified users
//       const action={
//         client:client
//       }
//       dispatch(getAllUsers(action));
//       setModifiedUsers([]); 
//     } catch (error) {
//       enqueueSnackbar("Failed to update user roles", { variant: "error" });
//     }
//   };

//   useEffect(() => {
//     const action={
//       client:client
//     }
//     dispatch(getAllUsers(action));
//   }, [dispatch]);
//   const filteredUsers = allUsersList.filter((user) => {
//     const searchLower = searchQuery.toLowerCase();
//     return (
//       user.userFirstName?.toLowerCase().includes(searchLower) ||
//       user.userEmailId?.toLowerCase().includes(searchLower) ||
//       user.userSudoEmailId?.toLowerCase().includes(searchLower) ||
//       user.userSudoFirstName?.toLowerCase().includes(searchLower) ||
//       user.userType.some((role) => role.toLowerCase().includes(searchLower))
//     );
//   });
  
// const handleViewData=(user)=>{
//   setOpenModalPage(!openModalPage)
//   setSelectUser([user])
// }
// const handleViewCancel=()=>{
//   setOpenModalPage(!openModalPage)
//   setSelectUser([])
// }
// const handleSelectPages = (event, option) => {
//   setSelectUser((prevUsers) =>
//     prevUsers.map((user) =>
//       user._id === selectUser[0]._id
//         ? {
//             ...user,
//             specialAccessList: event.target.checked
//               ? [...(user.specialAccessList || []), option] // Ensure it's an array
//               : (user.specialAccessList || []).filter((r) => r !== option),
//           }
//         : user
//     )
//   );

//   // setAllUsersList((prevUsers) =>
//   //   prevUsers.map((user) =>
//   //     user._id === selectUser[0]._id
//   //       ? {
//   //           ...user,
//   //           specialAccessList: event.target.checked
//   //             ? [...user.specialAccessList, option]
//   //             : user.specialAccessList.filter((r) => r !== option),
//   //         }
//   //       : user
//   //   )
//   // );

// };


//  // Extract links and icons
// const filterLinks = (menu) => {
//   let links = [];
//   menu.forEach(item => {
//       if (item.linkTo) {
//           links.push(item.linkTo);
//       }
//       if (item.subMenu) {
//           item.subMenu.forEach(subItem => {
//               if (subItem.linkTo) {
//                   links.push(subItem.linkTo);
//               }
//           });
//       }
//   });
//   return links;
// };
// const filteredLinks = filterLinks(storeData.userType.includes('Admin')?AdminShowSpecialAccess:SuperAdmin);
// const handleSavePages=async()=>{
//   if (selectUser.length === 0) {
//     enqueueSnackbar("No changes to save", { variant: "info" });
//     return;
//   }
//   setLoading(true)
//   try {
//     await dispatch(updateUsersTypeViaApi(selectUser));
//     const action={
//       client:client
//     }
//     dispatch(getAllUsers(action));
//     setSelectUser([]); 
//     handleViewCancel()
//   } catch (error) {
//     enqueueSnackbar("Failed to update user roles", { variant: "error" });
//   }
// }

// useEffect(() => {
//   if (loadingRedux) {
//     dispatch(stopLoadingRedux(false));
//     setLoading(false);

//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000);

//     return () => clearTimeout(timer);
//   }
// }, [loadingRedux, dispatch]);
//   return (
//     <React.Fragment>
//       <SnackbarProvider
//         maxSnack={4}
//         anchorOrigin={{ horizontal: "right", vertical: "top" }}
//       >
//         <LayoutSidebarMenu pageTitle={"Settings"}>
//           <Box sx={{ position: "relative", minHeight: "100vh" }}>
//             <Box>
//               <Button variant="contained" color="primary" onClick={handleSave}>
//                 Save Changes
//               </Button>
//             </Box>
//             {/* Search Box */}
//             <Box sx={{ my: 2 }}>
//               <TextField
//                 fullWidth
//                 variant="outlined"
//                 placeholder="Search users..."
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//             </Box>
//             {loading ? (
//               <ShimmerTable row={5} col={5} />
//             ) : (
//               <Grid container spacing={3} sx={{ pt: 8 }}>
//                 {filteredUsers.map((user) => (
//                   <Grid item xs={12} md={6} key={user._id}>
//                     <Box
//                       sx={{ p: 2, border: "1px solid #ddd", borderRadius: 2 }}
//                     >
//                       <Typography variant="h6" sx={{ mb: 2 }}>
//                         {user.userFirstName}
//                       </Typography>
//                       <Divider sx={{ mb: 2 }} />
//                       {roles.map((role) => (
//                         <FormControlLabel
//                           key={role}
//                           control={
//                             <Checkbox
//                               checked={user.userType.includes(role)}
//                               onChange={handleChange(user._id, role)}
//                               inputProps={{
//                                 "aria-label": `checkbox for ${role}`,
//                               }}
//                             />
//                           }
//                           label={role}
//                           sx={{ mb: 1 }}
//                         />
//                       ))}
//                       {/*{user.userType.includes("RATeam") && (
//                         <>*/}
//                           <Tooltip title="Preview">
//                             <IconButton
//                               size="small"
//                               color="warning"
//                               onClick={() => handleViewData(user)}
//                             >
//                               <VisibilityIcon fontSize="12" />
//                             </IconButton>
//                           </Tooltip>
//                        {/**</>
//                       )} */} 
//                     </Box>
//                   </Grid>
//                 ))}
//               </Grid>
//             )}
//           </Box>
//           <Modal
//       open={openModalPage}
//       // onClose={handleClose} // Uncomment and implement if needed
//       aria-labelledby="child-modal-title"
//       aria-describedby="child-modal-description"
//     >
//       <Box
//         sx={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           maxWidth: "400px",
//           width: "80%",
//           bgcolor: "background.paper",
//           border: "2px solid #000",
//           boxShadow: 24,
//           p: { xs: 1, md: 2 },
//           borderRadius: 2,
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             columnGap: 1,
//             rowGap: 0.5,
//             flexWrap: "wrap",
//             mb: 3,
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Typography fontWeight={"400"}>
//             Are you sure you want to assign to SpecialFeatures RA
//           </Typography>
//         </Box>
//         <Divider />
//         {filteredLinks.map((option, index) => (
//   <FormControlLabel
//     key={index}
//     control={
//       <Checkbox
//         checked={selectUser[0]?.specialAccessList?.includes(option)}
//         onChange={(event) => handleSelectPages(event, option)} 
//         inputProps={{
//           "aria-label": `checkbox for ${option}`,
//         }}
//       />
//     }
//     label={option} // Assuming `option` is a string. If it's an object, use `option.title`
//     sx={{ mb: 1 }}
//   />
// ))}

//         <Box
//           sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
//         >
//           <Button
//             color="inherit"
//             sx={{ textTransform: "inherit" }}
//             onClick={() => handleViewCancel()}
//           >
//             Cancel
//           </Button>
//           <Button
//             variant="contained"
//             color="success"
//             sx={{ textTransform: "inherit" }}
//             onClick={() => handleSavePages()} // Uncomment and implement if needed
//           >
//             Save
//           </Button>
//         </Box>
//       </Box>
//     </Modal>
//         </LayoutSidebarMenu>
//         <SnackbarComponent />
//       </SnackbarProvider>
//     </React.Fragment>
//   );
// };

// export default Settings;











import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import LayoutSettingSidebarMenu from "./Layout/LayoutSettingSidebarMenu";
import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import theme from "../../Theme";
import UserAcesses from "../../Components/Sections/SettingsSections/UserAcesses";
import { ScrollView } from "../../Components";
import ApiIntegration from "../../Components/Sections/SettingsSections/ApiIntegration";
import UsersList from "../../Components/Sections/SettingsSections/UsersList";
import Controller from "../../Components/Sections/SettingsSections/Controller";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate} from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      sx={{ width: "100%" }}
    >
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Settings = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSettingSidebarMenu pageTitle={"Main Settings"}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Box mb={2}>
              <Button
                size="small"
                color="error"
                variant="text"
                startIcon={<ArrowBackIcon />}
                sx={{ textTransform: "inherit" }}
                onClick={() => navigate(-1)}
              >
                back
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                width:"100%",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Box sx={{ width: 250 }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: "#f5f5f5", // Background color of the tabs container
                    borderRight: `2px solid ${theme.palette.primary.dark}`, // Border for better separation
                    p: 0,
                    ".MuiTab-root": {
                      fontSize: "14px", // Font size
                      px: 8,
                      textAlign: "left",
                      textTransform: "inherit",

                      "&.Mui-selected": {
                        color: theme.palette.primary.contrastText, // Text color when selected
                        backgroundColor: theme.palette.primary.dark, // Background when selected
                      },
                      "&:hover": {
                        color: theme.palette.primary.dark,
                        backgroundColor: theme.palette.primary[200], // Hover effect
                      },
                    },
                  }}
                >
                  <Tab label="User Acesses" {...a11yProps(0)} />
                  <Tab label="Integrations" {...a11yProps(1)} />
                  <Tab label="Users" {...a11yProps(2)} />
                  <Tab label="Controllers" {...a11yProps(3)} />
                </Tabs>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  overflowY: "auto",
                }}
              >
                <TabPanel value={value} index={0}>
                  <UserAcesses />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <ApiIntegration />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <UsersList />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Controller />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </LayoutSettingSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default Settings;
