import { Box, Button, Divider, Drawer, Typography } from '@mui/material'
import React from 'react'
import FormatDateTime from '../Helper/FormatDateTime'
import CustomFormField from '../Common/CustomFormField'
import SectionTitle from '../Helper/SectionTitle'
import theme from '../../Theme'
import ScrollView from '../Helper/ScrollView'
import MainBackground from '../Backgrounds/MainBackground'

 const JobBoardsAddNotes = ({open,close,allUsers,notes=[],note,handleNote,handleNoteSubmit}) => {
  return (
    <Drawer
    open={open}
    onClose={close}
    anchor="right"
  >
    <Box
      sx={{
        width: 500,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        flex: 1,
      }}
    >
      <Box p={2}>
        <SectionTitle title="Add New Note" size="small" color="error" />
        <Box mt={2} />
        {/* Notes for Job Code: {selectedJodCode} */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <CustomFormField
            //title={"Note"}
            components={[
              {
                type: "textArea",
                props: {
                  defaultValue: note,
                  placeholder: "Enter your Note",
                  onChange:(value)=>handleNote(value),
                },
              },
            ]}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={close}
            >
              Cancel
            </Button>
            <Button variant="outlined" size="small" disabled={!note?.trim()}  onClick={handleNoteSubmit}>
              Add Note
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          pb: 2,
          pl: 2,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: 12,
              letterSpacing: 0.2,
              color: theme.palette.primary.dark,
            }}
          >
            Total Notes: {notes?.length ?? 0}
          </Typography>
        </Box>
        <Box flex={1}>
          <ScrollView>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mr: 2,
              }}
            >
              {/* {Array.from({ length: 15 }, (_, index) => ( */}
                 {notes.map(
                  (noteItem, index) => (
                <MainBackground key={index}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 13,
                        letterSpacing: 0.2,
                        color: theme.palette.grey[800],
                      }}
                    >
                      Note: {noteItem.note}
                    </Typography>
                    <Divider sx={{ py: 0.5 }} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 0.5,
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 11,
                          letterSpacing: 0.2,
                          color: theme.palette.grey[500],
                        }}
                      >
                        User Name -  {allUsers.find((user) => user._id === noteItem.addedBy)
                  ?.userSudoFirstName || "Unknown"}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: 11,
                          letterSpacing: 0.2,
                          color: theme.palette.grey[500],
                        }}
                      >
                        {FormatDateTime(noteItem.addedOn, "full")}
                      </Typography>
                    </Box>
                  </Box>
                </MainBackground>
              ))}
            </Box>
          </ScrollView>
        </Box>
      </Box>
    </Box>
  </Drawer>
  )
}
export default JobBoardsAddNotes