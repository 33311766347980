import {
  all,
  call,
  delay,
  fork,
  put,
  race,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

// Login Redux States
import { EMAIL_INTEGRATION_VIA_API, GET_ALL_USERS, LOGIN_USER, LOGOUT_USER, UPDATE_USER_VIA_API, UPDATE_USERS_TYPES_VIA_API,  } from "./actionTypes";
import {
  apiError,
  getAllUsers,
  getAllUsersSuccess,
  loginSuccess,
  successGetProfileData,
} from "./actions";
import { setSnackbarMessage } from "../../search/action";
import { loadingViaRedux, userTypeAccessListViaSaga } from "../../actions";

//Include Both Helper File with needed methods
const apiUrl = process.env.REACT_APP_API_URL;
function getProfileDataViaApiSaga(email) {
 
  return fetch(apiUrl + "/accounts/" + email, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function getAllUsersApi(payload) {
  const client=payload.client
  return fetch(apiUrl + "/accounts/client/" + client, {
    // Corrected endpoint URL
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function loginUserActiveViaApiSaga(payload) {
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/accounts/loginActive", {
    // Corrected endpoint URL
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}

function updateUserViaApiSaga(payload) {
 
  const formData = new FormData();

  // Append non-file fields to the FormData
  Object.entries(payload).forEach(([key, value]) => {
    if (key !== 'userProfileImage') {
       // If the value is an array, convert it to a JSON string
       const valueToAppend = Array.isArray(value) ? JSON.stringify(value) : value;
       formData.append(key, valueToAppend);
    }
  });

  // Append file to the FormData
  if (payload.userProfileImage) {
    formData.append('userProfileImage', payload.userProfileImage);
  }

  return fetch(apiUrl + "/accounts/update", {
    method: "POST",
    body: formData,
  })
  .then((response) => response.json())
  .catch((error) => {
    throw error;
  });
}
function updateUsersTypesViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/accounts/userType", {
    // Corrected endpoint URL
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}
function emailIntegrationViaApiSaga(payload){
  const myPayload = JSON.stringify(payload);
  return fetch(apiUrl + "/accounts/emailIntegration", {
    // Corrected endpoint URL
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: myPayload,
  })
    .then((response) => response.json())
    .catch((error) => {
      throw error;
    });
}


function* loginUser({ payload: { user, navigate } }) {
  // For testing purpose, remove it laters
  // Actual logic is available below
    localStorage.removeItem("userData");
    localStorage.removeItem("otherInfo");
    sessionStorage.removeItem("storeData");
    localStorage.removeItem("persist:persist-key");
    localStorage.removeItem("i18nextLng");
    const response = yield call(getProfileDataViaApiSaga, user.email);
    if (response.userEmailId === user.email) {
      if (
        response.userEmailId === user.email &&
        response.userPassword === user.password
      ) {
        if (
          response.userStatus === "Inactive"
        ) {
          yield put(
            apiError({
              data: "Please contact admin for your account!",
            })
          );
        } else {
          const storeData = {
            userDetails: response,
          };
          sessionStorage.setItem("storeData", JSON.stringify(storeData));
          yield put(userTypeAccessListViaSaga(storeData))
          yield put(successGetProfileData(storeData));
          const action={client:storeData.userDetails.clientDb}
          yield put(getAllUsers(action));
          const loginActive = {
            client:storeData.userDetails.clientDb,
            id: storeData.userDetails._id,
            login: "Active",
          };

          const responseData = yield call(
            loginUserActiveViaApiSaga,
            loginActive
          );
          if (responseData) {
            if (responseData.error === "An internal server error occurred") {
              yield put(setSnackbarMessage(response.error, "error"));
              return null;
            }
            // if(responseData.message){
            //   yield put(setSnackbarMessage(responseData.message, "success"));
            // }
          }
          if (storeData.userDetails.userType.includes('BDM')
          ) {
          // if (storeData.userDetails.userType==='BDM') {
            navigate("/bdmDashboard");
          }else if(storeData.userDetails.userType.includes('Admin')){
          // }else if(storeData.userDetails.userType==='Admin'){
            navigate("/dashboardAdmin");
          // } else if(storeData.userDetails.userType==='RATeam') {
          } else if(storeData.userDetails.userType.includes('RATeam')) {
            navigate("/raDashboard");
          }else if(storeData.userDetails.userType.includes('RecruitmentTeam')){
          // }else if(storeData.userDetails.userType==='RecruitmentTeam'){
            navigate("/dashboardRecruiter")
          }else if(storeData.userDetails.userType.includes('Developer')){
          // }else if(storeData.userDetails.userType==='Developer'){
            navigate("/dashboardAdmin");
          }else if(storeData.userDetails.userType.includes('SuperAdmin')){
            navigate("/dashboardAdmin");
          }
        }
      } else {
        yield put(
          apiError({
            data: "Invalid credentials! Please try again or contact admin!",
          })
        );
      }
    } else {
      yield put(
        apiError({
          data: "Email ID doesn't exist, kindly contact admin!",
        })
      );
    }
  
  // Redirect the user after all logic is complete
}

function* getAllUsersSaga(action) {
  try {
    // Assuming formData is your FormData object

    const { response, timeout } = yield race({
      response: call(getAllUsersApi,action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      yield put(loadingViaRedux(true))
      yield put(getAllUsersSuccess(response));

      // Dispatch an action indicating successful profile save
      // yield put(createUserSuccess(response.account));
    } else {
      // yield new Promise((resolve) => {
      //   toast(response.error, {
      //     position: "top-right",
      //     hideProgressBar: false,
      //     autoClose: false, // Prevent automatic closing
      //     className: 'bg-danger text-white',
      //     onClose: () => resolve(),
      //   });
      // });
    }
  } catch (error) {
    console.error("Error:", error);
  }
}
function* logoutUserViaApiSaga(action) {
  try {
    const response = yield call(loginUserActiveViaApiSaga, action.payload);
    if (response) {
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }
      // if(response.message){
      //   yield put(setSnackbarMessage(response.message, "success"));
      // }
    } else {
      // yield new Promise((resolve) => {
      //   toast(response.error, {
      //     position: "top-right",
      //     hideProgressBar: false,
      //     autoClose: false, // Prevent automatic closing
      //     className: 'bg-danger text-white',
      //     onClose: () => resolve(),
      //   });
      // });
    }
  } catch (error) {
    console.error("Error:", error);
  }
}
function* updateUserViaSaga(action){
  try {
    const { response, timeout } = yield race({
      response: call(updateUserViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* updateUsersTypesViaSaga(action){
  try {
    const { response, timeout } = yield race({
      response: call(updateUsersTypesViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}
function* emailIntegrationViaSaga(action){
  try {
    const { response, timeout } = yield race({
      response: call(emailIntegrationViaApiSaga, action.payload), // Call the API function
      timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
    });
    if (response) {
      
      if (response.error === "An internal server error occurred") {
        yield put(setSnackbarMessage(response.error, "error"));
        return null;
      }

      if (response.error) {
        yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
        return null;
      } else if (response.message) {
        yield put(setSnackbarMessage(response.message, "success"));
      }
    }
  } catch (error) {}
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(GET_ALL_USERS, getAllUsersSaga);
  yield takeEvery(LOGOUT_USER, logoutUserViaApiSaga);
  yield takeEvery(UPDATE_USER_VIA_API,updateUserViaSaga);
  yield takeEvery(UPDATE_USERS_TYPES_VIA_API,updateUsersTypesViaSaga)
  yield takeEvery(EMAIL_INTEGRATION_VIA_API,emailIntegrationViaSaga)
}

export default authSaga;
