import { Badge, Box, Divider, Typography } from "@mui/material";
import React from "react";
import ScrollView from "../Helper/ScrollView";
import FormatDateTime from "../Helper/FormatDateTime";
import theme from "../../Theme";
import KeyData from "../Helper/KeyData";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useSelector } from "react-redux";

const CustomTimeline = ({ data }) => {
  // const sortedActivities = data.sort(
  //   (a, b) => new Date(b.onTime) - new Date(a.onTime)
  // );

  const allUsersData = useSelector((state) => state.Login.allUsers);
  const Applicants = useSelector((state) => state.Recruiters?.applicants);

  const applicantsArray = data.submissions.flatMap((list) => list.statusChanges.map(item => ({...item, applicantID: list.applicantId})));
  const dataArray = [...data.jobStatusArray, ...applicantsArray].sort((a,b)=> new Date(a.addedOn) -new Date(b.addedOn));

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
            lineHeight: 1,
            p: 0.5,
            px: 1,
            borderRadius: 4,
            fontSize: 11,
            fontWeight: 600,
          }}
        >
          {dataArray.length}
        </Box>

        <Typography
          sx={{
            fontSize: 11,
            letterSpacing: 0.2,
            color: theme.palette.grey[500],
          }}
        >
          from ({FormatDateTime(dataArray[0].addedOn, "short")}) to ({FormatDateTime(dataArray[dataArray.length - 1].addedOn, "short")})
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          //flexDirection: "column",
          gap: 2,
          overflow: "auto",
          pt: 3,
          pb: 3,
          // For Firefox
          scrollbarWidth: "thin",
          scrollbarColor: `${theme.palette.primary[900]} ${theme.palette.grey[100]}`,
          // For WebKit-based browsers (Chrome, Safari)
          "&::-webkit-scrollbar": {
            width: "8px", // Width of the scrollbar
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary[900], // Color of the scrollbar thumb
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.grey[100], // Track color
          },
        }}
      >
              {/* initial timeline box to display created timeline card  */}
              <Box
              sx={{
                width: "350px",
                minWidth: "350px",
                borderTop: `2px solid ${theme.palette.primary[300]}`,
                position: "relative",
                "&:before": {
                  content: `'${FormatDateTime(data.createdDate, "full")}'`, // Required to make the :before pseudo-element appear
                  display: "block",
                  height: "16px",
                  width: "fit-content",
                  borderRadius: 10,
                  py: 0.5,
                  px: 1.5,
                  border: "10px solid white",
                  backgroundColor: theme.palette.primary.dark,
                  mt: "-22px",
                  fontSize: 11,
                  letterSpacing: 0.2,
                  color: "white",
                },
              }}
            >
              <Box sx={{ pl: 2 }}>
                <Typography fontWeight={600}>Job Created</Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.grey[500],
                  }}
                >
                  {data.createdBy.userSudoFirstName} - {data.createdBy.userRole}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.primary.dark,
                    mt: 0.2,
                  }}
                >
                
                
                &nbsp;
                </Typography>
                <Divider sx={{ py: 0.5 }} />
                <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.grey[500],
                  }}
                >
                  Note: The job posting for the data analyst role has been closed after successfully filling the position.
                </Typography>
              </Box>
                <KeyboardDoubleArrowRightIcon
                  sx={{
                    color: theme.palette.primary[300],
                    position: "absolute",
                    right: -14,
                    top: -13,
                  }}
                />
            </Box>

        {dataArray?.slice(1).map((item, key) => {
         const userInfo = allUsersData.find((user) => user._id === item.addedBy);
          const applicantInfo= Applicants.find((user) => user._id === item.applicantID);
          let applicantName = [applicantInfo?.firstName, applicantInfo?.middleName,applicantInfo?.lastName].filter(Boolean).join(' ');

          return (
            <Box
              sx={{
                width: "350px",
                minWidth: "350px",
                borderTop: `2px solid ${theme.palette.primary[300]}`,
                position: "relative",
                "&:before": {
                  content: `'${FormatDateTime(item.addedOn, "full")}'`, // Required to make the :before pseudo-element appear
                  display: "block",
                  height: "16px",
                  width: "fit-content",
                  borderRadius: 10,
                  py: 0.5,
                  px: 1.5,
                  border: "10px solid white",
                  backgroundColor: theme.palette.primary.dark,
                  mt: "-22px",
                  fontSize: 11,
                  letterSpacing: 0.2,
                  color: "white",
                },
              }}
              key={key}
            >
              <Box sx={{ pl: 2 }}>
                {/* <Typography
                sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.grey[500],
                    }}
                    >
                    {FormatDateTime(item.onTime, "full")}
                    </Typography> */}
                <Typography fontWeight={600}>{item.status}</Typography>
                <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.grey[500],
                  }}
                >
                  {userInfo?.userSudoFirstName} {userInfo?.userRole && '-'} {userInfo?.userRole}
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.grey[500],
                  }}
                >
                  Applicant : {applicantName}
                </Typography> */}
                <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.primary.dark,
                    mt: 0.2,
                  }}
                >
                
               {applicantName ? ` Applicant :${applicantName}`: <>&nbsp;</>} 
                
                </Typography>
                <Divider sx={{ py: 0.5 }} />
                <Typography
                  sx={{
                    fontSize: 11,
                    letterSpacing: 0.2,
                    color: theme.palette.grey[500],
                  }}
                >
                  Note: The job posting for the data analyst role has been closed after successfully filling the position.
                  </Typography>
              </Box>

              {dataArray.length !== key + 1 && dataArray.length > 2 && (
                <KeyboardDoubleArrowRightIcon
                  sx={{
                    color: theme.palette.primary[300],
                    position: "absolute",
                    right: -14,
                    top: -13,
                  }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomTimeline;
