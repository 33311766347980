import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import React from 'react'
import SectionTitle from '../Helper/SectionTitle';
import KeyData from '../Helper/KeyData';

 const JobBoardsQuickSubmitConfirmModal = ({open,close,cofirm,jobId,applicantName}) => {
  return (
    <Modal
    open={open}
    //onClose={handleClose}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "400px",
        width: "80%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: {
          xs: 1,
          md: 2,
        },
        borderRadius: 2,
      }}
    >

      <Box sx={{display:'flex', flexDirection:"column", gap:2}}>
        <Box >
            <SectionTitle title='Are you sure you want to QuickSubmit'/>
        </Box>
        <Box sx={{display:'flex', flexDirection:"column", gap:1}}>
            <KeyData title={"Job ID"} value={jobId} fullContent/>
            <KeyData title={"AplicantName"} value={applicantName}/>
           
        </Box>
        <Divider/>
        <Box sx={{display:"flex", justifyContent:"end", gap:2}}>
        <Button
          color="error"
          onClick={() => close()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => cofirm()}
        >
          Confirm
        </Button>
        </Box>
      </Box>


      
    </Box>
  </Modal>
  )
}
export default JobBoardsQuickSubmitConfirmModal;