const degreesOptions = [
    "Bachelor of Science",
    "Industrial Design/Spatial Design",
    "Bachelor's Degree, Computer Science, 4.0",
    "Bachelor of Commerce, Business and Accounting(started)",
    "MA and Ph.D, Communication Theory",
    "+2, Commerce",
    "+2, Maths, Physics and Chemistry",
    "+2, Science",
    ".NET Development Certificate",
    "1 Year Certificate, CCNA Program",
    "12th Std, Computer Science",
    "12th Std, High School/Secondary Diplomas and Certificates",
    "2 year degree, Electronics and Computer Science",
    "2 yr AAS, Computer Programming",
    "2005 4GL, Database",
    "AA Level / AS Level / BTEC / NVQA Level, Information Technology",
    "A lot of mathematics and science and a lot of everything else, too.",
    "A+ Certification, Information Technology",
    "A+ Certification/Microsoft Certified Technology Specialist, Computer and Information Sciences and Support Services",
    "A+ TRAINING CERTIFICATE, Microcomputer Repair",
    "A.A, Journalism",
    "A.A.S, Networking Technology (NET)",
    "A.A.S., Information Technology",
    "A.A.S., Network Technology and Security",
    "A.Arts",
    "A.S, General Studies",
    "A.S. in Digital Media & Web Development",
    "A.S",
    "A.A.S., Business Administration",
    "AA",
    "AA Degree, Persian Farsi",
    "AA, Business Technology",
    "AA, Computer Technology",
    "AA, Data Processing",
    "AA, Electronics",
    "AA, Health, Science, and Math",
    "AA, Paralegal",
    "AAS, Computer & Information Science",
    "AAS, Computer and Electronic Engineering Technology",
    "AAS, Electronics Engineering Technology",
    "AAS, Industrial Electronics",
    "AB, Business/Computers",
    "Accountancy",
    "Accounting",
    "Accounting Technology/Technician and Bookkeeping",
    "Advanced Study Program, Electrical Engineering & Computer Science",
    "Advanced High School Diploma",
    "Advanced Web Design Certification, Web Design",
    "A+",
    "Aeronautic Engineering Technology",
    "Aeronautics/Aviation/Aerospace Science and Technology, General",
    "AGILE Certification, Agile Product Owner, Silver Certified",
    "Agile Project Management, Computer Science, Management",
    "Agricultural Economics",
    "Airecrew Life Support Specialist, Aircrew Life Support",
    "AMSP, Multimedia Specialization",
    "Architecture, Art History, Psychology, Structural Engineering",
    "Architecture/Mechanical Design",
    "Artificial Intelligence",
    "AS, Computer Animation/Multimedia",
    "AS, Multimedia",
    "ASEET, Electrical Engineering Technology",
    "Associate of Science in MIS",
    "Associate",
    "Associate’s Degree",
    "Associate Degree, Cisco Certified Network Associate",
    "Associate Degree, Computer Science & Business Administration",
    "Associate Degree in Audio/Video Production",
    "Associate Degree in Liberal Arts & Science",
    "Associate Degree, EEG (Electroencephalography)",
    "Associate Degree of Technology, Electricity/Electronics",
    "Associate Degree, General Studies",
    "Associate of Applied Science (ADN), Registered Nursing (RN)",
    "Associate of Applied Science, Computer Information Systems",
    "Associate of Arts",
    "Associate of Arts (A.A.), Architecture",
    "Associate of Arts (A.A.), Film & TV: Production, 3.82 GPA (Overall)",
    "Associate of Arts (A.A.), Liberal Arts and Sciences, General Studies and Humanities",
    "Associate of Arts - AA, Business, Management, Marketing, and Related Support Services",
    "Associate of Arts and Sciences (A.A.S.)",
    "Associate of Arts and Sciences (A.A.S.), Business Administration",
    "Associate of Arts and Sciences (A.A.S.), Web Development",
    "Associate of Arts and Sciences (AAS) - Business Administration",
    "Associate of Arts and Sciences (AAS), Computer Science",
    "Associate of Arts and Sciences (AAS), Computer Science - Cyber Security",
    "Associate of Arts and Sciences (AAS), Data Processing",
    "Associate of Arts and Sciences (AAS), Information Technology",
    "Associate of Arts, Accounting",
    "Associate of Arts, Banking and Finance",
    "Associate of Science (A.S.) Information Technology",
    "Associate of Science (A.S.), Biology, General",
    "Associate of Science (A.S.), Computer and Information Sciences",
    "Associate of Science (A.S.), Computer Engineering",
    "Associate of Science (A.S.), Computer Engineering Technology/Technician, 3.0",
    "Associate of Science (A.S.), Computer Information Systems, Management",
    "Associate of Science (A.S.), Digital Media",
    "Associate of Science - AS",
    "Associate of Science - AS, Animation and Motion Graphics",
    "Associate of Science, Business Administration",
    "Associate of Science, Design",
    "Associate’s Degree, Business Administration and Management",
    "Associate’s Degree, Diploma in Accounting & Finance",
    "Associate’s Degree, Accounting",
    "Associate’s Degree, Chemical Engineering",
    "Associate’s Degree, Civil Engineering Technology/Technician",
    "Associate’s Degree, Computer and Information Sciences and Support",
    "Associate’s Degree, Computer Information Systems, 3.9",
    "Associate’s Degree, Computer Systems Networking and Administration",
    "Associate’s Degree, Computer Technology/Computer Systems Technology",
    "Associate’s Degree, Computer/Information Technology Administration",
    "Associate’s Degree, Culinary Arts and Related Services",
    "Associate’s Degree, Data Processing",
    "Associate’s Degree, Database Management",
    "Associate’s Degree, Digital Communication and Media/Multimedia",
    "Associate’s Degree, General Studies, 4.8",
    "Associate’s Degree, Information Technology",
    "Associate’s Degree, Information Technology - Computer Network Systems, 3.5 GPA",
    "Associate’s Degree, Liberal Arts and Sciences/Liberal Studies",
    "Associate’s Degree, Mechanical Drafting and Pipe Drafting CAD/CADD, 2.9 GPA",
    "Associate’s Degree, Network Specialist, 3.7",
    "Associate’s Degree, Specialized Business, 4.0",
    "Associate’s in Applied Science, Digital and Microprocessor Technology",
    "Associate, Applied Business Computer Information Systems",
    "Associate, Business Administration & Economics",
    "Associate, Electronic Engineering",
    "Associate, Graphic Design",
    "Associates Degree",
    "Associates in Applied Science, Software Development Technology",
    "Associates in Arts Degree, Business Administration",
    "Associates Not Completed",
    "Associates of Applied Science"
];
const currencyCodes =["USD", "INR", "CAD"]
const payScheduleTypes =["Hourly","Daily","Weekly","Bi-Weekly","Monthly","Yearly"]
const employmentTypes =["1099","C2C","C2C","Full Time","In-cop","Intern","Other","Part Time","W-2"]
const remoteJobOptions=["Yes","No","Hybrid"]
const jobStatusOptions=["Active","Closed","Filled","Hold by Client","On Hold"]
const jobTypeOptions=["Full Time","Cantract","Part Time"]
const requiredDocumentsOptions=
[
"Driving License",
"EML File",
"Passport",
"Resume",
"SSN",
"Transcripts"
]
const turnaroundTimeOptions=["In Hours","In Days"]
const priorityOptionsOptions=["Critical","High","Low","Medium"]
const workAuthorizationOptions=
[
"B1",
"Bangladesh Authorized", "Brazil Authorized",
"Can work for any employer",
"Canada Authorized",
"Canadian Citizen",
"Canadian Citizenship",
"China Authorized",
"Citizen",
"Colombia Authorized",
"Current Employer Only",
"Employment Auth Document",
"Employment Auth. Document",
"Employment Authorization Document",
"EU Eligible",
"GC",
"GC-EAD",
"Germany Authorized",
"Green Card",
"Green Card Holder",
"H1-B",
"H4 EAD",
"Have H1",
"Have H1 Visa",
"India Authorized",
"Ireland Authorized",
"Italy Authorized",
"Kuwait Authorized",
"L1-A",
"L1-B",
"L2-EAD",
"Luxembourg Authorized",
"Macedonia Authorized",
"Mexico Authorized",
"Need H1",
"Need H1 Visa",
"Need H1 Visa Sponsor",
"Not specified",
"OPT-EAD",
"Pakistan Authorized",
"Poland Authorized",
"Russian Federation Authorized",
"South Africa Authorized",
"Sponsorship Required",
"TN Permit Holder",
"TN Visa",
"Turkey Authorized",
"Ukraine Authorized",
"United Arab Emirates Authorized",
"United Kingdom Authorized",
"US Authorized",
"US Citizen",
"US Citizenship"
]
const industryOptions=[
    "Accounting - Finance",
    "Advertising",
    "Agriculture",
    "Airline - Aviation",
    "Architecture - Building",
    "Art - Photography - Journalism",
    "Automotive - Motor Vehicles - Parts",
    "Banking - Financial Services",
    "Biotechnology",
    "Broadcasting - Radio - TV",
    "Building Materials",
    "Chemical",
    "Computer Hardware",
    "Computer Software",
    "Construction",
    "Consulting",
    "Consumer Products",
    "Credit - Loan - Collections",
    "Defense - Aerospace",
    "Education - Teaching - Administration",
    "Electronics",
    "Employment - Recruiting - Staffing",
    "Energy - Utilities - Gas - Electric",
    "Entertainment",
    "Environmental",
    "Exercise - Fitness",
    "Fashion - Apparel - Textile",
    "Food",
    "Funeral - Cemetery",
    "Government - Civil Service",
    "Healthcare - Health Services",
    "Homebuilding",
    "Hospitality",
    "Hotel - Resort",
    "HVAC",
    "Import - Export",
    "Industrial",
    "Insurance",
    "Internet - ECommerce",
    "Landscaping",
    "Law Enforcement",
    "Legal",
    "Library Science",
    "Managed Care",
    "Manufacturing",
    "Medical Equipment",
    "Merchandising",
    "Military",
    "Mortgage",
    "Newspaper",
    "Not for Profit - Charitable",
    "Office Supplies - Equipment",
    "Oil Refining - Petroleum - Drilling",
    "Other Great Industries",
    "Packaging",
    "Pharmaceutical",
    "Printing - Publishing",
    "Public Relations",
    "Real Estate - Property Mgt",
    "Recreation",
    "Restaurant",
    "Retail",
    "Sales - Marketing",
    "Securities",
    "Security",
    "Semiconductor",
    "Social Services",
    "Telecommunications",
    "Training",
    "Transportation",
    "Travel",
    "Wireless"
    ]
    const languagesOptions=
    [
        "Arabic",
        "Chinese",
        "English",
        "French",
        "German",
        "Hindi",
        "Italian",
        "Japanese",
        "Korean",
        "Mandarin",
        "Persian",
        "Portuguese",
        "Russain",
        "Spanish",
        "Swedish",
        "Trukish",
        "Vietnamese"
        ]
        const taxTermsOptions=[
            "1099",
            "C2C",
            "C2H",
            "Full Time",
            "In-cop",
            "Intern",
            "OtherPart",
            "Time",
            "W-2"
            ]
         const  states = [
                "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", 
                "Connecticut","District of Columbia", "Delaware", "Florida", "Georgia", "Hawaii","Iowa", "Idaho", 
                "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", 
                "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", 
                "Mississippi", "Missouri", "Montana","North Carolina","North Dakota", "Nebraska", "Nevada", "New Hampshire", 
                "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", 
                "Ohio", "Oklahoma", "Oregon","Puerto Rico", "Pennsylvania", "Rhode Island", 
                "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", 
                "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming","U.S. Virgin Islands",
              ];
              const countries = [
                "Afghanistan", "Aland Islands", "Albania", "Algeria", "American Samoa", 
                "Andorra", "Angola", "Anguilla", "Antarctica", "Antigua and Barbuda", 
                "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", 
                "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", 
                "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia and Herzegovina", 
                "Botswana", "Bouvet Island", "Brazil", "British Indian Ocean Territory","British Virgin Islands", 
                "Brunei Darussalam", "Bulgaria", "Burkina Faso", "Burundi","CA", "Cabo Verde", 
                "Cambodia", "Cameroon", "Canada", "Cayman Islands", "Central African Republic", 
                "Chad", "Chile", "China", "Christmas Island", "Cocos (Keeling) Islands", 
                "Colombia", "Comoros", "Congo", "Congo (Democratic Republic)", "Cook Islands", 
                "Costa Rica", "Croatia", "Cuba", "Curaçao", "Cyprus", "Czech Republic", 
                "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", 
                "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", 
                "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", 
                "France", "French Guiana", "French Polynesia", "French Southern Territories", 
                "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Gibraltar", 
                "Greece", "Greenland", "Grenada", "Guadeloupe", "Guam", "Guatemala", 
                "Guernsey", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Heard Island and McDonald Islands", 
                "Holy See", "Honduras", "Hong Kong", "Hungary", "Iceland", "India", 
                "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", 
                "Italy", "Ivory Coast", "Jamaica", "Japan", "Jersey", "Jordan", 
                "Kazakhstan", "Kenya", "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", 
                "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", 
                "Lithuania", "Luxembourg", "Macao", "Madagascar", "Malawi", "Malaysia", 
                "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", "Mauritania", 
                "Mauritius", "Mayotte", "Mexico", "Micronesia", "Moldova", "Monaco", 
                "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", 
                "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Caledonia", 
                "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", 
                "North Korea", "North Macedonia", "Northern Mariana Islands", "Norway", 
                "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", 
                "Paraguay", "Peru", "Philippines", "Pitcairn Islands", "Poland", 
                "Portugal", "Puerto Rico", "Qatar", "Réunion", "Romania", "Russia", 
                "Rwanda", "Saint Barthélemy", "Saint Helena", "Saint Kitts and Nevis", 
                "Saint Lucia", "Saint Martin", "Saint Pierre and Miquelon", 
                "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
                "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", 
                "Seychelles", "Sierra Leone", "Singapore", "Sint Maarten", "Slovakia", 
                "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Georgia", 
                "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", 
                "Suriname", "Svalbard and Jan Mayen", "Sweden", "Switzerland", 
                "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", 
                "Togo", "Tokelau", "Tonga", "Trinidad and Tobago", "Tunisia", 
                "Turkey", "Turkmenistan", "Turks and Caicos Islands", "Tuvalu", 
                "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
                "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Venezuela", 
                "Vietnam", "Wallis and Futuna", "Western Sahara", "Yemen", "Zambia", "Zimbabwe"
              ];
const CBRadius=["5","15","30","50","100","250","500"]
const MonsterRadius=["5","10","20","30","40",
  "50","60","75","100","150","200"]
  const optionsMonsterPostFrom = [
    { label: "Today", value: 0 },
    { label: "1 day", value: 1440 },
    { label: "2 days", value: 2880 },
    { label: "3 days", value: 4320 },
    { label: "1 week", value: 8640 },
    { label: "2 weeks", value: 18720 },
    { label: "1 month", value: 41760 },
    { label: "3 months", value: 128160 },
    { label: "6 months", value: 257760 },
    { label: "9 months", value: 387360 },
];
const optionsMonsterTo = [
  { label: "Today", value: 1440 },
  { label: "1 day", value: 2880 },
  { label: "2 days", value: 4320 },
  { label: "3 days", value: 5760 },
  { label: "1 week", value: 10080 },
  { label: "2 weeks", value: 20160 },
  { label: "1 month", value: 43200 },
  { label: "3 months", value: 129600 },
  { label: "6 months", value: 259200 },
  { label: "9 months", value: 388800 },
  { label: "All Resumes", value: 1576800 },
];
const countryMonsterOptions = [
  { label: "United States", value: 840},
  { label: "Canada", value: 124 },
];
const MonsterRadiusOptions = [
  { label: "5 miles away", value: 5 },
  { label: "10 miles away", value: 10 },
  { label: "20 miles away", value: 20 },
  { label: "30 miles away", value: 30 },
  { label: "40 miles away", value: 40 },
  { label: "50 miles away", value: 50 },
  { label: "60 miles away", value: 60 },
  { label: "75 miles away", value: 75 },
  { label: "100 miles away", value: 100 },
  { label: "150 miles away", value: 150 },
  { label: "200 miles away", value: 200 },
];
const MonsterstateData = [
  { value: "3681", label: "Alabama" },
  { value: "3680", label: "Alaska" },
  { value: "3683", label: "Arizona" },
  { value: "3682", label: "Arkansas" },
  { value: "3684", label: "California" },
  { value: "3685", label: "Colorado" },
  { value: "3686", label: "Connecticut" },
  { value: "3688", label: "Delaware" },
  { value: "3687", label: "District of Columbia" },
  { value: "3689", label: "Florida" },
  { value: "3690", label: "Georgia" },
  { value: "3691", label: "Hawaii" },
  { value: "3693", label: "Idaho" },
  { value: "3694", label: "Illinois" },
  { value: "3695", label: "Indiana" },
  { value: "3692", label: "Iowa" },
  { value: "3696", label: "Kansas" },
  { value: "3697", label: "Kentucky" },
  { value: "3698", label: "Louisiana" },
  { value: "3701", label: "Maine" },
  { value: "3700", label: "Maryland" },
  { value: "3699", label: "Massachusetts" },
  { value: "3702", label: "Michigan" },
  { value: "3703", label: "Minnesota" },
  { value: "3705", label: "Mississippi" },
  { value: "3704", label: "Missouri" },
  { value: "3706", label: "Montana" },
  { value: "3709", label: "Nebraska" },
  { value: "3713", label: "Nevada" },
  { value: "3710", label: "New Hampshire" },
  { value: "3711", label: "New Jersey" },
  { value: "3712", label: "New Mexico" },
  { value: "3714", label: "New York" },
  { value: "3707", label: "North Carolina" },
  { value: "3708", label: "North Dakota" },
  { value: "3715", label: "Ohio" },
  { value: "3716", label: "Oklahoma" },
  { value: "3717", label: "Oregon" },
  { value: "3718", label: "Pennsylvania" },
  { value: "3719", label: "Rhode Island" },
  { value: "3720", label: "South Carolina" },
  { value: "3721", label: "South Dakota" },
  { value: "3722", label: "Tennessee" },
  { value: "3723", label: "Texas" },
  { value: "3724", label: "Utah" },
  { value: "3726", label: "Vermont" },
  { value: "3725", label: "Virginia" },
  { value: "3727", label: "Washington" },
  { value: "3729", label: "West Virginia" },
  { value: "3728", label: "Wisconsin" },
  { value: "3730", label: "Wyoming" },
];
const CareerBuilderRadius=[
  { label: "5 Miles to Location", value: "5" },
  { label: "15 Miles to Location", value: "15" },
  { label: "30 Miles to Location", value: "30" },
  { label: "50 Miles to Location", value: "50" },
  { label: "100 Miles to Location", value: "100" },
  { label: "250 Miles to Location", value: "250" },
  { label: "500 Miles to Location", value: "500" },
  { label: "By Area", value: "-1" }
]
const MonsterEducationLevel=[
  { label: "Some High School Coursework", value: "12" },
  { label: "High School or equivalent", value: "1" },
  { label: "Certification", value: "2" },
  { label: "Vocational", value: "3" },
  { label: "Some College Coursework Completed", value: "9" },
  { label: "Associate Degree", value: "4" },
  { label: "Bachelor's Degree", value: "5" },
  { label: "Master's Degree", value: "6" },
  { label: "Doctorate", value: "7" },
  { label: "Professional", value: "8" }
]
const salaryTypeMonsterOptions=[
  { label: "Per Year", value: "1" },
  { label: "Per Hour", value: "2" },
  { label: "Per Week", value: "3" },
  { label: "Per Month", value: "4" },
  { label: "Biweekly", value: "5" },
  { label: "Per Day", value: "6" },
  { label: "Quarter", value: "7" },
  { label: "Other", value: "8" },
  { label: "Piece Rate", value: "9" }
]

const LanguageskillsMonster= [
  { "label": "Aboriginal Dialects", "value": "1" },
  { "label": "Afrikaans", "value": "2" },
  { "label": "American Sign Language", "value": "3" },
  { "label": "Ancient Greek", "value": "4" },
  { "label": "Arabic", "value": "5" },
  { "label": "Assamese", "value": "6" },
  { "label": "Australian Sign Language", "value": "7" },
  { "label": "Bahasa Indonesia", "value": "8" },
  { "label": "Basque", "value": "9" },
  { "label": "Bengali", "value": "10" },
  { "label": "Brazilian Portuguese", "value": "11" },
  { "label": "Breton", "value": "12" },
  { "label": "British Sign Language", "value": "13" },
  { "label": "Bulgarian", "value": "14" },
  { "label": "Burmese", "value": "15" },
  { "label": "Catalan", "value": "16" },
  { "label": "Chinese", "value": "17" },
  { "label": "Chinese - Cantonese", "value": "18" },
  { "label": "Chinese - Mandarin", "value": "19" },
  { "label": "Chinese - Taiwanese", "value": "20" },
  { "label": "Corsican", "value": "21" },
  { "label": "Croatian", "value": "22" },
  { "label": "Czech", "value": "23" },
  { "label": "Danish", "value": "24" },
  { "label": "Dutch", "value": "25" },
  { "label": "Egyptian", "value": "26" },
  { "label": "English", "value": "27" },
  { "label": "Estonian", "value": "28" },
  { "label": "Farsi", "value": "29" },
  { "label": "Finnish", "value": "30" },
  { "label": "French", "value": "32" },
  { "label": "Frisian", "value": "33" },
  { "label": "Gaelic", "value": "34" },
  { "label": "German", "value": "35" },
  { "label": "Greek", "value": "36" },
  { "label": "Greenlandic", "value": "37" },
  { "label": "Gujarati", "value": "38" },
  { "label": "Hebrew", "value": "39" },
  { "label": "Hindi", "value": "40" },
  { "label": "Hindustan", "value": "41" },
  { "label": "Hmong", "value": "42" },
  { "label": "Hungarian", "value": "43" },
  { "label": "Icelandic", "value": "44" },
  { "label": "Irish", "value": "45" },
  { "label": "Irish Gaelic", "value": "46" },
  { "label": "Italian", "value": "47" },
  { "label": "Japanese", "value": "48" },
  { "label": "Kannada", "value": "49" },
  { "label": "Kashmiri", "value": "50" },
  { "label": "Korean", "value": "51" },
  { "label": "Kurdish", "value": "52" },
  { "label": "Latin", "value": "53" },
  { "label": "Latvian", "value": "54" },
  { "label": "Lithuanian", "value": "55" },
  { "label": "Malay", "value": "56" },
  { "label": "Malayalam", "value": "57" },
  { "label": "Manipuri", "value": "58" },
  { "label": "Manx Gaelic", "value": "59" },
  { "label": "Maori", "value": "60" },
  { "label": "Marathi", "value": "61" },
  { "label": "Nepali", "value": "62" },
  { "label": "Norwegian", "value": "63" },
  { "label": "Oriya", "value": "64" },
  { "label": "Other", "value": "65" },
  { "label": "Polish", "value": "66" },
  { "label": "Portuguese", "value": "67" },
  { "label": "Prakrit", "value": "68" },
  { "label": "Punjabi", "value": "69" },
  { "label": "Romanian", "value": "70" },
  { "label": "Russian", "value": "71" },
  { "label": "Sanskrit", "value": "72" },
  { "label": "Scots", "value": "73" },
  { "label": "Scots Gaelic", "value": "74" },
  { "label": "Serbian", "value": "75" },
  { "label": "Sicilian", "value": "76" },
  { "label": "Slovak", "value": "77" },
  { "label": "Slovene", "value": "78" },
  { "label": "Spanish", "value": "79" },
  { "label": "Swahili", "value": "80" },
  { "label": "Swedish", "value": "81" },
  { "label": "Tagalog", "value": "82" },
  { "label": "Tamil", "value": "83" },
  { "label": "Telugu", "value": "84" },
  { "label": "Thai", "value": "85" },
  { "label": "Tibetan", "value": "86" },
  { "label": "Turkish", "value": "87" },
  { "label": "Ukrainian", "value": "88" },
  { "label": "Urdu", "value": "89" },
  { "label": "Vietnamese", "value": "90" },
  { "label": "Welsh", "value": "91" },
  { "label": "Yiddish", "value": "92" },
  { "label": "Ainu", "value": "93" },
  { "label": "Akkadian", "value": "94" },
  { "label": "Alurian", "value": "95" },
  { "label": "Arkian", "value": "96" },
  { "label": "Assyrian", "value": "97" },
  { "label": "Asturian", "value": "98" },
  { "label": "Aymara", "value": "99" },
  { "label": "Basque Language-Euskara", "value": "100" },
  { "label": "Berber", "value": "101" },
  { "label": "Buhi", "value": "102" },
  { "label": "Cherokee", "value": "103" },
  { "label": "Chichewa", "value": "104" },
  { "label": "Church Slavonic", "value": "105" },
  { "label": "Cornish", "value": "106" },
  { "label": "Dakota", "value": "107" },
  { "label": "Degaspregos", "value": "108" },
  { "label": "Dilhok", "value": "109" },
  { "label": "Dongxiang", "value": "110" },
  { "label": "Esperanto", "value": "111" },
  { "label": "Eurolang", "value": "112" },
  { "label": "Faroese", "value": "113" },
  { "label": "Friulian", "value": "114" },
  { "label": "Galician", "value": "115" },
  { "label": "Georgian", "value": "116" },
  { "label": "Guarani", "value": "117" },
  { "label": "Haponish", "value": "118" },
  { "label": "Hausa", "value": "119" },
  { "label": "Hawaiian", "value": "120" },
  { "label": "Hawaiian Pidgin English", "value": "121" },
  { "label": "Ido", "value": "122" },
  { "label": "Ingush", "value": "123" },
  { "label": "Interlingua", "value": "124" },
  { "label": "Irish", "value": "125" },
  { "label": "Kijawi", "value": "126" },
  { "label": "Kinyarwanda", "value": "127" },
  { "label": "Kirghiz", "value": "128" },
  { "label": "Kuku-Yalanji", "value": "129" },
  { "label": "Lao", "value": "130" },
  { "label": "Lingala", "value": "131" },
  { "label": "Lithuanian", "value": "132" },
  { "label": "Low German", "value": "133" },
  { "label": "Luxembourgish", "value": "134" },
  { "label": "Macedonian", "value": "135" },
  { "label": "Malagasy", "value": "136" },
  { "label": "Maltese", "value": "137" },
  { "label": "Māori", "value": "138" },
  { "label": "Masai", "value": "139" },
  { "label": "Minangkabau", "value": "140" },
  { "label": "Mizoram", "value": "141" },
  { "label": "Mongolian", "value": "142" },
  { "label": "Nahuatl", "value": "143" },
  { "label": "Navajo", "value": "144" },
  { "label": "Ndebele", "value": "145" },
  { "label": "Nigerian Pidgin", "value": "146" },
  { "label": "Pashto", "value": "147" },
  { "label": "Persian", "value": "148" },
  { "label": "Quechua", "value": "149" },
  { "label": "Quechua Language", "value": "150" },
  { "label": "Romanian", "value": "151" },
  { "label": "Sango", "value": "152" },
  { "label": "Sardinian", "value": "153" },
  { "label": "Serbo-Croatian", "value": "154" },
  { "label": "Sesotho", "value": "155" },
  { "label": "Sinhalese", "value": "156" },
  { "label": "Somali", "value": "157" },
  { "label": "Sotho", "value": "158" },
  { "label": "Spanish", "value": "159" },
  { "label": "Swahili", "value": "160" },
  { "label": "Tajik", "value": "161" },
  { "label": "Tigrinya", "value": "162" },
  { "label": "Tigrinya Language", "value": "163" },
  { "label": "Tok Pisin", "value": "164" },
  { "label": "Twi", "value": "165" },
  { "label": "Uighur", "value": "166" },
  { "label": "Ukrainian", "value": "167" },
  { "label": "Uzbek", "value": "168" },
  { "label": "Warlpiri", "value": "169" },
  { "label": "Welsh", "value": "170" },
  { "label": "Xhosa", "value": "171" },
  { "label": "Yoruba", "value": "172" },
  { "label": "Zulu", "value": "173" }
]
const ProfessionalLevelMonsterOptions= [
  { "label": "Beginner", "value": "1" },
  { "label": "Intermediate", "value": "3" },
  { "label": "Advanced", "value": "5" },
  { "label": "Fluent", "value": "6" },
  { "label": "Unknown", "value": "0" }
]
const careerLevelMonster=[
  { "label": "Student (High School)", "value": "10" },
  { "label": "Entry Level", "value": "11" },
  { "label": "Experienced (Non-Manager)", "value": "12" },
  { "label": "Manager (Manager/Supervisor of Staff)", "value": "13" },
  { "label": "Executive (SVP, VP, Department Head, etc)", "value": "14" },
  { "label": "Senior Executive (President, CFO, etc)", "value": "15" }
]
const jonTypeMonster= [
  { "label": "Employee", "value": "1" },
  { "label": "Intern", "value": "3" },
  { "label": "Temporary/Contract/Project", "value": "2" },
  { "label": "Seasonal", "value": "20" },
]
const indusriesMonsterOptions=[
  { "label": "Agriculture/Forestry/Fishing", "value": "1" },
  { "label": "Metals and Minerals", "value": "2" },
  { "label": "Energy and Utilities", "value": "3" },
  { "label": "Construction - Industrial Facilities and Infrastructure", "value": "4" },
  { "label": "Trade Contractors", "value": "5" },
  { "label": "Aerospace and Defense", "value": "6" },
  { "label": "Automotive and Parts Mfg", "value": "7" },
  { "label": "Biotechnology/Pharmaceuticals", "value": "8" },
  { "label": "Chemicals/Petro-Chemicals", "value": "9" },
  { "label": "Consumer Packaged Goods Manufacturing", "value": "10" },
  { "label": "Electronics, Components, and Semiconductor Mfg", "value": "11" },
  { "label": "Manufacturing - Other", "value": "12" },
  { "label": "Printing and Publishing", "value": "13" },
  { "label": "Clothing and Textile Manufacturing", "value": "14" },
  { "label": "Wholesale Trade/Import-Export", "value": "15" },
  { "label": "Grocery/Convenience/Gas", "value": "16" },
  { "label": "Retail", "value": "17" },
  { "label": "Travel, Transportation and Tourism", "value": "18" },
  { "label": "Transport and Storage - Materials", "value": "19" },
  { "label": "Internet Services", "value": "20" },
  { "label": "Broadcasting, Music, and Film", "value": "21" },
  { "label": "Telecommunications Services", "value": "22" },
  { "label": "Banking", "value": "23" },
  { "label": "Insurance", "value": "24" },
  { "label": "Mortgage", "value": "25" },
  { "label": "Real Estate/Property Management", "value": "26" },
  { "label": "Rental Services", "value": "27" },
  { "label": "Accounting and Auditing Services", "value": "28" },
  { "label": "Advertising and PR Services", "value": "29" },
  { "label": "Architectural and Design Services", "value": "30" },
  { "label": "Management Consulting Services", "value": "31" },
  { "label": "Computer Hardware", "value": "32" },
  { "label": "Computer Software", "value": "33" },
  { "label": "Legal Services", "value": "34" },
  { "label": "Management and Holding Companies", "value": "35" },
  { "label": "Administrative and Support Services", "value": "36" },
  { "label": "Waste Management", "value": "37" },
  { "label": "Education", "value": "38" },
  { "label": "Healthcare Services", "value": "39" },
  { "label": "Nursing/Residential Care Facilities", "value": "40" },
  { "label": "Social Services", "value": "41" },
  { "label": "Performing and Fine Arts", "value": "42" },
  { "label": "Sports and Physical Recreation", "value": "43" },
  { "label": "Hotels and Lodging", "value": "44" },
  { "label": "Restaurant/Food Services", "value": "45" },
  { "label": "Staffing/Employment Agencies", "value": "46" },
  { "label": "Nonprofit Charitable Organizations", "value": "47" },
  { "label": "Personal and Household Services", "value": "48" },
  { "label": "Repair and Maintenance Services", "value": "49" },
  { "label": "Government and Military", "value": "50" },
  { "label": "Government- Military/Defense", "value": "51" },
  { "label": "Government- National", "value": "52" },
  { "label": "Security and Surveillance", "value": "74" },
  { "label": "Automotive Sales and Repair Services", "value": "75" },
  { "label": "Business Services - Other", "value": "76" },
  { "label": "Computer/IT Services", "value": "77" },
  { "label": "Construction - Residential & Commercial/Office", "value": "78" },
  { "label": "Engineering Services", "value": "79" },
  { "label": "Entertainment Venues and Theaters", "value": "80" },
  { "label": "Financial Services", "value": "81" },
  { "label": "Food and Beverage Production", "value": "82" },
  { "label": "Marine Mfg & Services", "value": "83" },
  { "label": "Medical Devices and Supplies", "value": "84" },
  { "label": "Other/Not Classified", "value": "85" }
]
const statesArrayOptionMonster = [
  { label: 'Alabama', value: 322 },
  { label: 'Alaska', value: 317 },
  { label: 'Arizona', value: 336 },
  { label: 'Arkansas', value: 330 },
  { label: 'California', value: 342 },
  { label: 'Colorado', value: 360 },
  { label: 'Connecticut', value: 366 },
  { label: 'Delaware', value: 372 },
  { label: 'District of Columbia', value: 696 },
  { label: 'Florida', value: 376 },
  { label: 'Georgia', value: 392 },
  { label: 'Hawaii', value: 400 },
  { label: 'Idaho', value: 411 },
  { label: 'Illinois', value: 415 },
  { label: 'Indiana', value: 425 },
  { label: 'Iowa', value: 403 },
  { label: 'Kansas', value: 432 },
  { label: 'Kentucky', value: 438 },
  { label: 'Louisiana', value: 444 },
  { label: 'Maine', value: 461 },
  { label: 'Maryland', value: 456 },
  { label: 'Massachusetts', value: 452 },
  { label: 'Michigan', value: 466 },
  { label: 'Minnesota', value: 477 },
  { label: 'Mississippi', value: 498 },
  { label: 'Missouri', value: 486 },
  { label: 'Montana', value: 504 },
  { label: 'Nebraska', value: 524 },
  { label: 'Nevada', value: 539 },
  { label: 'New Hampshire', value: 683 },
  { label: 'New Jersey', value: 682 },
  { label: 'New Mexico', value: 536 },
  { label: 'New York', value: 543 },
  { label: 'North Carolina', value: 511 },
  { label: 'North Dakota', value: 520 },
  { label: 'Oklahoma', value: 568 },
  { label: 'Oregon', value: 573 },
  { label: 'Ohio', value: 557},
  { label: 'Pennsylvania', value: 580 },
  { label: 'Rhode Island', value: 590 },
  { label: 'South Carolina', value: 592 },
  { label: 'South Dakota', value: 597 },
  { label: 'Tennessee', value: 602 },
  { label: 'Texas', value: 609 },
  { label: 'Utah', value: 629 },
  { label: 'Vermont', value: 642 },
  { label: 'Virginia', value: 631 },
  { label: 'Washington', value: 645 },
  { label: 'West Virginia', value: 660 },
  { label: 'Wisconsin', value: 650 },
  { label: 'Wyoming', value: 666 },
  { label: 'Puerto Rico', value: 315 },
  { label: 'Virgin Islands', value: 641 },
  { label: 'Armed Forces Americas', value: 32146 },
  { label: 'Armed Forces Europe, the Middle East, and Canada', value: 32145 },
  { label: 'Armed Forces Pacific', value: 32147 },
  { label: 'Federated States of Micronesia', value: 54897 },
  { label: 'Northern Mariana Islands', value: 54898 },
  
];
const countryOptionsIntegrationSearch = [
  { label: 'United States', value: 840 },
  { label: 'India', value: 356 },
  { label: 'Canada', value: 124 },
  { label: 'Afghanistan', value: 4 },
  { label: 'Aland Islands', value: 248 },
  { label: 'Albania', value: 8 },
  { label: 'Algeria', value: 12 },
  { label: 'American Samoa', value: 16 },
  { label: 'Andorra', value: 20 },
  { label: 'Angola', value: 24 },
  { label: 'Anguilla', value: 660 },
  { label: 'Antigua and Barbuda', value: 28 },
  { label: 'Argentina', value: 32 },
  { label: 'Armenia', value: 51 },
  { label: 'Aruba', value: 533 },
  { label: 'Australia', value: 36 },
  { label: 'Austria', value: 40 },
  { label: 'Azerbaijan', value: 31 },
  { label: 'Bahamas', value: 44 },
  { label: 'Bahrain', value: 48 },
  { label: 'Bangladesh', value: 50 },
  { label: 'Barbados', value: 52 },
  { label: 'Belarus', value: 112 },
  { label: 'Belgium', value: 56 },
  { label: 'Belize', value: 84 },
  { label: 'Benin', value: 204 },
  { label: 'Bermuda', value: 60 },
  { label: 'Bhutan', value: 64 },
  { label: 'Bolivia', value: 68 },
  { label: 'Bosnia and Herzegovina', value: 70 },
  { label: 'Botswana', value: 72 },
  { label: 'Bouvet Island', value: 74 },
  { label: 'Brazil', value: 76 },
  { label: 'British Indian Ocean Territory', value: 86 },
  { label: 'British Virgin Islands', value: 92 },
  { label: 'Brunei', value: 96 },
  { label: 'Bulgaria', value: 100 },
  { label: 'Burkina Faso', value: 854 },
  { label: 'Burundi', value: 108 },
  { label: 'CA', value: 896 },
  { label: 'Cambodia', value: 116 },
  { label: 'Cameroon', value: 120 },
  { label: 'Cape Verde', value: 132 },
  { label: 'Cayman Islands', value: 136 },
  { label: 'Central African Republic', value: 140 },
  { label: 'Chad', value: 148 },
  { label: 'Chile', value: 152 },
  { label: 'China', value: 156 },
  { label: 'Christmas Island', value: 162 },
  { label: 'Cocos Islands', value: 166 },
  { label: 'Colombia', value: 170 },
  { label: 'Comoros', value: 174 },
  { label: 'Cook Islands', value: 184 },
  { label: 'Costa Rica', value: 188 },
  { label: 'Croatia', value: 191 },
  { label: 'Cuba', value: 192 },
  { label: 'Cyprus', value: 196 },
  { label: 'Czech Republic', value: 203 },
  { label: 'Democratic Republic of the Congo', value: 180 },
  { label: 'Denmark', value: 208 },
  { label: 'Djibouti', value: 262 },
  { label: 'Dominica', value: 212 },
  { label: 'Dominican Republic', value: 214 },
  { label: 'East Timor', value: 626 },
  { label: 'Ecuador', value: 218 },
  { label: 'Egypt', value: 818 },
  { label: 'El Salvador', value: 222 },
  { label: 'Equatorial Guinea', value: 226 },
  { label: 'Eritrea', value: 232 },
  { label: 'Estonia', value: 233 },
  { label: 'Ethiopia', value: 231 },
  { label: 'Falkland Islands', value: 238 },
  { label: 'Faroe Islands', value: 234 },
  { label: 'Fiji', value: 242 },
  { label: 'Finland', value: 246 },
  { label: 'France', value: 250 },
  { label: 'French Guiana', value: 254 },
  { label: 'French Polynesia', value: 258 },
  { label: 'French Southern Territories', value: 260 },
  { label: 'Gabon', value: 266 },
  { label: 'Gambia', value: 270 },
  { label: 'Georgia', value: 268 },
  { label: 'Germany', value: 276 },
  { label: 'Ghana', value: 288 },
  { label: 'Gibraltar', value: 292 },
  { label: 'Glendale, California', value: 895 },
  { label: 'Greece', value: 300 },
  { label: 'Greenland', value: 304 },
  { label: 'Grenada', value: 308 },
  { label: 'Guadeloupe', value: 312 },
  { label: 'Guam', value: 316 },
  { label: 'Guatemala', value: 320 },
  { label: 'Guernsey', value: 831 },
  { label: 'Guinea', value: 324 },
  { label: 'Guinea-Bissau', value: 624 },
  { label: 'Guyana', value: 328 },
  { label: 'Haiti', value: 332 },
  { label: 'Heard Island and McDonald Islands', value: 334 },
  { label: 'Honduras', value: 340 },
  { label: 'Hong Kong', value: 344 },
  { label: 'Hungary', value: 348 },
  { label: 'Iceland', value: 352 },
  { label: 'Indonesia', value: 360 },
  { label: 'Iran', value: 364 },
  { label: 'Iraq', value: 368 },
  { label: 'Ireland', value: 372 },
  { label: 'Isle of Man', value: 833 },
  { label: 'Israel', value: 376 },
  { label: 'Italy', value: 380 },
  { label: 'Ivory Coast', value: 384 },
  { label: 'Jamaica', value: 388 },
  { label: 'Japan', value: 392 },
  { label: 'Jersey', value: 832 },
  { label: 'Jordan', value: 400 },
  { label: 'Kazakhstan', value: 398 },
  { label: 'Kenya', value: 404 },
  { label: 'Kiribati', value: 296 },
  { label: 'Kosovo', value: 855 },
  { label: 'Kuwait', value: 414 },
  { label: 'Kyrgyzstan', value: 417 },
  { label: 'Laos', value: 418 },
  { label: 'Latvia', value: 428 },
  { label: 'Lebanon', value: 422 },
  { label: 'Lesotho', value: 426 },
  { label: 'Liberia', value: 430 },
  { label: 'Libya', value: 434 },
  { label: 'Liechtenstein', value: 438 },
  { label: 'Lithuania', value: 440 },
  { label: 'Luxembourg', value: 442 },
  { label: 'Macao', value: 446 },
  { label: 'Macedonia', value: 807 },
  { label: 'Madagascar', value: 450 },
  { label: 'Malawi', value: 454 },
  { label: 'Malaysia', value: 458 },
  { label: 'Maldives', value: 462 },
  { label: 'Mali', value: 466 },
  { label: 'Malta', value: 470 },
  { label: 'Marshall Islands', value: 584 },
  { label: 'Martinique', value: 474 },
  { label: 'Mauritania', value: 478 },
  { label: 'Mauritius', value: 480 },
  { label: 'Mayotte', value: 175 },
  { label: 'Mexico', value: 484 },
  { label: 'Micronesia', value: 583 },
  { label: 'Moldova', value: 498 },
  { label: 'Monaco', value: 492 },
  { label: 'Mongolia', value: 496 },
  { label: 'Montenegro', value: 499 },
  { label: 'Montserrat', value: 500 },
  { label: 'Morocco', value: 504 },
  { label: 'Mozambique', value: 508 },
  { label: 'Myanmar', value: 104 },
  { label: 'Namibia', value: 516 },
  { label: 'Nauru', value: 520 },
  { label: 'Nepal', value: 524 },
  { label: 'Netherlands', value: 528 },
  { label: 'New Caledonia', value: 540 },
  { label: 'New Zealand', value: 554 },
  { label: 'Nicaragua', value: 558 },
  { label: 'Niger', value: 562 },
  { label: 'Nigeria', value: 566 },
  { label: 'Niue', value: 570 },
  { label: 'Norfolk Island', value: 574 },
  { label: 'North Korea', value: 408 },
  { label: 'Northern Mariana Islands', value: 580 },
  { label: 'Norway', value: 578 },
  { label: 'Oman', value: 512 },
  { label: 'Pakistan', value: 586 },
  { label: 'Palau', value: 585 },
  { label: 'Palestine', value: 275 },
  { label: 'Panama', value: 591 },
  { label: 'Papua New Guinea', value: 598 },
  { label: 'Paraguay', value: 600 },
  { label: 'Peru', value: 604 },
  { label: 'Philippines', value: 608 },
  { label: 'Pitcairn Islands', value: 612 },
  { label: 'Poland', value: 616 },
  { label: 'Portugal', value: 620 },
  { label: 'Puerto Rico', value: 630 },
  { label: 'Qatar', value: 634 },
  { label: 'Republic of the Congo', value: 178 },
  { label: 'Reunion', value: 638 },
  { label: 'Romania', value: 642 },
  { label: 'Russia', value: 643 },
  { label: 'Rwanda', value: 646 },
  { label: 'Saint Barthelemy', value: 652 },
  { label: 'Saint Helena', value: 654 },
  { label: 'Saint Kitts and Nevis', value: 659 },
  { label: 'Saint Lucia', value: 662 },
  { label: 'Saint Martin', value: 663 },
  { label: 'Saint Pierre and Miquelon', value: 666 },
  { label: 'Saint Vincent and the Grenadines', value: 670 },
  { label: 'Samoa', value: 882 },
  { label: 'San Marino', value: 674 },
  { label: 'Sao Tome and Principe', value: 678 },
  { label: 'Saudi Arabia', value: 682 },
  { label: 'Senegal', value: 686 },
  { label: 'Serbia', value: 688 },
  { label: 'Seychelles', value: 690 },
  { label: 'Sierra Leone', value: 694 },
  { label: 'Singapore', value: 702 },
  { label: 'Sint Maarten', value: 534 },
  { label: 'Slovakia', value: 703 },
  { label: 'Slovenia', value: 705 },
  { label: 'Solomon Islands', value: 90 },
  { label: 'Somalia', value: 706 },
  { label: 'South Africa', value: 710 },
  { label: 'South Georgia', value: 239 },
  { label: 'South Korea', value: 410 },
  { label: 'South Sudan', value: 728 },
  { label: 'Spain', value: 724 },
  { label: 'Sri Lanka', value: 144 },
  { label: 'Sudan', value: 729 },
  { label: 'Suriname', value: 740 },
  { label: 'Svalbard and Jan Mayen', value: 744 },
  { label: 'Swaziland', value: 748 },
  { label: 'Sweden', value: 752 },
  { label: 'Switzerland', value: 756 },
  { label: 'Syria', value: 760 },
  { label: 'Taiwan', value: 158 },
  { label: 'Tajikistan', value: 762 },
  { label: 'Tanzania', value: 834 },
  { label: 'Thailand', value: 764 },
  { label: 'Togo', value: 768 },
  { label: 'Tokelau', value: 772 },
  { label: 'Tonga', value: 776 },
  { label: 'Trinidad and Tobago', value: 780 },
  { label: 'Tunisia', value: 788 },
  { label: 'Turkey', value: 792 },
  { label: 'Turkmenistan', value: 795 },
  { label: 'Turks and Caicos Islands', value: 796 },
  { label: 'Tuvalu', value: 798 },
  { label: 'Uganda', value: 800 },
  { label: 'Ukraine', value: 804 },
  { label: 'United Arab Emirates', value: 784 },
  { label: 'United Kingdom', value: 826 },
  { label: 'United States Minor Outlying Islands', value: 581 },
  { label: 'Uruguay', value: 858 },
  { label: 'Uzbekistan', value: 860 },
  { label: 'Vanuatu', value: 548 },
  { label: 'Vatican', value: 336 },
  { label: 'Venezuela', value: 862 },
  { label: 'Vietnam', value: 704 },
  { label: 'Western Sahara', value: 732 },
  { label: 'Yemen', value: 887 },
  { label: 'Zambia', value: 894 },
  { label: 'Zimbabwe', value: 716 }
];
const radiusOptionsMonsterIntegrateSearch = [
  { label: "Select", value: "" },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
  { label: "100", value: 100 }
];
const lastUpdatedOptionsIntegrateSearch = [
  { label: "Select", value: "" },
  { label: "7 Days", value: 7 },
  { label: "30 Days", value: 30 },
  { label: "90 Days", value: 90 },
  { label: "All Resumes", value: 999 }
];
const resumeModifiedOptionsCB = [
  { label: "Select", value: "" },
  { label: "0 week", value: 0 },
  { label: "1 week", value: 7 },
  { label: "2 weeks", value: 14 },
  { label: "3 weeks", value: 21 },
  { label: "4 weeks", value: 28 },
  { label: "2 months", value: 56 },
  { label: "3 months", value: 84 },
  { label: "6 months", value: 168 },
  { label: "1 Year", value: 364 },
  { label: "2 Years", value: 728 }
];
const resumeModifiedToOptionsCB = [
  { label: "Select", value: "" },
  { label: "0 week", value: 0 },
  { label: "1 week", value: 7 },
  { label: "2 weeks", value: 14 },
  { label: "3 weeks", value: 21 },
  { label: "4 weeks", value: 28 },
  { label: "2 months", value: 56 },
  { label: "3 months", value: 84 },
  { label: "6 months", value: 168 },
  { label: "1 Year", value: 364 },
  { label: "2 Years", value: 728 },
  { label: "2+ Years", value: 10 }
];
const yearsOfExperienceOptionsCB = [
  { label: "Select", value: "" },
  { label: "0 yrs", value: 0 },
  { label: "1 yr", value: 1 },
  { label: "2 yrs", value: 2 },
  { label: "3 yrs", value: 3 },
  { label: "4 yrs", value: 4 },
  { label: "5 yrs", value: 5 },
  { label: "6 yrs", value: 6 },
  { label: "7 yrs", value: 7 },
  { label: "8 yrs", value: 8 },
  { label: "9 yrs", value: 9 },
  { label: "10 yrs", value: 10 },
  { label: "11 yrs", value: 11 },
  { label: "12 yrs", value: 12 },
  { label: "13 yrs", value: 13 },
  { label: "14 yrs", value: 14 },
  { label: "15 yrs", value: 15 },
  { label: "16 yrs", value: 16 },
  { label: "17 yrs", value: 17 },
  { label: "18 yrs", value: 18 },
  { label: "19 yrs", value: 19 },
  { label: "20 yrs", value: 20 }
];

const workStatusOptionsCB = [
  { label: "Can work for any employer", value: "CTAY" },
  { label: "US Citizen", value: "CTCT" },
  { label: "H1 Visa", value: "CTEM" },
  { label: "Green Card Holder", value: "CTGR" },
  { label: "Need H1 Visa Sponsor", value: "CTNO" },
  { label: "TN Permit Holder", value: "EATN" },
  { label: "Employment Authorization Document", value: "EAEA" },
  { label: "Not specified", value: "CTNS" }
];
const workAuthorizationIntegrationSearch= [
  "B1",
  "Bangladesh Authorized",
  "Brazil Authorized",
  "Can work for any employer",
  "Canada Authorized",
  "Canadian Citizen",
  "Canadian Citizenship",
  "China Authorized",
  "Citizen",
  "Colombia Authorized",
  "Current Employer Only",
  "Employment Auth Document",
  "Employment Auth. Document",
  "Employment Authorization Document",
  "EU Eligible",
  "GC",
  "GC-EAD",
  "Germany Authorized",
  "Green Card",
  "Green Card Holder",
  "H1-B",
  "H4 EAD",
  "Have H1",
  "Have H1 Visa",
  "India Authorized",
  "Ireland Authorized",
  "Italy Authorized",
  "Kuwait Authorized",
  "L1-A",
  "L1-B",
  "L2-EAD",
  "Luxembourg Authorized",
  "Macedonia Authorized",
  "Mexico Authorized",
  "Need H1",
  "Need H1 Visa",
  "Need H1 Visa Sponsor",
  "Not specified",
  "OPT-EAD",
  "Pakistan Authorized",
  "Poland Authorized",
  "Russian Federation Authorized",
  "South Africa Authorized",
  "Sponsorship Required",
  "TN Permit Holder",
  "TN Visa",
  "Turkey Authorized",
  "Ukraine Authorized",
  "United Arab Emirates Authorized",
  "United Kingdom Authorized",
  "US Authorized",
  "US Citizen",
  "US Citizenship",
  "CTCT",
  "CTEM",
  "EAEA"
]

export { degreesOptions,currencyCodes,payScheduleTypes,employmentTypes,remoteJobOptions,jobStatusOptions,jobTypeOptions,
    requiredDocumentsOptions,turnaroundTimeOptions,priorityOptionsOptions,workAuthorizationOptions,industryOptions,
    languagesOptions,taxTermsOptions,states,countries,CBRadius,MonsterRadius,optionsMonsterPostFrom,optionsMonsterTo,countryMonsterOptions,
    MonsterRadiusOptions,MonsterstateData,CareerBuilderRadius,MonsterEducationLevel,salaryTypeMonsterOptions,LanguageskillsMonster,
    ProfessionalLevelMonsterOptions,careerLevelMonster,jonTypeMonster,indusriesMonsterOptions,statesArrayOptionMonster,countryOptionsIntegrationSearch,
    radiusOptionsMonsterIntegrateSearch,lastUpdatedOptionsIntegrateSearch,resumeModifiedOptionsCB,resumeModifiedToOptionsCB,yearsOfExperienceOptionsCB,
    workStatusOptionsCB,workAuthorizationIntegrationSearch

 };