export const ADD_JOB_BOARDS_HISTORY_VIA_API='ADD_JOB_BOARDS_HISTORY_VIA_API'
export const GET_JOB_BOARDS_HISTORY_VIA_API='GET_JOB_BOARDS_HISTORY_VIA_API'
export const SUCCESS_JOB_BOARDS_HISTORY='SUCCESS_JOB_BOARDS_HISTORY'
export const DELETE_JOB_BOARDS='DELETE_JOB_BOARDS'
export const SAVE_JOB_BOARDS_HISTOTY_VIA_API='SAVE_JOB_BOARDS_HISTOTY_VIA_API'
export const DELETE_JOB_BAORDS_HISTORY_VIA_API='DELETE_JOB_BAORDS_HISTORY_VIA_API'
export const GET_JOB_BOARDS_STATUS_HISTORY_VIA_API='GET_JOB_BOARDS_STATUS_HISTORY_VIA_API'



export const REQUEST_VALIDATE_EMAIL = 'REQUEST_VALIDATE_EMAIL';
export const SUCCESS_VALIDATE_EMAIL = 'SUCCESS_VALIDATE_EMAIL';
export const FAILURE_VALIDATE_EMAIL = 'FAILURE_VALIDATE_EMAIL';