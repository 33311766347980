import {
    all,
    call,
    delay,
    fork,
    put,
    race,
    takeEvery,
    takeLatest,
  } from "redux-saga/effects";
  import {
    ADD_JOB_BOARDS_HISTORY_VIA_API,
    DELETE_JOB_BAORDS_HISTORY_VIA_API,
    FAILURE_VALIDATE_EMAIL,
    GET_JOB_BOARDS_HISTORY_VIA_API,
    GET_JOB_BOARDS_STATUS_HISTORY_VIA_API,
    REQUEST_VALIDATE_EMAIL,
    SAVE_JOB_BOARDS_HISTOTY_VIA_API,
    SUCCESS_VALIDATE_EMAIL,
  } from "./actionType";
  import {successJobboardsHistory} from "./action";
  import { getCompaniesSuccess, setSnackbarMessage } from "../search/action";
  import {loadingViaRedux} from "../reports/action"
  const apiUrl = process.env.REACT_APP_API_URL;
  function addJobBoardsHistoryViaApiSag(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/historyStored", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getJobBoardsHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/getHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function saveJobBoardsHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/saveHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function deleteJobBoardsHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/deleteHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
  function getJobBoardsStatusHistoryViaApiSaga(payload){
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/jobBoardsHistory/getStatusHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
  
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }
 
  function emailValidationApiSaga(payload) {
    const myPayload = JSON.stringify(payload);
    return fetch(apiUrl + "/emailLogs/validateEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: myPayload,
    })
      .then((response) => response.json())
      .catch((error) => {
        throw error;
      });
  }

  function* emailValidationSaga(action) {
    
    try {
      const { response, timeout } = yield race({
        response: call(emailValidationApiSaga, action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        yield put(loadingViaRedux(true))
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
  
        if (response.message) {
          yield put(setSnackbarMessage(response.message, "success")); // Dispatch success message
        }
        yield put({ type: SUCCESS_VALIDATE_EMAIL, payload: response });
        
      } else if (timeout) {
        yield put(setSnackbarMessage("Request timed out. Please try again.", "error"));
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action if necessary
      yield put(setSnackbarMessage("An error occurred during validation.", "error"));
      yield put({ type: FAILURE_VALIDATE_EMAIL, error });
    }
  }



 
  function* addJobBoardsHistoryViaSag(action) {
    try {
      const { response, timeout } = yield race({
        response: call(addJobBoardsHistoryViaApiSag,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        // if (response.message) {
        //   yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        // }
        // yield put(loadingViaRedux(true))
        // yield put (successAddApplicant(response))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getJobBoardsHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(getJobBoardsHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        // if (response.message) {
        //   yield put(setSnackbarMessage(response.message, "success")); // Dispatch error message with 'error' variant
        // }
        yield put (successJobboardsHistory(response))
        // yield put(loadingViaRedux(true))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* saveJobBoardsHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(saveJobBoardsHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
          yield put(loadingViaRedux(true))
          return null;
        }
  
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        
        yield put(loadingViaRedux(true))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* deleteJobBoardsHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(deleteJobBoardsHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
          yield put(setSnackbarMessage(response.error, "error"));
  
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  function* getJobBoardsStatusHistoryViaSaga(action) {
    try {
      const { response, timeout } = yield race({
        response: call(getJobBoardsStatusHistoryViaApiSaga,action.payload), // Call the API function
        timeout: delay(60000), // Wait for 1 minute (60,000 milliseconds)
      });
  
      if (response) {
        if (response.error === "An internal server error occurred") {
        yield put(loadingViaRedux(true))
          yield put(setSnackbarMessage(response.error, "error"));
          return null;
        }
        if (response.error) {
          yield put(setSnackbarMessage(response.error, "error")); // Dispatch error message with 'error' variant
          return null;
        }
        yield put(successJobboardsHistory(response))
        yield put(loadingViaRedux(true))
      }
    } catch (error) {
      // Handle errors here and dispatch a failure action
      // yield put(settleItems(error));
    }
  }
  
  // Watch for SETTLE_ITEMS action and run settleItemsSaga
  function* watchSettleItems() {
    yield takeEvery(ADD_JOB_BOARDS_HISTORY_VIA_API, addJobBoardsHistoryViaSag); 
    yield takeEvery(GET_JOB_BOARDS_HISTORY_VIA_API, getJobBoardsHistoryViaSaga); 
    yield takeEvery(SAVE_JOB_BOARDS_HISTOTY_VIA_API, saveJobBoardsHistoryViaSaga);
    yield takeEvery(DELETE_JOB_BAORDS_HISTORY_VIA_API,deleteJobBoardsHistoryViaSaga);
    yield takeEvery(GET_JOB_BOARDS_STATUS_HISTORY_VIA_API,getJobBoardsStatusHistoryViaSaga) 
    yield takeEvery(REQUEST_VALIDATE_EMAIL,emailValidationSaga) 


  }
  
  export default function* JobBoards() {
    yield all([
      // ... other sagas ...
  
      fork(watchSettleItems),
    ]);
  }
  