const EmailData = [
  {
    id: 1,
    subject: "Kula call",
    mailCommunications: [
      {
        formEmail: "arjun@kula.aaaai",
        fromName: "Arjun Ohri",
        toList: [
          {
            email: "abhi@tektreeinc.aaaacom",
            name: "Abhi",
          },
        ],
        ccList: [
          {
            email: "chandan@tektreeinc.aaaacom",
            name: "Chandan T",
          },
          {
            email: "mohsin@tektreeinc.aaaacom",
            name: "Mohsin",
          },
          {
            email: "mahender@tektreeinc.aaaacom",
            name: "Mahender",
          },
        ],
        mailSent: "2024-09-19T15:05:09.326Z",
        mailBody: `
              <p>Thanks Abhi, our ATS isn't designed for staffing industries and is mostly for in house recruiting teams. I think we can skip the call the.</p>
              <p>Appreciate it.</p>
              <p><srtong>Arjun Ohri</srtong></p>
              <p><srtong>Head of Sales</srtong></p>
              `,
      },
      {
        formEmail: "abhi@tektreeinc.aaaacom",
        fromName: "Abhi",
        toList: [
          {
            email: "arjun@kula.aaaai",
            name: "Arjun Ohri",
          },
        ],
        ccList: [
          {
            email: "chandan@tektreeinc.aaaacom",
            name: "Chandan T",
          },
          {
            email: "mohsin@tektreeinc.aaaacom",
            name: "Mohsin",
          },
          {
            email: "mahender@tektreeinc.aaaacom",
            name: "Mahender",
          },
        ],
        mailSent: "2024-09-20T15:05:09.326Z",
        mailBody: `<p>Hi&nbsp;Arjun,<br />Please find the responses below:</p>
              <p>What is your current ATS?<br />Ceipal&nbsp;<br />- What use cases are you looking to solve for?<br />Effective use of recruiter AI<br />- What is your timeline to make a decision?<br />2-3 weeks<br />- What industry do you operate in?<br />Staffing(All industries)</p>
              <p><srtong>Abhi</srtong></p>
              <p><srtong>Vice President - Operations</srtong></p>`,
      },
    ],
  },
  {
    id: 2,
    subject: "tech5.ai logo fine tuned",
    tags: ["Inbox", "External"],
    mailCommunications: [
      {
        formEmail: "omkar@tektreeinc.aaaacom",
        fromName: "Omkar",
        toList: [
          {
            email: "abhi@tektreeinc.aaaacom",
            name: "Abhi",
          },
        ],
        ccList: [
          {
            email: "chandan@tektreeinc.aaaacom",
            name: "Chandan T",
          },
          {
            email: "mohsin@tektreeinc.aaaacom",
            name: "Mohsin",
          },
          {
            email: "mahender@tektreeinc.aaaacom",
            name: "Mahender",
          },
        ],
        mailSent: "2024-09-19T15:05:09.326Z",
        mailBody: `
              <p>Thanks Abhi, Omkars mail</p>
              
              `,
      },
      {
        formEmail: "abhi@tektreeinc.aaaacom",
        fromName: "Abhi",
        toList: [
          {
            email: "omkar@tektreeinc.aaaacom",
            name: "Omkar",
          },
        ],
        ccList: [
          {
            email: "chandan@tektreeinc.aaaacom",
            name: "Chandan T",
          },
          {
            email: "mohsin@tektreeinc.aaaacom",
            name: "Mohsin",
          },
          {
            email: "mahender@tektreeinc.aaaacom",
            name: "Mahender",
          },
        ],
        mailSent: "2024-09-20T15:05:09.326Z",
        mailBody: `  <p>Thanks Team, Abhi mail</p>`,
      },
      {
        formEmail: "mohsin@tektreeinc.aaaacom",
        fromName: "Mohsin",
        toList: [
          {
            email: "abhi@tektreeinc.aaaacom",
            name: "Abhi",
          },
        ],
        ccList: [
          {
            email: "chandan@tektreeinc.aaaacom",
            name: "Chandan T",
          },
          {
            email: "omkar@tektreeinc.aaaacom",
            name: "Omkar",
          },
          {
            email: "mahender@tektreeinc.aaaacom",
            name: "Mahender",
          },
        ],
        mailSent: "2024-09-25T12:05:09.326Z",
        mailBody: `<p>There is already a website on tech5.ai</p> <p>The logo text is same in that website and our website.</p><p>Abhi, your thoughts on this?</p>`,
      },
      {
        formEmail: "chandan@tektreeinc.aaaacom",
        //fromName: "Chandan T",
        toList: [
          {
            email: "abhi@tektreeinc.aaaacom",
            name: "Abhi",
          },
          {
            email: "raghu@tektreeinc.aaaacom",
            //name: "Raghu",
          },
          {
            email: "balaji@tektreeinc.aaaacom",
            name: "Balaji",
          },
        ],
        ccList: [
          {
            email: "mohsin@tektreeinc.aaaacom",
            name: "Mohsin",
          },
          {
            email: "omkar@tektreeinc.aaaacom",
            name: "Omkar",
          },
          {
            email: "mahender@tektreeinc.aaaacom",
            name: "Mahender",
          },
        ],
        mailSent: "2024-09-27T14:05:09.326Z",
        mailBody: `<p>Some More Information about logo</p> <p>The logo text is same in that website and our website.</p><p>Abhi, your thoughts on this?</p>`,
      },
    ],
  },
];

export { EmailData };
