import{
    DELETE_JOB_BOARDS,
    FAILURE_VALIDATE_EMAIL,
    SUCCESS_JOB_BOARDS_HISTORY,
    SUCCESS_VALIDATE_EMAIL
} from "./actionType"

const INIT_STATE = {
    jobBoardsHistory:[],
    validationResults: [],
    validationError: null,

  };
  const jobBoards = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SUCCESS_JOB_BOARDS_HISTORY: {
        const jobBoardsHistoryData = action.payload;
        return {
          ...state,
          jobBoardsHistory: jobBoardsHistoryData,
        };
      }
    
      case DELETE_JOB_BOARDS: {
        
        return INIT_STATE;
      }
      case SUCCESS_VALIDATE_EMAIL: {
        const validationData = action.payload;
        return {
          ...state,
          validationResults: validationData,
          validationError: null,
        };
      }
      case FAILURE_VALIDATE_EMAIL: {
        const error = action.payload;
        return {
          ...state,
          validationError: error,
        };
      }

      default:
        return state;
    }
  };
  
  export default jobBoards;
  