import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CustomButton,
  CustomDetailViewSection,
  CustomSubmissionTilmeLine,
  JobPostingForm,
  KeyData,
  MainBackground,
  ScrollView,
  SectionTitle,
} from "../../Components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import dayjs from "dayjs";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonSearchRoundedIcon from "@mui/icons-material/PersonSearchRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { Link, useNavigate } from "react-router-dom";
import {
  addIndustriesNamesViaApi,
  jobPostRecruiterViaApi,
} from "../../store/actions";
import { debounce } from "lodash";
import theme from "../../Theme";

import { timelineList } from "../../Data/timelineList";
import CustomTimeline from "../../Components/Common/CustomTimeline";

const TooltipIconButton = ({
  title,
  arrow = false,
  pointer = "right",
  icon = <ArrowBackIcon />,
}) => {
  const styledIcon = React.cloneElement(icon, {
    sx: { fontSize: 14 },
  });
  return (
    <Box>
      <Tooltip title={title} arrow={arrow} placement={pointer}>
        <IconButton>{styledIcon}</IconButton>
      </Tooltip>
    </Box>
  );
};
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const JobPostDetails = () => {
  const [tabShow, setTabShow] = React.useState(0);
  const [showJobDesc, setShowJobDesc] = React.useState(false);

  const handleToggleJobDesc = () => {
    setShowJobDesc(!showJobDesc);
  };

  const handleTabChange = (event, newValue) => {
    setTabShow(newValue);
  };
  const location = useLocation();
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [companyDetails, setCompanyDetails] = useState(
    location.state?.company || {}
  );
  const [selectDocument, setSelectDocument] = useState("");
  const defaultJobDetails = {
    jobCode: "",
    jobTitle: "",
    jobWebsite: "",
    clientBillRateSalary: {
      currency: "USD",
      amount: "",
      paySchedule: "",
      jobType: "",
    },
    payRateSalary: {
      currency: "USD",
      minAmount: "",
      maxAmount: "",
      paySchedule: "",
      jobType: "",
    },
    jobStartDate: new Date(),
    jobEndDate: null,
    respondBy: "",
    remoteJob: "",
    expensesPaid: "",
    country: "USA",
    states: "",
    location: "",
    jobStatus: "Active",
    jobType: "",
    requiredHourWeek: "",
    client: "",
    clientManager: "",
    endClient: "",
    clientJobID: "",
    requiredDocuments: [],
    turnoutTime: {
      time: "",
      dates: "",
    },
    priority: "",
    clientCategory: "",
    duration: "",
    additionalDetails: "",
    areaCode: "",
    workAuthorization: [],
    interviewMode: "",
    applicationForm: "",
    clearance: "",
    address: "",
    employmentTestTemplate: "",
    employmentLevel: "",
  };
  const defaultSkills = {
    industry: "",
    degree: "",
    experience: {
      min: "",
      max: "",
    },
    evaluationTemplate: "",
    primarySkills: [],
    secondarySkills: "",
    languages: [],
  };
  const defaultOrganizationalInformation = {
    numberofPositions: "",
    maximumAllowedSubmission: "",
    taxTerms: [],
    salesManager: "",
    department: "",
    recruitmentManager: "",
    assignedTo: "",
    primaryRecruiter: "",
    comments: "",
    additionalNotifications: "",
    careerPortalPublishedDate: null,
    jobDescription: "",
  };
  const industries = useSelector((state) => state.Search.industries || []);

  const industryNames = industries.map((item) => item.industryName) || [];
  const [jobDetails, setJobDetails] = useState({});
  const [skills, setSkills] = useState({});
  const [assignedTo, setAssignedTo] = useState("");
  const [organizationalInformation, setOrganizationalInformation] = useState(
    {}
  );
  const [jobPostStatus, setJobPostStatus] = useState("");
  const [jobPostingId, setJobPostingId] = useState("");
  const [errors, setErrors] = useState({});
  const [isJobPostingModalOpen, setIsJobPostingModalOpen] = useState(false);
  const handleJobPostingCloseModal = () => {
    setJobDetails(defaultJobDetails);
    setSkills(defaultSkills);
    setOrganizationalInformation(defaultOrganizationalInformation);
    setIsJobPostingModalOpen(!isJobPostingModalOpen);
    setErrors({});
    setAssignedTo("");
  };

  const trimFields = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (Array.isArray(value)) {
        acc[key] = value;
      } else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = trimFields(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  const handleJobPostingSaveDraft = debounce(() => {
    const action = {
      addedBy: storeData._id,
      addedOn: new Date(),
      jobDetails: jobDetails,
      skills: skills,
      organizationalInformation: organizationalInformation,
      bdmCompanyId: selectDocument,
      status: "Draft",
      assignedTo: assignedTo,
      id: jobPostingId,
      industrieName: skills.industry,
      client: client,
    };
    dispatch(jobPostRecruiterViaApi(action));
    if (action.industrieName) {
      dispatch(addIndustriesNamesViaApi(action));
    }
    handleJobPostingCloseModal();
  }, 1000);
  const validateForm = () => {
    const newErrors = {};

    // Check if jobCode is present and not empty inside jobDetails
    if (!jobDetails?.jobCode) {
      newErrors.jobCode = "Job Code is required in Job Details";
    }

    // Check if jobTitle is empty
    if (!jobDetails?.jobTitle) {
      newErrors.jobTitle = "Job Title is required";
    }

    // Validate country
    if (!jobDetails?.country) {
      newErrors.country = "Country is required";
    }

    // Validate remoteJob
    if (!jobDetails?.remoteJob) {
      newErrors.remoteJob = "Remote Job status is required";
    }

    // Validate location
    if (!jobDetails?.location) {
      newErrors.location = "Location is required";
    }
    if (!jobDetails?.jobStatus) {
      newErrors.jobStatus = "jobStatus is required";
    }

    // Validate duration
    if (jobDetails.jobType !== "Full Time" && !jobDetails?.duration) {
      newErrors.duration = "Duration is required";
    }
    if (!skills?.experience?.min) {
      newErrors.skillsExperienceMin = "min is required";
    }
    if (!skills?.experience?.max) {
      newErrors.skillsExperienceMax = "Max is required";
    }
    // Validate primarySkills
    if (!skills?.primarySkills || skills.primarySkills.length === 0) {
      newErrors.primarySkills = "At least one primary skill is required";
    }

    if (!organizationalInformation?.numberofPositions) {
      newErrors.numberofPositions = "NumberofPositions is required";
    }
    if (
      !organizationalInformation?.taxTerms ||
      organizationalInformation.taxTerms.length === 0
    ) {
      newErrors.taxTerms = "NumberofPositions is required";
    }

    setErrors(newErrors);

    // Returns true if no errors exist
    return Object.keys(newErrors).length === 0;
  };

  const handleJobPostingSaveSubmit = debounce(() => {
    if (validateForm()) {
      // Trim spaces from jobDetails, skills, and organizationalInformation
      const trimmedJobDetails = trimFields(jobDetails);
      const trimmedSkills = trimFields(skills);
      const trimmedOrganizationalInformation = trimFields(
        organizationalInformation
      );

      const action = {
        addedBy: storeData._id,
        addedOn: new Date(),
        jobDetails: trimmedJobDetails,
        skills: trimmedSkills,
        organizationalInformation: trimmedOrganizationalInformation,
        bdmCompanyId: selectDocument,
        assignedTo: assignedTo,
        status: "Submit",
        id: jobPostingId,
        industrieName: skills.industry,
        client: client,
      };
      dispatch(jobPostRecruiterViaApi(action));
      if (action.industrieName) {
        dispatch(addIndustriesNamesViaApi(action));
      }
      const today = dayjs().tz("America/New_York").startOf("day");

      handleJobPostingCloseModal();
    }
  }, 1000);
  useEffect(() => {
    if (JobPosting && JobPosting.length > 0 && companyDetails) {
      const findExactDocument = JobPosting.find(
        (job) => job._id === companyDetails.jobPostingId
      );
      if (findExactDocument) {
        setSkills(findExactDocument.skills || defaultSkills);
        setJobDetails(findExactDocument.jobDetails || defaultJobDetails);
        setOrganizationalInformation(
          findExactDocument.organizationalInformation ||
            defaultOrganizationalInformation
        );

        setSelectDocument(findExactDocument.bdmCompanyId);
        setJobPostStatus(findExactDocument.status);
        setJobPostingId(findExactDocument._id);
      }
    }
  }, [JobPosting, companyDetails]);

  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const [checkBackURL, setCheckBackURL] = useState("jobPosts");
  function getDisplayString(fullString) {
    if (!fullString) {
      return null;
    }
    // Split the string by the hyphen and remove the last part (timestamp)
    const parts = fullString.split("-");

    // Join the first two parts to get the display string
    const displayString = `${parts[0]}-${parts[1]}`;

    return displayString;
  }
  useEffect(() => {
    if (companyDetails.page && companyDetails.page === "client") {
      const findExactDocument = JobPosting.find(
        (job) => job.bdmCompanyId === companyDetails.id
      );
      setCheckBackURL(companyDetails.page);
      if (findExactDocument) {
        const assignedToUser = allUsers.find(
          (user) => user._id === findExactDocument.assignedTo
        );
        const createdByUser = allUsers.find(
          (user) => user._id === findExactDocument.addedBy
        );
        const deliveryManager = allUsers.find(
          (user) => user._id === findExactDocument.assignedBy
        );

        const updatedDetails = {
          jobPostingId: findExactDocument?._id || "",
          jobCode: findExactDocument.jobDetails?.jobCode || "",
          companyName: findExactDocument.jobDetails?.client || "",
          jobTitle: findExactDocument.jobDetails?.jobTitle || "",
          jobDescription: findExactDocument.jobDetails?.jobDescription || "",
          jobWebsite: findExactDocument.jobDetails?.jobWebsite || "",
          jobLocation: findExactDocument.jobDetails?.location || "",
          jobStatus: findExactDocument.jobDetails?.jobStatus || "",
          assignedTo: assignedToUser?.userSudoFirstName || "",
          assignedToID: findExactDocument?.assignedTo || "",
          jobCreatedBy: createdByUser?.userSudoFirstName || "",
          jobCreatedOn: formatDateForDisplay(findExactDocument.addedOn) || "",
          jobDetails: findExactDocument.jobDetails,
          skills: findExactDocument.skills,
          submissions: findExactDocument.submissions,
          organizationalInformation:
            findExactDocument.organizationalInformation,
          deliveryManager: deliveryManager?.userSudoFirstName || "",
          note: true,
          clientBill: `${
            findExactDocument.jobDetails?.clientBillRateSalary?.currency || ""
          }/${
            findExactDocument.jobDetails?.clientBillRateSalary?.amount || ""
          }/${
            findExactDocument.jobDetails?.clientBillRateSalary?.paySchedule ||
            ""
          }/${
            findExactDocument.jobDetails?.clientBillRateSalary?.jobType || ""
          }`,
          payBill: `${
            findExactDocument.jobDetails?.payRateSalary?.currency || ""
          }/${findExactDocument.jobDetails?.payRateSalary?.paySchedule || ""}/${
            findExactDocument.jobDetails?.payRateSalary?.jobType || ""
          }`,
          minSalary: `${
            findExactDocument.jobDetails?.payRateSalary?.minAmount || ""
          }/`,
          maxSalary: `${
            findExactDocument.jobDetails?.payRateSalary?.maxAmount || ""
          }/`,
        };

        setCompanyDetails(updatedDetails);
      }
    }
  }, [companyDetails.page, JobPosting, allUsers]);
  const jobRows = [
    {
      id: 1,
      jodCode: "CAL-SOLA-1724045786376",
      companyName: "Cal Solar Inc.",
      website: "#",
      jobTitle: "Project Manager",
      jobLocation: "Hayward, CA",
      jobStatus: "Active",
      clientBill: "USD/120000/Yearly/Fulltime",
      payBill: "USD/80000/Yearly/Fulltime",
      recruitmentManager: "Recruitment Manager - 01",
      primaryRecruiter: "Primary Recruiter - 01",
      assignedTo: "Assigned To - 01",
      jobCreatedBy: "Job Created By - 01",
      jobCreatedOn: "Time",
      minSalary: "USD/68000/Yearly/Fulltime",
      maxSalary: "USD/78000/Yearly/Fulltime",
      status: "active",
      note: true,
    },
  ];
  
  const isObject = (value) =>
    value && typeof value === "object" && !Array.isArray(value);

  // Function to check if a value is an array
  const isArray = (value) => Array.isArray(value);

  // Function to convert objects or arrays into a formatted string
  const convertDynamically = (value) => {
    if (isObject(value)) {
      // If the value is an object, convert its values to a backslash-separated string
      return Object.values(value)
        .filter((val) => val !== undefined && val !== null)
        .join(",");
    } else if (isArray(value)) {
      // If the value is an array, join its elements with a backslash
      return value.join("");
    }
    return value; // If neither object nor array, return the value as it is
  };
// Assuming companyDetails is coming from props or state
const jobStartDate = companyDetails?.jobDetails?.jobStartDate; 
const jobEndDate = companyDetails?.jobDetails?.jobEndDate; 


// Log the formatted end date
// Format jobStartDate and jobEndDate as strings
// Assuming you want to format the job start and end dates to a readable format with timezone
const formattedJobStartDate = jobStartDate
  ? moment.tz(jobStartDate, 'America/New_York').format('YYYY-MM-DD HH:mm')
  : 'N/A';

const formattedJobEndDate = jobEndDate
  ? moment.tz(jobEndDate, 'America/New_York').format('YYYY-MM-DD HH:mm')
  : 'N/A';


  const filterDataJobDetails = [
    {
      jobCode: companyDetails?.jobDetails?.jobCode || "N/A",
      jobTitle: companyDetails?.jobDetails?.jobTitle || "N/A",
      jobWebsite: companyDetails?.jobDetails?.jobWebsite || "N/A",
      clientBillRateSalary: `${companyDetails?.jobDetails?.clientBillRateSalary?.currency}/${companyDetails?.jobDetails?.clientBillRateSalary?.amount}/${companyDetails?.jobDetails?.clientBillRateSalary?.paySchedule}/${companyDetails?.jobDetails?.clientBillRateSalary?.jobType}`,
      payRateSalary: `${companyDetails?.jobDetails?.payRateSalary?.currency}/${companyDetails?.jobDetails?.payRateSalary?.minAmount}/${companyDetails?.jobDetails?.payRateSalary?.maxAmount}/${companyDetails?.jobDetails?.payRateSalary?.paySchedule}/${companyDetails?.jobDetails?.payRateSalary?.jobType}`,
      jobStartDate: formattedJobStartDate,
    jobEndDate: formattedJobEndDate,
      remoteJob: companyDetails?.jobDetails?.remoteJob || "N/A",
      expensesPaid: companyDetails?.jobDetails?.expensesPaid || "N/A",
      country: companyDetails?.jobDetails?.country || "N/A",
      states: companyDetails?.jobDetails?.states || "N/A",
      location: companyDetails?.jobDetails?.location || "N/A",
      jobStatus: companyDetails?.jobDetails?.jobStatus || "N/A",
      jobType: companyDetails?.jobDetails?.jobType || "N/A",
      requiredHourWeek: companyDetails?.jobDetails?.requiredHourWeek || "N/A",
      client: companyDetails?.jobDetails?.client || "N/A",
      endClient: companyDetails?.endClient || "N/A",
      clientJobID: companyDetails?.jobDetails?.clientJobID || "N/A",
      requiredDocuments:
        Array.isArray(companyDetails?.jobDetails?.requiredDocuments) &&
        companyDetails?.jobDetails?.requiredDocuments.length > 0
          ? convertDynamically(companyDetails?.jobDetails?.requiredDocuments)
          : "N/A",
      turnoutTime:
        Array.isArray(companyDetails?.jobDetails?.turnoutTime) &&
        companyDetails?.jobDetails?.turnoutTime.length > 0
          ? convertDynamically(companyDetails?.jobDetails?.turnoutTime)
          : "N/A",
      priority: companyDetails?.jobDetails?.priority || "N/A",
      duration: companyDetails?.jobDetails?.duration || "N/A",
      areaCode: companyDetails?.jobDetails?.areaCode || "N/A",

      workAuthorization:
        Array.isArray(companyDetails?.jobDetails?.requiredDocuments) &&
        companyDetails?.jobDetails?.workAuthorization.length > 0
          ? convertDynamically(companyDetails?.jobDetails?.workAuthorization)
          : "N/A",
      clearance: companyDetails?.jobDetails?.clearance || "N/A",
      address: companyDetails?.jobDetails?.address || "N/A",
    },
  ];
  const filterSkilss = [
    {
      industry: companyDetails?.skills?.industry || "N/A",
      degree: companyDetails?.skills?.degree || "N/A",
      experience:
        `${companyDetails?.skills?.experience?.min}/${companyDetails?.skills?.experience?.max}` ||
        "N/A",
      primarySkills:
        Array.isArray(companyDetails?.skills?.primarySkills) &&
        companyDetails?.skills?.primarySkills.length > 0
          ? convertDynamically(companyDetails?.skills?.primarySkills)
          : "N/A",
      languages: companyDetails?.skills?.languages || "N/A",
    },
  ];
  const removeHtmlTags = (htmlString) => {
    // Create a temporary DOM element
    const tempDiv = document.createElement("div");
    // Set the HTML content
    tempDiv.innerHTML = htmlString;
    // Return the text content of the element
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  const filterOrganizational = [
    {
      numberofPositions:
        companyDetails?.organizationalInformation?.numberofPositions || "N/A",
      taxTerms:
        Array.isArray(companyDetails?.organizationalInformation?.taxTerms) &&
        companyDetails?.organizationalInformation?.taxTerms.length > 0
          ? convertDynamically(
              companyDetails?.organizationalInformation?.taxTerms
            )
          : "N/A",
      comments: companyDetails?.organizationalInformation?.comments || "N/A",
      jobDescription:
        removeHtmlTags(
          companyDetails?.organizationalInformation?.jobDescription
        ) || "N/A",
    },
  ];
 

 
  const nameFind =
    allUsers.find((user) => user._id === storeData._id)?.userSudoFirstName ||
    "Unknown";
    function stripHtml(html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      return tempDiv.textContent || tempDiv.innerText || "";
  }
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Post Details"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <MainBackground>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                        mb: 1,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Link
                          component="button" // Use `button` for a clickable element
                          to={`/${checkBackURL}`}
                        >
                          <TooltipIconButton title="back" icon={<ArrowBackIcon />} />
                        </Link>
                        <Tooltip title={""}>
                          <CircleIcon
                            sx={{ fontSize: 12 }}
                            color={
                              companyDetails?.jobStatus === "Active"
                                ? "success"
                                : "disabled"
                            }
                          />
                        </Tooltip>
                        <Chip
                          label={companyDetails?.jobStatus || ""}
                          color="error"
                          size="small"
                          variant="outlined"
                          sx={{ px: 0.5, borderRadius: 1 }}
                        />
                      </Box>
                      <Box flex={1} bgcolor={"red"}></Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <TooltipIconButton
                          title="left"
                          icon={<ChevronLeftIcon />}
                        />
                        <TooltipIconButton
                          title="right"
                          icon={<ChevronRightIcon />}
                        />
                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600 }}>
                          {getDisplayString(companyDetails?.jobCode)} -{" "}
                          {companyDetails?.companyName}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <ButtonGroup
                            variant="outlined"
                            size="small"
                            color="error"
                          >
                            {/* <Button
                              startIcon={<PersonAddIcon fontSize="inherit" />}
                              to={"/applicants"}
                              component={Link}
                              state={{ jobPost: companyDetails }}
                            >
                              Add Applicant
                            </Button> */}
                            <Button
                              startIcon={
                                <PersonSearchRoundedIcon fontSize="inherit" />
                              }
                              to={"/jobBoards"}
                              component={Link}
                            >
                              Find Applicant
                            </Button>
                            {companyDetails?.jobCreatedBy === nameFind && (
                              <Button
                                startIcon={
                                  <EditIcon
                                    fontSize="inherit"
                                    onClick={() =>
                                      setIsJobPostingModalOpen(
                                        !isJobPostingModalOpen
                                      )
                                    }
                                  />
                                }
                              >
                                Edit
                              </Button>
                            )}
                          </ButtonGroup>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box display={"flex"} gap={1}>
                          <KeyData
                            title={"Company Name"}
                            value={companyDetails?.companyName}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Website"}
                            link
                            linkTitle={
                              companyDetails?.jobWebsite
                                ? companyDetails?.jobWebsite
                                : "NA"
                            }
                            value={
                              companyDetails?.jobWebsite
                                ? companyDetails.jobWebsite
                                : ""
                            }

                            //   "https://mui.com/material-ui/react-skeleton/"
                            // }
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Job Title"}
                            value={companyDetails?.jobTitle}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Location"}
                            value={companyDetails?.jobLocation}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Assigned To"}
                            value={companyDetails?.assignedTo}
                          />
                          <Divider orientation="vertical" flexItem />
                          <Box flex={1} />
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box display={"flex"} gap={1}>
                          <KeyData
                            title={"Recruitment Manager"}
                            value={companyDetails?.jobCreatedBy}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Client Bill Rate / Salary "}
                            value={companyDetails?.clientBill}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Pay Rate / Salary "}
                            value={companyDetails?.payBill}
                          />
                          <Divider orientation="vertical" flexItem />
                          <KeyData
                            title={"Created By & On "}
                            jobCreatedBy
                            value={companyDetails?.jobCreatedBy}
                          />
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 0.2,
                          }}
                        >
                          <Box
                            onClick={handleToggleJobDesc}
                            sx={{
                              fontSize: 13,
                              fontWeight: 400,
                              lineHeight: 1.2,
                              textTransform: "inherit",
                              color: theme.palette.primary.main,
                              cursor: "pointer",
                            }}
                          >
                            Job Description
                          </Box>

                          <IconButton
                            size="small"
                            onClick={handleToggleJobDesc}
                          >
                            {showJobDesc ? (
                              <VisibilityOffIcon fontSize="11px" />
                            ) : (
                              <VisibilityIcon fontSize="11px" />
                            )}
                          </IconButton>
                        </Box>
                        <ScrollView height={"150px"}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1,
                              height: showJobDesc ? "auto" : "40px",
                              overflow: "hidden",
                            }}
                          >
                            <Typography variant="caption">
                              {stripHtml(companyDetails?.organizationalInformation?.jobDescription)}
                            {/* {companyDetails?.organizationalInformation?.jobDescription} */}
                            </Typography>
                            {/* <Typography variant="caption">
                              Vivamus libero arcu, facilisis eu lobortis in,
                              mattis eget justo. Vestibulum fringilla lorem id
                              elit finibus tincidunt. Integer quis arcu at nisl
                              ornare aliquet at sed massa. Sed semper semper
                              pellentesque. Proin interdum urna vitae mollis
                              finibus. Nulla a ligula rutrum, tempus ante
                              interdum, pulvinar quam. Nam tincidunt elementum
                              convallis. Aenean ornare pharetra enim, a
                              malesuada massa pharetra non. Nulla vel dolor
                              egestas, accumsan erat eu, dictum enim. Quisque
                              eget convallis enim. Nam maximus est nec risus
                              laoreet aliquam. Integer eget libero malesuada
                              erat pretium luctus. Proin ut augue ac lacus
                              bibendum sagittis.
                            </Typography>
                            <Typography variant="caption">
                              Curabitur venenatis ipsum quis arcu blandit
                              ultrices. Morbi quis est risus. Etiam dictum ut
                              dolor ut aliquam. In quis lectus non ante
                              hendrerit pretium. Suspendisse potenti. Praesent
                              malesuada ligula eget tincidunt eleifend. Cras
                              nisi enim, aliquam vel pellentesque non,
                              scelerisque nec justo. Curabitur sit amet ipsum
                              iaculis ex dignissim sollicitudin et sed quam.
                              Fusce quis aliquet lacus, sit amet sodales eros.
                            </Typography>
                            <Typography variant="caption">
                              Vestibulum ut ultricies ante. Donec id leo porta
                              urna ullamcorper fringilla vel nec mi. Praesent
                              sed est vestibulum, aliquet sapien quis,
                              vestibulum ipsum. Sed vitae lacus ullamcorper,
                              faucibus velit sit amet, ultrices est. Nullam et
                              pharetra lacus. Nam pellentesque sodales tellus
                              sit amet pellentesque. Praesent iaculis dui ac
                              nunc interdum, in tempus erat vestibulum. Integer
                              mollis metus lacus, nec porta urna volutpat eu.
                              Morbi dictum libero sed nisl gravida, vel bibendum
                              augue consequat. Vestibulum ante ipsum primis in
                              faucibus orci luctus et ultrices posuere cubilia
                              curae; Phasellus ullamcorper ac justo id ornare.
                            </Typography>
                            <Typography variant="caption">
                              Nullam quis nibh dictum, finibus ligula sed,
                              mollis velit. Sed molestie sapien in libero
                              lobortis, eu pellentesque ex volutpat. Mauris
                              dapibus, magna in facilisis volutpat, metus enim
                              tristique sapien, ac rutrum sapien neque ac est.
                              Suspendisse a volutpat dolor. Phasellus ultricies
                              orci quis nulla tincidunt, id vehicula nulla
                              suscipit. In at tortor eu quam interdum efficitur
                              a non orci. In consequat lorem at tellus
                              scelerisque dignissim. Integer ac dolor sed risus
                              pulvinar interdum. Vestibulum pharetra dui eget
                              semper ullamcorper. Sed pulvinar at massa mollis
                              sagittis.
                            </Typography> */}
                          </Box>
                        </ScrollView>
                      </Box>
                    </Box>
                  </Box>
                </MainBackground>

                <Tabs
                  value={tabShow}
                  onChange={handleTabChange}
                  sx={{ mt: 4, mb: 2 }}
                >
                  <Tab label="Shortlist" />
                  <Tab label="Snapshot" />
                  <Tab label="Job Details" />
                  {/* <Tab label="Additional Details" /> */}
                  {/* <Tab label=" Task Manager" /> */}
                  <Tab label=" Activities" />
                  {/* <Tab label=" Email Merges" disabled /> */}
                </Tabs>
                <TabPanel value={tabShow} index={0}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Shortlist" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <ButtonGroup>
                            <CustomButton title="Tagged" />
                            <CustomButton title="Emailed" value={2} btnActive />
                            <CustomButton title="Under Review" value={2} />
                          </ButtonGroup>
                        </Box>
                      </Box>
                    )}
                    // bodyData={() => (
                    //   <CustomSubmissionTilmeLine
                    //     submissions={companyDetails.submissions}
                    //   />
                    // )}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={1}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Submissions" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <ButtonGroup>
                            <CustomButton title="Pipeline" />
                            <CustomButton title="All" value={2} btnActive />
                            <CustomButton
                              title="Client Submissions"
                              value={2}
                            />
                            <CustomButton title="Interviews" value={2} />
                            <CustomButton title="Confirmations" value={2} />
                            <CustomButton title="Placements" value={2} />
                            <CustomButton title="Not Joined" value={2} />
                          </ButtonGroup>
                        </Box>
                      </Box>
                    )}
                    bodyData={() => (
                      <CustomSubmissionTilmeLine
                        submissions={companyDetails.submissions}
                      />
                    )}
                  />

                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 3,
                          }}
                        >
                          <SectionTitle title="Notes" />
                          <ButtonGroup>
                            <CustomButton title="Job Posting" value={2} />
                            <CustomButton
                              title="Applicant Reference"
                              value={0}
                            />
                          </ButtonGroup>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" />
                        </Box>
                      </Box>
                    )}
                  />
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Documents" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          <CustomButton title="Add" active />
                        </Box>
                      </Box>
                    )}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={2}>
                  <CustomDetailViewSection
                    headerData={() => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                        }}
                      >
                        <SectionTitle title="Job Details" />
                        <Box
                          sx={{
                            display: "flex",
                            gap: 0.5,
                          }}
                        >
                          {/* <CustomButton title="Edit" active /> */}
                        </Box>
                      </Box>
                    )}
                    title={"Job Details"}
                    data={filterDataJobDetails}
                  />
                  <CustomDetailViewSection
                    title={"Skills"}
                    data={filterSkilss}
                  />
                  <CustomDetailViewSection
                    title={"Organizational Information"}
                    data={filterOrganizational}
                  />
                </TabPanel>
                <TabPanel value={tabShow} index={3}>
                  <MainBackground>
                    <SectionTitle title="Timeline" />

                    <CustomTimeline data={companyDetails} />
                  </MainBackground>
                </TabPanel>
                {/* <TabPanel value={tabShow} index={4}>
                  <MainBackground>Task Manager</MainBackground>
                </TabPanel> */}
              </Box>
            </Grid>
            {/* <Grid item xs={3.5}>
              <MainBackground>adf</MainBackground>
            </Grid> */}
          </Grid>
          <Modal
            open={isJobPostingModalOpen}
            onClose={handleJobPostingCloseModal}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background:
                  "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
                height: "100vh",
              }}
            >
              <Box>
                <Paper
                  elevation={1}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    py: 1,
                    px: 4,
                  }}
                >
                  <Typography variant="h5" fontWeight={600}>
                    Job Posting
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Button color="error" onClick={handleJobPostingCloseModal}>
                      Close
                    </Button>
                    <Button
                      color="inherit"
                      variant="outlined"
                      onClick={handleJobPostingSaveDraft}
                      disabled={jobPostStatus === "Submit"}
                      type="button"
                    >
                      Save as a Draft
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleJobPostingSaveSubmit}
                    >
                      Save and Submit
                    </Button>
                  </Box>
                </Paper>
              </Box>

              <Box flex={1} overflow={"auto"} py={1}>
                <JobPostingForm
                  jobDetails={jobDetails}
                  setJobDetails={setJobDetails}
                  skills={skills}
                  setSkills={setSkills}
                  organizationalInformation={organizationalInformation}
                  setOrganizationalInformation={setOrganizationalInformation}
                  errors={errors}
                  allUsers={allUsers}
                  setAssignedTo={setAssignedTo}
                  assignedTo={assignedTo}
                  industryNames={industryNames}
                />
              </Box>
            </Box>
          </Modal>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default JobPostDetails;
