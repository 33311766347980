export const SEARCH_DATA_VIA_API = "SEARCH_DATA_VIA_API";
export const SUCCESS_SEARCH_DATA = "SUCCESS_SEARCH_DATA";
export const ADD_COMPANY_VIA_API = "ADD_COMPANY_VIA_API";
export const DELETE_SEARCH = "DELETE_SEARCH";
export const SET_SNACKBAR_MESSAGE = "SET_SNACKBAR_MESSAGE";
export const CLEAR_SNACKBAR_MESSAGE = "CLEAR_SNACKBAR_MESSAGE";
export const ADD_COMPANY_NAME_VIA_API = "ADD_COMPANY_NAME_VIA_API";
export const GET_COMPANIES_VIA_API = "GET_COMPANIES_VIA_API";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_ALL_USERS_COMPANIES_LIST_VIA_API ="GET_ALL_USERS_COMPANIES_LIST_VIA_API";
export const SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API ="SUCCESS_ALL_USERS_COMPANIES_LIST_VIA_API";
export const DELETE_COMPANY_VIA_API = "DELETE_COMPANY_VIA_API";
export const ADD_JOB_DETAILS_VIA_API = "ADD_JOB_DETAILS_VIA_API";
export const CHECK_COMPANY_NAME='CHECK_COMPANY_NAME';
export const DELETE_COMPANY_REDUX='DELETE_COMPANY_REDUX';
export const SEND_EMAILS_VIA_API='SEND_EMAILS_VIA_API';

export const GLOBAL_SEARCH_VIA_API='GLOBAL_SEARCH_VIA_API';
export const SUCCES_GLOBAL_SEARCH='SUCCES_GLOBAL_SEARCH';

export const SEND_EXCELUPLOAD_ALL_COMPANIES_VIA_API='SEND_EXCELUPLOAD_ALL_COMPANIES_VIA_API'

export const GET_ALL_INDUSTRIES_VIA_API='GET_ALL_INDUSTRIES_VIA_API';
export const SUCCESS_GET_ALL_INDUSTRIES='SUCCESS_GET_ALL_INDUSTRIES';

export const INDUSTRIES_NAMES_VIA_API='INDUSTRIES_NAMES_VIA_API';

export const CHANGE_STATUS_VIA_API='CHANGE_STATUS_VIA_API';

export const ADD_EMAIL_TEMPLATE_VIA_API='ADD_EMAIL_TEMPLATE_VIA_API';
export const GET_ALL_TEMPLATES_VIA_API='GET_ALL_TEMPLATES_VIA_API';
export const SUCCESS_GET_ALL_TEMPLATES='SUCCESS_GET_ALL_TEMPLATES';

export const COUNT_SENT_MAILS_VIA_API='COUNT_SENT_MAILS_VIA_API';

export const SUCCESS_COUNT_SENT_MAILS='SUCCESS_COUNT_SENT_MAILS';
export const REMOVE_COUNT_MAILS='REMOVE_COUNT_MAILS';

export const DATE_SEARCH_USERS_COMPANIES_VIA_API='DATE_SEARCH_USERS_COMPANIES_VIA_API';
export const SUCCESS_DATE_SEARCH_USERS_COMPANIES='SUCCESS_DATE_SEARCH_USERS_COMPANIES';

export const SHUFFLED_DATA_VIA_API='SHUFFLED_DATA_VIA_API';
export const SUCCESS_SHUFFLED_DATA='SUCCESS_SHUFFLED_DATA';

export const GET_SHUFFLED_DATA_VIA_API='GET_SHUFFLED_DATA_VIA_API';
export const STASTUS_CHANGE_VIA_API='STASTUS_CHANGE_VIA_API';
export const UNSUBSCRIBE_VIA_API='UNSUBSCRIBE_VIA_API';

export const SUCCESS_NEW_COMPANY='SUCCESS_NEW_COMPANY';

export const REMOVE_NEW_COMPANY='REMOVE_NEW_COMPANY';
export const ASSIGN_USERS_DATA_SEARCH_VIA_API='ASSIGN_USERS_DATA_SEARCH_VIA_API';
export const LAST_WEEK_SHUFFLED_DATA_VIA_API='LAST_WEEK_SHUFFLED_DATA_VIA_API';

export const RA_WEEK_REPORTS_VIA_API='RA_WEEK_REPORTS_VIA_API';
export const YESTERDAY_RA_REPORTS_VIA_API='YESTERDAY_RA_REPORTS_VIA_API';

export const ADD_INDUSTRIES_NAMES_VIA_API='ADD_INDUSTRIES_NAMES_VIA_API';

export const DATE_SEARCH_RAUSERS_COMPANIES_VIA_API='DATE_SEARCH_RAUSERS_COMPANIES_VIA_API';

export const ADD_NEW_JOB_VIA_API='ADD_NEW_JOB_VIA_API';







