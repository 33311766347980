import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import theme from "../../Theme";

import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  BDMCustomeDateFilter,
  CustomDateFilter,
  CustomSelect,
  FilterHeader,
  KeyData,
  MainBackground,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import {
  bdmReportDateSearchViaApi,
  searchDateBdmCompanyStatusViaApi,
  searchRAReportsViaApi,
  stopLoadingRedux,
} from "../../store/reports/action";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";
import dayjs from "dayjs";
import Chart from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import CustomSelectThree from "../../Components/Common/CustomSelectThree";
import { getAllUsers } from "../../store/auth/login/actions";
import {
  ShimmerPostList,
  ShimmerSimpleGallery,
  ShimmerTable,
} from "react-shimmer-effects";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactQuill from "react-quill";
import companyStatusElements from "../../Components/StatusElements/companyStatus";
import { useNavigate } from "react-router-dom";
const DashboardAdmin = () => {
  const {
    companyBlocked,
    companyDNC,
    companyNoFollowUp,
    employeeDNC,
    employeeBlocked,
    companyOnProcess,
  } = companyStatusElements;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));

  const client = storeData.clientDb;
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  let companyStatus = useSelector((state) => state.Reports?.companyStatus);
  const raReports = useSelector((state) => state.Reports?.raReports);
  const bdmReports = useSelector((state) => state.Reports?.bdmReports);
  const [loading, setLoading] = useState(true);
  const [loadingBDM, setLoadingBDM] = useState(true);
  const [loadingRA, setLoadingRA] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
  }, []);
  const [searchFromDateBDM, setSearchFromDateBDM] = useState("");

  const [searchToDateBDM, setSearchToDateBDM] = useState("");
  const [searchFromDateBDMStatus, setSearchFromDateBDMStatus] = useState("");
  const [searchToDateBDMStatus, setSearchToDateBDMStatus] = useState("");

  const [searchDashboardCompanyName, setSearchDashboardCompanyName] =
    useState("");
  const [searchDashboardBDMName, setSearchDashboardBDMName] = useState([]);
  const [searchDashboardStatus, setSearchDashboardStatus] = useState([]);
  const [searchDashboardRAName, setSearchDashboardRAName] = useState("");
  const [dashboardBDMName, setDashboardBDMName] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  // Transform the input data

  let allBDMUserCompanies = Array.isArray(bdmReports)
    ? bdmReports
        .reduce((acc, companies) => {
          const useraddedBy = companies.addedBy;
          const existingGroupIndex = acc.findIndex(
            (group) => group.useraddedBy === useraddedBy
          );
          if (existingGroupIndex !== -1) {
            acc[existingGroupIndex].companies.push(companies);
          } else {
            acc.push({ useraddedBy, companies: [companies] });
          }
          return acc;
        }, [])
        .map((group) => ({
          addedBy: group.useraddedBy,
          companies: group.companies,
        }))
    : [];
  let allRAUserCompanies = Array.isArray(raReports)
    ? raReports
        .reduce((acc, companies) => {
          const useraddedBy = companies.leadRAId;
          const existingGroupIndex = acc.findIndex(
            (group) => group.useraddedBy === useraddedBy
          );
          if (existingGroupIndex !== -1) {
            acc[existingGroupIndex].companies.push(companies);
          } else {
            acc.push({ useraddedBy, companies: [companies] });
          }
          return acc;
        }, [])
        .map((group) => ({
          addedBy: group.useraddedBy,
          companies: group.companies,
        }))
    : [];

  let AllBDMReport = allBDMUserCompanies.map((companies, index) => {
    let Positive = [];
    let Open = [];
    let Close = [];
    let Hold = [];
    let Backout = [];
    let InterviewL1 = [];
    let InterviewL2 = [];
    let InterviewL3 = [];
    let Priority = [];
    let Placed = [];
    let Agreement = [];
    let Transfer = [];

    const userData = allUsers.find((user) => user._id === companies.addedBy);
    const bdmName = userData?.userSudoFirstName ?? "";

    if (companies && Array.isArray(companies.companies)) {
      companies.companies.forEach((company) => {
        if (company.transFerBdm) {
          Transfer.push(company);
        } else if (company.jobDetails && Array.isArray(company.jobDetails)) {
          company.jobDetails.forEach((job) => {
            switch (job.status) {
              case "Open":
                Open.push(company);
                break;
              case "Positive":
              case "Active":
                Positive.push(company);
                break;
              case "Close":
                Close.push(company);
                break;
              case "Hold":
                Hold.push(company);
                break;
              case "Backout":
                Backout.push(company);
                break;
              case "Interview L1":
                InterviewL1.push(company);
                break;
              case "Interview L2":
                InterviewL2.push(company);
                break;
              case "Interview L3":
                InterviewL3.push(company);
                break;
              case "Priority":
                Priority.push(company);
                break;
              case "Placed":
                Placed.push(company);
                break;
              case "Agreement":
                Agreement.push(company);
                break;
            }
          });
        }
      });
    }

    return {
      id: index + 1, // Corrected to use the index properly
      bdmName: bdmName,
      Positive: Positive,
      Open: Open,
      Close: Close,
      Hold: Hold,
      Backout: Backout,
      InterviewL1: InterviewL1,
      InterviewL2: InterviewL2,
      InterviewL3: InterviewL3,
      Priority: Priority,
      Placed: Placed,
      Agreement: Agreement,
      Transfer: Transfer,
    };
  });
  const filterAllBDMReport = Array.isArray(AllBDMReport)
    ? AllBDMReport.filter((data) => {
        const lowerBdmName = data.bdmName || "";
        const SearchEmailReportBDMName = dashboardBDMName || [];
        // Filter logic
        return (
          SearchEmailReportBDMName.length === 0 ||
          SearchEmailReportBDMName.includes(lowerBdmName)
        );
      })
    : [];
  let AllRAReport = allRAUserCompanies
    .map((companies, index) => {
      let Positive = [];
      let Open = [];
      let Close = [];
      let Hold = [];
      let Backout = [];
      let InterviewL1 = [];
      let InterviewL2 = [];
      let InterviewL3 = [];
      let Priority = [];
      let Placed = [];
      let Agreement = [];
      let Transfer = [];

      const userData = allUsers.find((user) => user._id === companies.addedBy);
      const raName = userData?.userFirstName ?? "";

      if (companies && Array.isArray(companies.companies)) {
        companies.companies.forEach((company) => {
          if (company.transFerBdm) {
            Transfer.push(company);
          } else if (company.jobDetails && Array.isArray(company.jobDetails)) {
            company.jobDetails.forEach((job) => {
              switch (job.status) {
                case "Open":
                  Open.push(company);
                  break;
                case "Positive":
                case "Active":
                  Positive.push(company);
                  break;
                case "Close":
                  Close.push(company);
                  break;
                case "Hold":
                  Hold.push(company);
                  break;
                case "Backout":
                  Backout.push(company);
                  break;
                case "Interview L1":
                  InterviewL1.push(company);
                  break;
                case "Interview L2":
                  InterviewL2.push(company);
                  break;
                case "Interview L3":
                  InterviewL3.push(company);
                  break;
                case "Priority":
                  Priority.push(company);
                  break;
                case "Placed":
                  Placed.push(company);
                  break;
                case "Agreement":
                  Agreement.push(company);
                  break;
              }
            });
          }
        });
      }

      if (raName) {
        return {
          id: index + 1,
          raName: raName,
          Open: Open,
          Positive: Positive,
          Close: Close,
          Hold: Hold,
          Backout: Backout,
          InterviewL1: InterviewL1,
          InterviewL2: InterviewL2,
          InterviewL3: InterviewL3,
          Priority: Priority,
          Placed: Placed,
          Agreement: Agreement,
          Transfer: Transfer,
        };
      }

      // Return null to filter it out later
      return null;
    })
    .filter((report) => report !== null); // Filter out the null values
  const filterAllRAReport = Array.isArray(AllRAReport)
    ? AllRAReport.filter((data) => {
        const lowerRaName = data.raName || "";
        const SearchEmailReportRAName = searchDashboardRAName || [];
        // Filter logic
        return (
          SearchEmailReportRAName.length === 0 ||
          SearchEmailReportRAName.includes(lowerRaName)
        );
      })
    : [];
  const aggregateCounts = (reports) => {
    const aggregated = {
      Positive: 0,
      Open: 0,
      Close: 0,
      Hold: 0,
      Backout: 0,
      InterviewL1: 0,
      InterviewL2: 0,
      InterviewL3: 0,
      Priority: 0,
      Placed: 0,
      Agreement: 0,
      Transfer: 0,
    };

    reports.forEach((report) => {
      aggregated.Positive += report.Positive.length;
      aggregated.Open += report.Open.length;
      aggregated.Close += report.Close.length;
      aggregated.Hold += report.Hold.length;
      aggregated.Backout += report.Backout.length;
      aggregated.InterviewL1 += report.InterviewL1.length;
      aggregated.InterviewL2 += report.InterviewL2.length;
      aggregated.InterviewL3 += report.InterviewL3.length;
      aggregated.Priority += report.Priority.length;
      aggregated.Placed += report.Placed.length;
      aggregated.Agreement += report.Agreement.length;
      aggregated.Transfer += report.Transfer.length;
    });

    return aggregated;
  };
  const AllBDMReportCount = aggregateCounts(
    selectedRows.length > 0 ? selectedRows : filterAllBDMReport
  );
  const ReportsData = [
    ["Steps", "No", { role: "style" }],
    ["Job Positives", AllBDMReportCount.Positive, "#AAFF00"],
    ["Job Openings", AllBDMReportCount.Open, "#3366CC"],
    ["Job Closed", AllBDMReportCount.Close, "#DC3912"],
    ["Job Hold", AllBDMReportCount.Hold, "#FF9900"],
    ["Shared", AllBDMReportCount.Transfer, "#109618"],
    ["Agreement", AllBDMReportCount.Agreement, "#990099"],
    ["Interview L1", AllBDMReportCount.InterviewL1, "#DD4477"],
    ["Interview L2", AllBDMReportCount.InterviewL2, "#66AA00"],
    ["Interview L3", AllBDMReportCount.InterviewL3, "#B82E2E"],
    ["Priority", AllBDMReportCount.Priority, "#316395"],
    ["Placed", AllBDMReportCount.Placed, "#994499"],
    ["Backout", AllBDMReportCount.Backout, "#22AA99"],
  ];

  const options = {
    chart: {
      title: "Performance",
    },
    hAxis: {
      textStyle: {
        fontSize: 12,
        slantedText: true,
        slantedTextAngle: 90,
      },
    },
    vAxis: {
      //title: "Steps",
      titleTextStyle: {
        //fontSize: 18,
        color: theme.palette.primary.main,
        italic: false,
        bold: true,
      },
      textStyle: {
        fontSize: 12,
        slantedText: true,
        slantedTextAngle: 75,
      },
    },
    //bars: "horizontal",
    bar: {
      groupWidth: "80%",
    },
    legend: {
      position: "none",
    },
    chartArea: {
      // Adjust chart area if needed
      top: 20,
      bottom: 80,
      right: 20,
      left: 50,
      // width: "80%",
      // height: "80%",
    },

    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    },
  };

  // Modify data to include values in the legend labels
  const modifiedData = ReportsData.map((row, index) => {
    if (index === 0) {
      return row;
    }
    return [`${row[0]} (${row[1]})`, row[1], row[2]];
  });
  const optionsPie = {
    //title: "My Daily Activities",
    pieHole: 0.4,
    //is3D: true,
    //legend: "none",
    //pieSliceText: "label",
    chartArea: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    },
    //pieSliceText: "none", // Avoid clutter in the pie slices
    legend: {
      position: "right",
      alignment: "center",
      maxLines: 30, // Increase the max lines to show all legends at once
      textStyle: {
        fontSize: 11,
      },
    },
  };

  // Function to extract state information from jobLocation
  const extractStates = (data) => {
    const stateCount = {};

    data.forEach((entry) => {
      // Iterate through all job categories
      Object.keys(entry).forEach((category) => {
        const items = entry[category];
        if (Array.isArray(items)) {
          items.forEach((item) => {
            // Validate that jobDetails is an array
            if (Array.isArray(item.jobDetails)) {
              item.jobDetails.forEach((detail) => {
                // Extract and count states from jobLocation
                if (typeof detail.jobLocation === "string") {
                  const parts = detail.jobLocation.split(", ");
                  if (parts.length > 1) {
                    const state = parts[1].trim();
                    if (state) {
                      stateCount[state] = (stateCount[state] || 0) + 1;
                    }
                  }
                }
              });
            }
          });
        }
      });
    });

    // Convert stateCount object to array format
    return Object.entries(stateCount);
  };

  // Example usage

  const resultextractStates = extractStates(
    selectedRows.length > 0 ? selectedRows : filterAllBDMReport
  );

  //////
  const geoData = [["State", "Job Openings"], ...resultextractStates];

  const geoOptions = {
    region: "US", // Focus on the United States
    displayMode: "regions", // Use 'markers' for cities
    resolution: "provinces", // Show states
    colorAxis: {
      colors: ["#E7EFF8", "#6DA2D6", "#0C64BB", "#0A5096", "#073C70"],
    }, // Adjust the color range as needed
    //legend: "show", // Hide the legend
    legend: {
      position: "bottom",
      alignment: "center",
      maxLines: 80,
      textStyle: {
        fontSize: 11,
      },
    },
    chartArea: {
      top: 0,
      bottom: 0, // Increase bottom padding for the legend
      left: 0,
      right: 0,
      //width: "100%", // Ensure full width
      //height: "180%", // Adjust height if needed
    },
    datalessRegionColor: "#f8bbd0", // Color for regions with no data
    defaultColor: "#f5f5f5", // Default color
    resolution: "provinces", // Show states
  };

  //////

  const BDMReportscolumns = [
    {
      field: "bdmName",
      headerName: "BDM Name",
      width: 180,
      minWidth: 180,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setDashboardBDMName={setDashboardBDMName}
          selectedValue={dashboardBDMName}
          title={"bdmNameDasboard"}
          options={[
            ...new Set(
              AllBDMReport.filter((user) => user.bdmName).map(
                (user) => user.bdmName
              )
            ),
          ]}
        />
      ),
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              navigate("/bdmLeadAnalysisView", {
                state: {
                  report: params.row,
                  bdmName: params.row.bdmName,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            {params.row.bdmName}
          </div>
        );
      },
    },
    {
      field: "Positive",
      headerName: "Positives",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.Positive,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Positive && params.row.Positive.length > 0
            ? params.row.Positive.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Open",
      headerName: "Openings",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: { report: params.row.Open, bdmName: params.row.bdmName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Open && params.row.Open.length > 0
            ? params.row.Open.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Close",
      headerName: "Closed",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: { report: params.row.Close, bdmName: params.row.bdmName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Close && params.row.Close.length > 0
            ? params.row.Close.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Hold",
      headerName: "Hold",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: { report: params.row.Hold, bdmName: params.row.bdmName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Hold && params.row.Hold.length > 0
            ? params.row.Hold.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Transfer",
      headerName: "Shared",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.Transfer,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Transfer && params.row.Transfer.length > 0
            ? params.row.Transfer.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Priority",
      headerName: "Priority",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.Priority,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Priority && params.row.Priority.length > 0
            ? params.row.Priority.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Agreement",
      headerName: "Agreement",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.Agreement,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Agreement && params.row.Agreement.length > 0
            ? params.row.Agreement.length
            : "--"}
        </div>
      ),
    },
    {
      field: "InterviewL1",
      headerName: "L1",
      width: 50,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.InterviewL1,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.InterviewL1 && params.row.InterviewL1.length > 0
            ? params.row.InterviewL1.length
            : "--"}
        </div>
      ),
    },
    {
      field: "InterviewL2",
      headerName: "L2",
      width: 50,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.InterviewL2,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.InterviewL2 && params.row.InterviewL2.length > 0
            ? params.row.InterviewL2.length
            : "--"}
        </div>
      ),
    },
    {
      field: "InterviewL3",
      headerName: "L3",
      width: 50,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.InterviewL3,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.InterviewL3 && params.row.InterviewL3.length > 0
            ? params.row.InterviewL3.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Placed",
      headerName: "Placed",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: { report: params.row.Placed, bdmName: params.row.bdmName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Placed && params.row.Placed.length > 0
            ? params.row.Placed.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Backout",
      headerName: "Backout",
      width: 90,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/bdmLeadAnalysisView", {
              state: {
                report: params.row.Backout,
                bdmName: params.row.bdmName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Backout && params.row.Backout.length > 0
            ? params.row.Backout.length
            : "--"}
        </div>
      ),
    },
  ];
  const RAcolumns = [
    {
      field: "raName",
      headerName: "RA Name",
      width: 180,
      minWidth: 180,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setSearchDashboardRAName={setSearchDashboardRAName}
          selectedValue={searchDashboardRAName}
          title={"RANameDasboard"}
          options={[
            ...new Set(
              AllRAReport.filter((user) => user.raName).map(
                (user) => user.raName
              )
            ),
          ]}
        />
      ),
      renderCell: (params) => {
        return (
          <div
            onClick={() => {
              navigate("/RaAnalysisView", {
                state: {
                  report: params.row,
                  raName: params.row.raName,
                },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            {params.row.raName}
          </div>
        );
      },
    },
    {
      field: "Positive",
      headerName: "Positives",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Positive, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Positive && params.row.Positive.length > 0
            ? params.row.Positive.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Open",
      headerName: "Openings",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Open, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Open && params.row.Open.length > 0
            ? params.row.Open.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Close",
      headerName: "Closed",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Close, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Close && params.row.Close.length > 0
            ? params.row.Close.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Hold",
      headerName: "Hold",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Hold, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Hold && params.row.Hold.length > 0
            ? params.row.Hold.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Transfer",
      headerName: "Shared",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Transfer, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Transfer && params.row.Transfer.length > 0
            ? params.row.Transfer.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Priority",
      headerName: "Priority",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Priority, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Priority && params.row.Priority.length > 0
            ? params.row.Priority.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Agreement",
      headerName: "Agreement",
      width: 100,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: {
                report: params.row.Agreement,
                raName: params.row.raName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Agreement && params.row.Agreement.length > 0
            ? params.row.Agreement.length
            : "--"}
        </div>
      ),
    },
    {
      field: "InterviewL1",
      headerName: "L1",
      width: 50,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: {
                report: params.row.InterviewL1,
                raName: params.row.raName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.InterviewL1 && params.row.InterviewL1.length > 0
            ? params.row.InterviewL1.length
            : "--"}
        </div>
      ),
    },
    {
      field: "InterviewL2",
      headerName: "L2",
      width: 50,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: {
                report: params.row.InterviewL2,
                raName: params.row.raName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.InterviewL2 && params.row.InterviewL2.length > 0
            ? params.row.InterviewL2.length
            : "--"}
        </div>
      ),
    },
    {
      field: "InterviewL3",
      headerName: "L3",
      width: 50,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: {
                report: params.row.InterviewL3,
                raName: params.row.raName,
              },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.InterviewL3 && params.row.InterviewL3.length > 0
            ? params.row.InterviewL3.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Placed",
      headerName: "Placed",
      width: 80,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Placed, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Placed && params.row.Placed.length > 0
            ? params.row.Placed.length
            : "--"}
        </div>
      ),
    },
    {
      field: "Backout",
      headerName: "Backout",
      width: 90,
      valueGetter: (params) => {
        if (params && params.length > 0) {
          return params.length;
        } else {
          return "--";
        }
      },
      renderCell: (params) => (
        <div
          onClick={() => {
            navigate("/RaAnalysisView", {
              state: { report: params.row.Backout, raName: params.row.raName },
            });
          }}
          style={{ cursor: "pointer" }}
        >
          {params.row.Backout && params.row.Backout.length > 0
            ? params.row.Backout.length
            : "--"}
        </div>
      ),
    },
  ];

  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };

  const [closeReasonContent, setCloseReasonContent] = useState("");
  const [closeReasonModal, setCloseReasonModal] = useState(false);
  const quillRef = useRef(null);
  const handleCloseReasonModal = () => {
    setCloseReasonModal(false);
    setCloseReasonContent("");
  };
  const containsHTML = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    return Array.from(div.childNodes).some((node) => node.nodeType === 1); // Check if any child node is an element
  };
  const convertToHTML = (text) => {
    return `<p>${text}</p>`;
  };
  const handleClick = (reason) => {
    if (containsHTML(reason)) {
      setCloseReasonContent(reason);
      setCloseReasonModal(true);
    } else {
      const htmlReason = convertToHTML(reason);
      setCloseReasonContent(htmlReason);
      setCloseReasonModal(true);
    }
  };

  const [selectedRowsRA, setSelectedRowRA] = useState([]);

  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoadingRA(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        raUsers:
          allUsers &&
          allUsers.filter((user) => user.userType.includes("RATeam")),
        client: client,
      };
      dispatch(searchRAReportsViaApi(action));
    }
  };
  const handleDateChangeBDM = (selectedDates) => {
    setSearchFromDateBDM("");
    setSearchToDateBDM("");
    setLoadingBDM(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDateBDM(formattedDates[0]);
      setSearchToDateBDM(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        bdmUsers:
          allUsers && allUsers.filter((user) => user.userType.includes("BDM")),
        client: client,
      };

      dispatch(bdmReportDateSearchViaApi(action));
    }
  };
  const handleDateChangeBDMCompanyStatus = (selectedDates) => {
    setSearchFromDateBDMStatus("");
    setSearchToDateBDMStatus("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });
      const [fromDate, toDate] = selectedDates;
      setSearchFromDateBDMStatus(formattedDates[0]);
      setSearchToDateBDMStatus(formattedDates[1]);
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        bdmUsers:
          allUsers && allUsers.filter((user) => user.userType.includes("BDM")),
        client: client,
      };
      dispatch(searchDateBdmCompanyStatusViaApi(action));
    }
  };

  const companiesListWithStatus = Array.isArray(companyStatus)
    ? companyStatus.map((company, index) => {
        const user = allUsers.find((user) => user._id === company.addedBy);
        return {
          ...company,
          bdmName: user ? user.userSudoFirstName : "--",
          id: index + 1,
        };
      })
    : [];
  const filterCompaniesListWithStatus = Array.isArray(companiesListWithStatus)
    ? companiesListWithStatus.filter((data) => {
        // Convert necessary fields to lower case for case-insensitive comparison
        const lowerCaseCompanyName = data.companyName?.toLowerCase() || "";
        const lowerBdmName = data.bdmName || ""; // Convert to lower case
        let lowerStatus = data.status || ""; // Convert to lower case

        if (lowerStatus === companyOnProcess) {
          const jobStatus = data.jobDetails?.[0]?.status; // Get job status
          lowerStatus = jobStatus === "Active" ? "Positive" : jobStatus || "";
        }
        // Search term inputs
        const SearchDashboardCompanyName =
          searchDashboardCompanyName?.toLowerCase() || ""; // Convert to lower case
        const SearchDashboardBDMName = searchDashboardBDMName || [];
        const SearchDashboardStatus = searchDashboardStatus || [];

        // Filter logic
        return (
          lowerCaseCompanyName.includes(SearchDashboardCompanyName) &&
          (SearchDashboardBDMName.length === 0 ||
            SearchDashboardBDMName.includes(lowerBdmName)) &&
          (SearchDashboardStatus.length === 0 ||
            SearchDashboardStatus.includes(lowerStatus))
        );
      })
    : [];

  const BdmStatusColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "addedOn",
      headerName: "Date",
      width: 250,
      valueGetter: (params) => {
        if (params) {
          return formatDateForDisplay(params);
        } else {
          return "--";
        }
      },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          setSearchDashboardCompanyName={setSearchDashboardCompanyName}
          selectedValue={searchDashboardCompanyName}
          title={"dashBoardCompanyName"}
        />
      ),
    },
    {
      field: "bdmName",
      headerName: "BDM Name",
      minWidth: 150,
      flex: 1,
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setSearchDashboardBDMName={setSearchDashboardBDMName}
          selectedValue={searchDashboardBDMName}
          title={"dashboardBdmName"}
          options={[
            ...new Set(
              companiesListWithStatus
                .filter((user) => user.bdmName)
                .map((user) => user.bdmName)
            ),
          ]}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        if (row) {
          let status = row.status;
          if (
            row.jobDetails &&
            row.jobDetails.length > 0 &&
            status !== "DNC" &&
            status !== "Blocked" &&
            status !== "No FollowUp"
          ) {
            const company = row.jobDetails[0];
            status = company.status === "Active" ? "Positive" : company.status;
          }
          return (
            <Box>
              <Chip
                label={status}
                size="small"
                sx={{ px: 0.5 }}
                color={
                  status === "Positive"
                    ? "success"
                    : status === "Open"
                    ? "success"
                    : ["Blocked", "Close", "Company blocked"].includes(status)
                    ? "error"
                    : ["DNC", "No FallowUp", "Company DNC"].includes(status)
                    ? "warning"
                    : ["Interview L1", "Interview L2", "Interview L3"].includes(
                        status
                      )
                    ? "info"
                    : status === "Priority"
                    ? "secondary"
                    : "default"
                }
              />
            </Box>
          );
        }
        return "";
      },
      renderHeader: (params) => (
        <FilterHeader
          params={params}
          type="multiselect"
          setSearchDashboardStatus={setSearchDashboardStatus}
          selectedValue={searchDashboardStatus}
          title={"dashboardStatus"}
          options={[
            "Positive",
            "Open",
            "DNC",
            "No FallowUp",
            "Close",
            "Blocked",
            "Hold",
            "Interview L1",
            "Interview L2",
            "Interview L3",
          ]}
        />
      ),
    },
    {
      field: "jobDetails",
      headerName: "Reason",
      width: 80,
      renderCell: (params) => {
        const jobDetails =
          params.row && params.row.jobDetails ? params.row.jobDetails : [];
        const reason =
          jobDetails.length > 0 ? jobDetails[0].closeReason || "--" : "--";
        return (
          <Box>
            {reason !== "--" && (
              <IconButton onClick={() => handleClick(reason)}>
                <VisibilityIcon style={{ marginRight: 5 }} />
              </IconButton>
            )}
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    handleDateChangeBDM([
      dayjs("2024-01-01").tz("America/New_York").startOf("day"),
      dayjs().tz("America/New_York").endOf("day"),
    ]);
    handleDateChange([
      dayjs("2024-01-01").tz("Asia/Kolkata").startOf("day"),
      dayjs().tz("Asia/Kolkata").endOf("day"),
    ]);
    handleDateChangeBDMCompanyStatus([
      dayjs().tz("America/New_York").startOf("day"),
      dayjs().tz("America/New_York").endOf("day"),
    ]);
  }, []);

  const totalBDMCounts = aggregateCounts(selectedRows);
  const totalRACounts = aggregateCounts(selectedRowsRA);
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      setLoadingBDM(false);
      setLoadingRA(false);
      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
        setLoadingBDM(false);
        setLoadingRA(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);

  const handleChartClick = (chartWrapper) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection(); // Get the selected slice

    if (selection.length > 0) {
      const selectedItem = selection[0]; // Selection has row and column information
      const row = selectedItem.row; // Get the row index

      if (row !== null) {
        // Check if a row is selected
        const selectedData = modifiedData[row + 1]; // `+1` because the first row is the header
        const selectedCategory = selectedData[0]; // First element is the category
        const selectedValue = selectedData[1]; // Second element is the value
        const result = selectedCategory?.replace(/\s*\(.*?\)/, ""); // Removes everything inside parentheses
        if (result === "Job Positives") {
          if (Array.isArray(bdmReports)) {
            const positiveResults = bdmReports.filter((report) => {
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Positive";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: positiveResults },
            });
          }
        }
        if (result === "Job Openings") {
          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const openResults = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Open";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: openResults },
            });
          }
        }
        if (result === "Job Closed") {
          // Use strict equality operator (`===`)

          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const closedResults = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Close";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: closedResults },
            });
          }
        }
        if (result === "Agreement") {
          // Use strict equality operator (`===`)

          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const AgreementResults = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Agreement";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: AgreementResults },
            });
          }
        }
        if (result === "Interview L1") {
          // Use strict equality operator (`===`)

          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const InterviewL1Results = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Interview L1";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: InterviewL1Results },
            });
          }
        }
        if (result === "Interview L2") {
          // Use strict equality operator (`===`)

          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const InterviewL2Results = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Interview L2";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: InterviewL2Results },
            });
          }
        }
        if (result === "Priority") {
          // Use strict equality operator (`===`)

          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const PriorityResults = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Priority";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: PriorityResults },
            });
          }
        }
        if (result === "Placed") {
          // Use strict equality operator (`===`)

          if (Array.isArray(bdmReports)) {
            // Filtering the reports based on 'positive' status inside jobDetails array
            const PlacedResults = bdmReports.filter((report) => {
              // Safely access jobDetails and ensure status exists
              if (Array.isArray(report.jobDetails)) {
                return report?.jobDetails[0]?.status === "Placed";
              }
            });
            navigate("/PieChartAnalysisview", {
              state: { report: PlacedResults },
            });
          }
        }
      }
    }
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Dashboard"}>
          <Box>
            {loadingBDM ? (
              <>
                {/* <ShimmerTable row={5} col={5} /> */}
                {/* <ShimmerTable row={5} col={3} /> */}
                <ShimmerSimpleGallery card imageHeight={100} />
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                    <MainBackground height="full" mb={0}>
                      <SectionTitle title={"Reports"} />
                      <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="300px"
                        data={ReportsData}
                        options={options}
                      />
                    </MainBackground>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <MainBackground height="full" mb={0}>
                      <Chart
                        chartType="PieChart"
                        width="100%"
                        height="100%"
                        data={modifiedData}
                        options={optionsPie}
                        chartEvents={[
                          {
                            eventName: "select",
                            callback: ({ chartWrapper }) =>
                              handleChartClick(chartWrapper),
                          },
                        ]}
                      />
                    </MainBackground>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                    <MainBackground height="full" mb={0}>
                      <Chart
                        chartType="GeoChart"
                        width="100%"
                        height="100%"
                        data={geoData}
                        options={geoOptions}
                      />
                    </MainBackground>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>

          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
                <MainBackground height="full">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "top",
                    }}
                  >
                    <Box>
                      <SectionTitle title={"BDM Reports"} />
                    </Box>

                    <BDMCustomeDateFilter
                      handleDateChange={handleDateChangeBDM}
                      tillDate={"Till Date"}
                    />
                  </Box>
                  <Box>
                    {loadingBDM ? (
                      <>
                        {/* <ShimmerTable row={5} col={5} /> */}
                        <ShimmerTable row={5} col={5} />
                      </>
                    ) : (
                      <>
                        <DataGrid
                          rows={filterAllBDMReport}
                          columns={BDMReportscolumns}
                          checkboxSelection
                          onRowSelectionModelChange={(ids) => {
                            const selectedIDs = new Set(ids);
                            const selectedRows = AllBDMReport.filter((row) =>
                              selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRows);
                          }}
                          disableColumnMenu
                          disableColumnSorting
                          disableColumnSelector
                          disableColumnFilter
                          disableColumnReorder
                          disableColumnResize
                          //disableRowSelectionOnClick
                          disableColumnSort
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 25 },
                            },
                          }}
                          pageSizeOptions={[25, 50, 100]}
                          getRowHeight={() => "auto"}
                          autoHeight
                          sx={{
                            "& .MuiDataGrid-cell": {
                              display: "flex",
                              alignItems: "center !important",
                            },
                            [`& .MuiDataGrid-checkboxInput`]: {
                              color: theme.palette.primary.main,
                              p: 0,

                              "&.Mui-checked": {
                                color: theme.palette.primary.dark,
                              },
                            },
                            //Scroller
                            [`& .MuiDataGrid-scrollbar`]: {
                              scrollbarWidth: "thin",
                              scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                            },
                            [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                              {
                                borderRadius: "10px", // Apply border radius
                              },

                            [`& .MuiDataGrid-scrollbar:hover`]: {
                              scrollbarColor: `${theme.palette.primary.dark}`,
                            },
                          }}
                        />
                      </>
                    )}
                  </Box>
                </MainBackground>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                <MainBackground height="full">
                  <SectionTitle title={"Selected BDM Details"} />
                  {loadingBDM ? (
                    <>
                      {/* <ShimmerTable row={5} col={5} /> */}
                      <ShimmerTable row={5} col={5} />
                    </>
                  ) : (
                    <>
                      <Grid container>
                        <Grid xs={12}>
                          <KeyData
                            title={"BDM's"}
                            value={
                              selectedRows.length > 0
                                ? selectedRows
                                    .map((item) => item.bdmName)
                                    .join(", ")
                                : "BDM Not Selected"
                            }
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Job Positive"}
                            value={totalBDMCounts.Positive}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Job Openings"}
                            value={totalBDMCounts.Open}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Job Closed"}
                            value={totalBDMCounts.Close}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Job Hold"}
                            value={totalBDMCounts.Hold}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Shared"}
                            value={totalBDMCounts.Transfer}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Agreement"}
                            value={totalBDMCounts.Agreement}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Interview L1"}
                            value={totalBDMCounts.InterviewL1}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Interview L2"}
                            value={totalBDMCounts.InterviewL2}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Interview L3"}
                            value={totalBDMCounts.InterviewL3}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Priority"}
                            value={totalBDMCounts.Priority}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Placed"}
                            value={totalBDMCounts.Placed}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} md={4}>
                          <KeyData
                            title={"Backout"}
                            value={totalBDMCounts.Backout}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </MainBackground>
              </Grid>
            </Grid>
          </Box>
          {/* {storeData.userEmailId !== "naren@tektreeinc.com" && ( */}
            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
                  <MainBackground height="full">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "top",
                      }}
                    >
                      <Box>
                        <SectionTitle title={"RA Reports"} />
                      </Box>

                      <CustomDateFilter
                        handleDateChange={handleDateChange}
                        tillDate={"Till Date"}
                      />
                    </Box>
                    <Box>
                      {loadingRA ? (
                        <>
                          {/* <ShimmerTable row={5} col={5} /> */}
                          <ShimmerTable row={5} col={5} />
                        </>
                      ) : (
                        <>
                          <DataGrid
                            rows={filterAllRAReport}
                            columns={RAcolumns}
                            checkboxSelection
                            onRowSelectionModelChange={(ids) => {
                              const selectedIDs = new Set(ids);
                              const selectedRows = AllRAReport.filter((row) =>
                                selectedIDs.has(row.id)
                              );
                              setSelectedRowRA(selectedRows);
                            }}
                            disableColumnMenu
                            disableColumnSorting
                            disableColumnSelector
                            disableColumnFilter
                            disableColumnReorder
                            disableColumnResize
                            //disableRowSelectionOnClick
                            disableColumnSort
                            initialState={{
                              pagination: {
                                paginationModel: { page: 0, pageSize: 25 },
                              },
                            }}
                            pageSizeOptions={[25, 50, 100]}
                            getRowHeight={() => "auto"}
                            autoHeight
                            sx={{
                              "& .MuiDataGrid-cell": {
                                display: "flex",
                                alignItems: "center !important",
                              },
                              [`& .MuiDataGrid-checkboxInput`]: {
                                color: theme.palette.primary.main,
                                p: 0,

                                "&.Mui-checked": {
                                  color: theme.palette.primary.dark,
                                },
                              },
                              //Scroller
                              [`& .MuiDataGrid-scrollbar`]: {
                                scrollbarWidth: "thin",
                                scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                              },
                              [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                                {
                                  borderRadius: "10px", // Apply border radius
                                },

                              [`& .MuiDataGrid-scrollbar:hover`]: {
                                scrollbarColor: `${theme.palette.primary.dark}`,
                              },
                            }}
                          />
                        </>
                      )}
                    </Box>
                  </MainBackground>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
                  <MainBackground height="full">
                    <SectionTitle title={"Selected RA Details"} />
                    {loadingRA ? (
                      <>
                        {/* <ShimmerTable row={5} col={5} /> */}
                        <ShimmerTable row={5} col={5} />
                      </>
                    ) : (
                      <>
                        <Grid container>
                          <Grid xs={12}>
                            <KeyData
                              title={"RA's"}
                              value={
                                selectedRowsRA.length > 0
                                  ? selectedRowsRA
                                      .map((item) => item.raName)
                                      .join(", ")
                                  : "RA Not Selected"
                              }
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Job Openings"}
                              value={totalRACounts.Open}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Job Positive"}
                              value={totalRACounts.Positive}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Job Closed"}
                              value={totalRACounts.Close}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Job Hold"}
                              value={totalRACounts.Hold}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Shared"}
                              value={totalRACounts.Transfer}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Agreement"}
                              value={totalRACounts.Agreement}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Interview L1"}
                              value={totalRACounts.InterviewL1}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Interview L2"}
                              value={totalRACounts.InterviewL2}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Interview L3"}
                              value={totalRACounts.InterviewL3}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Priority"}
                              value={totalRACounts.Priority}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Placed"}
                              value={totalRACounts.Placed}
                            />
                          </Grid>
                          <Grid xs={12} sm={6} md={4}>
                            <KeyData
                              title={"Backout"}
                              value={totalRACounts.Backout}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </MainBackground>
                </Grid>
              </Grid>
            </Box>
          {/* // )} */}
          <Box py={2}>
            <MainBackground>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "self-end",
                }}
              >
                <Box>
                  <SectionTitle title="Company Status" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "self-end",
                    gap: 1,
                    flex: 1,
                  }}
                >
                  <Box flex={1}>
                    <BDMCustomeDateFilter
                      handleDateChange={handleDateChangeBDMCompanyStatus}
                    />
                  </Box>
                </Box>
              </Box>
              {loading ? (
                <>
                  {/* <ShimmerTable row={5} col={5} /> */}
                  <ShimmerTable row={5} col={5} />
                </>
              ) : (
                <>
                  <DataGrid
                    rows={filterCompaniesListWithStatus}
                    columns={BdmStatusColumns}
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnResize
                    hideFooter={filterCompaniesListWithStatus.length < 5}
                    disableColumnSort
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                      },
                    }}
                    pageSizeOptions={[100]}
                    getRowHeight={() => "auto"}
                    autoHeight
                    sx={{
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        alignItems: "center !important",
                      },
                      [`& .MuiDataGrid-checkboxInput`]: {
                        color: theme.palette.primary.main,
                        p: 0,

                        "&.Mui-checked": {
                          color: theme.palette.primary.dark,
                        },
                      },
                      //Scroller
                      [`& .MuiDataGrid-scrollbar`]: {
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      },
                      [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                        borderRadius: "10px", // Apply border radius
                      },

                      [`& .MuiDataGrid-scrollbar:hover`]: {
                        scrollbarColor: `${theme.palette.primary.dark}`,
                      },
                    }}
                  />
                </>
              )}
            </MainBackground>
          </Box>

          <Modal
            open={closeReasonModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "calc(100% - 32px)",
                maxWidth: 900,
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                m: 2,
                p: 4,
              }}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Reason for Closing
              </Typography>

              <Box
                sx={{
                  maxHeight: "70vh",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "5px", // width of the scrollbar
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: theme.palette.primary[400],
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 5,
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                }}
              >
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  value={closeReasonContent}
                  placeholder="Paste Email respone/thread here.. "
                  modules={{
                    toolbar: false, // Disable the toolbar
                  }}
                  readOnly={true} // Make the editor read-only
                />
              </Box>

              <Box></Box>
              <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={handleCloseReasonModal} sx={{ mr: 1 }}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default DashboardAdmin;
