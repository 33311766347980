import React, { useEffect, useRef, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Tab,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomFormField, MainBackground, SectionTitle } from "..";
import theme from "../../Theme";
import InternalDB from "../JobBoardsResult/InternalDB";
import { defaults } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addJobBoardsHistoryViaApi,
  clearSearchResults,
  deleteJobBoardsHistoryViaApi,
  getJobBoardsHistoryViaApi,
  getjobBoardsStatusHistoryViaApi,
  saveJobBoardsHistoryViaApi,
  searchResumeInternalDbViaApi,
  stopLoadingRedux,
} from "../../store/actions";
import { useNavigate } from "react-router-dom";
import { countryMonsterOptions } from "../../Data/JobPostingData";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import dayjs from "dayjs";
import moment from "moment-timezone";
import SearchHistorySection from "../JobBoardsResult/SearchHistorySection";

const JobBoardInternalDb = () => {
  let navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const JobBoardHistory = useSelector(
    (state) => state.JobBoards.jobBoardsHistory
  );
  const JobBoardsHistoryRecent = Array.isArray(JobBoardHistory)
    ? JobBoardHistory.filter(
        (job) => job.searchType === "InternalDB" && job.status === "recent"
      )
    : [];
  const JobBoardsHistorySaved = Array.isArray(JobBoardHistory)
    ? JobBoardHistory.filter(
        (job) => job.searchType === "InternalDB" && job.status === "saved"
      )
    : [];

  const InternalResumesList = useSelector(
    (state) => state.Resumes.internalResumesList
  );
  const [loading, setLoading] = useState(false);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  const [keyWords, setKeyWords] = useState();
  const [name, setName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");

  const [keyWordError, setKeyWordError] = useState();
  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  useEffect(() => {
    // Dynamically set the height of the content when options are toggled
    if (contentRef.current) {
      setHeight(showOptions ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [showOptions]);
  const [limitPage, setLimtPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearchInternalDB = () => {
    setKeyWordError("");

    if (!keyWords && !name && !jobTitle) {
      setKeyWordError("This filed is required.");
      return; // This will stop further execution and prevent API call
    }
    setLoading(true);
    const action = {
      client: client,
      keyWords: keyWords,
      name: name,
      jobTitle: jobTitle,
      pageSize: limitPage,
      page: currentPage,
    };
    dispatch(clearSearchResults());
    dispatch(searchResumeInternalDbViaApi(action));
    const historyAction = {
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: "recent",
      searchType: "InternalDB",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      navigate("/jobBoardsResult", {
        state: { ...action, keyWords: keyWords, searchType: "InternalDB" },
      });
    }, 2000);
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Update the current page
    setKeyWordError("");

    if (!keyWords && !name && !jobTitle) {
      setKeyWordError("This filed is required.");
      return; // This will stop further execution and prevent API call
    }
    setLoading(true);
    const action = {
      client: client,
      keyWords: keyWords,
      name: name,
      jobTitle: jobTitle,
      pageSize: limitPage,
      page: value,
    };
    dispatch(clearSearchResults());
    dispatch(searchResumeInternalDbViaApi(action));
  };
  const handleResetSearch = () => {
    setKeyWords("");
    setName("");
    setJobTitle("");
    setKeyWordError("");
  };
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const handleClose = () => "";
  const [openTabId, setOpenTabId] = useState("recentHistory");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
    if (newValue === "recentHistory") {
      const action = {
        client: client,
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "recent",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    } else {
      const action = {
        client: client,
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }
  };

  const handleClickHistory = (value) => {
    const action = value.query;
    setLoading(true);
    dispatch(searchResumeInternalDbViaApi(action));
    const historyAction = {
      id: value._id,
      query: action,
      addedBy: storeData._id,
      addedOn: new Date(),
      client: client,
      status: value.status,
      searchType: "InternalDB",
    };
    dispatch(addJobBoardsHistoryViaApi(historyAction));
    setTimeout(() => {
      dispatch(getJobBoardsHistoryViaApi(historyAction));
      navigate("/jobBoardsResult", {
        state: {
          ...action,
          keyWords: action.keyWords,
          searchType: "InternalDB",
        },
      });
    }, 2000);
  };
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [historyTitle, setHistoryTitle] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [selectHistory, setSelectHistory] = useState("");
  const [deleteHistoryModal, setDeleteHistoryModal] = useState(false);
  const handleClickSaveHistory = (value) => {
    setHistoryTitle("");
    setErrTitle("");
    setSelectHistory("");
    if (value) {
      setAddNoteModal(!addNoteModal);
      setSelectHistory(value);
    }
  };
  const handleClickDeleteHistory = (value) => {
    if (value) {
      setSelectHistory(value);
      setDeleteHistoryModal(!deleteHistoryModal);
    }
  };
  const handleConfirmHistorySave = () => {
    setErrTitle("");
    if (!selectHistory && !historyTitle) {
      setErrTitle("please type title");
      return "";
    }
    const action = {
      id: selectHistory._id,
      client: client,
      title: historyTitle,
    };
    dispatch(saveJobBoardsHistoryViaApi(action));
    setLoading(true);
    setAddNoteModal(!addNoteModal);
    setTimeout(() => {
      const action = {
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "recent",
        client: client,
      };
      dispatch(getjobBoardsStatusHistoryViaApi(action));
    }, 2000);
  };
  const handleConfirmHistoryDelete = () => {
    const action = {
      id: selectHistory._id,
      client: client,
    };
    dispatch(deleteJobBoardsHistoryViaApi(action));
    setLoading(true);
    setDeleteHistoryModal(!deleteHistoryModal);
    setTimeout(() => {
      const fetchAction = {
        client: client,
        addedBy: storeData._id,
        searchType: "InternalDB",
        status: "saved",
      };
      dispatch(getjobBoardsStatusHistoryViaApi(fetchAction));
    }, 2000);
  };
  return (
    <Box>
      <MainBackground p={0}>
        <Grid container>
          <Grid item xs={8}>
            <Box
              sx={{
                p: 2,
                bgcolor: theme.palette.primary[100],
              }}
            >
              <Box sx={{ mb: 3 }}>
                <SectionTitle
                  title="Applicant Search"
                  size="sm"
                  color="secondary"
                />
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CustomFormField
                    title={"Keyword"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          placeholder: "skills",
                          required: true,
                          defaultValue: keyWords,
                          onChange: (value) => setKeyWords(value),
                          error: !!keyWordError,
                          errorMessage: keyWordError,
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Name"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          required: true,
                          defaultValue: name,
                          onChange: (value) => setName(value),
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Job Title"}
                    required
                    components={[
                      {
                        type: "text",
                        props: {
                          required: true,
                          defaultValue: jobTitle,
                          onChange: (value) => setJobTitle(value),
                          // error:!!errorKeyword,
                          // errorMessage:errorKeyword
                        },
                      },
                    ]}
                  />
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label="Recent Job Title"
                      componentsProps={{
                        typography: { sx: { fontSize: "12px" } },
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} display={"none"}>
                  <CustomFormField
                    components={[
                      {
                        type: "radio",
                        props: {
                          title: "",

                          options: [
                            "Search with in Selected Location",
                            "Search with in Radius",
                          ],
                          defaultValue: "Search with in Selected Location",
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Country"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "Country",
                          defaultValue: "",
                          options: ["Selct-01", "Selct-02"],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"State"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "State",
                          defaultValue: "",
                          options: ["Selct-01", "Selct-02"],
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <CustomFormField
                    title={"City"}
                    components={[
                      {
                        type: "select",
                        props: {
                          title: "City",
                          defaultValue: "",
                          options: ["Selct-01", "Selct-02"],
                        },
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={toggleOptions}
                  >
                    Advanced Options
                  </Button>

                  <Box
                    sx={{
                      height,
                      overflow: "hidden",
                      transition: "height 0.3s ease-in-out",

                      mt: 2,
                    }}
                    ref={contentRef}
                  >
                    <Box sx={{ mt: 5, mb: 2 }}>
                      <SectionTitle
                        title="Advanced Options"
                        size="sm"
                        color="secondary"
                      />
                    </Box>
                    <Grid container spacing={3}>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Total Experience"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Min Yrs",
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "months",
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "Max Yrs",
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "months",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Current CTC"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Min",
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "Max",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Slect",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Expected CTC"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Min",
                              },
                            },
                            {
                              type: "text",
                              props: {
                                placeholder: "Max",
                              },
                            },
                            {
                              type: "select",
                              props: {
                                title: "Slect",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Educational Details"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Slect",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Preferred Location"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Preferred Location",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <Box>
                            <CustomFormField
                              title={"Notice Period"}
                              required
                              components={[
                                {
                                  type: "select",
                                  props: {
                                    title: "Slect",
                                    defaultValue: "",
                                    options: ["Selct-01", "Selct-02"],
                                  },
                                },
                              ]}
                            />
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <CustomFormField
                              components={[
                                {
                                  type: "datepicker",
                                  props: {
                                    required: true,
                                    placeholder: "From Date",
                                    //defaultValue: details.yearCompleted,
                                  },
                                },
                                {
                                  type: "datepicker",
                                  props: {
                                    required: true,
                                    placeholder: "To Date",
                                    //defaultValue: details.yearCompleted,
                                  },
                                },
                              ]}
                            />
                            <Button sx={{ mt: 1 }}>Clear</Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Function"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Function",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Industry"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Industry",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Work Authorization"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Work Authorization",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Employer"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "Employer",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Willing to Relocate"}
                          required
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Willing to Relocate",
                                required: true,
                                options: ["Yes", "No"],
                                defaultValue: "no",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Clearance"}
                          required
                          components={[
                            {
                              type: "radio",
                              props: {
                                title: "Clearance",
                                required: true,
                                options: ["Yes", "No"],
                                defaultValue: "no",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"GPA"}
                          required
                          components={[
                            {
                              type: "text",
                              props: {
                                placeholder: "GPA",
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Domain"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Domain",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SectionTitle size="sm" title="EEO Details" />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Gender"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Gender",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Race/Ethnicity"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Race/Ethnicity",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Disability"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Disability",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <CustomFormField
                          title={"Veteran Status"}
                          required
                          components={[
                            {
                              type: "select",
                              props: {
                                title: "Veteran Status",
                                defaultValue: "",
                                options: ["Selct-01", "Selct-02"],
                              },
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button>Save</Button>
                        <Button onClick={toggleOptions}>Cancel</Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} alignContent={"center"}>
                  <Box display={"flex"} justifyContent={"center"} gap={2}>
                    <Button
                      variant="contained"
                      onClick={() => handleSearchInternalDB()}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleResetSearch()}
                    >
                      Reset
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                bgcolor: theme.palette.grey[50],
                px: 3,
                py: 1,
                height: "100%",
              }}
            >
              <TabContext value={openTabId}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange} aria-label="ob Boards tabs">
                    <Tab label="Recent History" value="recentHistory" />
                    <Tab label="Saved History" value="savedHistory" />
                  </TabList>
                </Box>

                <TabPanel value="recentHistory" sx={{ p: 0, pt: 1 }}>
                  {JobBoardsHistoryRecent.map((search) => (
                    <SearchHistorySection
                      key={search._id}
                      data={search.query.keyWords}
                      onClick={() => handleClickHistory(search)}
                      addedOn={search.addedOn}
                      onClickSave={() => handleClickSaveHistory(search)}
                      status="recent"
                    />
                  ))}
                </TabPanel>
                <TabPanel value="savedHistory" sx={{ p: 0, pt: 1 }}>
                  {JobBoardsHistorySaved.map((search) => (
                    <SearchHistorySection
                      key={search._id}
                      data={search.query.keyWords}
                      onClick={() => handleClickHistory(search)}
                      addedOn={search.addedOn}
                      onClickDelete={() => handleClickDeleteHistory(search)}
                      title={search.title}
                      status="saved"
                    />
                  ))}
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </MainBackground>
      <Modal
        open={addNoteModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexWrap: "wrap",

              mb: 3,
            }}
          >
            <SectionTitle title=" Save Search" />
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: theme.palette.primary.dark,
                textAlign: "center",
              }}
            >
              {/* {itemToDelete ? `${itemToDelete.companyName}` : ""} */}
            </Typography>
            {/* <Typography fontWeight={"400"}>
                from My Companies List?
              </Typography> */}
            <CustomFormField
              title={"Search Title"}
              required
              components={[
                {
                  type: "text",
                  props: {
                    required: true,
                    defaultValue: historyTitle,
                    onChange: (value) => setHistoryTitle(value),
                    error: !!errTitle,
                    errorMessage: errTitle,
                  },
                },
              ]}
            />
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setAddNoteModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistorySave()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={deleteHistoryModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",

              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"400"}>
              Are you sure you want to delete
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => setDeleteHistoryModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleConfirmHistoryDelete()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" size="3rem" />
      </Backdrop>
    </Box>
  );
};

export default JobBoardInternalDb;
