

import React, { useEffect, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { DataGrid } from "@mui/x-data-grid";
import theme from "../../Theme";
import {
  AddApplicantForm,
  CustomFormField,
  FilterHeader,
  KeyData,
  MainBackground,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
//
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

import {
  addApplicantNoteViaApi,
  addApplicantViaApi,
  addDegreeViaApi,
  addResumeSourcesViaApi,
  awsResumeUrl,
  changestatusOfApplicantViaApi,
  getAllApplicantsViaApi,
  getAllDegreesViaApi,
  getAllResumeSourceViaApi,
  getPaginationApplicantViaApi,
  getTotalApplicantsCountViaSaga,
  parseResumeViaApi,
  searchDateJobPostingDataViaApi,
  successAwsResumeUrl,
  sucessParseResume,
  tagApplicantToJobViaApi,
  updateApplicantViaApi,
} from "../../store/recruiter/action";
import { stopLoadingRedux } from "../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { getAllUsers } from "../../store/auth/login/actions";
import CloseIcon from "@mui/icons-material/Close";
import applicantStatusData from "../../Components/StatusElements/applicantStatus";
import {
  SnackbarProvider,
  enqueueSnackbar,
  closeSnackbar,
  useSnackbar,
} from "notistack";
import ResumeViewer from "../../Components/Common/ResumeViewer";
import ApplicantSerchFilterDrawer from "../../Components/Drawer/ApplicantSerchFilterDrawer";
import { applicantSearchInternalDbViaApi } from "../../store/actions";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import styled from "@emotion/styled";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});


const Applicants = () => {
  const { statusOfApplicants } = applicantStatusData;
  const initialEducationDetails = {
    schoolName: "",
    degree: "",
    yearCompleted: "",
    major: "",
    minor: "",
    gpa: "",
    country: "",
    state: "",
    city: "",
  };
  const defaultMobileNumber = [
    { type: "primary", number: "" }, // Initial structure with primary mobile number
    { type: "home", number: "" },
  ];
  const defaultEmailID = [
    { type: "primary", email: "" },
    { type: "secondary", email: "" },
  ];
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.Login.storeData);
  const Applicants = useSelector((state) => state.Recruiters?.applicants);
  const ParseResumeData=useSelector((state)=>state.Recruiters?.parseResumeData)
  const totalApplicants = useSelector(
    (state) => state.Recruiters?.totalApplicants
  );
  const location = useLocation();
  const jobPost = location.state?.jobPost || "";
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const JobPostingData = Array.isArray(JobPosting) ? JobPosting : [];
  const jobPostingOptions = Array.isArray(JobPosting)
    ? JobPosting.map((job) => ({
        value: job.jobDetails.jobCode,
        label: `${job.jobDetails.jobCode}-${job.jobDetails.jobTitle}-${job.jobDetails.client}`,
      }))
    : [];
  const client = storeData.clientDb;
  const filterJobPostCode = jobPost
    ? jobPostingOptions.find((option) => option.value === jobPost.jobCode) || ""
    : "";
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
  }, []);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [selectNoteData, setSelectNoteData] = useState([]);

  const handleNoteDtawer = (note) => {
    setSelectNoteData(note);
    setOpenNoteDrawer(!openNoteDrawer);
  };
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const ApplicantIdData = Array.isArray(Applicants)
    ? Applicants.map((applicant, index) => {
        const recruiterData = allUsers.find(
          (user) => user._id === applicant.addedBy
        );
        const recruiterName = recruiterData?.userSudoFirstName ?? "";
        return {
          ...applicant,
          addedBy: recruiterName,
          addedOn: formatDateForDisplay(applicant.addedOn),
          status:applicant.status||[],
          id: index,
        };
      })
    : [];
  const resumeSources = useSelector((state) => state.Recruiters?.resumeSources);
  const resumeSourcesNames = Array.isArray(resumeSources)
    ? resumeSources.map((item) => item.resumeSource)
    : [];
  const degrees = useSelector((state) => state.Recruiters?.degrees);

  const degreesNames = Array.isArray(degrees)
    ? degrees.map((item) => item.degreeName)
    : [];
  const [limitPage, setLimtPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [emailAddress, setEmailAddress] = useState(defaultEmailID);
  const [mobileNumbers, setMobileNumbers] = useState(defaultMobileNumber);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [resume, setResume] = useState("");
  const [experienceYears, setExperienceYears] = useState("");
  const [experienceMonths, setExperienceMonths] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [source, setSource] = useState("");
  const [availability, setAvailability] = useState("");
  const [payRateSalary, setPayRateSalary] = useState({});
  const [skills, setSkills] = useState([]);
  const [workAuthorization, setWorkAuthorization] = useState("");
  const [relocate, setRelocate] = useState("");
  const [selectedApplicantStatus, setSelectedApplicantStatus] = useState("");
  const [handleApplicantStatusModel, setHandleApplicantStatusModel] =
    useState(false);
    const [applicantId , setApplicantId] = useState("")
    const [comment, setComment] = useState("")
    const [singleFile,setSingleFile]=useState('')
  const [selectApplicatResumes, setSelectApplicantResumes] = useState([]);

  const [editApplicantModalOpen, setEditApplicantModalOpen] = useState(false);
  const [educationDetails, setEducationDetails] = useState([
    initialEducationDetails,
  ]);
  const [applicantDocId, setApplicantDocId] = useState("");
  const [errors, setErrors] = useState({});
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleRowSelection = (newSelection) => {
    setSelectedRows(newSelection);
    const selectedData = ApplicantIdData.filter((row) =>
      newSelection.includes(row.id)
    );
    setSelectedRowData(selectedData);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const handleApplicantStatusModalOpen = (params) => {
    const status = params.value;

    // Check if status is an array with at least one element
    const lastStatus = Array.isArray(status) && status.length > 0 ? status[status.length - 1].status : 'New Lead';
    
    setSelectedApplicantStatus(lastStatus);
    setHandleApplicantStatusModel(true);
    setApplicantId(params.row?._id);
};

  const handleSaveApplicantStatus = async() => {
    const action = {
      client: client,
      addedBy: storeData._id,
      addedOn: new Date(),
      newStatus: selectedApplicantStatus,
      applicantId:applicantId,
      comment:comment,
      pageSize: itemsPerPage,
      page: currentPage,
    };
     dispatch(changestatusOfApplicantViaApi(action))
     setHandleApplicantStatusModel(!handleApplicantStatusModel);
  //  dispatch(getAllApplicantsViaApi(action));    
    setLoading(true);
    setTimeout(() => {
     dispatch(getPaginationApplicantViaApi(action));
    }, 3000);
  };

  const handleNote = (e) => {
  setComment(e.target.value);
   
  };

  const handleModalApplicantStatusCancel = () => {
    setHandleApplicantStatusModel(false)
    setApplicantId("")
    setSelectedApplicantStatus("")
  };

  ////////////
  ////////////
  const [isAddApplicantModalOpen, setIsAddApplicantModalOpen] = useState(false);

  const handleAddApplicantModal = () => {
    dispatch(sucessParseResume([]))
    setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
    setErrors({});
    setFirstName("");
    setLastName("");
    setMiddleName("");
    setGender("");
    setEmailAddress(defaultEmailID);
    setMobileNumbers(defaultMobileNumber);
    setState("");
    setCity("");
    setZipCode("");
    setResume("");
    setExperienceYears("");
    setExperienceMonths("");
    setJobTitle("");
    setSource("");
    setAvailability("");
    setPayRateSalary("");
    setSkills("");
    setWorkAuthorization("");
    setRelocate("");
    setEducationDetails([initialEducationDetails]);
    setApplicantDocId("");
    setSelectApplicantResumes([]);
  };

  const handleEditApplicant = (row) => {
    setApplicantDocId(row);
    setFirstName(row.firstName);
    setLastName(row.lastName);
    setMiddleName(row.middleName);
    setJobTitle(row.jobTitle);
    setGender(row.gender);
    setEmailAddress(row.emailAddress);
    setMobileNumbers(row.mobileNumbers);
    setState(row.state);
    setCity(row.city);
    setZipCode(row.zipCode);
    setExperienceYears(row.experienceYears);
    setExperienceMonths(row.experienceMonths);
    setWorkAuthorization(row.workAuthorization);
    setEducationDetails(row.educationDetails || []);
    setSkills(row.skills);
    setPayRateSalary(row.payRateSalary ? row.payRateSalary : payRateSalary);
    setSource(row.source);
    setAvailability(row.availability);

    setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
    setEditApplicantModalOpen(!editApplicantModalOpen);
    setSelectApplicantResumes(row.resumes);
    if (row.resumes && row.resumes.length > 0) {
      const lastResume = row.resumes[row.resumes.length - 1];
      const { originalFileName } = lastResume;
      setResume(originalFileName);
    } else {
      setResume("");
    }
  };
  const handleDeleteSelectData = (row) => {
    const filterSelectedRowData = selectedRowData.filter(
      (data) => data._id !== row._id
    );
    const filterSelectedRows = selectedRows.filter(
      (id) => id.applicantId !== row.applicantId
    );
    setSelectedRows(filterSelectedRows);
    setSelectedRowData(filterSelectedRowData);
    const action = {
      client: client,
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if firstName is present and not empty inside personalDetails
    if (!firstName) {
      newErrors.firstName = "First Name is required in Personal Details";
    }

    // Check if lastName is empty
    if (!lastName) {
      newErrors.lastName = "Last Name is required in Personal Details";
    }

    // Validate personalDetails fields
    if (!gender) {
      newErrors.gender = "Gender is required in Personal Details";
    }
    const primaryEmail = emailAddress.find((email) => email.type === "primary");
    // Check if the primary email is empty
    if (!primaryEmail || !primaryEmail.email || !primaryEmail.email.trim()) {
      newErrors.primaryEmail =
        "Primary Email Address is required in Personal Details";
    }

    // Validate primary mobile number"primary"
    const primaryMobile = mobileNumbers.find(
      (mobile) => mobile.type === "primary"
    );
    if (
      !primaryMobile ||
      !primaryMobile.number ||
      !primaryMobile.number.trim()
    ) {
      newErrors.mobileNumber =
        "Primary Mobile Number is required in Personal Details";
    }
    if (!state) {
      newErrors.state = "state is required in Personal Details";
    }
    if (!city) {
      newErrors.city = "city is required in Personal Details";
    }

    // Validate professionalDetails fields
    if (!resume) {
      newErrors.resumes = "resumes is required";
    }
    if (!payRateSalary?.minAmount) {
      newErrors.payRateMinAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!payRateSalary?.maxAmount) {
      newErrors.payRateMaxAmount =
        "payRateMaxAmount is required in Professional Details";
    }
    if (!payRateSalary?.paySchedule) {
      newErrors.payRatePaySchedule =
        "paySchedule is required in Professional Details";
    }
    if (!payRateSalary?.taxTerm) {
      newErrors.payRateTaxTerm = "jobType is required in Professional Details";
    }
    if (!experienceYears) {
      newErrors.years = "years is required in Professional Details";
    }
    if (!experienceMonths) {
      newErrors.months = "months is required in Professional Details";
    }
    if (!jobTitle) {
      newErrors.jobTitle = "title is required in Professional Details";
    }
    if (!source) {
      newErrors.source = "source is required in Professional Details";
    }
    if (!availability) {
      newErrors.availability =
        "availability is required in Professional Details";
    }
    if (!skills || skills.length === 0) {
      newErrors.skills = "At least one  skill is required";
    }
    if (!workAuthorization) {
      newErrors.workAuthorization =
        "At least one  workAuthorization is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const trimObjectValuesExceptResume = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      if (key === "resume") {
        acc[key] = value; // Skip trimming for the 'resume' field
      } else if (Array.isArray(value)) {
        acc[key] = value;
      } else if (typeof value === "string") {
        acc[key] = value.trim(); // Trim strings
      } else if (typeof value === "object" && value !== null) {
        acc[key] = trimObjectValuesExceptResume(value); // Recursively trim nested objects
      } else {
        acc[key] = value; // Handle other types as-is
      }
      return acc;
    }, {});
  };
  const handleSaveSubmitApplicant = debounce(async () => {
    if (validateForm()) {
      setLoading(true);

      // Define action object for the second API call
      const trimEducationDeatils =
        trimObjectValuesExceptResume(educationDetails);
      // const educationDetailsLast =educationDetails[educationDetails.length - 1];
      // const { degree } = educationDetailsLast;
      const degree = "";
      const payRateSalaryDaetils = trimObjectValuesExceptResume(payRateSalary);
      const action = {
        addedBy: storeData._id,
        addedOn: new Date().toISOString(),
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        gender: gender,
        emailAddress: emailAddress,
        mobileNumbers: mobileNumbers,
        state: state,
        city: city,
        zipCode: zipCode,
        resume: resume,
        resumes: selectApplicatResumes,
        experienceYears: experienceYears,
        experienceMonths: experienceMonths,
        payRateSalary: payRateSalaryDaetils,
        skills: skills,
        jobTitle: jobTitle,
        source: source,
        availability: availability,
        workAuthorization: workAuthorization,
        educationDetails: educationDetails,
        id: applicantDocId?._id || "",
        resumeSource: source?.trim() || "",
        degree: degree?.trim() || "",
        client: client,
      };
      const formData = new FormData();

      // Basic fields (strings, numbers)
      formData.append("addedBy", storeData._id);
      formData.append("addedOn", new Date().toISOString());
      formData.append("firstName", firstName);
      formData.append("middleName", middleName);
      formData.append("lastName", lastName);
      formData.append("gender", gender);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("zipCode", zipCode);
      formData.append("resume", singleFile?singleFile:resume); // Assuming resume is a file, otherwise stringify if it's data.
      formData.append("experienceYears", experienceYears);
      formData.append("experienceMonths", experienceMonths);
      formData.append("jobTitle", jobTitle);
      formData.append("source", source);
      formData.append("availability", availability);
      formData.append("workAuthorization", workAuthorization);
      formData.append("id", applicantDocId?._id || "");
      formData.append("resumeSource", source?.trim() || "");
      formData.append("degree", degree || "");
      formData.append("client", client);

      // Handling arrays (emailAddress, mobileNumbers, skills, etc.)
      formData.append("emailAddress", JSON.stringify(emailAddress));
      formData.append("mobileNumbers", JSON.stringify(mobileNumbers));
      formData.append("skills", JSON.stringify(skills));
      formData.append("resumes", JSON.stringify(selectApplicatResumes));

      // Handling nested objects (payRateSalary, educationDetails)
      formData.append("payRateSalary", JSON.stringify(payRateSalaryDaetils));
      formData.append("educationDetails", JSON.stringify(educationDetails));
      // // Dispatch the actions
      await dispatch(addApplicantViaApi(formData));
      await dispatch(addResumeSourcesViaApi(action));
      await dispatch(addDegreeViaApi(action));
      await dispatch(sucessParseResume([]))
      dispatch(searchDateJobPostingDataViaApi(action));
      setTimeout(() => {
        const action = {
          client: client,
        };

        dispatch(getAllDegreesViaApi(client));
        dispatch(getAllResumeSourceViaApi(action));
        handleAddApplicantModal();
      }, 2000);
    }
  }, 2000);
  // Handle checkbox change (toggle selection state)
  const handleCheckboxChange = (event, row) => {
    const newSelectedRows = event.target.checked
      ? !selectedRows.some((r) => r.applicantId === row.applicantId) // Check if the applicantId is not already selected
        ? [...selectedRows, row] // Add the row if it's not already selected
        : selectedRows // If already selected, keep it as is
      : selectedRows.filter((r) => r.applicantId !== row.applicantId); // Remove the row if unchecked

    setSelectedRowData(newSelectedRows);
    setSelectedRows(newSelectedRows); // Update the selected rows state
  };

  // Handle header checkbox change to select/deselect all
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedRows(ApplicantIdData); // Select all rows
    } else {
      setSelectedRows([]); // Deselect all rows
    }
  };

  
  const columns = [
    //   {
    //     field: 'select',
    //     headerName: 'Select',
    //     width: 150,
    //     renderCell: (params) => {
    //       return (
    //         <input
    //           type="checkbox"
    //           checked={selectedRowData.some((r) => r.applicantId === params.row.applicantId)}
    //           onChange={(e) => handleCheckboxChange(e, params.row)}
    //         />
    //       );
    //     },
    // },
    {
      field: "select",
      headerName: (
        <input
          type="checkbox"
          checked={
            selectedRows.length === ApplicantIdData.length &&
            selectedRows.length > 0
          } // Check if all rows are selected
          onChange={handleHeaderCheckboxChange} // Handle select all/deselect all
        />
      ),
      width: 20,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedRows.some(
              (r) => r.applicantId === params.row.applicantId
            )} // Check if the row is selected
            onChange={(e) => handleCheckboxChange(e, params.row)} // Handle checkbox toggle
          />
        );
      },
    },
    {
      field: "note",
      headerName: "",
      width: 20,
      renderCell: (params) => {
        const { status, note, jobCode, assignedTo, jobPostingId } = params.row;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center",
            }}
          >
            {note && note.length > 0 && (
              <IconButton
                size="small"
                //onClick={() => handleNoteDtawer(params.row.id)}
                onClick={() => handleNoteDtawer(note)}
                title="note"
              >
                <SpeakerNotesIcon sx={{ fontSize: 14 }} color="warning" />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    {
      field: "applicantId",
      headerName: "Applicant ID",
      width: 170,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
     
      renderCell: (params) => {
        return (
          <Button
            component={Link}
            state={{ applicant: params.row }}
            to={`/applicantDetails/${params.value}`} // Dynamic route if needed
            variant="text"
            sx={{ p: 0, color: "text.main", fontSize: 14, fontWeight: "400" }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "firstName",
      headerName: "Applicant Name",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,

      renderCell: (params) => {
        let { firstName, middleName, lastName, gender } = params.row;
        firstName = firstName || "";
        middleName = middleName || "";
        lastName = lastName || "";
        gender = gender || "";
        const fullName = `${firstName} ${middleName} ${lastName}`.trim();
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Avatar
              sx={{
                width: 18,
                height: 18,
                fontSize: 12,
                color: "white",
                background:
                  gender === "Male" ? theme.palette.primary.main : pink[300],
              }}
            >
              {gender?.charAt(0).toUpperCase()}
            </Avatar>

            {fullName}
          </Box>
        );
      },
    },
    {
      field: "emailAddress",
      headerName: "Email Address",
      width: 270,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   const emailAddress = params.row?.personalDetails?.emailAddress || "";
      //   return emailAddress;
      // },
      renderCell: (params) => {
        // Extract emailAddress array
        const emailAddresses = params.row.emailAddress || [];
        // Format the email addresses for display
        return (
          <Box>
            {emailAddresses
              ? emailAddresses
                  .filter((item) => item.email) // Filter out objects without an email
                  .map((item) => item.email)
                  .join(", ")
              : ""}
          </Box>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   const mobileNumber = params.row?.personalDetails?.mobileNumber || "";
      //   return mobileNumber;
      // },
      renderCell: (params) => {
        // Extract mobileNumber array
        const mobileNumbers = params.row?.mobileNumbers || [];
        // Format the mobile numbers for display
        return (
          <Box>
            {mobileNumbers
              ? mobileNumbers
                  .filter((item) => item.number) // Filter out objects without an email
                  .map((item) => item.number)
                  .join(", ")
              : ""}
          </Box>
        );
      },
    },
    {
      field: "source",
      headerName: "Source",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   const sourceOfResume =
      //     params.row?.professionalDetails?.sourceOfResume || "";
      //   return sourceOfResume;
      // },
    },
    {
      field: "city",
      headerName: "City",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   const { city } = params.row.personalDetails;
      //   return (
      //     <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      //       {city}
      //     </Box>
      //   );
      // },
    },
    {
      field: "state",
      headerName: "State",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   const { state } = params.row.personalDetails;
      //   return (
      //     <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      //       {state}
      //     </Box>
      //   );
      // },
    },
    {
      field: "status",
      headerName: "Applicant Status",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const status = params.value;
        const lastStatus = Array.isArray(status) && status.length > 0
          ? status[status.length - 1].status
          : 'New Lead';
        const result = typeof lastStatus === 'string' ? lastStatus.replace(/\(Allowed to submit\)/g, '') : '';
        return (
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Chip
              label={result}
              color={result === "SubmitJob" ? "primary" : "success"}
              onClick={() => handleApplicantStatusModalOpen(params)}
              variant="outlined"
              size="small"
              style={{ cursor: 'pointer' }}
              sx={{ px: 0.5, lineHeight: 1 }}
            />
          </Box>
        );
      },
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // renderCell: (params) => {
      //   const title = params.row?.professionalDetails?.title || "";
      //   return title;
      // },
    },
    // {
    //   field: "ownership",
    //   headerName: "Ownership",
    //   width: 200,
    //   sortable: false,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    // },
    {
      field: "workAuthorization",
      headerName: "Work Authorization",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "addedBy",
      headerName: "Created By",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "addedOn",
      headerName: "Created On",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const [openModalNotAutharized, setOpenModalNotAutharized] = useState(false);
  const [selectAction, setSelectAction] = useState(
    jobPost ? "Submit to Job" : ""
  );
  const [addNote, setAddNote] = useState("");
  // Assign the entire object to selectJobTag
  const [selectJobTag, setSelectJobTag] = useState(filterJobPostCode || "");

  const handleChangeAction = (value) => {
    setSelectAction(value);
    const action = { client: client };
    dispatch(searchDateJobPostingDataViaApi(action));
  };
  const handleJobSelect = (value) => {
    setSelectJobTag(value);
    const action = { client: client };
    dispatch(searchDateJobPostingDataViaApi(action));
  };

  const handleApplyAction = () => {
    // Check if any rows are selected
    if (selectedRowData.length === 0) {
      return enqueueSnackbar("Please select at least one row!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }

    // Check if an action is selected
    if (!selectAction) {
      return enqueueSnackbar("Please select an action!", {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
    }
    // Handle 'Add Notes' action
    if (selectAction === "Add Notes") {
      if (addNote && addNote.trim()) {
        selectedRowData.forEach((selectedRow) => {
          const newNote = {
            addedBy: storeData._id,
            addedOn: new Date(),
            note: addNote,
          };
          const existingNotes = Array.isArray(selectedRow.note)
            ? selectedRow.note
            : [];
          const updatedNotes = [...existingNotes, newNote];
          const action = {
            id: selectedRow._id,
            note: updatedNotes,
            client: client,
          };
          dispatch(addApplicantNoteViaApi(action));
        });

        // Refresh the applicant data
        setLoading(true);
        setTimeout(() => {
          dispatch(getAllDegreesViaApi(client));
          const action = {
            client: client,
          };
          dispatch(searchDateJobPostingDataViaApi(action));
        }, 2000);
      } else {
        return enqueueSnackbar("Please add a note!", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
    }

    // Handle 'Submit to Job' action
    else if (selectAction === "Submit to Job") {
      if (selectJobTag) {
        const takeJobCode = filterJobPostCode
          ? filterJobPostCode.value
          : selectJobTag.value;
        const findId = JobPostingData.find(
          (job) => job.jobDetails.jobCode === takeJobCode
        );
        const submissionData = findId?.submissions || [];
        // Check if any applicant is already tagged by another person
        const isAlreadyTagged = selectedRowData.some((select) =>
          submissionData.some(
            (job) =>
              select._id === job.applicantId && job.addedBy !== storeData._id
          )
        );
        if (isAlreadyTagged) {
          setOpenModalNotAutharized(!openModalNotAutharized);
          return;
        }

        const submission = selectedRowData.map((select) => ({
          addedBy: storeData._id,
          addedOn: new Date(),
          applicantId: select._id,
          applicantStatus: "SubmitJob",
        }));

        const jobSubmissionAction = {
          id: findId ? findId._id : "",
          submissions: submission,
        };

        let tagAction = [];

        selectedRowData.forEach((selectedRow) => {
          const tagData = {
            addedBy: storeData._id,
            addedOn: new Date(),
            jobPostId: findId ? findId._id : "",
            applicantStatus: "SubmitJob",
          };
          const existingTagJobs = Array.isArray(selectedRow.tagJobs)
            ? selectedRow.tagJobs.filter(
                (tag) => tag.jobPostId !== tagData.jobPostId
              )
            : [];
          const updatedTagJobs = [...existingTagJobs, tagData];
          tagAction.push({
            id: selectedRow._id,
            tagJobs: updatedTagJobs,
          });
        });

        const action = {
          jobSubmissionAction,
          tagAction,
          addedBy: storeData._id,
          client: client,
        };

        setLoading(true);
        dispatch(tagApplicantToJobViaApi(action));
        setTimeout(() => {
          dispatch(getAllDegreesViaApi(client));
          dispatch(searchDateJobPostingDataViaApi(action));
        }, 2000);
      } else {
        return enqueueSnackbar("Please select a job!", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
    }

    // // Handle 'Tag to Job' action
    else if (selectAction === "Tag to Job") {
      if (selectJobTag) {
        const takeJobCode = filterJobPostCode
          ? filterJobPostCode.value
          : selectJobTag.value;
        const findId = JobPostingData.find(
          (job) => job.jobDetails.jobCode === takeJobCode
        );
        const submissionData = findId?.submissions || [];

        // Check if any applicant is already tagged by another person
        const isAlreadyTagged = selectedRowData.some((select) =>
          submissionData.some(
            (job) =>
              select._id === job.applicantId && job.addedBy !== storeData._id
          )
        );
        if (isAlreadyTagged) {
          setOpenModalNotAutharized(!openModalNotAutharized);
          return;
        }

        const submission = selectedRowData.map((select) => ({
          addedBy: storeData._id,
          addedOn: new Date(),
          applicantId: select._id,
          applicantStatus: "TagJob",
        }));

        const jobSubmissionAction = {
          id: findId ? findId._id : "",
          submissions: submission,
        };

        let tagAction = [];

        selectedRowData.forEach((selectedRow) => {
          const tagData = {
            addedBy: storeData._id,
            addedOn: new Date(),
            jobPostId: findId ? findId._id : "",
            applicantStatus: "TagJob",
          };
          const existingTagJobs = Array.isArray(selectedRow.tagJobs)
            ? selectedRow.tagJobs.filter(
                (tag) => tag.jobPostId !== tagData.jobPostId
              )
            : [];
          const updatedTagJobs = [...existingTagJobs, tagData];
          tagAction.push({
            id: selectedRow._id,
            tagJobs: updatedTagJobs,
          });
        });

        const action = {
          jobSubmissionAction,
          tagAction,
          addedBy: storeData._id,
          client: client,
        };
        setLoading(true);
        dispatch(tagApplicantToJobViaApi(action));
        setTimeout(() => {
          dispatch(getAllDegreesViaApi(client));
          const action = { client: client };
          dispatch(searchDateJobPostingDataViaApi(action));
        }, 2000);
      } else {
        return enqueueSnackbar("Please select a job!", {
          variant: "error",
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} color="inherit">
              <CloseIcon />
            </IconButton>
          ),
        });
      }
    }
  };

  const fetchApplicants = async (startIndex, limitSize) => {
    const action = {
      client: client,
      pageSize: limitSize,
      page: startIndex,
    };
    setCurrentStartIndex(startIndex);
    setLimtPage(limitSize);
    dispatch(getPaginationApplicantViaApi(action));
    setLoading(true)
  };
  useEffect(() => {
    const action = {
      client: client,
      pageSize: itemsPerPage,
      page: currentPage,
    };
    dispatch(getTotalApplicantsCountViaSaga(action));
    dispatch(getAllResumeSourceViaApi(action));
    dispatch(getPaginationApplicantViaApi(action));
    dispatch(getAllDegreesViaApi(client));
    dispatch(searchDateJobPostingDataViaApi(action));
    dispatch(sucessParseResume([]))
  }, []);
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };
  const fileExtension = AwsResumeUrl?.split(".")
    .pop()
    .split("?")[0]
    .toLowerCase();

  const handlePageChange = (newPage) => {
    const { page, pageSize } = newPage;
    setCurrentStartIndex(page);
    setLimtPage(pageSize);
    fetchApplicants(page, pageSize);
    setCurrentStartIndex(newPage);
  };
  const handlePageChanges = (newPage) => {
    if (totalApplicants === 0) {
      return;
    }
    if (newPage < 1) {
      setCurrentPage(1);
      fetchApplicants(newPage, itemsPerPage);
    } else if (newPage > totalApplicants) {
      setCurrentPage(totalApplicants);
    } else {
      setCurrentPage(newPage);
      fetchApplicants(newPage, itemsPerPage);
    }
  };
  const handleChangeItemsPerPage = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    fetchApplicants(currentPage, newItemsPerPage);
  };
  const [openSerchFilterDrawer, setOpenSerchFilterDrawer] = useState(false);
  const [email,setEMail]=useState('')
  const [keyWords, setKeyWords] = useState('');
  const [excludeKeyWords,setExcludeKeyWords]=useState('');
  const [searchJobTitle,setSearchJobTitle]=useState('');
  const handleSerchFilterDrawer = () => {
    setOpenSerchFilterDrawer(!openSerchFilterDrawer);
  };
 
  const handleSearchInternalDB=()=>{
    const action={
      keyWords:keyWords,
      client:client,
      jobTitle:searchJobTitle,
      // education:education,
      email:email,
      // workAuthorization:workAuthorization
    }
    setOpenSerchFilterDrawer(!openSerchFilterDrawer);
    dispatch(applicantSearchInternalDbViaApi(action))
    setLoading(true)
  }
  const handleResetSearchFilters=()=>{
    setEMail('')
    setKeyWords('')
    setSearchJobTitle('')
    setOpenSerchFilterDrawer(!openSerchFilterDrawer);
    const action = {
      client: client,
      pageSize: itemsPerPage,
      page: currentPage,
    };
    setLoading(true)
    dispatch(getTotalApplicantsCountViaSaga(action));
    dispatch(getAllResumeSourceViaApi(action));
    dispatch(getPaginationApplicantViaApi(action));
    dispatch(getAllDegreesViaApi(client));
    dispatch(searchDateJobPostingDataViaApi(action));
  }
  const handleClose = () => '';

  const [addNewApplicant, setAddNewApplicant] = useState(false);
  const handleAddNewApplicantModal = () => {
    setAddNewApplicant(!addNewApplicant);
  };

  const handleParseResume = (event) => {
    const file = event.target.files ? event.target.files[0] : null; // Ensure `files` exists
    if (file) {
      setSingleFile(file);
      const formData = new FormData();
      formData.append('resume', file); 
      dispatch(parseResumeViaApi(formData));
      setAddNewApplicant(!addNewApplicant);
      setLoading(true)
    } else {
      console.log('No file selected');
    }
  };
// useEffect(()=>{
//   if (ParseResumeData && ParseResumeData.length) { 
//          const parseData=ParseResumeData[0]
//          setIsAddApplicantModalOpen(!isAddApplicantModalOpen);
//          setErrors({});
//          setFirstName(parseData.firstName);
//          setLastName(parseData.lastName);
//          setMiddleName(parseData.middleName);
//          setGender("");
//          setEmailAddress(parseData.emailAddress);
//          setMobileNumbers(parseData.mobileNumbers);
//          setState(parseData.state);
//          setCity(parseData.city);
//          setZipCode(parseData.zipCode);
//          setExperienceYears("");
//          setExperienceMonths("");
//          setJobTitle(parseData.jobTitle);
//          setSource("");
//          setAvailability("");
//          setPayRateSalary("");
//          setSkills(parseData.skills);
//          setWorkAuthorization("");
//          setRelocate("");
//          setResume(singleFile.name)
//          setEducationDetails(parseData.educationDetails);
//          setApplicantDocId("");
//          setSelectApplicantResumes([]);
//      }
// },[ParseResumeData])  

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Applicants"}>
          <Box>
            <Grid container spacing={2} height={"100%"}>
              <Grid item xs={selectedRows.length > 0 || jobPost ? 9 : 12}>
              <Box
                  sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
                >

                  <Button
                    variant="text"
                    color="inherit"
                    size="small"
                    startIcon={<FilterAltOutlinedIcon />}
                    onClick={handleSerchFilterDrawer}
                  >
                    Search Filter
                  </Button>
                 <Button variant="contained"  component={Link} to="/jobBoards" color="error">Job Board</Button>
                    
               
                  <Button
                    variant="contained"
                    size="small"
                   //onClick={handleAddApplicantModal}
                   onClick={handleAddNewApplicantModal}
                    
                    
                  >
                    Add New
                  </Button>
                </Box>
                
                    <MainBackground>
                      <DataGrid
                        rows={ApplicantIdData}
                        columns={columns}
                        disableRowSelectionOnClick
                        disableColumnMenu
                        disableColumnSorting
                        disableColumnSelector
                        disableColumnFilter
                        disableColumnReorder
                        disableColumnResize
                        disableColumnSort
                        hideFooter
                        getRowHeight={() => "auto"}
                        autoHeight
                        sx={{
                          "& .MuiDataGrid-cell": {
                            display: "flex",
                            alignItems: "center !important",
                          },
                          [`& .MuiDataGrid-checkboxInput`]: {
                            color: theme.palette.primary.main,
                            p: 0,

                            "&.Mui-checked": {
                              color: theme.palette.primary.dark,
                            },
                          },
                          //Scroller
                          [`& .MuiDataGrid-scrollbar`]: {
                            scrollbarWidth: "thin",
                            scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                          },
                          [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                            {
                              borderRadius: "10px", // Apply border radius
                            },

                          [`& .MuiDataGrid-scrollbar:hover`]: {
                            scrollbarColor: `${theme.palette.primary.dark}`,
                          },
                          [`& .MuiDataGrid-virtualScroller`]: {
                            maxHeight: "640px",
                            overflowY: "auto !important",
                            scrollbarWidth: "thin",
                            scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                          },
                        }}
                        checkboxSelection={false}
                        // onRowSelectionModelChange={handleRowSelection}
                        // rowSelectionModel={selectedRows}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <Box
                          sx={{
                            minWidth: 150,
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          Row per page
                          {/* <CustomSelect
                            title="Row per page"
                            options={[10, 20, 60, 100]}
                            value={itemsPerPage}
                            onChange={(e) =>
                              handleChangeItemsPerPage(e.target.value)
                            }
                          /> */}
                          <Select
                            size="small"
                            value={itemsPerPage}
                            onChange={(e) =>
                              handleChangeItemsPerPage(e.target.value)
                            }
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              hideBackdrop: true,
                            }}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={60}>60</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                          </Select>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <Typography>
                              {(currentPage - 1) * itemsPerPage + 1} -{" "}
                              {itemsPerPage * currentPage} of
                            </Typography>
                            <Typography>{totalApplicants}</Typography>
                          </Box>
                          <IconButton
                            size="small"
                            onClick={() => handlePageChanges(currentPage - 1)}
                            disabled={currentPage === 1}
                          >
                            <NavigateBeforeIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handlePageChanges(currentPage + 1)}
                            disabled={
                              currentPage * itemsPerPage >= totalApplicants
                            }
                          >
                            <NavigateNextIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </MainBackground>
                 
              </Grid>
              {(selectedRows.length > 0 || jobPost) && (
                <Grid
                  item
                  xs={3}
                  sx={{
                    position: "sticky", // Sticks within the container
                    top: -15, // Sticky from the top
                    height: "85vh", // Full viewport height
                    zIndex: 1, // Ensure it stays on top of other content
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        width: "100%",
                        top: 0,
                        bottom: 0,
                        height: "100%",
                      }}
                    >
                      <MainBackground height="full">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            gap: 2,
                          }}
                        >
                          <Box>
                            <CustomFormField
                              title={"Select Action "}
                              components={[
                                {
                                  type: "select",
                                  props: {
                                    title: "Action",
                                    options: [
                                      "Add Notes",
                                      // "Add Tag",
                                      // "Add to Folder",
                                      // "Add to Bench",
                                      // "Mark as Favorite",
                                      // "Remove Favorite Tag",
                                      // "Send Email",
                                      "Submit to Job",
                                      // "Delete",
                                      // "Change Ownership",
                                      // "Add to Snip List",
                                      // "Add to Group",
                                      // "Add Blacklist",
                                      "Tag to Job",
                                      // "Macro",
                                      // "Share Profiles",
                                      // "Share a Job",
                                      // "Archive",
                                      // "Download Resumes",
                                    ],
                                    //multiple: true,
                                    defaultValue: selectAction,
                                    onChange: (value) =>
                                      handleChangeAction(value),
                                  },
                                },
                              ]}
                            />
                          </Box>
                          {selectAction === "Add Notes" && (
                            <CustomFormField
                              title={"Add Notes"}
                              components={[
                                {
                                  type: "textArea",
                                  props: {
                                    title: "Action",

                                    //multiple: true,
                                    defaultValue: addNote,
                                    onChange: (value) => setAddNote(value),
                                  },
                                },
                              ]}
                            />
                          )}
                          {(selectAction === "Submit to Job" ||
                            selectAction === "Tag to Job") && (
                            <CustomFormField
                              title={selectAction}
                              components={[
                                {
                                  type: "autocomplete",
                                  props: {
                                    placeholder: `select the job`,
                                    options: jobPostingOptions,
                                    defaultValue: selectJobTag,
                                    onChange: (value) => handleJobSelect(value),
                                    // error: !!errors.sourceOfResume,
                                    // errorMessage: errors.sourceOfResume,
                                  },
                                },
                              ]}
                            />
                          )}
                          <Box sx={{ flex: 1, mb: 4, pb: 2 }}>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 0.5,
                                alignItems: "center",
                                mb: 1,
                                mt: 2,
                              }}
                            >
                              <Avatar
                                sx={{
                                  width: 18,
                                  height: 18,
                                  fontSize: 12,
                                  background: theme.palette.primary.main,
                                }}
                              >
                                {selectedRows.length}
                              </Avatar>
                              <Typography>
                                {selectedRows.length > 1
                                  ? `Applicants`
                                  : `Applicant`}
                              </Typography>
                            </Box>
                            <ScrollView>
                              {selectedRowData.length > 0 ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 1,
                                  }}
                                >
                                  {selectedRowData.map((row) => (
                                    <Box
                                      key={row.id}
                                      sx={{
                                        borderRadius: 2,
                                        p: 1,
                                        mx: 0.5,
                                        background: `linear-gradient(to right bottom, ${theme.palette.grey[100]}, ${theme.palette.primary.contrastText})`,
                                        border: `1px solid ${theme.palette.grey[200]}`,
                                      }}
                                    >
                                      <Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            columnGap: 1,
                                            alignItems: "center",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          <Typography
                                            sx={{ fontWeight: "bold" }}
                                            noWrap
                                          >
                                            {`${row.firstName} ${row.middleName} ${row.lastName}`}
                                            -{row.applicantId || ""}
                                          </Typography>
                                          <Typography variant="caption" noWrap>
                                            {row.jobTitle || ""}
                                          </Typography>
                                        </Box>
                                        <Divider />
                                        <Box
                                          display={"flex"}
                                          flexWrap={"wrap"}
                                          py={0.5}
                                        >
                                          <Typography variant="caption" noWrap>
                                            {row.emailAddress
                                              ? row.emailAddress
                                                  .filter((item) => item.email) // Filter out objects without an email
                                                  .map((item) => item.email)
                                                  .join(", ")
                                              : ""}
                                          </Typography>
                                          <Box flex={1} />
                                          <Typography variant="caption" noWrap>
                                            {row.mobileNumbers
                                              ? row.mobileNumbers
                                                  .filter((item) => item.number) // Filter out objects without an email
                                                  .map((item) => item.number)
                                                  .join(", ")
                                              : ""}
                                          </Typography>

                                          {/* <Typography variant="caption" noWrap>
                                            {row.personalDetails.emailAddress ||
                                              ""}
                                          </Typography>
                                          <Box flex={1} />
                                          <Typography variant="caption" noWrap>
                                            {row.personalDetails.mobileNumber ||
                                              ""}
                                          </Typography> */}
                                        </Box>
                                        <Divider />
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: 1,
                                          my: 1,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            justifyContent: "space-between",
                                            gap: 1,
                                          }}
                                        >
                                          <KeyData
                                            title={"Availability:"}
                                            value={row.availability || ""}
                                          />
                                          <Divider
                                            orientation="vertical"
                                            flexItem
                                          />
                                          <KeyData
                                            title={"Pay Rate:"}
                                            value={
                                              `${row.payRateSalary?.currency}/${row.payRateSalary?.minAmount}/${row.payRateSalary?.maxAmount}/${row.payRateSalary?.paySchedule}/${row.payRateSalary?.taxTerm} ` ||
                                              ""
                                            }
                                          />
                                        </Box>
                                        <Divider />
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexWrap: "nowrap",
                                            justifyContent: "space-between",
                                            gap: 1,
                                          }}
                                        >
                                          <KeyData
                                            title="Resume"
                                            value={
                                              row?.professionalDetails
                                                ?.resume || ""
                                            }
                                            link
                                            linkTitle={
                                              row?.professionalDetails
                                                ?.resume || ""
                                            }
                                            bottomInfo={
                                              row?.professionalDetails
                                                ?.resume || ""
                                            }
                                          />
                                        </Box>
                                        <Box>
                                          {/* <Button
                                            onClick={() =>
                                              handleAxiosLink(
                                                "https://atsdocuments.s3.ap-south-2.amazonaws.com/tech5/09_2024/4_1_1.pdf"
                                              )
                                            }
                                          >
                                            click
                                          </Button> */}

                                          {row.resumes?.map((resume, index) => (
                                            <Box key={index} sx={{ mb: 2 }}>
                                              <Button
                                                onClick={() =>
                                                  handleAxiosLink(
                                                    resume.serverLocation
                                                  )
                                                } // Using dynamic URL from "serverLocation"
                                              >
                                                {resume.originalFileName}
                                              </Button>
                                            </Box>
                                          ))}
                                        </Box>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexWrap: "wrap",
                                          gap: 1,
                                          justifyContent: "end",
                                        }}
                                      >
                                        <Tooltip title="Edit">
                                          <IconButton
                                            aria-label="Edit"
                                            size="xsmall"
                                            color="warning"
                                          >
                                            <EditIcon
                                              fontSize="inherit"
                                              onClick={() =>
                                                handleEditApplicant(row)
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>

                                        <Tooltip title="Delete">
                                          <IconButton
                                            aria-label="Delete"
                                            size="xsmall"
                                            color="error"
                                          >
                                            <DeleteIcon
                                              fontSize="inherit"
                                              onClick={() =>
                                                handleDeleteSelectData(row)
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                              ) : (
                                <Typography>No rows selected</Typography>
                              )}
                            </ScrollView>
                          </Box>

                          <Box>
                            <Button
                              variant="contained"
                              onClick={handleApplyAction}
                            >
                              {" "}
                              {selectAction ? selectAction : "Apply"}
                            </Button>
                          </Box>
                        </Box>
                      </MainBackground>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </LayoutSidebarMenu>
        <SnackbarComponent />

        <Modal open={isAddApplicantModalOpen} onClose={handleAddApplicantModal}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background:
                "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
              height: "100vh",
            }}
          >
            <Box>
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 4,
                }}
              >
                <Typography variant="h5" fontWeight={600}>
                  Add Applicant
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button color="error" onClick={handleAddApplicantModal}>
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveSubmitApplicant}
                  >
                    Submit Applicant
                  </Button>
                </Box>
              </Paper>
            </Box>

            <Box flex={1} overflow={"auto"} p={1}>
              <AddApplicantForm
                firstName={firstName}
                setFirstName={setFirstName}
                middleName={middleName}
                setMiddleName={setMiddleName}
                lastName={lastName}
                setLastName={setLastName}
                gender={gender}
                setGender={setGender}
                emailAddress={emailAddress}
                mobileNumbers={mobileNumbers}
                setMobileNumbers={setMobileNumbers}
                setEmailAddress={setEmailAddress}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                zipCode={zipCode}
                setZipCode={setZipCode}
                resume={resume}
                setResume={setResume}
                experienceYears={experienceYears}
                setExperienceYears={setExperienceYears}
                experienceMonths={experienceMonths}
                setExperienceMonths={setExperienceMonths}
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                source={source}
                setSource={setSource}
                availability={availability}
                setAvailability={setAvailability}
                payRateSalary={payRateSalary}
                setPayRateSalary={setPayRateSalary}
                skills={skills}
                setSkills={setSkills}
                workAuthorization={workAuthorization}
                setWorkAuthorization={setWorkAuthorization}
                relocate={relocate}
                setRelocate={setRelocate}
                errors={errors}
                resumeSourcesNames={resumeSourcesNames}
                educationDetails={educationDetails}
                setEducationDetails={setEducationDetails}
                degreesNames={degreesNames}
                editApplicantModalOpen={editApplicantModalOpen}
                selectApplicatResumes={selectApplicatResumes}
                setSelectApplicantResumes={setSelectApplicantResumes}
              />
            </Box>
          </Box>
        </Modal>

        
        <Modal
          open={handleApplicantStatusModel}
          // onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: { xs: 1, md: 2 },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                rowGap: 0.5,
                flexWrap: "wrap",
                mb: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography fontWeight={400}>
                Are you sure you want to assign a Applicant status?
              </Typography>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="job-status-label">Applicant Status</InputLabel>

                <Select
                  labelId="demo-single-select-label"
                  id="demo-single-select"
                  value={selectedApplicantStatus} // This holds the single selected status
                  onChange={(e) => setSelectedApplicantStatus(e.target.value)} // Function to handle the change
                  input={<OutlinedInput label="Applicant Status" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      <Chip key={selected} label={selectedApplicantStatus} />
                    </Box>
                  )}
                >
                  {statusOfApplicants.map((status, id) => (
                    <MenuItem key={id} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center",mb:"15px" }}>
          <FormControl>
            <FormLabel>COMMENT</FormLabel>
            <TextField
              label="Enter your comment"
              multiline
              rows={4} // Set the number of visible rows
              cols={8}
              variant="outlined"
              fullWidth
              value={comment}
              onChange={handleNote}
              helperText= "Add a comment Here..." 
              
              sx={{
                width: 300, // 300px wide (you can adjust this value to suit your needs)
              }}
            />
          </FormControl>
        </Box>
            <Divider />
            <Box
              sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
            >
              <Button
                color="inherit"
                sx={{ textTransform: "inherit" }}
                onClick={() => handleModalApplicantStatusCancel()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "inherit" }}
                onClick={() => handleSaveApplicantStatus()}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>

        <Drawer
          open={openNoteDrawer}
          onClose={() => setOpenNoteDrawer(false)}
          anchor="right"
        >
          <Box sx={{ width: 300, p: 3, position: "relative" }}>
            <IconButton
              onClick={() => setOpenNoteDrawer(false)}
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ mt: 4 }}>
              Note
            </Typography>
            {selectNoteData.map((note, index) => (
              <Box key={index} sx={{ mt: 2 }}>
                <Typography variant="body1">
                  <strong>Added By:</strong>{" "}
                  {
                    allUsers.find((user) => user._id === note.addedBy)
                      ?.userSudoFirstName
                  }
                </Typography>
                <Typography variant="body1">
                  <strong>Added On:</strong>{" "}
                  {formatDateForDisplay(note.addedOn)}
                </Typography>
                <Typography variant="body1">
                  <strong>Note:</strong> {note.note}
                </Typography>
              </Box>
            ))}
          </Box>
        </Drawer>
        <Modal
          open={openModalNotAutharized}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "400px",
              width: "80%",
              bgcolor: "background.paper",
              border: "2px solid #f44336", // Red border to indicate danger
              boxShadow: 24,
              p: { xs: 2, md: 3 },
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                columnGap: 1,
                rowGap: 0.5,
                flexWrap: "wrap",
                mb: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ReportProblemOutlinedIcon
                sx={{ color: "#f44336", fontSize: 40 }} // Increase icon size to 40px
              />
              <Typography
                fontWeight={500}
                variant="h6"
                sx={{
                  color: "#f44336",
                  textAlign: "center",
                  fontSize: "1.25rem",
                }} // Slightly larger text
              >
                Other recruiter has already tagged or submitted this applicant
              </Typography>
            </Box>

            <Divider />

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                variant="contained"
                color="error" // Error color for the button
                sx={{
                  textTransform: "none",
                  fontWeight: 500,
                  fontSize: "1rem",
                }} // Increase button text size
                onClick={() => setOpenModalNotAutharized(false)} // Close modal on click
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openResumeModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          // onClose={handleCloseResumeModal} // Function to close modal
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={handleCloseResumeModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* Resume iframe or fallback */}
            <Modal
              open={openResumeModal}
              onClose={handleCloseResumeModal}
              aria-labelledby="resume-modal-title"
              aria-describedby="resume-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                {/* Close Button */}
                <IconButton
                  aria-label="close"
                  onClick={handleCloseResumeModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {/* Conditional rendering based on file type */}
                {AwsResumeUrl ? (
            <ResumeViewer AwsResumeUrl={AwsResumeUrl}/>
          ) : (
            <Typography variant="h6" textAlign="center">
              No resume available.
            </Typography>
          )}
              </Box>
            </Modal>
          </Box>
        </Modal>

        <Modal open={addNewApplicant} onClose={handleAddNewApplicantModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Box>
              <SectionTitle title="Add Applicant" size="xl" />
              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  size="large"
                >
                  Parse Resume
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleParseResume}
                    //multiple
                  />
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={handleAddApplicantModal}
                >
                  Manual Creation
                </Button>
                <Button variant="contained" size="large">
                  Bulk Parse
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        
        <ApplicantSerchFilterDrawer 
        open={openSerchFilterDrawer} 
        close={handleSerchFilterDrawer} 
        email={email} 
        setEMail={setEMail}
        keyWords={keyWords}
        setKeyWords={setKeyWords}
        excludeKeyWords={excludeKeyWords}
        setExcludeKeyWords={setExcludeKeyWords}
        searchJobTitle={searchJobTitle}
        setSearchJobTitle={setSearchJobTitle}
        handleSearchInternalDB={handleSearchInternalDB}
        handleResetSearchFilters={handleResetSearchFilters}
          
        />
        <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={loading}
  onClick={handleClose}
>
  <CircularProgress color="inherit" size="3rem" />
</Backdrop>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default Applicants;
