
//Auth
import { all, fork } from "redux-saga/effects";
import AuthSaga from "./auth/login/saga";
import SearchSaga from "./search/saga";
import CompaniesList from "./companiesList/saga"
import Reports from "./reports/saga";
import Recruiter from "./recruiter/saga";
import layoutSaga from "./auth/layout/saga";
import ResumeSaga from "./resumes/saga";
import JobBoardSaga from "./jobBoards/saga";
export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(SearchSaga),
    fork(CompaniesList),
    fork(Reports),
    fork(Recruiter),
    fork(layoutSaga),
    fork(ResumeSaga),
    fork(JobBoardSaga),
  ]);
}
