import { type } from "@testing-library/user-event/dist/type";
import {
    CLEAR_SEARCH_RESULTS,
    ADVANCED_RESUME_SEARCH_VIA_API,
    SUCCESS_ADVANCED_RESUME_SEARCH,
    SEARCH_RESUMEID_INTERNALDB_VIA_API,
    SUCCESS_SEARCH_RESUMEID_INTERNALDB,
    GET_RESUME_AND_STORE_INTERNALDB_VIA_API,
    MONSTER_ADVANCED_RESUME_SEARCH_VIA_API,
    SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH,
    GET_MONSTER_RESUME_AND_STORE_INTERNALDB_VIA_API,
    SEARCH_RESUME_INTERNAL_DB_VIA_API,
    SUCCESS_SEARCH_RESUME_INTERNAL_DB,
    INITIALSTATE_RESUME_DATA,
    EMAILS_SEND_TO_APPLICANTS_VIA_API,
    INTEGRATE_SEARCH_INTERNAL_DB_VIA_API,
    APPLICANT_SEARCH_INTERNAL_DB_VIA_API,
    INTERNAL_DB_SEARCH_VIA_API,
    SUCCESS_INTERNAL_DB_SEARCH,
    GET_MONSTER_CREDITS_VIA_API,
    GET_INTEGRATIONS_VIA_API,
    INTEGRATE_NEW_MONSTER_ACCOUNT_VIA_API,
    SUCCESS_INTEGRATIONS,
    ASSIGNED_TO_MONSTER_ACCESS_VIA_API,
    DISTRIBUTE_MONSTER_CREDITS_VIA_API,
    INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH_VIA_API,
    SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH,
    INTEGRTAE_CB_ADVANCED_RESUMES_SEARCH_VIA_API,
    SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH,
    INTEGRATE_INTERNAL_DB_SEARCH_VIA_API,
    SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH
} from "./actionType";

// Advanced resume search actions
export const advancedResumeSearchViaApi = (action) => ({
    type: ADVANCED_RESUME_SEARCH_VIA_API,
    payload: action,
});

export const successAdvancedResumeSearch = (reponse) => ({
    type: SUCCESS_ADVANCED_RESUME_SEARCH,
    payload: reponse,
});
export const searchResumeIdInternalDbViaApi=(action)=>({
    type:SEARCH_RESUMEID_INTERNALDB_VIA_API,
    payload:action
})
export const successSearchResumeIdInternalDb=(reponse)=>({
    type:SUCCESS_SEARCH_RESUMEID_INTERNALDB,
    payload:reponse
})
export const getResumeAndStoreInternalDbViaApi=(action)=>({
    type:GET_RESUME_AND_STORE_INTERNALDB_VIA_API,
    payload:action
})
export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS,
});
/////// MONSTER
export const monsterAdvancedResumeSearchViaApi = (action) => ({
    type: MONSTER_ADVANCED_RESUME_SEARCH_VIA_API,
    payload:action
});
export const successMonsterAdvancedResumeSearch = (action) => ({
    type: SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH,
    payload:action
});
export const  getMonsterResumeAndStoreInternalDbViaApi= (action) => ({
    type: GET_MONSTER_RESUME_AND_STORE_INTERNALDB_VIA_API,
    payload:action
});
export const searchResumeInternalDbViaApi=(action)=>({
    type:SEARCH_RESUME_INTERNAL_DB_VIA_API,
    payload:action
})
export const successSearchResumeInternalDb=(response)=>({
    type:SUCCESS_SEARCH_RESUME_INTERNAL_DB,
    payload:response
})
export const initialStateResumeData=(response)=>({
    type:INITIALSTATE_RESUME_DATA,
    payload:response
})
export const emailsSendToApplicantsViaApi=(action)=>{
    return{
        type:EMAILS_SEND_TO_APPLICANTS_VIA_API,
        payload:action
    }
}
export const integrateSearchInternalDbViaApi=(action)=>{
    return{
        type:INTEGRATE_SEARCH_INTERNAL_DB_VIA_API,
        payload:action
    }
}
export const applicantSearchInternalDbViaApi=(action)=>{
    return{
        type:APPLICANT_SEARCH_INTERNAL_DB_VIA_API,
        payload:action
    }
}
export const internalDbSearchViaApi=(action)=>{
    return{
        type:INTERNAL_DB_SEARCH_VIA_API,
        payload:action
    }
}
export const successInternalDbSearch=(action)=>{
    return{
        type:SUCCESS_INTERNAL_DB_SEARCH,
        payload:action
    }
}
export const getMonsterCreditsViaApi=(action)=>{
    return{
        type:GET_MONSTER_CREDITS_VIA_API,
        payload:action
    }
}

export const getIntegrationsViaApi=(action)=>{
    return{
        type:GET_INTEGRATIONS_VIA_API,
        payload:action
    }
}
export const integrationNewMonsterAccountViaApi=(action)=>{
    return{
        type:INTEGRATE_NEW_MONSTER_ACCOUNT_VIA_API,
        payload:action
    }
}
export const successInegrations=(reponse)=>{
    return{
        type:SUCCESS_INTEGRATIONS,
        payload:reponse
    }
}
export const assignedToMonsterAccessViaApi=(action)=>{
    return{
        type:ASSIGNED_TO_MONSTER_ACCESS_VIA_API,
        payload:action
    }
}

export const distributeMonsterCreditsViaApi=(action)=>{
    return{
        type:DISTRIBUTE_MONSTER_CREDITS_VIA_API,
        payload:action
    }
}
//
export const integrateMonsterAdvancedResumesSearchViaApi=(action)=>{
    return{
        type:INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH_VIA_API,
        payload:action
    }
}
export const successIntegrateMonsterAvancedResumesSearch=(reponse)=>{
    return{
        type:SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH,
        payload:reponse
    }
}

export const integrateCBAdvancedResumesSearchViaApi=(action)=>{
    return{
        type:INTEGRTAE_CB_ADVANCED_RESUMES_SEARCH_VIA_API,
        payload:action
    }
}
export const successIntegrateCBAdvancedResumesSearch=(reponse)=>{
    return{
        type:SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH,
        payload:reponse
    }
}
export const integrateInternalDBSearchViaApi=(action)=>{
    return{
        type:INTEGRATE_INTERNAL_DB_SEARCH_VIA_API,
        payload:action
    }
}
export const successIntegrateInternalDBSearch=(reponse)=>{
    return{
        type:SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH,
        payload:reponse
    }
}