const applicantStatus = [
    "Waiting for Evaluation",
    "Internal Interview",
    "Selected Internally",
    "Rejected Internally",
    "Withdrawn",
    "Internal Disqualified",
    "Offer Sent",
    "Offer Accepted",
    "Offer Rejected",
    "Placed",
    "No Show",
    "Archived",
    "Keep Hold",
    "Negotiation",
    "Submitted",
    "Project Completed",
    "Submitted To Client",
    "Submitted To Vendor",
    "Rejected By Client",
    "Selected but no response from the client",
    "Rejected after Client Selection",
    "Submitted to End Client",
    "Vendor Interview",
    "Vendor Interview Reject",
    "Client Interview",
    "Client Interview Select",
    "Client Interview Reject",
    "Client Interview Completed",
    "End Client Interview",
    "End Client Interview Completed",
    "Selected By Vendor",
    "Selected By Client",
    "Selected By End Client",
    "Rejected By Vendor",
    "Rejected by End Client",
    "Disqualified By Vendor",
    "Disqualified By Client",
    "Disqualified by End Client",
    "Hold Internally",
    "Hold By Vendor",
    "Hold By Client",
    "Hold by End Client",
    "Offer Withdrawn",
    "Got Pay Rate Confirmation",
    "BGV Failed",
    "Drop",
    "Interview Scheduled",
    "Confirmed",
    "Position on Hold",
    "Project Ended",
    "Not Joined",
    "L1 Interview Rejected",
    "L3 Interview Rejected",
    "L1 Interview Scheduled",
    "L3 Interview Scheduled",
    "L2 Interview Rejected",
    "L2 Interview Scheduled",
    "L1 Interview Feedback Pending",
    "L3 Interview Feedback Pending",
    "L2 Interview Feedback Pending",
    "L1 Interview Completed",
    "L3 Interview Completed",
    "L2 Interview Completed",
    "Backout",
    "L1 Interview Selected",
    "L3 Interview Selected",
    "L2 Interview Selected",
    "Position Filled",
    "L1 Interview Reschedule",
    "L3 Interview Reschedule",
    "L2 Interview Reschedule",
    "PW Interview Proposed By Buyer",
    "PW Interview Proposed By Supplier",
    "PW Interview Failed",
    "PW Interview Cancelled",
    "PW Interview Success",
    "Assignment Contract Sent",
    "Assignment Contract Verified",
    "Assignment Contract Rejected",
    "Confirmed",
  ];
  const jobStatus = [
    "Active",
    "On Hold",
    "Hold by Client",
    "Interview L1",
    "Interview L2",
    "Interview L3",
    "Priority",
    "Filled",
    "Closed",
  ];
  const statusOfApplicants = [
    "Do Not call (Allowed to submit)",
    "Do Not Submit (Not allowed to submit)",
    "New lead (Allowed to submit)",
    "Out of market (Allowed to submit)",
    "Placed (Allowed to submit)",
  ];
  export default{applicantStatus,jobStatus, statusOfApplicants}