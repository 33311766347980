import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Avatar,
  AvatarGroup,
  Box,
  Chip,
  Grid,
  IconButton,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { Email, Edit, Delete } from "@mui/icons-material";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import theme from "../../Theme";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
import { Navigate } from "react-router-dom";
import {
  CustomFormField,
  FilterHeader,
  TableFilterHeader,
} from "../../Components";
import { EmailData } from "../../Data/EmailData";
const ManageEmails = () => {
  const handleRowClick = (params) => {
    console.log("Row clicked:", params.row); // Handle row click logic here
  };
  const handleEditClick = (id) => {
    console.log("Edit clicked for row:", id); // Handle edit logic here
  };

  const handleDeleteClick = (id) => {
    console.log("Delete clicked for row:", id); // Handle delete logic here
  };
  const capitalizeFirstLetter = (str) =>
    str.charAt(0).toUpperCase() + str.slice(1);
  const columns = [
    {
      field: "sender",
      headerName: "Sender",
      width: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box flex={1}>
              <TextField
                variant="standard"
                //label={`${params.colDef.headerName} Filter`}
                placeholder={`${params.colDef.headerName} Filter`}
                fullWidth
                InputProps={{
                  style: { fontSize: "12px" }, // Set font size for the input element
                }}
                //onChange={handleSearch}
              />
            </Box>
          </Box>
        );
      },
      renderCell: (params) => {
        const { mailCommunications, subject, id } = params.row;
        const lastCommunicationDetails = mailCommunications.sort(
          (a, b) => new Date(b.mailSent) - new Date(a.mailSent)
        )[0];
        return (
          <Box
            sx={{
              width: "99%",
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: "100%",
                display: "flex",
                gap: 0.5,
                alignItems: "center",
              }}
            >
              <AvatarGroup
                max={4}
                sx={{
                  "& .MuiAvatarGroup-avatar": {
                    width: 18,
                    height: 18,
                    fontSize: 12,
                    // Background color for overflow avatars like +2
                  },
                }}
              >
                <Avatar
                  alt={
                    lastCommunicationDetails?.fromName
                      ? capitalizeFirstLetter(lastCommunicationDetails.fromName)
                      : capitalizeFirstLetter(
                          lastCommunicationDetails.formEmail
                        )
                  }
                  src="/static/images/avatar/1.jpg"
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    width: 18,
                    height: 18,
                    fontSize: 12,
                  }}
                />

                {lastCommunicationDetails.toList.map((to, index) => (
                  <Avatar
                    key={index}
                    alt={
                      to?.name
                        ? capitalizeFirstLetter(to.name)
                        : capitalizeFirstLetter(to.email)
                    } // Adjusted for proper name handling from ccList
                    src="/static/images/avatar/2.jpg"
                    sx={{
                      backgroundColor: theme.palette.error.dark,
                      width: 18,
                      height: 18,
                      fontSize: 12,
                    }}
                  />
                ))}
                {lastCommunicationDetails.ccList.map((cc, index) => (
                  <Avatar
                    key={index}
                    alt={
                      cc?.name
                        ? capitalizeFirstLetter(cc.name)
                        : capitalizeFirstLetter(cc.email)
                    }
                    src="/static/images/avatar/2.jpg"
                    sx={{
                      backgroundColor: theme.palette.secondary.dark,
                      width: 18,
                      height: 18,
                      fontSize: 12,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Typography noWrap sx={{ fontSize: "14px" }}>
                {lastCommunicationDetails?.fromName
                  ? lastCommunicationDetails.fromName
                  : lastCommunicationDetails.formEmail}
                ,
              </Typography>

              <Typography noWrap sx={{ fontSize: "14px" }}>
                {lastCommunicationDetails.toList
                  .map((item, key) => (item?.name ? item.name : item.email))
                  .join(", ")}
              </Typography>
            </Box>
          </Box>
        );
      },
    },

    {
      field: "detail",
      headerName: "Mail",
      flex: 1,
      minWidth: 300,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box flex={1}>
              <TextField
                variant="standard"
                //label={`${params.colDef.headerName} Filter`}
                placeholder={`${params.colDef.headerName} Filter`}
                fullWidth
                InputProps={{
                  style: { fontSize: "12px" }, // Set font size for the input element
                }}
                //onChange={handleSearch}
              />
            </Box>
          </Box>
        );
      },
      renderCell: (params) => {
        const { mailCommunications, subject, id } = params.row;
        const lastCommunicationDetails = mailCommunications.sort(
          (a, b) => new Date(b.mailSent) - new Date(a.mailSent)
        )[0];
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 1,
              alignItems: "center",
            }}
          >
            <FiberManualRecordRoundedIcon
              fontSize={"12px"}
              color={id === 1 ? "error" : "success"}
            />
            <Box>
              <Chip
                color="default"
                variant="outlined"
                size="small"
                label={mailCommunications.length}
                sx={{
                  fontSize: "10px",
                  height: 18,
                  opacity: 0.5,
                  lineHeight: "10px",
                }}
              />
            </Box>
            <Typography sx={{ fontSize: "14px" }}>{subject} -</Typography>
            <Typography noWrap sx={{ fontSize: "14px", flex: 1 }}>
              {
                lastCommunicationDetails.mailBody
                  .replace(/<br\s*\/?>/gi, "\n") // Replace <br> and <br /> with new lines
                  .replace(/&nbsp;/g, " ") // Replace &nbsp; with space
                  .replace(/<[^>]+>/g, "") // Remove all other HTML tags
              }
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "date",
      headerName: "",
      width: 240,
      renderCell: (params) => {
        const { mailCommunications, subject, id } = params.row;
        const lastCommunicationDetails = mailCommunications.sort(
          (a, b) => new Date(b.mailSent) - new Date(a.mailSent)
        )[0];
        const date = new Date(lastCommunicationDetails.mailSent);
        const options = { day: "numeric", month: "short" };
        const formattedDate = date.toLocaleDateString("en-GB", options);
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: 1,
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Typography noWrap sx={{ fontSize: "12px" }}>
              {FormatDateTime(lastCommunicationDetails.mailSent, "full")}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const sortedMailData = EmailData.map((email) => {
    const lastCommunication = email.mailCommunications.sort(
      (a, b) => new Date(b.mailSent) - new Date(a.mailSent)
    )[0];

    return {
      ...email,
      lastMailSent: lastCommunication.mailSent, // Add a field to hold the last sent date for sorting
    };
  }).sort((a, b) => new Date(b.lastMailSent) - new Date(a.lastMailSent));

  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Manage Emails"}>
          <Box sx={{ width: "100%", overflowX: "auto" }}>
            <Box pb={2}>
              <Box>
                <CustomFormField
                  title={"Select Account"}
                  components={[
                    {
                      type: "autocomplete",

                      props: {
                        title: "From",
                        multiple: true,
                        defaultValue: ["@tektreeinc.com", "@tektreellc.com"],
                        options: [
                          "@tektreeinc.com",
                          "@tektreellc.com",
                          "@techfive.ai",
                        ],
                      },
                    },
                  ]}
                />
              </Box>
            </Box>
            {/* <Box pb={2}>
              <Box>
                <CustomFormField
                  title={"Search Here "}
                  components={[
                    {
                      type: "text",
                      props: {
                        placeholder: "Email ",
                      },
                    },
                    {
                      type: "text",
                      props: {
                        placeholder: "Subject ",
                      },
                    },
                  ]}
                />
              </Box>
            </Box> */}
            {/* <DataGrid
              rows={sortedMailData}
              columns={columns}
              onRowClick={handleRowClick} // Row click handler
              disableColumnMenu
              disableColumnSelector
              disableColumnFilter
              disableColumnReorder
              disableColumnResize
              disableColumnSort
              disableRowSelectionOnClick
              //hideFooter
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[100]}
              getRowHeight={() => "auto"}
              autoHeight
              sx={{
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center !important",
                  background: "transparent",
                  py: 0.5,

                  "&:focus": {
                    outline: "none",
                  },
                },
                [`& .MuiDataGrid-row`]: {
                  backgroundColor: "white",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: theme.palette.grey[200],
                  },
                },
                [`& .MuiDataGrid-checkboxInput`]: {
                  color: theme.palette.primary.main,
                  p: 0,
                  "&.Mui-checked": {
                    color: theme.palette.primary.dark,
                  },
                },
                //Scroller
                [`& .MuiDataGrid-scrollbar`]: {
                  scrollbarWidth: "thin",
                  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                },
                [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                  borderRadius: "10px", // Apply border radius
                },

                [`& .MuiDataGrid-scrollbar:hover`]: {
                  scrollbarColor: `${theme.palette.primary.dark}`,
                },
                [`& .MuiDataGrid-columnHeader`]: {
                  //display: "none", // Hides the header row
                },
                [`& .MuiDataGrid-columnHeaderTitleContainer`]: {
                  //display: "none", // Hides the header row
                  bgcolor: "red",
                },
              }}
            /> */}
            <DataGrid
              rows={sortedMailData}
              columns={columns}
              onRowClick={handleRowClick} // Row click handler
              disableRowSelectionOnClick
              disableColumnMenu
              disableColumnSorting
              disableColumnSelector
              disableColumnFilter
              disableColumnReorder
              disableColumnResize
              disableColumnSort
              hideFooter
              initialState={{
                pagination: {
                  paginationModel: {
                    page: 0,
                    pageSize: 100,
                  },
                },
              }}
              pageSizeOptions={[100]}
              getRowHeight={() => "auto"}
              autoHeight
              sx={{
                "& .MuiDataGrid-cell": {
                  display: "flex",
                  alignItems: "center !important",
                },
                [`& .MuiDataGrid-checkboxInput`]: {
                  color: theme.palette.primary.main,
                  p: 0,

                  "&.Mui-checked": {
                    color: theme.palette.primary.dark,
                  },
                },
                //Scroller
                [`& .MuiDataGrid-scrollbar`]: {
                  scrollbarWidth: "thin",
                  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                },
                [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                  borderRadius: "10px", // Apply border radius
                },

                [`& .MuiDataGrid-scrollbar:hover`]: {
                  scrollbarColor: `${theme.palette.primary.dark}`,
                },
                [`& .MuiDataGrid-columnHeaderTitleContainer`]: {
                  display: "grid",
                },
              }}
            />
          </Box>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default ManageEmails;
