import { SnackbarProvider } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Modal,
  NativeSelect,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import theme from "../../Theme";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import InsertDriveFileRoundedIcon from "@mui/icons-material/InsertDriveFileRounded";
import ViewCompactRoundedIcon from "@mui/icons-material/ViewCompactRounded";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PersonIcon from "@mui/icons-material/Person";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
//////////////
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import {
  CopyToClipboardButton,
  CustomFormField,
  FilterHeader,
  JobPostingForm,
  MainBackground,
  ScrollView,
  SectionTitle,
  SnackbarComponent,
} from "../../Components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addNoteViaApi,
  awsResumeUrl,
  changeApplicantStatusViaApi,
  changeJobStatusViaApi,
  jobCloseRequestAdminViaApi,
  jobPostAssignedToRecruiterViaApi,
  searchDateJobPostingDataViaApi,
  successAwsResumeUrl,
} from "../../store/recruiter/action";
import { getAllUsers } from "../../store/auth/login/actions";
import dayjs from "dayjs";
import moment from "moment-timezone";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { getAllTemplatesViaApi, stopLoadingRedux } from "../../store/actions";
import { ShimmerTable } from "react-shimmer-effects";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import { deepOrange } from "@mui/material/colors";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CloseIcon from "@mui/icons-material/Close";
import applicantStatusData from "../../Components/StatusElements/applicantStatus";
import ResumeViewer from "../../Components/Common/ResumeViewer";
import CandidateSendMail from "../../Components/Modals/CandidateSendMail";
import ReactQuill from "react-quill";
import FormatDateTime from "../../Components/Helper/FormatDateTime";
const defaultJobDetails = {
  jobCode: "",
  jobTitle: "",
  jobWebsite: "",
  clientBillRateSalary: {
    currency: "USD",
    amount: "",
    paySchedule: "",
    jobType: "",
  },
  payRateSalary: {
    currency: "USD",
    minAmount: "",
    maxAmount: "",
    paySchedule: "",
    jobType: "",
  },
  jobStartDate: new Date(),
  jobEndDate: null,
  respondBy: "",
  remoteJob: "",
  expensesPaid: "",
  country: "USA",
  states: "",
  location: "",
  jobStatus: "Active",
  jobType: "",
  requiredHourWeek: "",
  client: "",
  clientManager: "",
  endClient: "",
  clientJobID: "",
  requiredDocuments: [],
  turnoutTime: {
    time: "",
    dates: "",
  },
  priority: "",
  clientCategory: "",
  duration: "",
  additionalDetails: "",
  areaCode: "",
  workAuthorization: [],
  interviewMode: "",
  applicationForm: "",
  clearance: "",
  address: "",
  employmentTestTemplate: "",
  employmentLevel: "",
};
const defaultSkills = {
  industry: "",
  degree: "",
  experience: {
    min: "",
    max: "",
  },
  evaluationTemplate: "",
  primarySkills: [],
  secondarySkills: "",
  languages: [],
};
const defaultOrganizationalInformation = {
  numberofPositions: "",
  maximumAllowedSubmission: "",
  taxTerms: [],
  salesManager: "",
  department: "",
  recruitmentManager: "",
  assignedTo: "",
  primaryRecruiter: "",
  comments: "",
  additionalNotifications: "",
  careerPortalPublishedDate: null,
  jobDescription: "",
};
const TableFilterHeader = ({
  params,
  type = "line", // select, multiselect
  options = [],
  selectedValue = type === "multiselect" ? [] : "",
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const SectionHeader = ({ title }) => (
    <InputLabel
      id={`${title}-label`}
      sx={{ fontSize: "12px", fontWeight: 600 }}
    >
      {title}
    </InputLabel>
  );
  return (
    <React.Fragment>
      {!showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Box flex={1}>
            <Typography
              noWrap
              sx={{
                backgroundColor: "#FFFFFF",
                fontSize: "15px",
                color: theme.palette.primary.main,
              }}
            >
              {params.headerName}
            </Typography>
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="primary">
              <FilterListIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
      {showFilter && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box flex={1}>
            <SectionHeader title={params.headerName} />
            {type !== "line" ? (
              <Select
                labelId={`${params.headerName}-label`}
                id={params.headerName}
                label={params.headerName}
                MenuProps={{}}
                variant="standard"
                fullWidth
                size="small"
                // multiple={type === "multiselect" ? true : false}
                multiple={type === "multiselect"}
                value={selectedValue || []}
                sx={{ fontSize: "12px", fontWeight: 600 }}
                //onChange={handleSelectMultiple}
              >
                {options &&
                  options.map((item, key) => (
                    <MenuItem
                      value={item}
                      key={key}
                      sx={{ fontSize: "12px", fontWeight: 600 }}
                    >
                      {item}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <TextField
                variant="standard"
                //label={`${params.colDef.headerName} Filter`}
                placeholder={`${params.headerName} Filter`}
                fullWidth
                InputProps={{
                  style: { fontSize: "12px" }, // Set font size for the input element
                }}
                //onChange={handleSearch}
              />
            )}
          </Box>
          <Box>
            <IconButton size="small" onClick={handleShowFilter} color="error">
              <FilterListOffIcon sx={{ fontSize: 14 }} />
            </IconButton>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

const JobPosts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const JobPosting = useSelector((state) => state.Recruiters?.jobPostings);
  const { applicantStatus, jobStatus } = applicantStatusData;
  const Applicants = useSelector((state) => state.Recruiters?.applicants);
  const ApplicantsData = Array.isArray(Applicants) ? Applicants : [];
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const industries = useSelector((state) => state.Search.industries || []);

  const industryNames = industries.map((item) => item.industryName) || [];
  const client = storeData.clientDb;
  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllUsers(action));
    dispatch(getAllTemplatesViaApi(action));
  }, []);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const [openRows, setOpenRows] = useState({});
  const [openNoteDrawer, setOpenNoteDrawer] = useState(false);
  const [selectedJodCode, setSelectedJodCode] = useState(""); // Add this line
  const [selectOptionJobPost, setSelectOptionJobPost] = useState("Submit");
  const [filterJobPostChange, setFilterJobPostChange] = useState([]);
  const [selectedJobStatus, setSelectedJobStatus] = useState("");
  const [bdmCompanyId, setBdmCompanyId] = useState("");
  const [takeJobTitle, setTakeJobTitle] = useState("");

  const [modalAssignOpenApplicantStatus, setModalAssignOpenApplicantStatus] =
    useState(false);
  const [selectedApplicantId, setSelectedApplicantId] = useState("");
  const [selectedApplicantStatus, setSelectedApplicantStatus] = useState("");

  ///////
  //// job close request to Admin
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [closeReason, setCloseReason] = useState("");
  ///
  const quillRef = useRef(null);
  const handleSelectJobStatus = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleModalClose = () => {
    setIsModalOpen(!isModalOpen);
    setCloseReason("");
  };

  const handleToggleCollapse = (rowId) => {
    setOpenRows((prevOpenRows) => ({
      ...prevOpenRows,
      [rowId]: !prevOpenRows[rowId],
    }));
  };
  // const handleNoteDtawer = (rowId) => {
  //   setSelectedJodCode(rowId); // Set the selected job code
  //   setOpenNoteDrawer(!openNoteDrawer);
  // };

  const handleNoteDrawer = (rowId, jobId) => {
    setSelectedJodCode(rowId); // Set the selected job code
    setOpenNoteDrawer(!openNoteDrawer);
    setAssignJobPostId(jobId);
    setNote(""); // Clear any existing note
    setNoteError("");
  };
  const handleModalAssignOpenApplicantStatus = (params) => {
    setModalAssignOpenApplicantStatus(!modalAssignOpenApplicantStatus);
    setAssignJobPostId(params.row.jobPostingId);
    setSelectedApplicantStatus(params.row.applicantStatus);
    setSelectedApplicantId(params.row._id);
  };

  useEffect(() => {
    const action = {
      client: client,
      page: "jobpage",
    };
    dispatch(searchDateJobPostingDataViaApi(action));
  }, []);
  const formatDateForDisplay = (date) => {
    const dateInNY = dayjs(date).tz("America/New_York");
    const isDST = moment.tz(date, "America/New_York").isDST();
    const timeZoneAbbr = isDST ? "EDT" : "EST";
    return dateInNY.format(`MMMM DD, YYYY h:mm A [${timeZoneAbbr}]`);
  };
  // const checkRecruiter = storeData.userRole === "Recruiter";
  let filterRecruiter = Array.isArray(JobPosting)
    ? JobPosting.filter((job) => job.status === "Submit")
    : [];

  function getDisplayString(fullString) {
    // Split the string by the hyphen and remove the last part (timestamp)
    const parts = fullString.split("-");

    // Join the first two parts to get the display string
    const displayString = `${parts[0]}-${parts[1]}`;

    return displayString;
  }
  const handleChangeJobPost = (value) => {
    setSelectOptionJobPost(value);
    let filterRecruiterChange = [];
    filterRecruiterChange = Array.isArray(JobPosting)
      ? JobPosting.filter((job) => job.status === value)
      : [];
    if (storeData.userType.includes("BDM")) {
      filterRecruiterChange = Array.isArray(JobPosting)
        ? JobPosting.filter(
            (job) => job.status === value && job.addedBy === storeData._id
          )
        : [];
    }
    const changeJobPostingData = Array.isArray(filterRecruiterChange)
      ? filterRecruiterChange.map((data, index) => {
          const assignedToUser = allUsers.find(
            (user) => user._id === data.assignedTo
          );
          const createdByUser = allUsers.find(
            (user) => user._id === data.addedBy
          );
          const deliveryManager = allUsers.find(
            (user) => user._id === data.assignedBy
          );
          return {
            id: index,
            jobPostingId: data?._id || "",
            jobCode: data.jobDetails?.jobCode || "",
            companyName: data.jobDetails?.client || "",
            jobTitle: data.jobDetails?.jobTitle || "",
            jobWebsite: data.jobDetails?.jobWebsite || "",
            jobLocation: data.jobDetails?.location || "",
            jobStatus: data.jobStatus?.length
              ? data.jobStatus[data.jobStatus.length - 1].status
              : "",
            approvalStatus:
              data.jobStatus && data.jobStatus.length > 0
                ? data.jobStatus[data.jobStatus.length - 1].approvalStatus
                : "",

            assignedTo: assignedToUser?.userSudoFirstName || "", // Adjust the property based on your user object structure
            assignedToRecruiters: data?.assignedToRecruiters || [],
            assignedToID: data?.assignedTo || "", // Adjust the property based on your user object structure
            jobCreatedBy: createdByUser?.userSudoFirstName || "", // Adjust the property based on your user object structure
            jobCreatedOn: formatDateForDisplay(data.addedOn) || "",
            deliveryManager: deliveryManager?.userSudoFirstName || "",
            note: true,
            // Uncomment and adjust the following lines as needed
            clientBill: `${
              data.jobDetails?.clientBillRateSalary?.currency || ""
            }/${data.jobDetails?.clientBillRateSalary?.amount || ""}/${
              data.jobDetails?.clientBillRateSalary?.paySchedule || ""
            }/${data.jobDetails?.clientBillRateSalary?.jobType || ""}`,
            payBill: `${data.jobDetails?.payRateSalary?.currency || ""}/${
              data.jobDetails?.payRateSalary?.paySchedule || ""
            }/${data.jobDetails?.payRateSalary?.jobType || ""}`,
            minSalary: `${data.jobDetails?.payRateSalary?.minAmount || ""}/`,
            maxSalary: `${data.jobDetails?.payRateSalary?.maxAmount || ""}/`,
            jobDetails: data.jobDetails,
            skills: data.skills,
            submissions: data.submission || [],
            bdmCompanyId: data.bdmCompanyId,
            organizationalInformation: data.organizationalInformation,
          };
        })
      : [];

    setFilterJobPostChange(changeJobPostingData);
  };

  const filterJobPostingData = Array.isArray(filterRecruiter)
    ? filterRecruiter.map((data, index) => {
        const assignedToUser = allUsers.find(
          (user) => user._id === data.assignedTo
        );
        const createdByUser = allUsers.find(
          (user) => user._id === data.addedBy
        );
        const deliveryManager = allUsers.find(
          (user) => user._id === data.assignedBy
        );
        return {
          id: index,
          jobPostingId: data?._id || "",
          jobCode: data.jobDetails?.jobCode || "",
          companyName: data.jobDetails?.client || "",
          jobTitle: data.jobDetails?.jobTitle || "",
          jobWebsite: data.jobDetails?.jobWebsite || "",
          jobLocation: data.jobDetails?.location || "",
          jobStatus: data.jobStatus?.length
            ? data.jobStatus[data.jobStatus.length - 1].status
            : "",
          approvalStatus:
            data.jobStatus && data.jobStatus.length > 0
              ? data.jobStatus[data.jobStatus.length - 1].approvalStatus
              : "",
          //adding these three to avoid breaking of existing codebase setup
          createdBy: createdByUser || {},
          createdDate: data.addedOn || "",
          jobStatusArray: data.jobStatus?.length ? data.jobStatus : [],

          assignedTo: assignedToUser?.userSudoFirstName || "Not Assign", // Adjust the property based on your user object structure
          assignedToID: data?.assignedTo || "", // Adjust the property based on your user object structure
          assignedToRecruiters: data?.assignedToRecruiters || [],
          jobCreatedBy: createdByUser?.userSudoFirstName || "", // Adjust the property based on your user object structure
          jobCreatedOn: formatDateForDisplay(data.addedOn) || "",
          deliveryManager: deliveryManager?.userSudoFirstName || "",
          note: true,
          // Uncomment and adjust the following lines as needed
          clientBill: `${
            data.jobDetails?.clientBillRateSalary?.currency || ""
          }/${data.jobDetails?.clientBillRateSalary?.amount || ""}/${
            data.jobDetails?.clientBillRateSalary?.paySchedule || ""
          }/${data.jobDetails?.clientBillRateSalary?.jobType || ""}`,
          payBill: `${data.jobDetails?.payRateSalary?.currency || ""}/${
            data.jobDetails?.payRateSalary?.paySchedule || ""
          }/${data.jobDetails?.payRateSalary?.jobType || ""}`,
          minSalary: `${data.jobDetails?.payRateSalary?.minAmount || ""}/`,
          maxSalary: `${data.jobDetails?.payRateSalary?.maxAmount || ""}/`,
          jobDetails: data.jobDetails,
          skills: data.skills,
          submissions: data.submissions || [],
          bdmCompanyId: data.bdmCompanyId,
          organizationalInformation: data.organizationalInformation,
        };
      })
    : [];
  const JobPostingData = Array.isArray(JobPosting)
    ? JobPosting.filter((job) => job.status === "Submit")
    : [];

  const filterApplicantsByJob = (jobPosts, applicants) => {
    return jobPosts.map((job, index) => {
      // Get applicant IDs related to the job post
      const relevantApplicants = applicants.filter(
        (applicant) =>
          Array.isArray(applicant.tagJobs) &&
          applicant.tagJobs.some((tagJob) => tagJob.jobPostId === job._id)
      );
      return {
        jobId: job._id,
        submissions: relevantApplicants,
      };
    });
  };

  const filterApplicantByJobData = filterApplicantsByJob(
    JobPostingData,
    ApplicantsData
  );

  const getSubmissionCount = (jobId) => {
    const submission = filterApplicantByJobData.find(
      (item) => item.jobId === jobId
    );
    return submission ? submission.submissions.length : 0;
  };

  const recruiterIds = allUsers
    .filter(
      (user) =>
        user.userType.includes("RecruitmentTeam") ||
        user.userRole === "DeliveryManager"
    )
    .map((user) => user._id);

  const [modalAssignOpen, setModalAssignOpen] = useState(false);
  const [assignedRecruiter, setAssignedRecruiter] = useState([]);
  const [assignJobPostId, setAssignJobPostId] = useState("");
  const [modalStatusChange, setModalStatusChange] = useState(false);
  const [note, setNote] = useState("");
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [noteError, setNoteError] = useState("");

  const handleModalAssignOpen = (data, params) => {
    setModalAssignOpen(!modalAssignOpen);
    setAssignedRecruiter(data);
    setAssignJobPostId(params.row.jobPostingId);
  };
  const handleModalAssignCancel = () => {
    setModalAssignOpen(!modalAssignOpen);
    setAssignedRecruiter([]);
    setAssignJobPostId("");
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    // `value` is an array of selected recruiterIDs
    setAssignedRecruiter(
      value.map((recruiterID) => {
        // Check if the recruiterID already exists in assignedRecruiter
        const existingRecruiter = assignedRecruiter.find(
          (recruiter) => recruiter.recruiterID === recruiterID
        );
        return (
          existingRecruiter || {
            recruiterID,
            assignedBy: storeData._id,
            status: "Assigned",
            addedOn: new Date(),
          }
        );
      })
    );
  };
  const handleAssignRecruiter = () => {
    // Add your logic to handle the recruiter assignment here
    if (assignJobPostId && assignedRecruiter.length > 0) {
      const action = {
        jobPostingId: assignJobPostId,
        assignedToRecruiters: assignedRecruiter,
        client: client,
      };
      setLoading(true);
      dispatch(jobPostAssignedToRecruiterViaApi(action));

      handleModalAssignCancel();
      setTimeout(() => {
        const action = {
          client,
          page: "jobpage",
        };
        dispatch(searchDateJobPostingDataViaApi(action));
      }, 3000);
    }
  };
  const handleModalStatusChange = (params) => {
    setModalStatusChange(!modalStatusChange);
    setAssignJobPostId(params.row.jobPostingId);
    setSelectedJobStatus(params.row.jobStatus);
    setTakeJobTitle(params.row.jobTitle);
    setBdmCompanyId(params.row.bdmCompanyId);
  };

  const handleModalAssignStatusCancel = () => {
    setModalStatusChange(!modalStatusChange);
    setAssignJobPostId("");
  };
  const handleSaveJobStatus = () => {
    if (selectedJobStatus === "Closed" || selectedJobStatus === "On Hold") {
      handleSelectJobStatus();
      return "";
    }
    const action = {
      client: client,
      addedBy: storeData._id,
      addedOn: new Date(),
      jobId: assignJobPostId,
      newStatus: selectedJobStatus,
      takeJobTitle: takeJobTitle,
      bdmCompanyId: bdmCompanyId,
      page: "jobpage",
    };
    dispatch(changeJobStatusViaApi(action));
    setModalStatusChange(!modalStatusChange);
    setAssignJobPostId("");
    setLoading(true);
    setTimeout(() => {
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 3000);
  };
  const handleModalConfirm = () => {
    const action = {
      client: client,
      addedBy: storeData._id,
      addedOn: new Date(),
      jobId: assignJobPostId,
      newStatus: selectedJobStatus,
      takeJobTitle: takeJobTitle,
      bdmCompanyId: bdmCompanyId,
      page: "jobpage",
      bdmRequestDate: new Date(),
      closeReason: closeReason,
      statusAdvice:
        checkboxes.find((checkbox) => checkbox.isChecked)?.label || "",
    };
    dispatch(jobCloseRequestAdminViaApi(action));
    setModalStatusChange(!modalStatusChange);
    setIsModalOpen(!isModalOpen);
    setCloseReason("");
    setAssignJobPostId("");
    setLoading(true);
    setTimeout(() => {
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 3000);
  };
  const handleModalAssignApplicantStatusCancel = () => {
    setModalAssignOpenApplicantStatus(!modalAssignOpenApplicantStatus);
    setAssignJobPostId("");
  };
  const handleSaveApplicantStatus = async () => {
    const action = {
      client: storeData.clientDb,
      addedBy: storeData._id,
      addedOn: new Date(),
      newStatus: selectedApplicantStatus,
      jobId: assignJobPostId,
      applicantId: selectedApplicantId,
    };
    dispatch(changeApplicantStatusViaApi(action));
    setModalAssignOpenApplicantStatus(!modalAssignOpenApplicantStatus);
    setAssignJobPostId("");
    setLoading(true);
    setOpenRows([]);
    setTimeout(() => {
      dispatch(searchDateJobPostingDataViaApi(action));
    }, 3000);
  };
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };
  const fileExtension = AwsResumeUrl?.split(".")
    .pop()
    .split("?")[0]
    .toLowerCase();
  const tableColumns = [
    {
      field: "",
      headerName: "",
      minWidth: 180,
      renderCell: (params) => {
        const {
          status,
          note,
          jobCode,
          assignedTo,
          jobPostingId,
          assignedToRecruiters,
          submissions,
        } = params.row;
        const submissionCount = getSubmissionCount(jobPostingId);
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              alignItems: "center",
            }}
          >
            <PersonIcon
              sx={{ fontSize: 20 }}
              color={assignedToRecruiters.length > 0 ? "success" : "disabled"}
            />
            <CircleIcon
              sx={{ fontSize: 12 }}
              color={status === "active" ? "success" : "disabled"}
            />

            {submissionCount > 0 && (
              <Box display={"flex"} alignItems={"center"} gap={0.2}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => handleToggleCollapse(params.row.id)}
                >
                  {openRows[params.row.id] ? (
                    <RemoveIcon sx={{ fontSize: 14 }} color="primary" />
                  ) : (
                    <AddIcon sx={{ fontSize: 14 }} color="primary" />
                  )}
                </IconButton>

                <Typography
                  sx={{
                    background: theme.palette.primary.dark,
                    color: "primary.contrastText",
                    fontSize: 11,
                    px: 0.5,
                    py: 0.2,
                    borderRadius: 1,
                  }}
                >
                  {submissionCount}
                </Typography>
              </Box>
            )}

            {note && (
              <IconButton
                size="small"
                //onClick={() => handleNoteDtawer(params.row.id)}
                // onClick={() => handleNoteDtawer(params.row.jobCode)}
                onClick={() =>
                  handleNoteDrawer(params.row.jobCode, params.row.jobPostingId)
                }
              >
                <SpeakerNotesIcon sx={{ fontSize: 14 }} color="warning" />
              </IconButton>
            )}
            {/* {submissions && Array.isArray(submissions) && submissions.length > 0 && (
              <IconButton  size="small" onClick={()=>handleToggleSendMailModal(submissions,jobPostingId)}>
              <MailOutlineIcon  sx={{ fontSize: 14 }} color="error"/>
            </IconButton>
            )} */}
          </Box>
        );
      },
    },
    {
      field: "jobCode",
      headerName: "Job Code",
      minWidth: 320,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.2,
            }}
          >
            <Tooltip title={params.value} arrow placement="top">
              {/* <Link to={"/jobPostDetails"}> */}
              <Link
                component="button" // Use `button` for a clickable element
                to="/jobPostDetails"
                state={{ company: params.row }}
              >
                <Typography
                  noWrap
                  sx={{
                    fontSize: "14px",
                    color: theme.palette.primary.dark,
                    textDecoration: "none",
                  }}
                >
                  {getDisplayString(params.value)}
                </Typography>
              </Link>
            </Tooltip>
            <Tooltip title={"Quick View"} arrow placement="top">
              <IconButton size="small">
                <ViewCompactRoundedIcon sx={{ fontSize: 14 }} color="primary" />
              </IconButton>
            </Tooltip>
          </Box>
          <CopyToClipboardButton text={params.value} />
        </Box>
      ),
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      minWidth: 250,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => (
        <Tooltip title={params.value} arrow placement="top">
          <Typography noWrap sx={{ fontSize: "14px" }}>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 250,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => (
        <Tooltip title={params.value} arrow placement="top">
          <Typography noWrap sx={{ fontSize: "14px" }}>
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "jobLocation",
      headerName: "Job Location",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "jobStatus",
      headerName: "Job Status",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
      renderCell: (params) => {
        const approvalStatus = params.row.approvalStatus;
        const orignalStatus = params.value;
        const status =
          approvalStatus === false ? "Pending for Approval" : orignalStatus;

        return (
          <Box>
            <Chip
              label={status}
              color={
                status === "Active"
                  ? "success"
                  : status === "Draft"
                  ? "warning"
                  : "primary"
              }
              variant="outlined"
              size="small"
              sx={{ px: 0.5, lineHeight: 1 }}
              onClick={() => handleModalStatusChange(params)}
            />
          </Box>
        );
      },
    },
    {
      field: "clientBill",
      headerName: "Client Bill",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "payBill",
      headerName: "Pay Bill",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "assignedTo",
      headerName: "Delivery Manager",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "",
      headerName: "Recruiter",
      minWidth: 150,
      renderCell: (params) => {
        const currentRecruiterIds = params.row.assignedToRecruiters || []; // Ensure it's an array

        if (
          (!Array.isArray(currentRecruiterIds) ||
            currentRecruiterIds.length === 0) &&
          (storeData.userType.includes("SuperAdmin") ||
            storeData.userType.includes("Admin") ||
            storeData.userType.includes("Developer") ||
            storeData.userLevel === "DeliveryManager")
        ) {
          return (
            <Box sx={{ flex: "1" }}>
              <Tooltip
                title={
                  currentRecruiterIds.length > 0
                    ? "Add more recruiters..."
                    : "Add recruiter"
                }
                placement="right-start"
                onClick={() =>
                  handleModalAssignOpen(currentRecruiterIds, params)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.2,
                    cursor: "pointer",
                  }}
                >
                  Add recruiter
                  <ControlPointIcon sx={{ fontSize: 16 }} />
                </Box>
              </Tooltip>
            </Box>
          );
        }
        const Unknown = "Unknown";
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.2,
              cursor: "pointer",
            }}
          >
            <AvatarGroup
              total={currentRecruiterIds.length}
              sx={{
                "& .MuiAvatar-root": { width: 24, height: 24, fontSize: 12 },
              }}
            >
              {currentRecruiterIds.slice(0, 2).map((id) => {
                const recruiter = allUsers.find(
                  (user) => user._id === id.recruiterID
                );
                return recruiter ? (
                  <Avatar
                    key={id}
                    sx={{ bgcolor: deepOrange[500] }}
                    alt={recruiter.userSudoFirstName}
                    title={`${recruiter.userSudoFirstName}${""}${
                      recruiter.userSudoLastName
                    }`}
                  >
                    {recruiter.userSudoFirstName.slice(0, 2).toUpperCase()}
                  </Avatar>
                ) : (
                  <Avatar
                    key={id}
                    sx={{ bgcolor: deepOrange[500] }}
                    alt={Unknown}
                  >
                    {Unknown.slice(0, 2).toUpperCase()}
                  </Avatar>
                );
              })}
            </AvatarGroup>
            {currentRecruiterIds.length > 0 &&
              (storeData.userType.includes("SuperAdmin") ||
                storeData.userType.includes("Admin") ||
                storeData.userType.includes("Developer") ||
                storeData.userLevel === "DeliveryManager") && (
                <Tooltip
                  title={"Add more recruiters..."}
                  placement="right-start"
                  onClick={() =>
                    handleModalAssignOpen(currentRecruiterIds, params)
                  }
                >
                  <ControlPointIcon sx={{ fontSize: 16 }} />
                </Tooltip>
              )}
          </Box>
        );
      },
    },

    {
      field: "jobCreatedBy",
      headerName: "Job Created By",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "jobCreatedOn",
      headerName: "Job Created On",
      minWidth: 150,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "minSalary",
      headerName: "Min Salary",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
    {
      field: "maxSalary",
      headerName: "Max Salary",
      minWidth: 200,
      renderHeader: (params) => (
        <TableFilterHeader params={params} type={"line"} />
      ),
    },
  ];

  const nestedTableColunms = [
    {
      field: "id",
      headerName: "Submission Id",
      width: 170,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "applicantName",
      headerName: "Applicant Name",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "workAuthorization",
      headerName: "Work Authorization",
      width: 230,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile Number",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        return <div>{params.value}</div>; // Render the cell value inside a div
      },
    },
    {
      field: "submissionScrore",
      headerName: "Submission Scrore",
      width: 190,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => (
        <Box sx={{ flex: 1, display: "flex", alignItems: "center", gap: 3 }}>
          <Box flex={1}>
            <LinearProgress
              variant="determinate"
              value={params.value}
              color={
                params.value < 50
                  ? "error"
                  : params.value >= 50 && params.value < 70
                  ? "warning"
                  : params.value >= 70 && params.value < 90
                  ? "info"
                  : params.value >= 90
                  ? "success"
                  : "primary"
              }
            />
          </Box>
          {params.value} %
        </Box>
      ),
    },
    {
      field: "city",
      headerName: "Location",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "experience",
      headerName: "Experience",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "source",
      headerName: "Source",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "resume",
      headerName: "Resume",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const lastDocument = params.value?.length ? params.value.at(-1) : {};
        return (
          <Box>
            <IconButton aria-label="expand row" size="small">
              <InsertDriveFileRoundedIcon
                sx={{ fontSize: 16 }}
                onClick={() => handleAxiosLink(lastDocument?.serverLocation)}
              />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "revisionStatus",
      headerName: "Revision Status",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "applicantStatus",
      headerName: "Applicaton Status",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", gap: "5px" }}>
            <Chip
              label={params.value}
              color={params.value === "SubmitJob" ? "primary" : "success"}
              onClick={() => handleModalAssignOpenApplicantStatus(params)}
              variant="outlined"
              size="small"
              sx={{ px: 0.5, lineHeight: 1 }}
            />
          </Box>
        );
      },
    },
    {
      field: "outlookMSG",
      headerName: "Outlook MSG",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "billRate",
      headerName: "Bill Rate",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "payRate",
      headerName: "Pay Rate",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "employeerName",
      headerName: "Employeer Name",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "availability",
      headerName: "Availability",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "submitedBy",
      headerName: "Submited By",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "pwSubmissionType",
      headerName: "PW Submission Type",
      width: 220,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "noticePeriod",
      headerName: "Notice Period",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "currentCTC",
      headerName: "Current CTC",
      width: 180,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "submitedOn",
      headerName: "Submited On",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: () => (
        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <IconButton aria-label="expand row" size="small">
            <InsertDriveFileRoundedIcon sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      ),
    },
  ];

  const flattenedRows = filterApplicantByJobData.flatMap((job) =>
    job.submissions.map((submission) => {
      // Find the relevant tagJob for this submission
      const tagJob = submission.tagJobs.find(
        (tagjob) => tagjob.jobPostId === job.jobId
      );

      // Find the user who submitted this based on the tagJob  sub.statusChanges[0].addedBy
      const submitedBy = allUsers.find(
        (user) => tagJob?.statusChanges?.[0]?.addedBy === user._id
      )?.userSudoFirstName;

      return {
        id: submission.applicantId,
        jobPostingId: job.jobId,
        applicantName: `${submission?.firstName || ""} ${
          submission?.middleName || ""
        } ${submission?.lastName || ""}`.trim(),
        mobileNumber: submission.mobileNumbers
          ? submission.mobileNumbers
              .filter((item) => item.number) // Filter out objects without an email
              .map((item) => item.number)
              .join(", ")
          : "",
        city: submission?.city || "",
        country: submission?.state || "",
        experience: `${submission?.experienceYears || 0}/${
          submission?.experienceMonths || 0
        }`,
        source: submission?.source || "",
        resume: submission.resumes || "",
        workAuthorization: submission.workAuthorization || "",
        availability: submission?.availability || "",
        applicantStatus: tagJob?.applicantStatus || "",
        payRate: `${submission.payRateSalary?.currency || ""}/${
          submission.payRateSalary?.paySchedule || ""
        }/${submission?.taxTerm || ""}`,
        submitedBy: submitedBy || "Unknown",
        submitedOn: formatDateForDisplay(submission.addedOn),
        ...submission, // Spread to include other properties
      };
    })
  );
  const getFilteredRows = (jobPostingId) => {
    const trimmedJobPostingId = jobPostingId.trim();
    const filteredRows = flattenedRows.filter(
      (row) => row.jobPostingId.trim() === trimmedJobPostingId
    );
    return filteredRows;
  };

  const dataToRender =
    selectOptionJobPost === "Draft"
      ? filterJobPostChange
      : filterJobPostingData;

  const [isJobPostingModalOpen, setIsJobPostingModalOpen] = useState(false);
  const [jobDetails, setJobDetails] = useState(defaultJobDetails);
  const [skills, setSkills] = useState(defaultSkills);
  const [jobStatusPost, setStatusPost] = useState([
    {
      addedBy: storeData._id,
      addedOn: new Date(),
      status: "Active",
    },
  ]);
  const [assignedTo, setAssignedTo] = useState("");
  const [organizationalInformation, setOrganizationalInformation] = useState(
    defaultOrganizationalInformation
  );
  const [jobPostStatus, setJobPostStatus] = useState("");
  const [jobPostingId, setJobPostingId] = useState("");
  const [errors, setErrors] = useState({});

  const handleJobPostingCloseModal = () => {
    setJobDetails(defaultJobDetails);
    setSkills(defaultSkills);
    setOrganizationalInformation(defaultOrganizationalInformation);
    setIsJobPostingModalOpen(!isJobPostingModalOpen);
    setErrors({});
    setAssignedTo("");
  };

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [applicantsList, setApplicantsList] = useState([]);
  const [jobId, setJobId] = useState("");
  // const [applicantId,setAppl]
  const handleToggleSendMailModal = (row, jobPostingId) => {
    let listData = [];

    // Ensure row is an array
    const rowArray = Array.isArray(row) ? row : [];

    rowArray.forEach((applicant) => {
      const foundApplicant = ApplicantsData.find(
        (applicantData) => applicantData._id === applicant.applicantId
      );
      if (foundApplicant) {
        listData.push(foundApplicant);
      }
    });
    setJobId(jobPostingId);

    setApplicantsList(listData);
    setSendMailModal(!sendMailModal);
  };
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, label: "DNC", isChecked: false },
    { id: 2, label: "Blocked", isChecked: false },
  ]);
  // Handler for checkbox change
  const handleCheckboxChange = (id) => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: checkbox.id === id,
    }));
    setCheckboxes(updatedCheckboxes);
  };
  const handleNote = (value) => {
    setNote(value);
  };
  const handleNoteSubmit = async () => {
    setIsAddingNote(true);
    try {
      const noteData = [
        {
          addedBy: storeData._id,
          addedOn: new Date(),
          note: note.trim(),
        },
      ];
      const action = {
        client: client,
        jobId: assignJobPostId,
        note: noteData,
      };
      dispatch(addNoteViaApi(action));
      setLoading(true);
      setNote("");
      setTimeout(() => {
        dispatch(searchDateJobPostingDataViaApi(action));
        // dispatch(jobPostRecruiterViaApi(action))
      }, 3000);
    } finally {
      setIsAddingNote(false);
    }
  };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Job Posts"}>
          <Box>
            {(storeData.userType.includes("SuperAdmin") ||
              storeData.userType.includes("Admin") ||
              storeData.userType.includes("Developer") ||
              storeData.userType.includes("BDM")) && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button
                  onClick={() => handleChangeJobPost("Submit")}
                  variant={
                    selectOptionJobPost === "Submit" ? "contained" : "outlined"
                  }
                >
                  Submit Job Posts
                </Button>
                <Button
                  onClick={() => handleChangeJobPost("Draft")}
                  color="error"
                  variant={
                    selectOptionJobPost === "Draft" ? "contained" : "outlined"
                  }
                >
                  Draft Job Posts
                </Button>
                {/* <Button color="error" variant="contained" onClick={() => {setIsJobPostingModalOpen(true)}}> */}
                <Button color="error" variant="contained">
                  Add New Job Post
                </Button>
              </Box>
            )}
            {loading ? (
              <>
                <ShimmerTable row={5} col={5} />
              </>
            ) : (
              <>
                <TableContainer
                  component={Paper}
                  sx={{
                    mt: 3,
                    overflowX: "auto",
                    maxHeight: "660px",
                    overflowY: "auto !important",
                    scrollbarWidth: "thin",
                    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                  }}
                >
                  <Table aria-label="spanning table" size="small">
                    <TableHead>
                      <TableRow sx={{ height: 60 }}>
                        {tableColumns.map((column) => (
                          <TableCell
                            key={column.field}
                            sx={{
                              maxWidth: column.minWidth,
                              minWidth: column.minWidth,
                              width: "auto",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {column.renderHeader ? (
                                column.renderHeader({
                                  headerName: column.headerName,
                                }) // Pass the header name as params
                              ) : (
                                <Typography
                                  noWrap
                                  sx={{
                                    backgroundColor: "#FFFFFF",
                                    fontSize: "15px",
                                    color: theme.palette.primary.main,
                                  }}
                                >
                                  {column.headerName}
                                </Typography>
                              )}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {dataToRender.map((row) => (
                        <React.Fragment key={row.id}>
                          <TableRow>
                            {tableColumns.map((column) => (
                              <TableCell
                                key={column.field}
                                sx={{
                                  maxWidth: column.minWidth,
                                  minWidth: column.minWidth,
                                  width: "auto",
                                }}
                              >
                                {column.renderCell ? (
                                  column.renderCell({
                                    value: row[column.field],
                                    row,
                                  })
                                ) : (
                                  <Typography noWrap sx={{ fontSize: 14 }}>
                                    {row[column.field]}
                                  </Typography>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={tableColumns.length}
                              sx={{ padding: 0 }}
                            >
                              <Collapse
                                in={openRows[row.id]}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box
                                  sx={{
                                    bgcolor: theme.palette.grey[100],
                                    p: 2,
                                  }}
                                >
                                  <DataGrid
                                    rows={getFilteredRows(row.jobPostingId)}
                                    columns={nestedTableColunms}
                                    disableRowSelectionOnClick
                                    disableColumnMenu
                                    disableColumnSorting
                                    disableColumnSelector
                                    disableColumnFilter
                                    disableColumnReorder
                                    disableColumnResize
                                    disableColumnSort
                                    hideFooter
                                    initialState={{
                                      pagination: {
                                        paginationModel: {
                                          page: 0,
                                          pageSize: 100,
                                        },
                                      },
                                    }}
                                    pageSizeOptions={[100]}
                                    getRowHeight={() => "auto"}
                                    autoHeight
                                    sx={{
                                      "& .MuiDataGrid-cell": {
                                        display: "flex",
                                        alignItems: "center !important",
                                      },
                                      [`& .MuiDataGrid-checkboxInput`]: {
                                        color: theme.palette.primary.main,
                                        p: 0,

                                        "&.Mui-checked": {
                                          color: theme.palette.primary.dark,
                                        },
                                      },
                                      //Scroller
                                      [`& .MuiDataGrid-scrollbar`]: {
                                        scrollbarWidth: "thin",
                                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                                      },
                                      [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]:
                                        {
                                          borderRadius: "10px", // Apply border radius
                                        },

                                      [`& .MuiDataGrid-scrollbar:hover`]: {
                                        scrollbarColor: `${theme.palette.primary.dark}`,
                                      },
                                    }}
                                  />
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[100]}
                  component="div"
                  count={dataToRender.length}
                  rowsPerPage={100}
                  page={0}
                  // onPageChange={handleChangePage}
                  // onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Box>
        </LayoutSidebarMenu>
        <SnackbarComponent />
      </SnackbarProvider>

      <Drawer
        open={openNoteDrawer}
        onClose={() => setOpenNoteDrawer(false)}
        anchor="right"
      >
        <Box
          sx={{
            width: 500,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            flex: 1,
          }}
        >
          <Box p={2}>
            <SectionTitle title="Add New Note" size="small" color="error" />
            <Box mt={2} />
            {/* Notes for Job Code: {selectedJodCode} */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <CustomFormField
                //title={"Note"}
                components={[
                  {
                    type: "textArea",
                    props: {
                      defaultValue: note,
                      placeholder: "Enter your Note",
                      onChange: (value) => handleNote(value),
                    },
                  },
                ]}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <Button
                  variant="text"
                  size="small"
                  color="error"
                  onClick={() => setOpenNoteDrawer(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  disabled={!note.trim()}
                  onClick={handleNoteSubmit}
                >
                  Add Note
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: 1,
              pb: 2,
              pl: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color: theme.palette.primary.dark,
                }}
              >
                Total Notes:{" "}
                {JobPosting?.find((job) => job._id === assignJobPostId)?.notes
                  ?.length ?? 0}
              </Typography>
            </Box>
            <Box flex={1}>
              <ScrollView>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mr: 2,
                  }}
                >
                  {/* {Array.from({ length: 15 }, (_, index) => ( */}
                  {JobPosting?.find(
                    (job) => job._id === assignJobPostId
                  )?.notes?.map((noteItem, index) => (
                    <MainBackground key={index}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 13,
                            letterSpacing: 0.2,
                            color: theme.palette.grey[800],
                          }}
                        >
                          Note: {noteItem.note}
                        </Typography>
                        <Divider sx={{ py: 0.5 }} />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: 0.5,
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 11,
                              letterSpacing: 0.2,
                              color: theme.palette.grey[500],
                            }}
                          >
                            User Name -{" "}
                            {allUsers.find(
                              (user) => user._id === noteItem.addedBy
                            )?.userSudoFirstName || "Unknown"}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: 11,
                              letterSpacing: 0.2,
                              color: theme.palette.grey[500],
                            }}
                          >
                            {FormatDateTime(noteItem.addedOn, "full")}
                          </Typography>
                        </Box>
                      </Box>
                    </MainBackground>
                  ))}
                </Box>
              </ScrollView>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Modal
        open={modalAssignOpen}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to Assign
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-chip-label">Assign</InputLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={assignedRecruiter.map((item) => item.recruiterID)} // Extract recruiterID from assignedRecruiter
                onChange={handleChange} // Handle change to update the array
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((recruiterID) => {
                      const recruiter = allUsers.find(
                        (user) => user._id === recruiterID
                      );
                      return recruiter ? (
                        <Chip
                          key={recruiter._id}
                          label={recruiter.userSudoFirstName}
                        />
                      ) : (
                        <Chip key={recruiterID} label="Unknown" />
                      );
                    })}
                  </Box>
                )}
              >
                {recruiterIds.map((id) => {
                  const recruiter = allUsers.find((user) => user._id === id);
                  return (
                    <MenuItem key={id} value={id}>
                      {recruiter ? recruiter.userSudoFirstName : "Unknown"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleAssignRecruiter()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={modalStatusChange}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to assign a job status?
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="job-status-label">Job Status</InputLabel>

              <Select
                labelId="demo-single-select-label"
                id="demo-single-select"
                value={selectedJobStatus} // This holds the single selected status
                onChange={(e) => setSelectedJobStatus(e.target.value)} // Function to handle the change
                input={<OutlinedInput label="Job Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip key={selected} label={selectedJobStatus} />
                  </Box>
                )}
              >
                {jobStatus.map((status, id) => (
                  <MenuItem key={id} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignStatusCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveJobStatus()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={modalAssignOpenApplicantStatus}
        // onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: { xs: 1, md: 2 },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              mb: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={400}>
              Are you sure you want to change a Applicant status?
            </Typography>
            <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="job-status-label">Applicant Status</InputLabel>

              <Select
                labelId="demo-single-select-label"
                id="demo-single-select"
                value={selectedApplicantStatus} // This holds the single selected status
                onChange={(e) => setSelectedApplicantStatus(e.target.value)} // Function to handle the change
                input={<OutlinedInput label="Applicant Status" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    <Chip key={selected} label={selected} />
                  </Box>
                )}
              >
                {applicantStatus.map((status, id) => {
                  return (
                    <MenuItem key={id} value={status}>
                      {status}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalAssignApplicantStatusCancel()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSaveApplicantStatus()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openResumeModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        // onClose={handleCloseResumeModal} // Function to close modal
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={handleCloseResumeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Resume iframe or fallback */}
          <Modal
            open={openResumeModal}
            onClose={handleCloseResumeModal}
            aria-labelledby="resume-modal-title"
            aria-describedby="resume-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                overflow: "hidden",
              }}
            >
              {/* Close Button */}
              <IconButton
                aria-label="close"
                onClick={handleCloseResumeModal}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>

              {/* Conditional rendering based on file type */}
              {AwsResumeUrl ? (
                <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
              ) : (
                <Typography variant="h6" textAlign="center">
                  No resume available.
                </Typography>
              )}
            </Box>
          </Modal>
        </Box>
      </Modal>
      <Modal open={isJobPostingModalOpen} onClose={handleJobPostingCloseModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)",
            height: "100vh",
          }}
        >
          <Box>
            <Paper
              elevation={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1,
                px: 4,
              }}
            >
              <Typography variant="h5" fontWeight={600}>
                Job Posting
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button color="error" onClick={handleJobPostingCloseModal}>
                  Close
                </Button>
                {/* <Button
                      color="inherit"
                      variant="outlined"
                      // onClick={handleJobPostingSaveDraft}
                      // disabled={jobPostStatus === "Submit"}
                      type="button"
                    >
                      Save as a Draft
                    </Button> */}

                <Button
                  variant="contained"
                  color="primary"
                  // onClick={handleJobPostingSaveSubmit}
                >
                  Save and Submit
                </Button>
              </Box>
            </Paper>
          </Box>

          <Box flex={1} overflow={"auto"} py={1}>
            <JobPostingForm
              jobDetails={jobDetails}
              setJobDetails={setJobDetails}
              skills={skills}
              setSkills={setSkills}
              organizationalInformation={organizationalInformation}
              setOrganizationalInformation={setOrganizationalInformation}
              errors={errors}
              allUsers={allUsers}
              setAssignedTo={setAssignedTo}
              assignedTo={assignedTo}
              industryNames={industryNames}
            />
          </Box>
        </Box>
      </Modal>
      <Modal
        open={sendMailModal}
        onClose={handleToggleSendMailModal}
        aria-labelledby="send email"
        aria-describedby="send email modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <CandidateSendMail
            close={handleToggleSendMailModal}
            applicantsList={applicantsList}
            jobId={jobId}
          />
        </Box>
      </Modal>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Reason for {selectedJobStatus}
          </Typography>

          <Box
            sx={{
              maxHeight: 200,
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "5px", // width of the scrollbar
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: theme.palette.primary[400],
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.light,
                borderRadius: 5,
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={closeReason}
              onChange={setCloseReason}
              placeholder="Paste Email response/thread here.. "
              modules={{
                toolbar: false, // Disable the toolbar
              }}
            />
          </Box>

          <Box>
            <FormGroup sx={{ display: "flex", flexDirection: "column" }}>
              {checkboxes.map((checkbox) => (
                <FormControlLabel
                  key={checkbox.id}
                  control={
                    <Checkbox
                      checked={checkbox.isChecked}
                      onChange={() => handleCheckboxChange(checkbox.id)}
                    />
                  }
                  label={checkbox.label}
                />
              ))}
            </FormGroup>
          </Box>
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleModalClose} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={() => handleModalConfirm()}
              variant="contained"
              color="primary"
              disabled={!closeReason.trim()}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default JobPosts;
