import React from 'react'
import ResumeViewer from '../../Components/Common/ResumeViewer';
import { Box, IconButton, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export const ViewResume = () => {
    const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  return (
    <Box
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
      overflow: "hidden",
    }}
  >
    {/* Close Button */}
    <IconButton
      aria-label="close"
      
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      {/* <CloseIcon /> */}
    </IconButton>
    {/* Conditional rendering based on file type */}
    {AwsResumeUrl ? (
      <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
    ) : (
      <Typography variant="h6" textAlign="center">
        No resume available.
      </Typography>
    )}
  </Box>
  )
}

export default ViewResume;