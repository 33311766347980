// Search actions
export const ADVANCED_RESUME_SEARCH_VIA_API = 'ADVANCED_RESUME_SEARCH_VIA_API';
export const SUCCESS_ADVANCED_RESUME_SEARCH = 'SUCCESS_ADVANCED_RESUME_SEARCH';
export const SEARCH_RESUMEID_INTERNALDB_VIA_API='SEARCH_RESUMEID_INTERNALDB_VIA_API';
export const SUCCESS_SEARCH_RESUMEID_INTERNALDB='SUCCESS_SEARCH_RESUMEID_INTERNALDB';
export const GET_RESUME_AND_STORE_INTERNALDB_VIA_API='GET_RESUME_AND_STORE_INTERNALDB_VIA_API';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'; 
//
export const MONSTER_ADVANCED_RESUME_SEARCH_VIA_API='MONSTER_ADVANCED_RESUME_SEARCH_VIA_API';
export const SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH='SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH';
export const GET_MONSTER_RESUME_AND_STORE_INTERNALDB_VIA_API='GET_MONSTER_RESUME_AND_STORE_INTERNALDB_VIA_API';

export const SEARCH_RESUME_INTERNAL_DB_VIA_API='SEARCH_RESUME_INTERNAL_DB_VIA_API'
export const SUCCESS_SEARCH_RESUME_INTERNAL_DB='SUCCESS_SEARCH_RESUME_INTERNAL_DB';
export const INITIALSTATE_RESUME_DATA='INITIALSTATE_RESUME_DATA'
export const EMAILS_SEND_TO_APPLICANTS_VIA_API='EMAILS_SEND_TO_APPLICANTS_VIA_API';

export const INTEGRATE_SEARCH_INTERNAL_DB_VIA_API='INTEGRATE_SEARCH_INTERNAL_DB_VIA_API';

export const APPLICANT_SEARCH_INTERNAL_DB_VIA_API='APPLICANT_SEARCH_INTERNAL_DB_VIA_API';

export const INTERNAL_DB_SEARCH_VIA_API='INTERNAL_DB_SEARCH_VIA_API';
export const SUCCESS_INTERNAL_DB_SEARCH='SUCCESS_INTERNAL_DB_SEARCH';
export const GET_MONSTER_CREDITS_VIA_API='GET_MONSTER_CREDITS_VIA_API';

export const CREATE_INTEGRATION_VIA_API='CREATE_INTEGRATION_VIA_API'

export const GET_INTEGRATIONS_VIA_API='GET_INTEGRATIONS_VIA_API';
export const SUCCESS_INTEGRATIONS='SUCCESS_INTEGRATIONS';

export const INTEGRATE_NEW_MONSTER_ACCOUNT_VIA_API='INTEGRATE_NEW_MONSTER_ACCOUNT_VIA_API'

export const ASSIGNED_TO_MONSTER_ACCESS_VIA_API='ASSIGNED_TO_MONSTER_ACCESS_VIA_API'

export const DISTRIBUTE_MONSTER_CREDITS_VIA_API='DISTRIBUTE_MONSTER_CREDITS_VIA_API';

//
export const INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH_VIA_API='INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH_VIA_API'
export const SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH='SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH'

export const INTEGRTAE_CB_ADVANCED_RESUMES_SEARCH_VIA_API='INTEGRTAE_CB_ADVANCED_RESUMES_SEARCH_VIA_API'
export const SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH='SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH'

export const INTEGRATE_INTERNAL_DB_SEARCH_VIA_API='INTEGRATE_INTERNAL_DB_SEARCH_VIA_API'
export const SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH='SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH'