import {
  SnackbarProvider,
  enqueueSnackbar,
  closeSnackbar,
  useSnackbar,
} from "notistack";
import React, { useEffect, useRef, useState } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import {
  CustomFormField,
  CustomSelect,
  CustomTextEditor,
  KeyData,
  PageHeader,
  SnackbarComponent,
  Widget,
  WidgetMain,
} from "../../Components";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Modal,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";

import CustomSelectChip from "../../Components/Common/CustomSelectChip";
import { useSelector, useDispatch } from "react-redux";
import {
  countSentMailsViaApi,
  dateSearchRaUsersCompaniesViaApi,
  getAllTemplatesViaApi,
  getShuffledDataViaApi,
  lastWeekShuffledDataViaApi,
  removeCountMails,
  sendEmailViaApi,
} from "../../store/search/action";
import CloseIcon from "@mui/icons-material/Close";
import { Source } from "@mui/icons-material";
import { getAllUsers } from "../../store/auth/login/actions";
import { values } from "lodash";
import { getSentEmailsViaApi } from "../../store/companiesList/action";
import CopyToClipboardButton from "../../Components/Common/CopyToClipboardButton";
import ReactQuill from "react-quill";
import PropTypes from "prop-types";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";

import dayjs from "dayjs";
import BasicDatePicker from "../../Components/DateandTime/DatePicker";
import FollowupSheet from "../../Components/FollowupSheet";

dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <BorderLinearProgress
          color="success"
          variant="determinate"
          {...props}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
const ManualEmail = () => {
  const dispatch = useDispatch();
  const templatesData = useSelector((state) => state.Search.templates) || [];
  const emailCount = useSelector((state) => state.Search.emailCount) || {};
  const { allEmailCount, duplicateEmailCount } = emailCount;
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  // const shuffledData = useSelector((state) => state.Search.shuffled) || [];
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const AllCompaniesList =
    useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
  const AllSentEmails =
    useSelector((state) => state.CompaniesList.sentEmails) || [];

  const [searchFromDate, setSearchFromDate] = useState(dayjs().startOf("day"));
  const [searchToDate, setSearchToDate] = useState(dayjs().endOf("day"));
  const shuffledDataRedux = useSelector((state) => state.Search.shuffled) || [];
  const [shuffledData, setShuffledData] = useState([]);
  function filterUsersByEmailType(users) {
    const filteredUsers = {};

    users.forEach((user) => {
      if (user.emailsIntegration) {
        user.emailsIntegration.forEach((integration) => {
          // Get the integration type dynamically
          const type = integration.type;

          // Initialize the array for this type if it doesn't exist
          if (!filteredUsers[type]) {
            filteredUsers[type] = [];
          }

          // Push the emails into the corresponding type array
          filteredUsers[type].push(...integration.emails);
        });
      }
    });

    return filteredUsers;
  }

  const result = filterUsersByEmailType(allUsers);
  // Function to extract unique email types
  function getUniqueEmailTypes(users) {
    const emailTypes = new Set(); // Using Set to ensure uniqueness

    users.forEach((user) => {
      if (user.emailsIntegration) {
        user.emailsIntegration.forEach((integration) => {
          emailTypes.add(integration.type); // Add the type to the Set
        });
      }
    });

    return Array.from(emailTypes); // Convert Set back to array
  }

  // Call the function to get unique email types
  const optionsEmailSource = getUniqueEmailTypes(allUsers);
  useEffect(() => {
    if (shuffledDataRedux.length > 0 && allUsers.length > 0) {
      const latestShuffledData = shuffledDataRedux[0]?.shuffled;
      if (latestShuffledData && latestShuffledData.length > 0) {
        const latestData = latestShuffledData[latestShuffledData.length - 1];
        setShuffledData([latestData]);
      }
    }
  }, [shuffledDataRedux, allUsers]);
  const [emailSource, setEmailSource] = useState("");
  const [emailAccount, setEmailAccount] = useState("");
  const [typeOfEmail, setTypeOfEmail] = useState("");
  const [template, setTemplate] = useState("");
  const [data, setData] = useState("");
  const [optionsData, setOptionsData] = useState([]);
  const [optionsEmailAccount, setOptionsEmailAccount] = useState([]);
  const [templateSubjectType, setTemplateSubjectType] = useState("");
  const [templateBodyType, setTemplateBodyType] = useState("");
  const [optionsTemplate, setOptionsTemplate] = useState([]);
  const [templateId, setTemplateId] = useState("");
const [emailInput,setEmailInput]=useState("")
const [emailList,setEmailList]=useState([])
  let takenAssignedData = shuffledData.flatMap((shufflesData) => {
    return (shufflesData.shuffledData || [])
      .filter((data) => data.BdmId === storeData._id)
      .flatMap((filteredData) => filteredData.assignUsers || []);
  });
  // if(storeData.userType==='Admin'&&emailAccount){
  //   takenAssignedData = shuffledData.flatMap((shufflesData) => {
  //     return (shufflesData.shuffledData || [])
  //       .filter((data) => data.BdmEmail === emailAccount||data.BdmpseudoEmail===emailAccount)
  //       .flatMap((filteredData) => filteredData.assignUsers || []);
  //   });
  // }
  if (
    (storeData.userType.includes("SuperAdmin") ||
      storeData.userType.includes("Admin") ||
      storeData.userType.includes("Developer")) &&
    emailAccount
  ) {
    const emailAccountName = (emailAccount.split("@")[0] || "").trim();

    takenAssignedData = shuffledData.flatMap((shufflesData) => {
      return (shufflesData.shuffledData || [])
        .filter((data) => {
          const bdmEmailName = (data.BdmEmail?.split("@")[0] || "").trim();
          const bdmPseudoEmailName = (
            data.BdmpseudoEmail?.split("@")[0] || ""
          ).trim();
          return (
            bdmEmailName === emailAccountName ||
            bdmPseudoEmailName === emailAccountName
          );
        })
        .flatMap((filteredData) => filteredData.assignUsers || []);
    });
  }

  let totalCompanies = 0;
  let totalEmails = 0;
  let sentEmailsCount = 0;
  let production = 0;
  takenAssignedData.forEach((assignUser) => {
    AllCompaniesList.forEach((company) => {
      if (company.addedBy === assignUser) {
        totalCompanies++;
        (company.contactDetails || []).forEach((contact) => {
          if (contact.emailId) {
            totalEmails++;
          }
        });
      }
    });
  });
  AllSentEmails.forEach((email) => {
    if (
      email.fromEmail === storeData.userEmailId ||
      email.fromEmail === storeData.userSudoEmailId ||
      email.fromEmail === emailAccount
    ) {
      sentEmailsCount++;
    }
  });

  AllSentEmails.forEach((email) => {
    if (
      (email.fromEmail === storeData.userEmailId ||
        email.fromEmail === storeData.userSudoEmailId ||
        email.fromEmail === emailAccount) &&
      email.toEmail === "production@tektreeinc.com"
    ) {
      production++;
    }
  });

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => date.toISOString());
      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client: client,
      };
      dispatch(dateSearchRaUsersCompaniesViaApi(action));

      dispatch(lastWeekShuffledDataViaApi(action));
    }
  };

  useEffect(() => {
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
    const action = {
      searchFromDate: today,
      searchToDate: today.endOf("day"),
      client: client,
    };
    dispatch(getSentEmailsViaApi(action));
    handleDateChange([today, today.endOf("day")]);
  }, []);

  useEffect(() => {
    const action = {
      client: client,
    };
    dispatch(getAllTemplatesViaApi(action));
    dispatch(getAllUsers(action));
    dispatch(getShuffledDataViaApi(action));
  }, []);
  // Function to filter users by email integration type
  // Function to filter users by email integration type dynamically

  const handleChangeEmailSource = (title, value) => {
    setEmailSource(value);
    const emailsForTitle = result[value];
    setOptionsEmailAccount(emailsForTitle);
    if (storeData.userType.includes("BDM")) {
      const selectedIntegration = storeData.emailsIntegration.find(
        (integration) => integration.type === value
      )?.emails;
      setOptionsEmailAccount(selectedIntegration || []);
    }
  };
  const handleChangeEmailAccount = (title, value) => {
    setEmailAccount(value);
    if (value) {
      const username = value.split("@")[0];

      const checkBdmEMail = allUsers.find(
        (user) => user.userEmailId.split("@")[0] === username
      );

      const takenAssignedTemplate = templatesData
        ? templatesData.filter(
            (data) => data.templateAssignToId === checkBdmEMail?._id
          )
        : [];

      const takeTemplateOption = takenAssignedTemplate
        ? takenAssignedTemplate.map((template) => template.templateTitle)
        : [];
      setOptionsTemplate(takeTemplateOption);
      setTemplateSubjectType("");
      setTemplateBodyType("");
    }
  };
  const handleChangeTypeOfEmail = (title, value) => {
    setTypeOfEmail(value);
    if (value === "Latest data") {
      setSearchFromDate(dayjs().tz("Asia/Kolkata").startOf("day"));
      setSearchToDate(dayjs().tz("Asia/Kolkata").endOf("day"));
      handleDateChange([
        dayjs().tz("Asia/Kolkata").startOf("day"),
        dayjs().tz("Asia/Kolkata").endOf("day"),
      ]);
    } else if (value === "Follow-up data") {
      setSearchFromDate(
        dayjs().tz("Asia/Kolkata").subtract(1, "day").startOf("day")
      );
      setSearchToDate(
        dayjs().tz("Asia/Kolkata").subtract(1, "day").endOf("day")
      );
      handleDateChange([
        dayjs().tz("Asia/Kolkata").subtract(1, "day").startOf("day"),
        dayjs().tz("Asia/Kolkata").subtract(1, "day").endOf("day"),
      ]);
    }
  };

  const handleChangeTemplate = (title, value) => {
    setTemplate(value);
    if (value === "Add New Template") {
      return "";
    }

    const filtered = templatesData
      ? templatesData.filter((template) => template.templateTitle === value)
      : [];
    const { type, emailSubject, emailBody, _id } = filtered ? filtered[0] : [];
    setTemplateBodyType(emailBody.length > 0 ? emailBody : "");
    setTemplateSubjectType(emailSubject.length > 0 ? emailSubject : "");
    setTemplateId(_id || "");
  };

  // const optionsEmailSource = ["Gmail", "Outlook", "MailGun"];
  // const optionsEmailAccount = ["ats@tektreecrop.com", "tektree.llc", "tektree.info"];
  const optionsTypeOfEmail = ["Latest data", "Follow-up data"];

  const [mailModal, setMailModal] = useState(false);
  const [preViewModal, setPreViewModal] = useState(false);
  const handleSaveTiger = () => {
    const allShuffledData = shuffledData
      ? shuffledData.flatMap((entry) => entry.shuffledData || [])
      : [];

    const filteredData = allShuffledData.find((data) => {
      const emailAccountUsername = emailAccount.split("@")[0].trim();
      const bdmEmailUsername = data.BdmEmail.split("@")[0].trim();
      const bdmPseudoEmailUsername = data.BdmpseudoEmail.split("@")[0].trim();

      return (
        emailAccountUsername === bdmEmailUsername ||
        emailAccountUsername === bdmPseudoEmailUsername
      );
    });

    if (
      templateSubjectType === "" ||
      templateBodyType === "" ||
      emailSource == "" ||
      emailAccount == "" ||
      !searchFromDate ||
      !searchToDate
    ) {
      enqueueSnackbar(`Please fill all details`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true;
      return; // Prevent further execution of the function
    }
    const action = {
      // duration: data,
      // dateRanges:dateRanges,
      searchFromDate: searchFromDate,
      searchToDate: searchToDate,
      assignUsers: filteredData ? filteredData.assignUsers : [],
      client: client,
    };
    dispatch(countSentMailsViaApi(action));
    setPreViewModal(!preViewModal);
  };

  let toastShown = false;

  const handleSendEmail = () => {
    const allShuffledData = shuffledData
      ? shuffledData.flatMap((entry) => entry.shuffledData || [])
      : [];

    //  // Find the matching data
    //  const filteredData = allShuffledData.find(
    //    data => data.BdmEmail.trim() === emailAccount || data.BdmpseudoEmail.trim() === emailAccount
    //  );
    // Find the matching data
    const filteredData = allShuffledData.find((data) => {
      const emailAccountUsername = emailAccount.split("@")[0].trim();
      const bdmEmailUsername = data.BdmEmail.split("@")[0].trim();
      const bdmPseudoEmailUsername = data.BdmpseudoEmail.split("@")[0].trim();

      return (
        emailAccountUsername === bdmEmailUsername ||
        emailAccountUsername === bdmPseudoEmailUsername
      );
    });

    if (
      templateSubjectType === "" ||
      templateBodyType === "" ||
      emailSource == "" ||
      emailAccount === "" ||
      !filteredData ||
      !Array.isArray(filteredData.assignUsers) ||
      !searchFromDate ||
      !searchToDate
    ) {
      enqueueSnackbar(`Please fill all details and shuffled DBMS`, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true;
      return; // Prevent further execution of the function
    }

    const action = {
      fromEmail: emailAccount,
      emailSource: emailSource,
      typeOfEmail: typeOfEmail,
      templateName: template,
      subject: templateSubjectType,
      body: templateBodyType,
      userId: storeData._id,
      userEmail: storeData.userEmailId,
      assignUsers: filteredData ? filteredData.assignUsers : [],
      templateId,
      searchFromDate: searchFromDate,
      searchToDate: searchToDate,
      client: client,
      emailList:emailList,
    };
    console.log("action", action);

    dispatch(sendEmailViaApi(action));
    dispatch(removeCountMails());

    setMailModal(!mailModal);
  };
  const handleClose = () => {
    dispatch(removeCountMails());
    setMailModal(!mailModal);
  };

  const handleOpenViewModal = () => {
    setPreViewModal(!preViewModal);
  };
  const handleModalSkip = () => {
    setMailModal(!mailModal);
    setPreViewModal(!preViewModal);
  };
  const replacePlaceholders = (text, contact) => {
    return text.replace(/\${(\w+)}/g, (match, p1) => {
      switch (p1) {
        case "firstName":
          return contact.firstName;
        case "jobTitle":
          return contact.jobTitle;
        case "jobLocation":
          return contact.jobLocation || "Hyderabad"; // Default location
        default:
          return match; // Return the placeholder itself if not recognized
      }
    });
  };
  const contactExample = {
    firstName: "Abhi",
    jobTitle: "Web Developer",
    jobLocation: "Hyderabad",
  };
  const quillRef = useRef(null);
  const [editorHtml, setEditorHtml] = useState("");
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Manual Email"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={9} order={{ xs: 2, lg: 1 }}>
              <Paper sx={{ height: "100%" }}>
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <CustomSelect
                        title="Email source"
                        options={optionsEmailSource}
                        value={emailSource}
                        onChange={handleChangeEmailSource}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <CustomSelect
                        title="Email Account"
                        options={optionsEmailAccount}
                        value={emailAccount}
                        onChange={handleChangeEmailAccount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <CustomSelect
                        title="Type of Email"
                        options={optionsTypeOfEmail}
                        value={typeOfEmail}
                        onChange={handleChangeTypeOfEmail}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      {/* <CustomSelectChip
                        title="Data"
                        options={optionsData}
                        value={data}
                        onChange={handleChangeData}
                        disabled={typeOfEmail === "" && true}
                      /> */}
                      <BasicDatePicker
                        handleDateChange={handleDateChange}
                        searchFromDate={searchFromDate}
                        setSearchFromDate={setSearchFromDate}
                        setSearchToDate={setSearchToDate}
                        typeOfEmail={typeOfEmail}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <CustomSelect
                        title="Template"
                        options={optionsTemplate}
                        value={template}
                        onChange={handleChangeTemplate}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        id="subject"
                        label="Subject"
                        variant="standard"
                        fullWidth
                        value={templateSubjectType}
                        onChange={(event) =>
                          setTemplateSubjectType(event.target.value)
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomFormField
                        title={`Include Test Emails here : - ${emailList.length} (Note: Please separate the email addresses with spaces or commas.)`}
                        components={[
                          {
                            type: "textArea",
                            props: {
                              title: "Action",
                              defaultValue: emailInput,
                              onChange: (value) => {
                                setEmailInput(value);
                                const emails = value.split(/[\s,]+/);
                                const validEmails = emails.filter((email) =>
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                                    email.trim()
                                  )
                                );
                                setEmailList(validEmails);
                              },
                            },
                          },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ReactQuill
                        ref={quillRef}
                        theme="snow"
                        value={templateBodyType}
                        onChange={setEditorHtml}
                        placeholder="Text Here"
                        modules={{
                          toolbar: false, // Disable the toolbar
                        }}
                        readOnly={true} // Make the editor read-only
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/*<Button onClick={()=>handleOpenViewModal()}>Preview</Button>*/}

                        {/*<Button variant="outlined">
                          Trigger without saving
                        </Button>
                        */}
                        <Button variant="contained" onClick={handleSaveTiger}>
                          Save & Trigger
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ width: "100%" }}>
                      <LinearProgressWithLabel
                        value={
                          (production === 0
                            ? sentEmailsCount / totalEmails
                            : sentEmailsCount / sentEmailsCount) * 100
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={3} order={{ xs: 1, lg: 2 }}>
              <Paper sx={{ height: "100%" }}>
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box>
                    <WidgetMain
                      title="Count of emails"
                      value={totalEmails}
                      icon={<BusinessIcon />}
                      color="primary"
                    />
                  </Box>
                  <Box>
                    <WidgetMain
                      title="Emails sent today"
                      value={sentEmailsCount}
                      icon={<BusinessIcon />}
                      color="primary"
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <SnackbarComponent />
          {(storeData.userType.includes("SuperAdmin") ||
            storeData.userType.includes("Admin") ||
            storeData.userType.includes("Developer")) && <FollowupSheet />}
        </LayoutSidebarMenu>
      </SnackbarProvider>
      <Modal
        open={mailModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              flexDirection: "column",

              mb: 1,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Are you sure you want to sent mails?
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography>
                Duplicate Count:{" "}
                {duplicateEmailCount && !isNaN(parseInt(duplicateEmailCount))
                  ? parseInt(duplicateEmailCount)
                  : "-"}
              </Typography>
              <Typography>
                Unique mails:{" "}
                {allEmailCount &&
                !isNaN(parseInt(allEmailCount)) &&
                !isNaN(parseInt(duplicateEmailCount))
                  ? parseInt(allEmailCount) - parseInt(duplicateEmailCount)
                  : "-"}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleSendEmail()}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={preViewModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "900px",
            width: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: {
              xs: 1,
              md: 2,
            },
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              columnGap: 1,
              rowGap: 0.5,
              flexWrap: "wrap",
              flexDirection: "column",

              mb: 1,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Are you sure you want to sent mails check the preview?
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                columnGap: 2,
                rowGap: 0.5,
                flexWrap: "wrap",
              }}
            >
              <KeyData
                title={"Email Source"}
                size="medium"
                value={emailSource}
              />
              <KeyData
                title={"Email Account"}
                size="medium"
                value={emailAccount}
              />
              <KeyData
                title={"Type of Email"}
                size="medium"
                value={typeOfEmail}
              />
              {/* <KeyData title={"Data"} size="medium" value={data}/> */}
              <KeyData title={"Template"} size="medium" value={template} />
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <KeyData
                title={"Subject"}
                size="medium"
                value={replacePlaceholders(templateSubjectType, contactExample)}
              />
              {/* <Typography>
                Subject:
                {replacePlaceholders(templateSubjectType, contactExample)}
              </Typography> */}
            </Box>
            <Box>
              {/* <Typography>
                Body:
                {replacePlaceholders(templateBodyType, contactExample) +
                  `\n\nIf you no longer wish to receive these emails, you can <a href=" https://ats.tektreeinc.com/unSubscribe?email=${emailAccount}">unsubscribe here</a>.`}
              
              </Typography> */}
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={replacePlaceholders(templateBodyType, contactExample)}
                onChange={setEditorHtml}
                placeholder="Text Here"
                modules={{
                  toolbar: false, // Disable the toolbar
                }}
                readOnly={true} // Make the editor read-only
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{ display: "flex", gap: 2, justifyContent: "flex-end", mt: 2 }}
          >
            <Button
              color="inherit"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleOpenViewModal()}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "inherit" }}
              onClick={() => handleModalSkip()}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ManualEmail;
