import { createTheme, ThemeProvider } from "@mui/material/styles";
import { bgcolor } from "@mui/system";

const theme = createTheme({
  palette: {
    primary: {
      default: "86, 45, 212",
      main: "#562DD4",
      light: "#6742D8",
      dark: "#4524AA",
      900: "#7857DD",
      800: "#896CE1",
      700: "#9A81E5",
      600: "#AA96EA",
      500: "#BBABEE",
      400: "#CCC0F2",
      300: "#DDD5F6",
      200: "#EEEAFB",
      contrastText: "#FFF",
    },
    secondary: {
      default: "168, 98, 167",
      main: "#A862A7",
      light: "#B172B0",
      dark: "#864E86",
      900: "#B981B9",
      800: "#C291C1",
      700: "#CBA1CA",
      600: "#D3B0D3",
      500: "#DCC0DC",
      400: "#E5D0E5",
      300: "#EEE0ED",
      200: "#F6EFF6",
      contrastText: "#FFF",
    },
    info: {
      default: "0, 176, 255",
      main: "#00B0FF",
      light: "#19B8FF",
      dark: "#008DCC",
      900: "#33C0FF",
      800: "#4CC8FF",
      700: "#66D0FF",
      600: "#80D7FF",
      500: "#99DFFF",
      400: "#B2E7FF",
      300: "#CCEFFF",
      200: "#E5F7FF",
      contrastText: "#FFF",
    },
    success: {
      default: "38, 193, 134",
      main: "#26C186",
      light: "#3CC792",
      dark: "#1E9A6B",
      900: "#51CD9E",
      800: "#67D4AA",
      700: "#7DDAB6",
      600: "#92E0C3",
      500: "#A8E6CF",
      400: "#BEECDB",
      300: "#D4F3E7",
      200: "#E9F9F3",
      contrastText: "#FFF",
    },
    warning: {
      default: "250, 164, 26",
      main: "#FAA41A",
      light: "#FBAD31",
      dark: "#C88315",
      900: "#FBB648",
      800: "#FCBF5F",
      700: "#FCC876",
      600: "#FDD28C",
      500: "#FDDBA3",
      400: "#FDE4BA",
      300: "#FEEDD1",
      200: "#FFF6E8",
    },
    error: {
      default: "244, 0, 69",
      main: "#F40045",
      light: "#F51958",
      dark: "#C30037",
      900: "#F6336A",
      800: "#F74C7D",
      700: "#F8668F",
      600: "#FA80A2",
      500: "#FB99B5",
      400: "#FCB2C7",
      300: "#FDCCDA",
      200: "#FEE5EC",
      contrastText: "#FFF",
    },
    transparent: {
      main: "#FFFFFF00",
      light: "#FFFFFF00",
      dark: "#FFFFFF00",
      contrastText: "#000",
    },
    background: {
      default: "#F1F8E9", // Background color (Light Green)
    },
    text: {
      primary: "#333333", // Text color (Dark Gray)
      main: "#292929", // Text color (mid Gray)
      light: "#474747", // Text color (light Gray)
      900: "#5C5C5C",
      800: "#707070",
      700: "#858585",
      600: "#999999",
      500: "#ADADAD",
      400: "#C2C2C2",
      300: "#D6D6D6",
      200: "#EBEBEB",
      100: "#F2F2F2",
      50: "#FFFFFF",
    },
    gray: {
      1000: "#000000",
      900: "#191919",
      800: "#333333",
      700: "#4C4C4C",
      600: "#666666",
      500: "#808080",
      400: "#999999",
      300: "#B2B2B2",
      200: "#CCCCCC",
      100: "#E5E5E5",
      50: "#F2F2F2",
    },

    // Additional accent colors
  },
  typography: {
    //fontFamily: "Oxygen, sans-serif",
    fontFamily: "Poppins, sans-serif",
  },

  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Customize the backdrop color here
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeXsmall: {
          width: "20px",
          height: "20px",
          padding: "6px",
          "& .MuiSvgIcon-root": {
            fontSize: "14px",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          //fontSize: "16px",
        },
        columnHeaders: {
          backgroundColor: "#FFFFFF",
          fontSize: "15px",
          color: "#4524AA",
        },
        cell: {
          backgroundColor: "#FFFFFF",
          paddingTop: "8px",
          paddingBottom: "8px",
          display: "flex",
          alignItem: "center",
        },
        selectedRow: {
          backgroundColor: "red",
        },
        footerContainer: {
          backgroundColor: "#FFFFFF",
        },
        checkbox: {
          color: "#4524AA",
        },
      },
    },

    MuiAccordion: {
      styleOverrides: {
        root: {
          //backgroundColor: "red", // Customize the default background color
          // border: "1px solid #ccc", // Add border if needed
          // borderRadius: "4px", // Customize the border radius
          boxShadow: "none", // Remove shadow
          // margin: 0,
          //padding: 3,
          minHeight: 30,
        },
        expanded: {
          backgroundColor: "blue", // Customize background when expanded
          margin: 0,
          padding: 30,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: "#EEEAFB", // Customize the summary background color
          color: "#000000", // Customize the text color

          "&:hover": {
            backgroundColor: "#EEEAFB", // Change background on hover
          },
        },
        content: {
          margin: 0,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
        },
      },
    },
  },
  customClasses: {
    boxShadow3: {
      boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 2px", // Define your desired box shadow here
    },
    // Add other custom classes as needed
  },
});

export default theme;
