

import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Pagination,
  Tab,
  Typography,
  Modal,
  Tooltip,
  Menu,
  MenuItem,
  Avatar
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import MainBackground from "../Backgrounds/MainBackground";
import CustomFormField from "../Common/CustomFormField";
import theme from "../../Theme";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import KeyData from "../Helper/KeyData";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AlbumOutlinedIcon from "@mui/icons-material/AlbumOutlined";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import GroupsIcon from "@mui/icons-material/Groups";
import ShareIcon from "@mui/icons-material/Share";
import LabelIcon from "@mui/icons-material/Label";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CommentIcon from "@mui/icons-material/Comment";
import {
  awsResumeUrl,
  getResumeAndStoreInternalDbViaApi,
  searchResumeIdInternalDbViaApi,
  stopLoadingRedux,
  successAwsResumeUrl,
  successHtmlResumeConvert,
  successSearchResumeIdInternalDb,
} from "../../store/actions";
import { ShimmerText } from "react-shimmer-effects";
import { set } from "lodash";
import ResumeViewer from "../Common/ResumeViewer";
import ResumeText from "../Common/ResumeText";
import CandidateSendMail from "../Modals/CandidateSendMail";
import logoDice from "../../Assets/Images/logo-dice.jpg";
import logoMonster from "../../Assets/Images/logo-monster.jpg";
import logoCB from "../../Assets/Images/logo-cb.jpg";
import { DataGrid } from "@mui/x-data-grid";
import Highlighter from "react-highlight-words";
const data = {
  creditData: {
    remainingCredit: 99,
    currentMonthViews: 1,
    dailyLimit: 299,
    monthlyLimit: 138,
  },
};

const ChipSet = ({
  title,
  value,
  color = "default", // default,error,info,primary,secondary,success,warning
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
      <Typography fontSize={13}>{title} :</Typography>
      <Chip label={value} size="small" color={color} />
    </Box>
  );
};

const CareerBuilder = ({
  keyWords, dataList, matched, page,
  setPage, pageSize, setPageSize, handlePageChange,loading,setLoading
}) => {
  const dispatch = useDispatch();
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const resumesFile = useSelector((state) => state.Resumes.resumesFile);
  const { storeData } = useSelector((state) => ({
    storeData: state.Login.storeData,
  }));
  const AwsResumeUrl = useSelector((state) => state.Recruiters?.awsResumeUrl);
  const HtmlResume = useSelector((state) => state.Recruiters?.htmlResume);
  const fileExtension = AwsResumeUrl?.split(".")
    .pop()
    .split("?")[0]
    .toLowerCase();
  const client = storeData.clientDb;
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSelect = (key) => {
    setLoading(true);
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    const action = {
      client: client,
      resumeId: dataList[key].resumeid,

    };
    dispatch(successSearchResumeIdInternalDb([]));
    dispatch(searchResumeIdInternalDbViaApi(action));
    setSelectedCandidate(key);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedCandidate(null);
    setDrawerOpen(false);
  };

  const [openTabId, setOpenTabId] = useState("1");
  const handleChange = (event, newValue) => {
    setOpenTabId(newValue);
  };
  const handleGetResume = () => {
    const selectCandidateData = dataList[selectedCandidate];
    setLoading(true);
    const fullName = selectCandidateData.name.split(" ");
    const firstName = fullName[0];
    const lastName = fullName.slice(1).join(" "); // In case the last name has multiple parts
    const action = {
      client: client,
      resumeId: selectCandidateData.resumeid,
      addedBy: storeData._id,
      addedOn: new Date(),
      firstName: firstName,
      lastName: lastName,
      experienceMonths: selectCandidateData.MonthsOfExperience, // Convert months to years
      jobTitle: selectCandidateData.JobTitle,
      source: "CareerBuilder",
    };
    dispatch(getResumeAndStoreInternalDbViaApi(action));
  };
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const handleCloseResumeModal = () => {
    setOpenResumeModal(!openResumeModal);
  };
  const handleAxiosLink = async (key) => {
    dispatch(successAwsResumeUrl(""));
    dispatch(successHtmlResumeConvert(""));
    const action = { key: key };
    dispatch(awsResumeUrl(action));
    setOpenResumeModal(!openResumeModal);
  };
  useEffect(() => {
    if (resumesFile && !AwsResumeUrl) {
      const action = {
        key: resumesFile?.[0]?.resumes?.[
          resumesFile[0].resumes.length - 1
        ]?.serverLocation
      };

      dispatch(awsResumeUrl(action));
    }
  }, [resumesFile])
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const [sendMailModal, setSendMailModal] = useState(false);
  const handleToggleSendMailModal = () => {
    setSendMailModal(!sendMailModal);
  };

  /////////////////////////
  const [bulkActionMenu, setBulkActionMenu] = useState(null);
  const open = Boolean(bulkActionMenu);
  const handleClickBulkAction = (event) => {
    setBulkActionMenu(event.currentTarget);
  };
  const handleCloseBulkAction = () => {
    setBulkActionMenu(null);
  };
  const [check, setCheck] = useState('')
  const [list, setList] = useState(false);
  const [grid, setGrid] = useState(true);
  const [isGrid, setIsGrid] = useState(false);
  const gridColumns = [
    {
      field: "keyid",
      headerName: "",
      width: 190,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,



      renderCell: (params) => {
        return (
          <Box>
            <input
              type="checkbox"
            // checked={selectedRows.some(
            //   (r) => r.applicantId === params.row.applicantId
            // )} // Check if the row is selected
            // onChange={(e) => handleCheckboxChange(e, params.row)} // Handle checkbox toggle
            />
            <IconButton size="small">
              <CommentIcon fontSize="inherit" color="warning" />
            </IconButton>
            <IconButton size="small" title="Quick View" onClick={() => handleSelect(params.row.id)}>
              <VisibilityIcon fontSize="inherit" />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "source",
      headerName: "Id",
      width: 170,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { source } = params.row;
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>

            <Avatar
              sx={{
                width: 20,
                height: 20,
              }}
              src={
                source === "Career Builder" || source === "career builder"
                  ? logoCB
                  : source === "Monster" || source === "monster"
                    ? logoMonster
                    : source === "Dice" || source === "dice"
                      ? logoDice
                      : ""
              }
            />
          </Box>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
      renderCell: (params) => {
        const { name } = params.row;
        return (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar
              sx={{
                width: 20,
                height: 20,
                fontSize: 10,
                // bgcolor:
                //   gender === "Male" ? theme.palette.primary.dark : pink[300],
              }}
            >
              {/* {gender?.charAt(0)} */}
            </Avatar>
            {name}
          </Box>
        );
      },
    },


    {
      field: "RecentJobTitle",
      headerName: "Job Title",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { RecentJobTitle } = params.row;
        return (
          <Typography noWrap variant="inherit">
            {RecentJobTitle}
          </Typography>
        );
      },
    },
    {
      field: "MonthsOfExperience",
      headerName: "Experience(M)",
      width: 120,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { MonthsOfExperience } = params.row;
        return (
          <Typography noWrap variant="inherit">

            {MonthsOfExperience !== "" &&
              MonthsOfExperience !== null &&
              `${MonthsOfExperience}`}
          </Typography>
        );
      },
    },
    {
      field: "highestEducation",
      headerName: "Highest Education",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { highestEducation } = params.row;
        return (
          <Typography noWrap variant="inherit">
            {highestEducation !== "" && highestEducation !== null
              ? `${highestEducation}`
              : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { location } = params.row;
        return (
          <Typography noWrap variant="inherit">
            {location.state !== "" && location.state !== null && `${location.state}, `}
            {location.city !== "" && location.city !== null && `${location.city} `}
            {location.zipCode !== "" && location.zipCode !== null && `(${location.zipCode})`}
          </Typography>
        );
      },
    },
    {
      field: "fromSource",
      headerName: "Source",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { source } = params.row;
        return (
          <Typography noWrap variant="inherit">
            {source !== "" && source !== null ? `${source}` : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "lastUpdated",
      headerName: "Last Update",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { lastUpdated } = params.row;
        return (
          <Typography noWrap variant="inherit">
            {lastUpdated !== "" && lastUpdated !== null
              ? `${lastUpdated}`
              : "N/A"}
          </Typography>
        );
      },
    },

  ];
  function extractKeywords(query) {
    // Remove parentheses and split by 'AND' operator
    const andClauses = query.replace(/[()]/g, '').split(/\s+and\s+/i);

    // Extract individual keywords and wildcards
    const keywords = [];
    andClauses.forEach(clause => {
      clause.split(/\s+or\s+/i).forEach(keyword => {
        // Trim and remove any '*' from keywords
        keywords.push(keyword.trim().replace(/\*/g, ''));
      });
    });

    return keywords;
  }


  const extractedKeywords = keyWords ? extractKeywords(keyWords) : [];
  const { highlightedHtml, keywordCounts } = useMemo(() => {
    const content = HtmlResume || '';
    let updatedHtml = content;
    const counts = {};

    extractedKeywords.forEach(keyword => {
      const regex = new RegExp(`(${keyword})`, 'gi');
      counts[keyword] = (content.match(regex) || []).length;

      // Replace keyword with highlighted <span>
      updatedHtml = updatedHtml.replace(regex, `<span style="background-color: yellow;">$1</span>`);
    });

    return { highlightedHtml: updatedHtml, keywordCounts: counts };
  }, [HtmlResume]);
  return (
    <div>

      <Box sx={{ mt: 5 }} />
      <MainBackground>
        <Grid container spacing={4}>
          <Grid item xs={2.5}>
            <Typography>Filter Result</Typography>
            <Box>
              <CustomFormField
                //colunm={true}
                //title={"Gender"}
                //required
                components={[
                  {
                    type: "radio",

                    props: {
                      title: "Select Gender",
                      required: true,
                      defaultValue: "All Results",
                      options: [
                        "All Results",
                        "Resume Results",
                        "Social Results",
                      ],
                    },
                  },
                ]}
              />
              <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                Apply
              </Button>
            </Box>
            <Box
              sx={{ mt: 1, display: "flex", gap: 1, flexDirection: "column" }}
            >
              <CustomFormField
                title={"Exclude"}
                components={[
                  {
                    type: "select",
                    props: {
                      title: "Select",
                      options: ["Option - 1", "Option - 2", "Option - 3"],
                    },
                  },
                ]}
              />
              <Divider />
              <CustomFormField
                title={"Experience"}
                colunm={true}
                components={[
                  {
                    type: "rtText",
                    props: {
                      text: "from",
                    },
                  },
                  {
                    type: "select",
                    props: {
                      title: "Select",
                      options: ["Option - 1", "Option - 2", "Option - 3"],
                    },
                  },
                  {
                    type: "rtText",
                    props: {
                      text: "to",
                    },
                  },
                  {
                    type: "select",
                    props: {
                      title: "Select",
                      options: ["Option - 1", "Option - 2", "Option - 3"],
                    },
                  },
                ]}
              />
              <Divider />
              <CustomFormField
                title={"Last Modified"}
                colunm={true}
                components={[
                  {
                    type: "rtText",
                    props: {
                      text: "from",
                    },
                  },
                  {
                    type: "select",
                    props: {
                      title: "Select",
                      options: ["Option - 1", "Option - 2", "Option - 3"],
                    },
                  },
                  {
                    type: "rtText",
                    props: {
                      text: "to",
                    },
                  },
                  {
                    type: "select",
                    props: {
                      title: "Select",
                      options: ["Option - 1", "Option - 2", "Option - 3"],
                    },
                  },
                ]}
              />
              <Divider />
              <CustomFormField
                title={"Contact Info"}
                components={[
                  {
                    type: "checkbox",
                    props: {
                      title: "Select Gender",
                      required: true,
                      defaultValue: check,
                      onChange: (value) => setCheck(value),
                      options: ["Male", "Female"],
                    },
                  },
                ]}
              />
              <Button fullWidth variant="outlined" sx={{ mt: 1 }}>
                Apply
              </Button>
            </Box>
          </Grid>
          <Grid item xs={9.5}>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
                gap: 0.5,
                color: theme.palette.grey[500],
                mb: 1,
              }}
            >
              <Typography sx={{ fontSize: 30, lineHeight: 1 }}>
                {matched}
              </Typography>
              <Typography>results</Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <CustomFormField
                  components={[
                    {
                      type: "checkbox",
                      props: {
                        title: "Migrated Profile",
                        required: true,
                        defaultValue: "",
                        options: ["Select Migrated Profile"],
                      },
                    },
                  ]}
                />
                <Button
                  variant="text"
                  startIcon={<SwapHorizIcon />}
                  size="small"
                >
                  Migrate Profiles
                </Button>
                <Box ml={1}>
                  <Button
                    size="small"
                    onClick={handleClickBulkAction}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Bulk Actions
                  </Button>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setList(false);
                      setGrid(true);
                      setIsGrid(false);
                    }}
                    color={isGrid ? "default" : "primary"}
                  >
                    <GridViewOutlinedIcon fontSize="inherit" />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setList(true);
                      setGrid(false);
                      setIsGrid(true);
                    }}
                    color={isGrid ? "primary" : "default"}
                  >
                    <FormatListBulletedIcon fontSize="inherit" />
                  </IconButton>
                  <Menu
                    anchorEl={bulkActionMenu}
                    open={Boolean(bulkActionMenu)}
                    onClose={handleCloseBulkAction}
                    onClick={handleCloseBulkAction}
                    hideBackdrop
                  >
                    <MenuItem onClick={handleCloseBulkAction}>
                      Send Email
                    </MenuItem>
                    <MenuItem onClick={handleCloseBulkAction}>
                      Share Job
                    </MenuItem>
                    <MenuItem onClick={handleCloseBulkAction}>
                      Add to Group
                    </MenuItem>
                    <MenuItem onClick={handleCloseBulkAction}>
                      Mail Merge
                    </MenuItem>
                  </Menu>
                </Box>

              </Box>
              <Box>
                <Pagination
                  count={pageSize}
                  page={page} // Set the current page
                  onChange={handlePageChange} // Handle page change
                  size="small"
                />
              </Box>
            </Box>
            <Box sx={{ mt: 1, bgcolor: theme.palette.grey[50], p: 1 }}>
              <Typography>{keyWords}</Typography>
            </Box>
            {grid && (
              <Box
                sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1 }}
              >
                {dataList.map((item, key) => {
                  const itemSelected = selectedCandidate === key;
                  return (
                    <Box
                      key={key}
                      onClick={() => handleSelect(key)}
                      sx={{
                        display: "flex",
                        gap: 2,
                        bgcolor: theme.palette.grey[50],
                        border: `1px solid ${theme.palette.grey[300]}`,
                        borderRadius: 1,
                        p: 2,
                        cursor: "pointer",
                      }}
                    >
                      <Box>
                        <IconButton size="small">
                          {itemSelected ? (
                            <CheckBoxIcon fontSize="inherit" color="primary" />
                          ) : (
                            <CheckBoxOutlineBlankIcon fontSize="inherit" />
                          )}
                        </IconButton>
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Box>
                          <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 12, color: theme.palette.grey[600] }}
                          >
                            {/* {item?.JobTitle} */}
                            <Highlighter
                              highlightClassName="YourHighlightClass"
                              searchWords={extractedKeywords}
                              autoEscape={true}
                              textToHighlight={item?.JobTitle}
                            />
                          </Typography>
                          <Box
                            sx={{
                              fontSize: 12,
                              color: theme.palette.grey[600],
                              display: "flex",
                              alignItems: "center",
                              gap: 0.5,
                            }}
                          >
                            <FmdGoodIcon fontSize="inherit" />
                            <Typography fontSize={"inherit"}>
                              {item.location.city} - {item.location.state} -{" "}
                              {item.location.country}
                            </Typography>
                          </Box>
                        </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Recent Position"}
                              fullContent
                              value={<Highlighter
                                highlightClassName="YourHighlightClass"
                                searchWords={extractedKeywords}
                                autoEscape={true}
                                textToHighlight={item?.RecentJobTitle}
                              />   }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Recent Education"}
                              fullContent
                              value={
                                item?.highestEducation
                                  ? item?.highestEducation
                                  : "n/a"
                              }
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <KeyData
                              title={"Experience"}
                              fullContent
                              value={(item?.MonthsOfExperience / 12).toFixed(1)}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            )}
            {list && (
              <Box>
                <DataGrid
                  rows={dataList}
                  columns={gridColumns}
                  disableRowSelectionOnClick
                  disableColumnMenu
                  disableColumnSorting
                  disableColumnSelector
                  disableColumnFilter
                  disableColumnReorder
                  disableColumnResize
                  disableColumnSort
                  hideFooter
                  initialState={{
                    pagination: {
                      paginationModel: {
                        page: 0,
                        pageSize: 100,
                      },
                    },
                  }}
                  pageSizeOptions={[100]}
                  getRowHeight={() => "auto"}
                  autoHeight
                  sx={{
                    "& .MuiDataGrid-cell": {
                      display: "flex",
                      alignItems: "center !important",
                    },
                    [`& .MuiDataGrid-checkboxInput`]: {
                      color: theme.palette.primary.main,
                      p: 0,

                      "&.Mui-checked": {
                        color: theme.palette.primary.dark,
                      },
                    },
                    //Scroller
                    [`& .MuiDataGrid-scrollbar`]: {
                      scrollbarWidth: "thin",
                      scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                    },
                    [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                      borderRadius: "10px", // Apply border radius
                    },

                    [`& .MuiDataGrid-scrollbar:hover`]: {
                      scrollbarColor: `${theme.palette.primary.dark}`,
                    },
                  }}
                /></Box>
            )}
          </Grid>
        </Grid>
      </MainBackground>

      {/* Drawer */}
      <Drawer open={drawerOpen} anchor="right" onClose={closeDrawer}>


        <Box sx={{
          width: 700, p: 2,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}>
          {/* {loading ? (
            <>
              {" "}
              <ShimmerText line={5} gap={10} />
            </>
          ) : (
            <> */}
              {selectedCandidate !== null && selectedCandidate !== undefined ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography variant="h6">
                        {dataList[selectedCandidate].name}
                      </Typography>
                    </Box>

                    <Box>
                      <ButtonGroup>
                        <IconButton size="small">
                          <Tooltip title="Similar Applicants">
                            <SupervisorAccountIcon sx={{ fontSize: "14px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Open in New Tab">
                            <OpenInNewIcon sx={{ fontSize: "14px" }} />
                          </Tooltip>
                        </IconButton>
                        {resumesFile.length > 0 ? (
                          <IconButton size="small">
                            <Tooltip title="View the Resume">
                              <VisibilityIcon onClick={() =>
                                handleAxiosLink(
                                  resumesFile?.[0]?.resumes?.[
                                    resumesFile[0].resumes.length - 1
                                  ]?.serverLocation
                                )
                              } title="View the resume" />
                            </Tooltip>
                          </IconButton>
                        ) : (
                          <IconButton size="small">
                            <Tooltip title="Mergeto Internal Database">

                              <AddIcon onClick={handleGetResume} />{" "}
                            </Tooltip>
                          </IconButton>
                        )}

                        <IconButton size="small">
                          <Tooltip title="Email" onClick={handleToggleSendMailModal}>
                            <MailOutlineIcon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Add Notes">
                            <NoteAddOutlinedIcon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Share the Profile">
                            <ShortcutIcon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Add to Group">
                            <GroupsIcon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Share Job">
                            <ShareIcon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Tag to Job">
                            <LabelIcon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                        <IconButton size="small">
                          <Tooltip title="Quick Submit to Job">
                            <PersonAddAlt1Icon sx={{ fontSize: "17px" }} />
                          </Tooltip>
                        </IconButton>
                      </ButtonGroup>

                    </Box>
                  </Box>

                  <Box
                    bgcolor={theme.palette.grey[100]}
                    padding={2}
                    marginY={2}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <KeyData
                          title={"Phone Number"}
                          value={dataList[selectedCandidate].phone}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyData
                          title={"Job Title"}
                          value={<Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={extractedKeywords}
                            autoEscape={true}
                            textToHighlight={dataList[selectedCandidate].JobTitle}
                          />  }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyData
                          title={"Location"}
                          value={dataList[selectedCandidate].HomeLocation}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyData
                          title={"Recent Education"}
                          value={dataList[selectedCandidate].highestEducation}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <KeyData
                          title={"Experience"}
                          value={dataList[selectedCandidate].MonthsOfExperience}
                        />
                      </Grid>
                      {/* <Grid item xs={4}>
                    <KeyData
                      title={"Phone Number"}
                      value={selectedCandidate.phone}
                    />
                  </Grid> */}
                    </Grid>
                  </Box>
                  <Box display={"flex"} flexDirection={"column"} flex={1}>
                    <TabContext value={openTabId}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="ob Boards tabs"
                          size="small"
                          variant="scrollable"
                        >
                          <Tab label="Resume" value="1" />
                          <Tab label="Personal Details" value="2" />
                          <Tab label="Job Applied" value="3" />
                          <Tab label="Notes" value="4" />
                          <Tab label="Work Experience" value="5" />
                          <Tab label="Email Merges" value="6" />
                        </TabList>
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <TabPanel value="1"
                          sx={{
                            p: 0,
                            flex: 1,
                          }}>
                          {HtmlResume ? (
                            <>
                           <Box sx={{p:2, display:"flex", gap:1, flexWrap:"wrap", }}>
                      {Object.entries(keywordCounts).map(([keyword, count]) => (
                        <Chip size="small" label={`${keyword} ${count} time(s)`} variant="outlined" />
                      ))}
                      </Box>
                        <div
                          dangerouslySetInnerHTML={{ __html: highlightedHtml }}
                          style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
                        />
                        </>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: 2,
                                  px: 10,
                                }}
                              >
                                <Typography variant="h5" textAlign={"center"}>
                                  By clicking here, credits will be used, and
                                  you will unlock the resume.
                                </Typography>

                                <Button
                                  variant="outlined"
                                  sx={{ textTransform: "inherit" }}
                                  onClick={() => handleGetResume()}
                                >
                                  Get the resume
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0, pt: 5 }}>
                          <Box
                            bgcolor={theme.palette.grey[100]}
                            padding={2}
                            marginY={2}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"FirstName"}
                                  value={resumesFile?.[0]?.firstName}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Middle Name"}
                                  value={resumesFile?.[0]?.middleName}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Last Name"}
                                  value={resumesFile?.[0]?.lastName}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"Gender"}
                                  value={resumesFile?.[0]?.gender}
                                />
                              </Grid>

                              <Grid item xs={4}>
                                {resumesFile?.[0]?.mobileNumbers?.map(
                                  (mob, index) => (
                                    <KeyData
                                      key={index}
                                      title={`${mob.type} Mobile Number`}
                                      value={mob.number || ""}
                                    />
                                  )
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                {resumesFile?.[0]?.emailAddress?.map(
                                  (mob, index) => (
                                    <KeyData
                                      key={index}
                                      title={`${mob.type} Email Address`}
                                      value={mob.email || ""}
                                    />
                                  )
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"State"}
                                  value={resumesFile?.[0]?.state}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"City"}
                                  value={resumesFile?.[0]?.city}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <KeyData
                                  title={"ZipCode"}
                                  value={resumesFile?.[0]?.zipCode}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0, pt: 5 }}>
                          Job Applied
                        </TabPanel>
                        <TabPanel value="4" sx={{ p: 0, pt: 5 }}>
                          Notes
                        </TabPanel>
                        <TabPanel value="5" sx={{ p: 0, pt: 5 }}>
                          Work Experience
                        </TabPanel>
                        <TabPanel value="6" sx={{ p: 0, pt: 5 }}>
                          Email Merges
                        </TabPanel>
                      </Box>
                    </TabContext>
                  </Box>
                </>
              ) : (
                <Typography variant="body1">
                  No details available {selectedCandidate}
                </Typography>
              )}
            {/* </>
          )} */}
        </Box>
      </Drawer>
      <Modal
        open={openResumeModal}
        onClose={handleCloseResumeModal}
        aria-labelledby="resume-modal-title"
        aria-describedby="resume-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          {/* Close Button */}
          <IconButton
            aria-label="close"
            onClick={handleCloseResumeModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* Conditional rendering based on file type */}
          {AwsResumeUrl ? (
            <ResumeViewer AwsResumeUrl={AwsResumeUrl} />
          ) : (
            <Typography variant="h6" textAlign="center">
              No resume available.
            </Typography>
          )}
        </Box>
      </Modal>
      <Modal
        open={sendMailModal}
        onClose={handleToggleSendMailModal}
        aria-labelledby="send email"
        aria-describedby="send email modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <CandidateSendMail close={handleToggleSendMailModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default CareerBuilder;
