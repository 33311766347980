import {
  CLEAR_SEARCH_RESULTS,
  INITIALSTATE_RESUME_DATA,
  SUCCESS_ADVANCED_RESUME_SEARCH,
  SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH,
  SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH,
  SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH,
  SUCCESS_INTEGRATIONS,
  SUCCESS_INTERNAL_DB_SEARCH,
  SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH,
  SUCCESS_SEARCH_RESUME_INTERNAL_DB,
  SUCCESS_SEARCH_RESUMEID_INTERNALDB,
} from "./actionType";

const INIT_STATE = {
  cbResumesList: [],
  resumesFile: [],
  monsterResumesList: [],
  internalResumesList: [],
  internalDBResumes: [],
  integrations: [],
  integrateMonsterResumesList: [],
  integrateCBResumesList: [],
  integrateInternalResumesList: [],
};

const resumes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        cbResumesList: [], // Clear previous results
        monsterResumesList: [],
        internalResumesList: [],
        internalDBResumes: [],
        integrateMonsterResumesList: [],
        integrateCBResumesList: [],
        integrateInternalResumesList: [],
      };

    case SUCCESS_ADVANCED_RESUME_SEARCH:
      return {
        ...state,
        cbResumesList: action.payload,
      };
   
    case SUCCESS_SEARCH_RESUMEID_INTERNALDB:
      return {
        ...state,
        resumesFile: action.payload,
      };
    case SUCCESS_MONSTER_ADVANCED_RESUME_SEARCH:
      return {
        ...state,
        monsterResumesList: action.payload,
      };

    case SUCCESS_SEARCH_RESUME_INTERNAL_DB:
      return {
        ...state,
        internalResumesList: action.payload,
      };
    case SUCCESS_INTERNAL_DB_SEARCH:
      return {
        ...state,
        internalDBResumes: action.payload,
      };
    case SUCCESS_INTEGRATIONS:
      return {
        ...state,
        integrations: action.payload,
      };
    case SUCCESS_INTEGRATE_MONSTER_ADVANCED_RESUMES_SEARCH:
      return {
        ...state,
        integrateMonsterResumesList: action.payload,
      };
      case SUCCESS_INTEGRATE_CB_ADVANCED_RESUMES_SEARCH:
        return {
          ...state,
          integrateCBResumesList: action.payload,
        };
      case SUCCESS_INTEGRATE_INTERNAL_DB_SEARCH:
        return {
          ...state,
          integrateInternalResumesList: action.payload,
        };
    case INITIALSTATE_RESUME_DATA: {
      return INIT_STATE;
    }

    default:
      return state;
  }
};

export default resumes;
