import { SnackbarProvider } from "notistack";
import React, { useState, useEffect } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { CustomFormField, MainBackground, PageHeader, SectionTitle, SnackbarComponent, Widget } from "../../Components";
import { Box, Button, Grid } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  dateSearchRaUsersCompaniesViaApi,
  getShuffledDataViaApi,
} from "../../store/search/action";
import { emailIntegrationViaApi, getAllUsers } from "../../store/auth/login/actions";
import { getSentEmailsViaApi } from "../../store/companiesList/action";
import theme from "../../Theme";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
const BulkEmail = () => {
  const dispatch = useDispatch();

  const AllCompaniesList =
    useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
  const AllSentEmails =
    useSelector((state) => state.CompaniesList.sentEmails) || [];
  const storeData = useSelector((state) => state.Login.storeData) || {};
  const client=storeData.clientDb
  const shuffledDataRedux = useSelector((state) => state.Search.shuffled) || [];
  const [shuffledData, setShuffledData] = useState([]);
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];

useEffect(() => {
  if (shuffledDataRedux.length > 0 && allUsers.length > 0) {
    const latestShuffledData = shuffledDataRedux[0]?.shuffled;
    if (latestShuffledData && latestShuffledData.length > 0) {
      const latestData = latestShuffledData[latestShuffledData.length - 1];
      setShuffledData([latestData]);
    }
  }
}, [shuffledDataRedux, allUsers]);
  const takenAssignedData = shuffledData.flatMap((shufflesData) => {
    return (shufflesData.shuffledData || [])
      .filter((data) => data.BdmId === storeData._id)
      .flatMap((filteredData) => filteredData.assignUsers || []);
  });

  let totalCompanies = 0;
  let totalEmails = 0;
  let sentEmailsCount = 0;

  takenAssignedData.forEach((assignUser) => {
    AllCompaniesList.forEach((company) => {
      if (company.addedBy === assignUser) {
        totalCompanies++;
        (company.contactDetails || []).forEach((contact) => {
          if (contact.emailId) {
            totalEmails++;
          }
        });
      }
    });
  });
  
  

  AllSentEmails.forEach((email) => {
    if (
      email.fromEmail === storeData.userEmailId ||
      email.fromEmail === storeData.userSudoEmailId
    ) {
      sentEmailsCount++;
    }
  });

  useEffect(() => {
    const action={
      client:client
    }
    dispatch(getAllUsers(action));
    
    dispatch(getShuffledDataViaApi(action));
  }, [dispatch]);

  const handleDateChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date) => date.toISOString());

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client:client,
      };
   
      dispatch(dateSearchRaUsersCompaniesViaApi(action));
      dispatch(getSentEmailsViaApi(action));
    }
  };

  useEffect(() => {
    const today = dayjs().tz("Asia/Kolkata").startOf("day");
    handleDateChange([today, today.endOf("day")]);
  }, []);
  const [emails, setEmails] = useState([]);
  const [selectBdmId,setSelectBdmId]=useState('');
  
  const [emailType,setEmailType]=useState('');
  const [errors, setErrors] = useState({});
  const [recruiterEmails,setRecruiterEmails]=useState([])
  const [selectRecruiterId,setSelectRecruiterId]=useState('');
  const [selectEmailType,setSelectEmailType]=useState('')
  const [recruiterErrors,setRecruiterErrors]=useState({})
  const handleSubmit = () => {
    setErrors({});
     // Validation logic
     const newErrors = {};
     const isBdmSelected = selectBdmId !== '';
     const isEmailTypeSelected = emailType !== '';
 
    
    if(emails.length==0){
      newErrors.emails = "Email field is required";
    }

  
     if (!isBdmSelected) {
       newErrors.bdmIds = "Please select a BDM.";
     }
 
     if (!isEmailTypeSelected) {
       newErrors.emailType = "Please select an Email Type.";
     }
 
     // If there are errors, set them and return
     if (Object.keys(newErrors).length > 0) {
       setErrors(newErrors);
       return;
     }
     const action={
      type:emailType,
      emails:emails,
      selectBdmId:selectBdmId,
     }
     dispatch(emailIntegrationViaApi(action))
  };
  const bdmIds = allUsers
    .filter((user) => user.userType.includes('BDM'))
    .map((user) => user._id);
    const recruiterIds=allUsers.filter((user)=>user.userType.includes('RecruitmentTeam')).map((user) => user._id);
    const handleChangeEmailType=(type)=>{
      setEmailType(type)
      setEmails([])
    }
    const handleChangeBdm=(bdmId)=>{
      setSelectBdmId(bdmId)
      setEmails([])
    }
    const handleRecruiterChangeEmailType=(type)=>{
      setSelectEmailType(type)
      setRecruiterEmails([])
    }
    const handleChangeRecruiter=(bdmId)=>{
      setSelectRecruiterId(bdmId)
      setRecruiterEmails([])
    }
    const handleRecruiterSubmit = () => {
      setRecruiterErrors({});
       // Validation logic
       const newErrors = {};
       const isBdmSelected = selectRecruiterId !== '';
       const isEmailTypeSelected = selectEmailType !== '';
   
      
      if(recruiterEmails.length==0){
        newErrors.emails = "Email field is required";
      }
  
    
       if (!isBdmSelected) {
         newErrors.recruiterIds = "Please select a BDM.";
       }
   
       if (!isEmailTypeSelected) {
         newErrors.emailType = "Please select an Email Type.";
       }
   
       // If there are errors, set them and return
       if (Object.keys(newErrors).length > 0) {
        setRecruiterErrors(newErrors);
         return;
       }
       const action={
        type:selectEmailType,
        emails:recruiterEmails,
        selectBdmId:selectRecruiterId,
       }
       dispatch(emailIntegrationViaApi(action))
    };
  return (
    <React.Fragment>
      <SnackbarProvider
        maxSnack={4}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <LayoutSidebarMenu pageTitle={"Bulk Email"}>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Emails assigned today"
                value={totalEmails}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Emails sent today"
                value={sentEmailsCount}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total Companies"
                value={totalCompanies}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <Widget
                title="Total emails delivered"
                value={0}
                icon={<BusinessIcon />}
                color="primary"
              />
            </Grid>
          </Grid>

          <Box>
            <Button
              variant="contained"
              LinkComponent={Link}
              to={"/manualEmail"}
            >
              Manual Email
            </Button>
          </Box>
          {(storeData.userType.includes("SuperAdmin") ||
            storeData.userType.includes("Admin") ||
            storeData.userType.includes("Developer"))&&(
              <>
              <Box sx={{ mt: 5 }}>
              <MainBackground>
          <Box
            sx={{
              p: 1,
              bgcolor: theme.palette.primary[100],
            }}
          >
            <Box sx={{ mb: 3 }}>
              <SectionTitle
                title="ALL BDM Email Interations"
                size="sm"
                color="secondary"
              />
            </Box>
            <Grid container columns={12} rowSpacing={2} columnSpacing={2}>
              <Grid item xs={2.4}>
                <CustomFormField
                  title={"Select BDM"}
                  required
                  components={[
                    {
                      type: "selectUser",
                      props: {
                        title: "Select",
                        options: bdmIds,
                        defaultValue: selectBdmId,
                        onChange: (value) =>handleChangeBdm(value) ,
                        allUsers: allUsers,
                        error: !!errors.bdmIds,
                        errorMessage: errors.bdmIds,
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
              <CustomFormField
                  title={"Email Type"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Gmail", "Outlook"],
                        defaultValue:emailType,
                        onChange:(value)=>handleChangeEmailType(value) ,
                        error: !!errors.emailType,
                        errorMessage: errors.emailType,
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Emails"}
                    required
                    components={[
                      {
                        type: "autoselect",
                        props: {
                          placeholder: "emails",
                          required: true,
                          defaultValue: emails,
                          onChange: (value) => setEmails(value),
                          error: !!errors.emails,
                          errorMessage: errors.emails,
                        },
                      },
                    ]}
                  />
                  note: Please type and press Enter.
                </Grid>
              
                <Grid item xs={2.4} sx={{ alignSelf: "center" }}>

                <Button onClick={handleSubmit} >Submit</Button>
              </Grid>
           </Grid>
           </Box>
           </MainBackground>
              </Box>
              <Box sx={{ mt: 5 }}>
              <MainBackground>
          <Box
            sx={{
              p: 1,
              bgcolor: theme.palette.primary[100],
            }}
          >
            <Box sx={{ mb: 3 }}>
              <SectionTitle
                title="ALL Recruiter Email Interations"
                size="sm"
                color="secondary"
              />
            </Box>
            <Grid container columns={12} rowSpacing={2} columnSpacing={2}>
              <Grid item xs={2.4}>
                <CustomFormField
                  title={"Select Recruiter"}
                  required
                  components={[
                    {
                      type: "selectUser",
                      props: {
                        title: "Select",
                        options: recruiterIds,
                        defaultValue: selectRecruiterId,
                        onChange: (value) =>handleChangeRecruiter(value) ,
                        allUsers: allUsers,
                        error: !!errors.recruiterIds,
                        errorMessage: errors.recruiterIds,
                      },
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
              <CustomFormField
                  title={"Email Type"}
                  required
                  components={[
                    {
                      type: "select",
                      props: {
                        title: "Select",
                        options: ["Gmail", "Outlook"],
                        defaultValue:selectEmailType,
                        onChange:(value)=>handleRecruiterChangeEmailType(value) ,
                        error: !!errors.emailType,
                        errorMessage: errors.emailType,
                      },
                    },
                  ]}
                />
              </Grid>
              
              <Grid item xs={2.4}>
                  <CustomFormField
                    title={"Emails"}
                    required
                    components={[
                      {
                        type: "autoselect",
                        props: {
                          placeholder: "emails",
                          required: true,
                          defaultValue: recruiterEmails,
                          onChange: (value) => setRecruiterEmails(value),
                          error: !!errors.emails,
                          errorMessage: errors.emails,
                        },
                      },
                    ]}
                  />
                  note: Please type and press Enter.
                </Grid>
              
                <Grid item xs={2.4} sx={{ alignSelf: "center" }}>

                <Button onClick={handleRecruiterSubmit} >Submit</Button>
              </Grid>
           </Grid>
           </Box>
           </MainBackground>
              </Box>
              </>
            )}
        
          <SnackbarComponent/>
        </LayoutSidebarMenu>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default BulkEmail;
