import { SnackbarProvider, useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import LayoutSidebarMenu from "./Layout/LayoutSidebarMenu";
import { CopyToClipboardButton, MainBackground, SnackbarComponent } from "../../Components";
import { TextField, Box, Button, Grid, Typography, CircularProgress, Backdrop } from "@mui/material";
import theme from "../../Theme";
import { requestValidateEmail, stopLoadingRedux } from "../../store/actions";
import { useSelector, useDispatch } from "react-redux";

const EmailValidator = () => {
  const dispatch = useDispatch();
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  const [emailInput, setEmailInput] = useState("");
  const [emailArray, setEmailArray] = useState([]); // Store valid emails in this array
  const [totalEmails, setTotalEmails] = useState(0);
  const [uniqueEmails, setUniqueEmails] = useState(0);
  const [loading, setLoading] = useState(false); // Track loading state
  const ValidationResults = useSelector((state) => state.JobBoards?.validationResults || { validEmails: [], failedEmails: [] });

  // Access validEmails and failedEmails directly
  const { validEmails, failedEmails } = ValidationResults;
  // Function to validate emails on blur
  const handleBlur = () => {
    // Split by commas, spaces, or new lines, then filter non-empty items
    const emails = emailInput
      .split(/[\s,]+/)
      .filter((email) => email.trim() !== "");
    // Validate emails and create a unique set
    const validEmails = emails.filter((email) => validateEmail(email));
    const uniqueEmailSet = new Set(validEmails);

    setEmailArray(validEmails); // Store the valid emails in the array
    setTotalEmails(validEmails.length); // Update total count
    setUniqueEmails(uniqueEmailSet.size); // Update unique count
  };

  // Function to dispatch the validation action with email array
  const handleClick = () => {
    if (emailArray.length > 0) {
      setLoading(true); // Set loading to true before dispatch
      dispatch(requestValidateEmail({ emails: emailArray }));
    }
  };

  const validateEmail = (email) => {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const handleClose=()=>''
  return (
    <SnackbarProvider
      maxSnack={4}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <LayoutSidebarMenu pageTitle={"Validate Email"}>
        <MainBackground>
          <Box
            sx={{
              maxWidth: 900,
              width: "100%",
              margin: "0px auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              <TextField
                label="Add Item"
                variant="outlined"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                onBlur={handleBlur} // Trigger validation and update on blur
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <Box>Total Email IDs: {totalEmails}</Box>
                  <Box>Unique Email IDs: {uniqueEmails}</Box>
                </Box>
                <Button variant="contained" size="large" onClick={handleClick}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : "Start Validate"}
                </Button>
              </Box>
            </Box>
          </Box>
        </MainBackground>

        <Box mt={5}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MainBackground>
                <Box sx={{ px: 2 }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Valid Email IDs:
                  </Typography>
                  <Grid container spacing={2}>
                    {(validEmails || []).map((email, index) => (
                      <Grid item xs={6} key={index}>
                        <Box
                          sx={{
                            borderBottom: `1px solid ${theme.palette.grey[300]}`,
                          }}
                        >
                          <Box
                            sx={{
                              py: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography noWrap variant="body2">
                              {email}
                            </Typography>
                            <CopyToClipboardButton text={email} />
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </MainBackground>
            </Grid>
            <Grid item xs={6}>
              <MainBackground>
                <Box sx={{ px: 2 }}>
                  <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    Invalid Email IDs:
                  </Typography>
                  <Grid container spacing={2}>
                    {(failedEmails || []).map((email, index) => (
                      <Grid item xs={6} key={index}>
                        <Box
                          sx={{
                            borderBottom: `1px solid ${theme.palette.grey[300]}`,
                          }}
                        >
                          <Box
                            sx={{
                              py: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography noWrap variant="body2">
                              {email}
                            </Typography>
                            <CopyToClipboardButton text={email} />
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </MainBackground>
            </Grid>
          </Grid>
        </Box>
        <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={loading}
  onClick={handleClose}
>
  <CircularProgress color="inherit" size="3rem" />
</Backdrop>
<SnackbarComponent />
      </LayoutSidebarMenu>
    </SnackbarProvider>
  );
};

export default EmailValidator;