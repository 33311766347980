import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import theme from "../../Theme";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import logoDice from "../../Assets/Images/logo-dice.jpg";
import logoMonster from "../../Assets/Images/logo-monster.jpg";
import logoCB from "../../Assets/Images/logo-cb.jpg";
import FormatDateTime from "../Helper/FormatDateTime";
import { pink } from "@mui/material/colors";
const JobBoardResultTable=({rows,handleCheckboxChange,selectedRows,searchType,handleSelect,handleClickNotes,resumesFile=[]})=>{
    const gridColumnsMonster = [
        {
          field: "keyid",
          headerName: "",
          width: 190,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            return (
              <Box>
                <input
                  type="checkbox"
                  checked={selectedRows.some(
                    (r) => r.ResumeId === params.row.ResumeId
                  )} // Check if the row is selected
                  onChange={(e) => handleCheckboxChange(e, params.row,"Monster")} // Handle checkbox toggle
                />
                {resumesFile.length>0&&resumesFile[0]?.resumeId===params.row.ResumeId&&(
                  <IconButton size="small">
                  <CommentIcon fontSize="inherit" color="warning" onClick={()=>handleClickNotes(resumesFile[0], "Monster",params.row.id)} />
                </IconButton>
                )}
                
                <IconButton
                  size="small"
                  title="Quick View"
                  onClick={() => handleSelect(params.row.id)}
                >
                  <VisibilityIcon fontSize="inherit" />
                </IconButton>
              </Box>
            );
          },
        },
        {
          field: "Name",
          headerName: "Name",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
          renderCell: (params) => {
            const { Name } = params.row;
            return (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: 10,
                    // bgcolor:
                    //   gender === "Male" ? theme.palette.primary.dark : pink[300],
                  }}
                >
                  {/* {gender?.charAt(0)} */}
                </Avatar>
                {Name}
              </Box>
            );
          },
        },
        {
            field: "Location",
            headerName: "Location",
            flex: 1,
            minWidth: 200,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { Location } = params.row;
              return (
                <Typography noWrap variant="inherit">
                  {Location.state !== "" &&
                    Location.state !== null &&
                    `${Location?.state}, `}
                  {Location.city !== "" &&
                    Location.city !== null &&
                    `${Location?.city} `}
                  {Location.zipCode !== "" &&
                    Location.zipCode !== null &&
                    `(${Location?.zipCode})`}
                </Typography>
              );
            },
          },
        {
          field: "JobTitle",
          headerName: "Job Title",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { JobTitle } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {JobTitle}
              </Typography>
            );
          },
        },
        {
            field: "Relocation",
            headerName: "Relocation",
            width: 200,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { Relocation } = params.row;
              return (
                <Typography noWrap variant="inherit">
                  {Relocation}
                </Typography>
              );
            },
          },
          {
            field: "ResumeUpdated",
            headerName: "Last Update",
            width: 150,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { ResumeUpdated } = params.row;
              return (
                <Typography noWrap variant="inherit">
                  {ResumeUpdated !== "" && ResumeUpdated !== null
                    ? `${ResumeUpdated}`
                    : "N/A"}
                </Typography>
              );
            },
          },
        {
            field: "HighestEducation",
            headerName: "Highest Education",
            width: 200,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { HighestEducation } = params.row;
              return (
                <Typography noWrap variant="inherit">
                  {HighestEducation !== "" && HighestEducation !== null
                    ? `${HighestEducation}`
                    : "N/A"}
                </Typography>
              );
            },
          },
          {
            field: "DesiredJobType",
            headerName: "Desired JobType",
            width: 200,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
              const { DesiredJobType } = params.row;
              return (
                <Typography noWrap variant="inherit">
                  {DesiredJobType !== "" && DesiredJobType !== null
                    ? `${DesiredJobType}`
                    : "N/A"}
                </Typography>
              );
            },
          },
        {
          field: "YearsOfExperience",
          headerName: "Experience",
          width: 120,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { ExperienceYears, ExperienceMonths } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {ExperienceYears !== "" &&
                  ExperienceYears !== null &&
                  `${ExperienceYears} -${ExperienceMonths}`}
              </Typography>
            );
          },
        },

        {
          field: "Source",
          headerName: "Source",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { Source } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {Source !== "" && Source !== null ? `${Source}` : "N/A"}
              </Typography>
            );
          },
        },

      ];
      const gridColumnsCareerBuilder = [
        {
          field: "keyid",
          headerName: "",
          width: 190,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    
          renderCell: (params) => {
            return (
              <Box>
                <input
                  type="checkbox"
                  checked={selectedRows.some(
                    (r) => r.ResumeId === params.row.ResumeId
                  )} // Check if the row is selected
                  onChange={(e) => handleCheckboxChange(e, params.row,"CareerBuilder")} // Handle checkbox toggle
                />
                {resumesFile.length>0&&resumesFile[0]?.resumeId===params.row.ResumeId&&(
                  <IconButton size="small">
                  <CommentIcon fontSize="inherit" color="warning" onClick={()=>handleClickNotes(resumesFile[0], "CareerBuilder",params.row.id)}/>
                </IconButton>
                )}
               
                <IconButton
                  size="small"
                  title="Quick View"
                  onClick={() => handleSelect(params.row.id)}
                >
                  <VisibilityIcon fontSize="inherit" />
                </IconButton>
              </Box>
            );
          },
        },
        {
          field: "Name",
          headerName: "Name",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
          renderCell: (params) => {
            const { Name } = params.row;
            return (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: 10,
                    // bgcolor:
                    //   gender === "Male" ? theme.palette.primary.dark : pink[300],
                  }}
                >
                  {/* {gender?.charAt(0)} */}
                </Avatar>
                {Name}
              </Box>
            );
          },
        },
    
        {
          field: "RecentJobTitle",
          headerName: "Job Title",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { RecentJobTitle } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {RecentJobTitle}
              </Typography>
            );
          },
        },
        {
          field: "MonthsOfExperience",
          headerName: "Experience(M)",
          width: 120,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { MonthsOfExperience } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {MonthsOfExperience !== "" &&
                  MonthsOfExperience !== null &&
                  `${MonthsOfExperience}`}
              </Typography>
            );
          },
        },
        {
          field: "highestEducation",
          headerName: "Highest Education",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { highestEducation } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {highestEducation !== "" && highestEducation !== null
                  ? `${highestEducation}`
                  : "N/A"}
              </Typography>
            );
          },
        },
        {
          field: "location",
          headerName: "Location",
          flex: 1,
          minWidth: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { location } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {location.state !== "" &&
                  location.state !== null &&
                  `${location.state}, `}
                {location.city !== "" &&
                  location.city !== null &&
                  `${location.city} `}
                {location.zipCode !== "" &&
                  location.zipCode !== null &&
                  `(${location.zipCode})`}
              </Typography>
            );
          },
        },
        {
          field: "fromSource",
          headerName: "Source",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { source } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {source !== "" && source !== null ? `${source}` : "N/A"}
              </Typography>
            );
          },
        },
        {
          field: "lastUpdated",
          headerName: "Last Update",
          width: 150,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { lastUpdated } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {lastUpdated !== "" && lastUpdated !== null
                  ? `${lastUpdated}`
                  : "N/A"}
              </Typography>
            );
          },
        },
      ];
      const gridColumnsInternalDB = [
        {
          field: "keyid",
          headerName: "",
          width: 190,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
    
          renderCell: (params) => {
            return (
              <Box>
                <input
                  type="checkbox"
                  checked={selectedRows.some(
                    (r) => r.applicantId === params.row.applicantId
                  )} // Check if the row is selected
                  onChange={(e) => handleCheckboxChange(e, params.row,"InternalDB")} // Handle checkbox toggle
                />
                <IconButton size="small">
                  <CommentIcon fontSize="inherit" color="warning" onClick={()=>handleClickNotes(params.row, "InternalDB",'')} />
                </IconButton>
                <IconButton
                  size="small"
                  title="Quick View"
                  onClick={() => handleSelect(params.row.id)}
                >
                  <VisibilityIcon fontSize="inherit" />
                </IconButton>
              </Box>
            );
          },
        },
        {
          field: "applicantId",
          headerName: "Id",
          width: 170,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { applicantId, source } = params.row;
            return (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                {applicantId}
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                  }}
                  src={
                    source === "Career Builder" || source === "career builder"
                      ? logoCB
                      : source === "Mosnter" || source === "monster"
                      ? logoMonster
                      : source === "Dice" || source === "dice"
                      ? logoDice
                      : ""
                  }
                />
              </Box>
            );
          },
        },
        {
          field: "name",
          headerName: "Name",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
          renderCell: (params) => {
            const { firstName, middleName, lastName, gender } = params.row;
            return (
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Avatar
                  sx={{
                    width: 20,
                    height: 20,
                    fontSize: 10,
                    bgcolor:
                      gender === "Male" ? theme.palette.primary.dark : pink[300],
                  }}
                >
                  {gender?.charAt(0)}
                </Avatar>
                {firstName} {middleName}
                {lastName}
              </Box>
            );
          },
        },
        {
          field: "emailAddress",
          headerName: "Email Id",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
          renderCell: (params) => {
            const { emailAddress } = params.row;
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: 1,
                  rowGap: 0.5,
                  alignItems: "center",
                }}
              >
                {emailAddress.map((item, key) => {
                  return (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        columnGap: 0.5,
    
                        alignItems: "center",
                      }}
                    >
                      {item.type === "primary" && (
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            fontSize: 10,
                            textTransform: "uppercase",
                            bgcolor: theme.palette.primary.contrastText,
                            color: theme.palette.primary.dark,
                            border: `1px solid ${theme.palette.grey[700]}`,
                          }}
                        >
                          p
                        </Avatar>
                      )}
                      {item.email}
                    </Box>
                  );
                })}
              </Box>
            );
          },
        },
        {
          field: "mobileNumbers",
          headerName: "Contact Number",
          width: 240,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          //renderHeader: (params) => <FilterHeader params={params} type={"line"} />,
          renderCell: (params) => {
            const { mobileNumbers } = params.row;
            return (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: 1,
                  rowGap: 0.5,
                  alignItems: "center",
                }}
              >
                {mobileNumbers.map((item, key) => {
                  return (
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        columnGap: 0.5,
    
                        alignItems: "center",
                      }}
                    >
                      {item.type === "primary" && (
                        <Avatar
                          sx={{
                            width: 15,
                            height: 15,
                            fontSize: 10,
                            textTransform: "uppercase",
                            bgcolor: theme.palette.primary.contrastText,
                            color: theme.palette.primary.dark,
                            border: `1px solid ${theme.palette.grey[700]}`,
                          }}
                        >
                          p
                        </Avatar>
                      )}
                      {item.number}
                    </Box>
                  );
                })}
              </Box>
            );
          },
        },
        {
          field: "jobTitle",
          headerName: "Job Title",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { jobTitle } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {jobTitle}
              </Typography>
            );
          },
        },
        {
          field: "experience",
          headerName: "Experience",
          width: 120,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { experienceYears, experienceMonths } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {experienceYears !== "" &&
                  experienceYears !== null &&
                  `${experienceYears}.`}
                {experienceMonths !== "" &&
                  experienceMonths !== null &&
                  `${experienceMonths}`}
              </Typography>
            );
          },
        },
        {
          field: "workAuthorization",
          headerName: "Work Authorization",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { workAuthorization } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {workAuthorization !== "" && workAuthorization !== null
                  ? `${workAuthorization}`
                  : "N/A"}
              </Typography>
            );
          },
        },
        {
          field: "location",
          headerName: "Location",
          flex: 1,
          minWidth: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { state, city, zipCode } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {state !== "" && state !== null && `${state}, `}
                {city !== "" && city !== null && `${city} `}
                {zipCode !== "" && zipCode !== null && `(${zipCode})`}
              </Typography>
            );
          },
        },
        {
          field: "fromSource",
          headerName: "Source",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { source } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {source !== "" && source !== null ? `${source}` : "N/A"}
              </Typography>
            );
          },
        },
    
        {
          field: "fromSource",
          headerName: "Source",
          width: 200,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { source } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {source !== "" && source !== null ? `${source}` : "N/A"}
              </Typography>
            );
          },
        },
    
        {
          field: "ctc",
          headerName: "Current CTC",
          width: 300,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { payRateSalary } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {payRateSalary?.currency !== "" && payRateSalary?.currency !== null
                  ? `${payRateSalary?.currency}/`
                  : "N/A"}
                {payRateSalary?.minAmount !== "" &&
                payRateSalary?.minAmount !== null
                  ? `${payRateSalary?.minAmount}/`
                  : "N/A"}
                {payRateSalary?.maxAmount !== "" &&
                payRateSalary?.maxAmount !== null
                  ? `${payRateSalary?.maxAmount}/`
                  : "N/A"}
                {payRateSalary?.paySchedule !== "" &&
                payRateSalary?.paySchedule !== null
                  ? `${payRateSalary?.paySchedule}/`
                  : "N/A"}
                {payRateSalary?.taxTerm !== "" && payRateSalary?.taxTerm !== null
                  ? `${payRateSalary?.taxTerm}`
                  : "N/A"}
              </Typography>
            );
          },
        },
        {
          field: "availability",
          headerName: "Notice Period",
          width: 150,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { availability } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {availability !== "" && availability !== null
                  ? `${availability}`
                  : "N/A"}
              </Typography>
            );
          },
        },
        {
          field: "employer",
          headerName: "Current Employer",
          width: 180,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { availability } = params.row;
            return (
              <Typography noWrap variant="inherit">
                N/A
              </Typography>
            );
          },
        },
        {
          field: "createdby",
          headerName: "Created By",
          width: 180,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { availability } = params.row;
            return (
              <Typography noWrap variant="inherit">
                N/A
              </Typography>
            );
          },
        },
        {
          field: "lastActive",
          headerName: "Last Active",
          width: 280,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { updatedAt } = params.row;
            return (
              <Typography noWrap variant="inherit">
                {FormatDateTime(updatedAt, "full")}
              </Typography>
            );
          },
        },
      ];
    return(
        <>
        <DataGrid
                      rows={
                        rows
                      }
                      columns={
                        searchType === "Monster"
                          ? gridColumnsMonster
                          : searchType === "CareerBuilder"
                          ? gridColumnsCareerBuilder
                          : gridColumnsInternalDB
                      }
                      disableRowSelectionOnClick
                      disableColumnMenu
                      disableColumnSorting
                      disableColumnSelector
                      disableColumnFilter
                      disableColumnReorder
                      disableColumnResize
                      disableColumnSort
                      hideFooter
                      initialState={{
                        pagination: {
                          paginationModel: {
                            page: 0,
                            pageSize: 100,
                          },
                        },
                      }}
                      pageSizeOptions={[100]}
                      getRowHeight={() => "auto"}
                      autoHeight
                      sx={{
                        "& .MuiDataGrid-cell": {
                          display: "flex",
                          alignItems: "center !important",
                        },
                        [`& .MuiDataGrid-checkboxInput`]: {
                          color: theme.palette.primary.main,
                          p: 0,

                          "&.Mui-checked": {
                            color: theme.palette.primary.dark,
                          },
                        },
                        //Scroller
                        [`& .MuiDataGrid-scrollbar`]: {
                          scrollbarWidth: "thin",
                          scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                        },
                        [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                          borderRadius: "10px", // Apply border radius
                        },

                        [`& .MuiDataGrid-scrollbar:hover`]: {
                          scrollbarColor: `${theme.palette.primary.dark}`,
                        },
                      }}
                    />
        </>
    )
}

export default JobBoardResultTable;