import { ADD_JOB_BOARDS_HISTORY_VIA_API, DELETE_JOB_BAORDS_HISTORY_VIA_API, FAILURE_VALIDATE_EMAIL, GET_JOB_BOARDS_HISTORY_VIA_API, GET_JOB_BOARDS_STATUS_HISTORY_VIA_API, REQUEST_VALIDATE_EMAIL, SAVE_JOB_BOARDS_HISTOTY_VIA_API, SUCCESS_JOB_BOARDS_HISTORY, SUCCESS_VALIDATE_EMAIL } from "./actionType"


export const addJobBoardsHistoryViaApi=(action)=>{
    return {
    type:ADD_JOB_BOARDS_HISTORY_VIA_API,
    payload:action
}
}
export const getJobBoardsHistoryViaApi=(action)=>({
    type:GET_JOB_BOARDS_HISTORY_VIA_API,
    payload:action
})
export const successJobboardsHistory=(reponse)=>({
    type:SUCCESS_JOB_BOARDS_HISTORY,
    payload:reponse
})

export const saveJobBoardsHistoryViaApi=(action)=>{
    return{
        type:SAVE_JOB_BOARDS_HISTOTY_VIA_API,
        payload:action
    }
}
export const deleteJobBoardsHistoryViaApi=(action)=>{
    return{
        type:DELETE_JOB_BAORDS_HISTORY_VIA_API,
        payload:action,
    }
}
export const getjobBoardsStatusHistoryViaApi=(action)=>{
    return{
        type:GET_JOB_BOARDS_STATUS_HISTORY_VIA_API,
        payload:action
    }
}
export const requestValidateEmail = (emails) => {
    return{
    type: REQUEST_VALIDATE_EMAIL,
    payload: emails,
    
  }};
  
  export const successValidateEmail = (data) => ({
    type: SUCCESS_VALIDATE_EMAIL,
    payload: data,
  });
  
  export const failureValidateEmail = (error) => ({
    type: FAILURE_VALIDATE_EMAIL,
    payload: error,
  });