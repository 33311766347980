import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import React from 'react'
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
 const JobBoardsQuickSubmitCheckAutharize = ({open,close}) => {
  return (
    <Modal
    open={open}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: "400px",
        width: "80%",
        bgcolor: "background.paper",
        border: "2px solid #f44336", // Red border to indicate danger
        boxShadow: 24,
        p: { xs: 2, md: 3 },
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          columnGap: 1,
          rowGap: 0.5,
          flexWrap: "wrap",
          mb: 3,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReportProblemOutlinedIcon
          sx={{ color: "#f44336", fontSize: 40 }} // Increase icon size to 40px
        />
        <Typography
          fontWeight={500}
          variant="h6"
          sx={{
            color: "#f44336",
            textAlign: "center",
            fontSize: "1.25rem",
          }} // Slightly larger text
        >
          Other recruiter has already tagged or submitted this applicant
        </Typography>
      </Box>

      <Divider />

      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Button
          variant="contained"
          color="error" // Error color for the button
          sx={{
            textTransform: "none",
            fontWeight: 500,
            fontSize: "1rem",
          }} // Increase button text size
          onClick={() => close(false)} // Close modal on click
        >
          Cancel
        </Button>
      </Box>
    </Box>
  </Modal>
  )
}
export default JobBoardsQuickSubmitCheckAutharize;