// import React from "react";

// const Controller = () => {
//   return <div>Controller</div>;
// };

// export default Controller;

import React, { useEffect, useState } from "react";

import {
  ControllerShowData,
  KeyData,
  MainBackground,
  SectionTitle,
  SnackbarComponent,
} from "../../../Components";
import theme from "../../../Theme";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
  IconButton,
  Modal,
  Chip,
  Button,
} from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import LogoLinkedIn from "../../../Assets/Images/logo/linkedIn.svg";
import LogoIndeed from "../../../Assets/Images/logo/indeed.svg";
import LogoGlassdoor from "../../../Assets/Images/logo/glassdoor.svg";
import CloseIcon from "@mui/icons-material/Close"; // Import close icon

import { styled } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  editDeskTopClients,
  getDeskTopClientsViaApi,
  stopLoadingRedux,
} from "../../../store/reports/action";
import { ShimmerTable } from "react-shimmer-effects";

import dayjs from "dayjs";
import { debounce } from "lodash";
import { dateSearchRaUsersCompaniesViaApi } from "../../../store/actions";
dayjs.extend(require("dayjs/plugin/timezone"));
dayjs.extend(require("dayjs/plugin/utc"));
dayjs.tz.setDefault("Asia/Kolkata");
// Create a styled List component
const CustomList = styled(List)(({ theme }) => ({
  margin: 0,
  padding: 0,
  width: "100%",
}));

// Create a styled ListItem component
const CustomListItem = styled(ListItem)(({ theme }) => ({
  margin: 0,
  padding: 4,
  display: "flex",
  flexWrap: "nowrap",
  gap: 5,
}));

// Create a styled ListItem component
const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: `${theme.palette.text[100]}`,
}));

const Controller = () => {
  let DesktopClients = useSelector((state) => state.Reports?.desktopClients);
  const [loading, setLoading] = useState(true);
  const loadingRedux = useSelector((state) => state.Reports.loadingRedux);
  let AllCompaniesList =
    useSelector((state) => state.Search.AllDateSearchComapniesList) || [];
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData) || [];
  const client = storeData.clientDb;
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };
  const handleEditSave = () => {
    if (selectedRow) {
      dispatch(editDeskTopClients(selectedRow));
      setOpen(false);
      setSelectedRow(null);
      setLoading(true);
      // Debounce the API call to avoid making too many requests in quick succession
      const debouncedFetch = debounce(() => {
        dispatch(getDeskTopClientsViaApi());
      }, 2000); // 2000ms delay

      debouncedFetch();
    } else {
      console.log("data is not there ");
    }
  };
  function parseTags(tagString) {
    // Check if the input string is not empty
    if (!tagString) return [];

    // Split the string by commas and trim any whitespace
    return tagString.split(",").map((tag) => tag.trim());
  }
  const displayCountData = (data, type) => {
    let countCompanies = 0;
    let countContact = 0;

    if (data.length > 0) {
      let allUsersData = [];

      // Iterate over each email in the data array
      data.forEach((email) => {
        // Iterate over each user in the allUsers array
        allUsers.forEach((user) => {
          // Check if either userEmailId or userSudoEmailId matches the current email
          if (user.userEmailId === email || user.userSudoEmailId === email) {
            // Push the user's _id to the allUsersData array
            allUsersData.push(user._id);
          }
        });
      });

      // Filter companies based on the _id found in allUsersData
      let AllCompanies = [];

      allUsersData.forEach((id) => {
        AllCompaniesList.forEach((company) => {
          if (company.addedBy === id) {
            if (company.jobDetails && company.jobDetails.length > 0) {
              const jobDescription = company.jobDetails[0].jobDescription || "";
              if (jobDescription.includes(type)) {
                AllCompanies.push(company);
              }
            }
          }
        });
      });

      // Count companies and their contacts
      AllCompanies.forEach((company) => {
        countCompanies++;
        if (company.contactDetails) {
          countContact += company.contactDetails.length;
        }
      });
    }

    // Returning the counts as a string in the format "Companies(Contacts)"
    return `${countCompanies}(${countContact})`;
  };

  const handleShowEmails = (data) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {data.map((value, key) => (
          <Chip
            key={key}
            label={value}
            size="small"
            variant="outlined"
            sx={{ fontSize: "12px", fontWeight: 600 }}
          />
        ))}
      </Box>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 40,
      sortable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "macAddress",
      headerName: "Mac Address",
      width: 150,
    },

    {
      field: "systemInfo",
      headerName: "System Info",
      minWidth: 270,
      //valueGetter: (params) => console.log({ params.os_info.version }),
      renderCell: (params) => (
        <CustomList>
          <CustomListItem>
            CPU Usage : {params.row.systemInfo?.cpu_info?.cpu_usage || "N/A"}
          </CustomListItem>
          <CustomDivider />
          <CustomListItem>
            Memory Percentage :{" "}
            {params.row.systemInfo?.memory_info?.memory_percentage || "N/A"}
          </CustomListItem>
          <CustomDivider />
          <CustomListItem>
            Total Cores :{" "}
            {params.row.systemInfo?.cpu_info?.total_cores || "N/A"}
          </CustomListItem>
        </CustomList>
      ),
    },

    {
      field: "config",
      headerName: "Config",
      minWidth: 350,
      flex: 1,
      renderCell: (params) => {
        const { config } = params.row || {}; // Extract config or set to an empty object
        const glassdoor = config?.glassdoor || {}; // Use optional chaining and fallback to empty object
        const linkedIn = config?.linkedin || {}; // Same for linkedIn
        const indeed = config?.indeed || {}; // Same for indeed

        // Safety checks for tag properties before parsing
        const convertGlassdoorArray = glassdoor.tag
          ? parseTags(glassdoor.tag)
          : [];
        const convertLinkedInArray = linkedIn.tag
          ? parseTags(linkedIn.tag)
          : [];
        const convertIndeedArray = indeed.tag ? parseTags(indeed.tag) : [];

        return (
          <CustomList>
            <CustomListItem>
              <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                <Box display="flex" alignItems="center" whiteSpace={"nowrap"}>
                  <Avatar src={LogoGlassdoor} sx={{ width: 18, height: 18 }} />{" "}
                  Glassdoor :
                </Box>
                <Box flex={1} flexWrap={"nowrap"}>
                  {handleShowEmails(convertGlassdoorArray)}
                </Box>
              </Box>
            </CustomListItem>
            <CustomDivider />
            <CustomListItem>
              <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                <Box display="flex" alignItems="center" whiteSpace={"nowrap"}>
                  <Avatar src={LogoLinkedIn} sx={{ width: 18, height: 18 }} />
                  Linked In :
                </Box>
                <Box flex={1} flexWrap={"nowrap"}>
                  {handleShowEmails(convertLinkedInArray)}
                </Box>
              </Box>
            </CustomListItem>
            <CustomDivider />
            <CustomListItem>
              <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                <Box display="flex" alignItems="center" whiteSpace={"nowrap"}>
                  <Avatar src={LogoIndeed} sx={{ width: 18, height: 18 }} />
                  Indeed :
                </Box>
                <Box flex={1} flexWrap={"nowrap"}>
                  {handleShowEmails(convertIndeedArray)}
                </Box>
              </Box>
            </CustomListItem>
          </CustomList>
        );
      },
    },
    {
      field: "scrapeSetting",
      headerName: "Scrape Status",
      minWidth: 250,
      flex: 1,

      renderCell: (params) => {
        const { scrapeStatus } = params.row || {}; // Extract config or set to an empty object
        const glassdoor = scrapeStatus?.glassdoor?.status || {}; // Use optional chaining and fallback to empty object
        const linkedIn = scrapeStatus?.linkedin?.status || {}; // Same for linkedIn
        const indeed = scrapeStatus?.indeed?.status || {}; // Same for indeed
        // const { glassdoor, linkedIn, indeed } = params.row.config;
        return (
          <CustomList>
            <CustomListItem>
              <Avatar src={LogoGlassdoor} sx={{ width: 18, height: 18 }} />{" "}
              Glassdoor :{" "}
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color:
                    glassdoor === true
                      ? theme.palette.success.dark
                      : theme.palette.error.dark,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                }}
              >
                {glassdoor === true ? "Running" : "Idle"}
              </Typography>
            </CustomListItem>
            <CustomDivider />
            <CustomListItem>
              <Avatar src={LogoLinkedIn} sx={{ width: 18, height: 18 }} />
              Linked In :
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color:
                    linkedIn === true
                      ? theme.palette.success.dark
                      : theme.palette.error.dark,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                }}
              >
                {linkedIn === true ? "Running" : "Idle"}
              </Typography>
            </CustomListItem>
            <CustomDivider />
            <CustomListItem>
              <Avatar src={LogoIndeed} sx={{ width: 18, height: 18 }} />
              Indeed :
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 12,
                  letterSpacing: 0.2,
                  color:
                    indeed === true
                      ? theme.palette.success.dark
                      : theme.palette.error.dark,
                  lineHeight: 1.2,
                  wordBreak: "break-word",
                }}
              >
                {indeed === true ? "Running" : "Idle"}
              </Typography>
            </CustomListItem>
          </CustomList>
        );
      },
    },
    {
      field: "companiesInLastTwoHours",
      headerName: "Companies Today",
      minWidth: 250,
      flex: 1,

      renderCell: (params) => {
        const { config } = params.row || {}; // Extract config or set to an empty object
        const glassdoor = config?.glassdoor || {}; // Use optional chaining and fallback to empty object
        const linkedIn = config?.linkedin || {}; // Same for linkedIn
        const indeed = config?.indeed || {}; // Same for indeed

        // Safety checks for tag properties before parsing
        const convertGlassdoorArray = glassdoor.tag
          ? parseTags(glassdoor.tag)
          : [];
        const convertLinkedInArray = linkedIn.tag
          ? parseTags(linkedIn.tag)
          : [];
        const convertIndeedArray = indeed.tag ? parseTags(indeed.tag) : [];
        return (
          <CustomList>
            <CustomListItem>
              <Avatar src={LogoGlassdoor} sx={{ width: 18, height: 18 }} />{" "}
              Glassdoor :{displayCountData(convertGlassdoorArray, "glassdoor")}
            </CustomListItem>
            <CustomDivider />
            <CustomListItem>
              <Avatar src={LogoLinkedIn} sx={{ width: 18, height: 18 }} />
              Linked In :{displayCountData(convertLinkedInArray, "linkedin")}
            </CustomListItem>
            <CustomDivider />
            <CustomListItem>
              <Avatar src={LogoIndeed} sx={{ width: 18, height: 18 }} />
              Indeed :{displayCountData(convertIndeedArray, "indeed")}
            </CustomListItem>
          </CustomList>
        );
      },
    },
  ];
  const rows = Array.isArray(DesktopClients)
    ? DesktopClients.map((client, index) => {
        return {
          ...client,
          id: index + 1,
        };
      })
    : [];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          pb: 0.5,
          backgroundColor: theme.palette.grey[50],
          borderRadius: 2,
          color: "white",
        }}
      >
        <GridToolbarQuickFilter sx={{ width: "100%" }} />
      </Box>
    );
  }

  const renderDetails = (details) => {
    return Object.entries(details).map(([key, value]) => (
      <Box key={key} sx={{ marginBottom: 2, display: "flex" }}>
        <Typography>{key}:</Typography>
        {typeof value === "object" && value !== null ? (
          renderDetails(value) // Recursively render nested objects
        ) : (
          <Typography variant="body1">{value.toString()}</Typography>
        )}
      </Box>
    ));
  };
  useEffect(() => {
    dispatch(getDeskTopClientsViaApi());
  }, []);
  useEffect(() => {
    if (loadingRedux) {
      dispatch(stopLoadingRedux(false)); // Dispatch action to stop loading in Redux
      setLoading(false);

      // Set a timer to stop loading after 5 seconds
      const timer = setTimeout(() => {
        setLoading(false);
      }, 1000);

      // Cleanup timer on unmount
      return () => clearTimeout(timer);
    }
  }, [loadingRedux, dispatch]);
  const [searchFromDate, setSearchFromDate] = useState("");
  const [startingDate, setStartingDate] = useState(
    dayjs().tz("Asia/Kolkata").startOf("day")
  );

  const [searchToDate, setSearchToDate] = useState("");
  const handleDateChange = (selectedDates) => {
    setSearchFromDate("");
    setSearchToDate("");
    setLoading(true);
    if (selectedDates.length === 2) {
      const formattedDates = selectedDates.map((date, index) => {
        return date.toISOString();
      });

      const [fromDate, toDate] = selectedDates;
      setSearchFromDate(formattedDates[0]);
      setSearchToDate(formattedDates[1]);

      const action = {
        searchFromDate: formattedDates[0],
        searchToDate: formattedDates[1],
        client: client,
      };
      dispatch(dateSearchRaUsersCompaniesViaApi(action));
    }
  };
  useEffect(() => {
    const today = dayjs().tz("Asia/Kolkata").startOf("day");

    handleDateChange([today, today.endOf("day")]);
  }, []);
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} xl={12}>
          <MainBackground>
            {loading ? (
              <>
                {/* <ShimmerTable row={5} col={5} /> */}
                <ShimmerTable row={5} col={5} />
              </>
            ) : (
              <>
                <Box>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnSelector
                    disableColumnFilter
                    disableColumnReorder
                    disableColumnResize
                    disableRowSelectionOnClick
                    disableColumnSort
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 100 },
                      },
                    }}
                    pageSizeOptions={[100]}
                    getRowHeight={() => "auto"}
                    autoHeight
                    onRowClick={handleRowClick} // Add this line
                    slots={{ toolbar: QuickSearchToolbar }}
                    sx={{
                      "& .MuiDataGrid-cell": {
                        display: "flex",
                        alignItems: "center !important",
                      },
                      [`& .MuiDataGrid-checkboxInput`]: {
                        color: theme.palette.primary.main,
                        p: 0,
                        "&.Mui-checked": {
                          color: theme.palette.primary.dark,
                        },
                      },
                      [`& .MuiDataGrid-scrollbar`]: {
                        scrollbarWidth: "thin",
                        scrollbarColor: `${theme.palette.primary.main} ${theme.palette.grey[200]}`,
                      },
                      [`& .MuiDataGrid-scrollbar::-webkit-scrollbar-thumb`]: {
                        borderRadius: "10px",
                      },
                      [`& .MuiDataGrid-scrollbar:hover`]: {
                        scrollbarColor: `${theme.palette.primary.dark}`,
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </MainBackground>
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 1200,
          }}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,

              pr: 0,
              mx: 2,
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                overflow: "auto",
                maxHeight: "70vh",
                pr: 2,
                //bgcolor: theme.palette.grey[100],
                "&::-webkit-scrollbar": {
                  width: "10px", // width of the scrollbar
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.primary[400],
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: 10,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <ControllerShowData
                data={selectedRow}
                setSelectedRow={setSelectedRow}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "end", pt: 2 }}>
              <Button onClick={handleClose} color="error">
                Close
              </Button>
              <Button onClick={() => handleEditSave()}>save</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Controller;
