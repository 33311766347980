import { Box } from "@mui/material";
import React, { useState } from "react";
import { Footer, Header, SideBar } from "../../../Components";
import theme from "../../../Theme";
const LayoutSettingSidebarMenu = ({ children, pageTitle }) => {
  const [sidebarOpenClose, setSidebarOpenClose] = useState(false);
  const toggleSidebarOpenClose = () => {
    setSidebarOpenClose(!sidebarOpenClose);
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          background:
            "linear-gradient(135deg, rgba(226,231,236,1) 0%, rgba(222,232,251,1) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100vh",
            overflowY: "hidden",
            background:
              "linear-gradient(135deg, rgba(255,255,255,.8) 0%, rgba(255,255,255,.8) 100%)",
          }}
        >
          {/* <Box
            sx={{
              width: sidebarOpenClose ? 250 : 110,
              minWidth: sidebarOpenClose ? 250 : 110,

              //backgroundColor: theme.palette.primary.dark,
              //background: `linear-gradient(180deg, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
              color: theme.palette.primary.dark,
              background: "#fff",
              transition: "width 1000ms, min-width 1000ms", // Transition duration set to 1000 milliseconds for both width and min-width
            }}
          >
            <SideBar sidebarStatus={sidebarOpenClose} />
          </Box> */}

          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflowX: "hidden",
            }}
          >
            <Box>
              <Header
                sidebarToggle={toggleSidebarOpenClose}
                pageTitle={pageTitle}
              />
            </Box>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                flex: "1",
                overflowY: "auto",
                overflowX: "hidden",

                //bgcolor: theme.palette.grey[100],
                "&::-webkit-scrollbar": {
                  width: "10px", // width of the scrollbar
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: theme.palette.primary[400],
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: 10,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              <Box sx={{ height: "100%" }}>{children}</Box>
            </Box>
            <Box>
              <Footer />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default LayoutSettingSidebarMenu;
