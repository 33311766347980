import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { styled } from "@mui/material/styles";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CustomFormField from "../Common/CustomFormField";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SectionTitle from "../Helper/SectionTitle";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { emailsSendToApplicantsViaApi } from "../../store/resumes/action";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CandidatesMailMerge = ({
  close,
  applicantsList,
  jobId,
  optionsTemplate,
  templateSubjectType,
  setTemplateSubjectType,
  templateBodyType,
  setTemplateBodyType,
  template,
  setTemplate,
  templateId,
}) => {
  const dispatch = useDispatch();
  const toEmails = [
    ...new Set(
      applicantsList.flatMap((applicant) =>
        applicant.emailAddress
          .filter((emailObj) => emailObj.email && emailObj.email.trim() !== "")
          .map((emailObj) => emailObj.email)
      )
    ),
  ];

  const templatesData = useSelector((state) => state.Search.templates) || [];
  const allUsersData = useSelector((state) => state.Login.allUsers);
  const allUsers = Array.isArray(allUsersData) ? allUsersData : [];
  const storeData = useSelector((state) => state.Login.storeData);
  const client = storeData.clientDb;
  const [files, setFiles] = useState([]);
  function filterUsersByEmailType(users) {
    const filteredUsers = {};

    users.forEach((user) => {
      if (user.emailsIntegration) {
        user.emailsIntegration.forEach((integration) => {
          // Get the integration type dynamically
          const type = integration.type;

          // Initialize the array for this type if it doesn't exist
          if (!filteredUsers[type]) {
            filteredUsers[type] = [];
          }

          // Push the emails into the corresponding type array
          filteredUsers[type].push(...integration.emails);
        });
      }
    });

    return filteredUsers;
  }

  const result = filterUsersByEmailType(allUsers);
  function getUniqueEmailTypes(users) {
    const emailTypes = new Set(); // Using Set to ensure uniqueness

    users.forEach((user) => {
      if (user.emailsIntegration) {
        user.emailsIntegration.forEach((integration) => {
          emailTypes.add(integration.type); // Add the type to the Set
        });
      }
    });

    return Array.from(emailTypes); // Convert Set back to array
  }

  // Call the function to get unique email types
  const optionsEmailSource = getUniqueEmailTypes(allUsers);

  const [emailSource, setEmailSource] = useState("");
  const [emailAccount, setEmailAccount] = useState("");
  const [optionsEmailAccount, setOptionsEmailAccount] = useState([]);

  const handleChangeEmailSource = (value) => {
    setEmailSource(value);
    const emailsForTitle = result[value];
    setOptionsEmailAccount(emailsForTitle);
    if (
      storeData.userType.includes("BDM") ||
      storeData.userType.includes("RecruitmentTeam")
    ) {
      const selectedIntegration = storeData.emailsIntegration.find(
        (integration) => integration.type === value
      )?.emails;
      setOptionsEmailAccount(selectedIntegration || []);
    }
  };
  const handleChangeEmailAccount = (value) => {
    setEmailAccount(value);
  };

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  // Handle file removal
  const handleFileRemove = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const buttons = ["${firstName}", "${lastName}"];

  const quillRef = useRef(null); // Initialize quillRef

  const insertCustomText = (quillRef, value) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      quill.insertText(range.index, value);
    }
  };
  const handleChangeSalutation = (value) => {
    insertCustomText(quillRef, value);
  };
  const uniqueEmails = new Set();

  const resultData = applicantsList
    .map((applicant) => {
      const primaryEmail = applicant.emailAddress.find(
        (emailObj) => emailObj.email && emailObj.email.trim() !== ""
      );

      // Use middleName if lastName is empty
      const lastName =
        applicant.lastName && applicant.lastName.trim() !== ""
          ? applicant.lastName
          : applicant.middleName;

      // Only add to the result if the email is unique
      if (primaryEmail && !uniqueEmails.has(primaryEmail.email)) {
        uniqueEmails.add(primaryEmail.email);
        return {
          applicantId: applicant._id,
          firstName: applicant.firstName,
          lastName: lastName,
          jobTitle: applicant.jobTitle,
          email: primaryEmail.email,
          jobLocation: `${applicant.state},${applicant.city},${applicant.zipCode}`,
        };
      }
      return null; // Return null for duplicates
    })
    .filter((applicant) => applicant !== null); // Filter out the null entries

  let toastShown = false;

  const handleSendEmail = () => {
    if (
      templateSubjectType === "" ||
      templateBodyType === "" ||
      emailSource == "" ||
      emailAccount === "" ||
      !resultData || // Check if resultData is null or undefined
      !Array.isArray(resultData) || // Check if resultData is not an array
      resultData.length < 0
    ) {
      enqueueSnackbar(`Please fill all details `, {
        variant: "error",
        action: (key) => (
          <IconButton onClick={() => closeSnackbar()} color="inherit">
            <CloseIcon />
          </IconButton>
        ),
      });
      toastShown = true;
      return; // Prevent further execution of the function
    }
    const action = {
      fromEmail: emailAccount,
      // fromEmail: 'andrew@tech5llc.com',
      emailSource: emailSource,
      // emailSource: 'Outlook',
      templateName: template,
      subject: templateSubjectType,
      body: templateBodyType,
      userId: storeData._id,
      userEmail: storeData.userEmailId,
      templateId,
      client: client,
      applicantData: resultData,
      attachments: files,
      jobId: jobId,
    };
    const formData = new FormData();
    formData.append("fromEmail", emailAccount);
    formData.append("emailSource", emailSource);
    formData.append("templateName", template);
    formData.append("subject", templateSubjectType);
    formData.append("body", templateBodyType);
    formData.append("userId", storeData._id);
    formData.append("userEmail", storeData.userEmailId);
    formData.append("templateId", templateId);
    formData.append("client", client);
    formData.append("jobId", jobId);
    if (files && files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file); // Assuming 'file' is a File object or Blob
      });
    }
    // Handling arrays
    formData.append("applicantData", JSON.stringify(resultData));
    for (const [key, value] of formData.entries()) {
      console.log(key, value);
    }
    dispatch(emailsSendToApplicantsViaApi(formData));
    close();
  };
  return (
    <Box>
      <Grid container spacing={5}>
        <Grid item xs={8.5}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle title="Send Mail" />
            </Grid>
            <Grid item xs={1.5}>
              <CustomFormField
                title={"Mail Type"}
                required
                components={[
                  {
                    type: "select",

                    props: {
                      title: "Select",
                      required: true,
                      defaultValue: emailSource,
                      options: optionsEmailSource,
                      onChange: (value) => handleChangeEmailSource(value),
                    },
                  },
                ]}
              />
            </Grid>

            <Grid item xs={4}>
              <CustomFormField
                title={"From"}
                required
                components={[
                  {
                    type: "select",
                    props: {
                      title: "From mail id",
                      required: true,
                      placeholder: "From",
                      defaultValue: emailAccount,
                      options: optionsEmailAccount,
                      onChange: (value) => handleChangeEmailAccount(value),
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomFormField
                title={"Mail Subject"}
                required
                components={[
                  {
                    type: "text",
                    props: {
                      required: true,
                      placeholder: "Mail Subject",
                      defaultValue: templateSubjectType,
                      onChange: (value) => setTemplateSubjectType(value),
                    },
                  },
                ]}
              />
            </Grid>

            <Grid item xs={2.5} alignSelf={"flex-end"}>
              <Box
                sx={{ display: "flex", justifyContent: "end", gap: 2, mb: 0.4 }}
              >
                <Button color="error" onClick={close} size="small">
                  Cancel
                </Button>
                {/* <Button variant="contained" size="small" > */}
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleSendEmail}
                >
                  Send
                </Button>
              </Box>
            </Grid>
        <Grid item xs={12}>
              Number of Recipients: {toEmails.length}
        </Grid>
            <Grid item xs={12}>
              List of email ids:
              <Box>
                {toEmails.slice(0, 3).join(", ")}
                {toEmails.length > 3 && " ..."}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                placeholder={"placeholder"}
                value={templateBodyType}
                onChange={setTemplateBodyType}
                modules={{
                  toolbar: {
                    container: [
                      [{ font: [] }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                      [{ script: "sub" }, { script: "super" }], // superscript/subscript
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                      [{ direction: "rtl" }], // text direction
                      [{ align: [] }], // text align
                      ["blockquote", "code-block"],
                      //["link", "image", "video"], // insert options
                      ["link"], // insert options
                      ["clean"], // remove formatting button
                    ],
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                size="small"
                startIcon={<CloudUploadIcon />}
              >
                Upload files
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileUpload}
                  multiple
                />
              </Button>
              <List
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  columnGap: 2,
                  flexWrap: "wrap",
                }}
              >
                {files.length > 0 && (
                  <ListItem sx={{ p: 0, width: "auto" }}>
                    {`Total files: ${files.length}`}
                  </ListItem>
                )}
                {files.map((file, index) => (
                  <ListItem key={index} sx={{ p: 0, width: "auto" }}>
                    {/* Delete icon before the filename */}
                    <ListItemIcon sx={{ minWidth: "auto" }}>
                      <IconButton
                        color="error"
                        size="small"
                        edge="start"
                        aria-label="delete"
                        onClick={() => handleFileRemove(file)}
                        sx={{ fontSize: 14 }}
                      >
                        <DeleteOutlinedIcon fontSize="small" />
                      </IconButton>
                    </ListItemIcon>

                    {/* Filename with custom font size */}
                    <ListItemText
                      primary={file.name}
                      primaryTypographyProps={{ sx: { fontSize: 12 } }} // Manage font size here
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3.5}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SectionTitle title="Customize Mail Body" />
              </Grid>
              <Grid item xs={12}>
                <CustomFormField
                  title={"Salutation"}
                  required
                  components={[
                    {
                      type: "selectRef",
                      props: {
                        required: true,
                        placeholder: "Select",

                        options: buttons,
                        onChange: (value) => handleChangeSalutation(value),
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CandidatesMailMerge;
